import { either } from 'fp-ts';
import { all, call, cancel, debounce, put, select, take, takeEvery, takeLeading } from 'typed-redux-saga';
import { isUserAuthorized } from '../../authorization/useAuthorization';
import { NotificationAction } from '../../store/notification/NotificationAction';
import { PERMISSION, SecurityState } from '../../store/security/SecurityState';
import { Notification } from '../../utils/notification';
import { $PaperworkProcess } from './PaperworkProcessState';
import { allowedFileTypes } from './utils/AllowedFileTypes';
function* fetchChannels(paperworkService) {
    yield* put($PaperworkProcess.ChannelsRequested());
    const channelsEither = yield* call(paperworkService.getChannels());
    if (either.isLeft(channelsEither)) {
        yield* put($PaperworkProcess.ChannelsNotFetched());
        return new Error('channels not fetched');
    }
    yield* put($PaperworkProcess.ChannelsFetched({ channels: channelsEither.right }));
    return channelsEither.right;
}
function* fetchInstitutes(paperworkService, payload) {
    yield* put($PaperworkProcess.InstitutesRequested());
    const institutesEither = yield* call(paperworkService.getInstitutes({
        productTypology: payload.productTypology,
        channel: payload.channel,
        paperworkId: payload.paperworkId,
    }));
    if (either.isLeft(institutesEither)) {
        yield* put($PaperworkProcess.InstitutesNotFetched());
        return new Error('institutes not fetched');
    }
    yield* put($PaperworkProcess.InstitutesFetched({ institutes: institutesEither.right }));
    return institutesEither.right;
}
function* fetchProducts(paperworkService, payload) {
    yield* put($PaperworkProcess.ProductsRequested());
    const productsEither = yield* call(paperworkService.getProducts({
        institute: payload.institute,
        productTypology: payload.productTypology,
        channel: payload.channel,
        paperworkId: payload.paperworkId,
    }));
    if (either.isLeft(productsEither)) {
        yield* put($PaperworkProcess.ProductsNotFetched());
        return new Error('Products not fetched');
    }
    yield* put($PaperworkProcess.ProductsFetched({ products: productsEither.right }));
    return productsEither.right;
}
function* fetchStores(paperworkService, search) {
    yield* put($PaperworkProcess.StoresRequested());
    const storesEither = yield* call(paperworkService.getStores(search));
    if (either.isLeft(storesEither)) {
        yield* put($PaperworkProcess.StoresNotFetched());
        return;
    }
    yield* put($PaperworkProcess.StoresFetched({ stores: storesEither.right }));
}
function* fetchLocation(paperworkService, search) {
    yield* put($PaperworkProcess.LocationRequested());
    const locationEither = yield* call(paperworkService.getLocalities({ search }));
    if (either.isLeft(locationEither)) {
        yield* put($PaperworkProcess.LocationNotFetched());
        return;
    }
    yield* put($PaperworkProcess.LocationFetched({ participantPlaceOfResidence: locationEither.right }));
}
function* updatePaperwork(paperworkService, formData, paperworkId, paperworkData) {
    yield* put($PaperworkProcess.PaperworkUpdateRequested());
    const userPermissionList = yield* select(SecurityState.selector.permissionList);
    const canViewChannel = isUserAuthorized(userPermissionList)(PERMISSION.CAN_VIEW_CHANNEL_FIELD);
    const paperworkEither = yield* call(paperworkService.updatePaperwork({
        formData: formData,
        paperworkId: paperworkId,
        paperworkData: paperworkData,
    }));
    if (either.isLeft(paperworkEither)) {
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore')));
        yield* put($PaperworkProcess.PaperworkNotUpdated());
        return;
    }
    yield* put(NotificationAction.Push(Notification.info('Dati salvati correttamente')));
    yield* put($PaperworkProcess.PaperworkUpdated({ paperwork: paperworkEither.right, permissions: { canViewChannel } }));
}
function* fetchPaperwork(paperworkService, payload) {
    yield* put($PaperworkProcess.PaperworkRequested());
    const userPermissionList = yield* select(SecurityState.selector.permissionList);
    const canViewChannel = isUserAuthorized(userPermissionList)(PERMISSION.CAN_VIEW_CHANNEL_FIELD);
    const paperworkEither = yield* call(paperworkService.getPaperwork({ paperworkId: payload.paperworkId }));
    if (either.isLeft(paperworkEither)) {
        yield* put($PaperworkProcess.PaperworkNotFetched());
        return new Error('paperwork not fetched');
    }
    yield* put($PaperworkProcess.PaperworkFetched({ paperwork: paperworkEither.right, permissions: { canViewChannel } }));
    return paperworkEither.right;
}
function* fillForm(paperworkService, paperwork) {
    const userPermissionList = yield* select(SecurityState.selector.permissionList);
    const canViewChannel = isUserAuthorized(userPermissionList)(PERMISSION.CAN_VIEW_CHANNEL_FIELD);
    yield* put($PaperworkProcess.FormFillRequested());
    const { channels, institutes, products } = yield* all(Object.assign(Object.assign(Object.assign({}, (canViewChannel && { channels: call(fetchChannels, paperworkService) })), (paperwork.data.rubinetto !== '' &&
        paperwork.data.cessione_del_quinto_finanziaria !== '' && {
        institutes: call(fetchInstitutes, paperworkService, {
            channel: paperwork.data.classe_rubinetto,
            productTypology: paperwork.details.productTypology,
            paperworkId: paperwork.details.id,
        }),
    })), (paperwork.data.rubinetto !== '' &&
        paperwork.data.cessione_del_quinto_finanziaria &&
        paperwork.data.cessione_del_quinto_finanziaria !== '' &&
        paperwork.data.istituto &&
        paperwork.data.istituto !== '' && {
        products: call(fetchProducts, paperworkService, {
            channel: paperwork.data.classe_rubinetto,
            productTypology: paperwork.details.productTypology,
            paperworkId: paperwork.details.id,
            institute: paperwork.data.istituto,
        }),
    })));
    if (channels instanceof Error || institutes instanceof Error || products instanceof Error) {
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore')));
        yield* put($PaperworkProcess.FormNotFilled());
        return;
    }
    yield* put($PaperworkProcess.FormFilled({ paperwork: paperwork }));
}
function* fetchCommissions(paperworkService, paperworkId) {
    yield* put($PaperworkProcess.CommissionsRequested());
    const commissionsEither = yield* call(paperworkService.getCommissions({ paperworkId: paperworkId }));
    if (either.isLeft(commissionsEither)) {
        yield* put($PaperworkProcess.CommissionsNotFetched({ error: commissionsEither.left }));
        return;
    }
    yield* put($PaperworkProcess.CommissionsFetched({ commissions: commissionsEither.right }));
}
function* processPaperwork(paperworkService, paperworkId, nextActionValue, onSuccess) {
    yield* put($PaperworkProcess.ProcessPaperworkRequested());
    const transitEither = yield* call(paperworkService.processPaperwork({ paperworkId: paperworkId, action: nextActionValue }));
    if (either.isLeft(transitEither)) {
        yield* put($PaperworkProcess.PaperworkNotProcessed());
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore')));
        return;
    }
    yield* put(NotificationAction.Push(Notification.info('Pratica modificata con successo')));
    if (onSuccess) {
        yield* call(onSuccess);
    }
    yield* put($PaperworkProcess.PaperworkProcessed({ paperwork: transitEither.right }));
}
function* signPaperworkWithDigitalSignature(paperworkService, paperworkId) {
    yield* put($PaperworkProcess.DigitalSignatureRequested());
    const signPaperworkEither = yield* call(paperworkService.processPaperwork({ paperworkId: paperworkId, action: 'requestDigitalSign' }));
    if (either.isLeft(signPaperworkEither)) {
        yield* put($PaperworkProcess.DigitalSignatureRequestFailed());
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore')));
        return;
    }
    yield* put($PaperworkProcess.DigitalSignatureRequestDone({ paperwork: signPaperworkEither.right }));
}
function* cancelDigitalSignature(paperworkService, paperworkId) {
    yield* put($PaperworkProcess.DigitalSignatureCancellationRequested());
    const cancelPaperworkEither = yield* call(paperworkService.cancelPaperworkSignature({ paperworkId: paperworkId }));
    if (either.isLeft(cancelPaperworkEither)) {
        yield* put($PaperworkProcess.DigitalSignatureNotCancelled());
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore')));
        return;
    }
    yield* put($PaperworkProcess.DigitalSignatureCancelled());
}
function* uploadFiles(paperworkService, fileList, documentTypeId, paperoworkId) {
    yield* all(fileList.map(function* (file) {
        yield* put($PaperworkProcess.FileUploadRequested({ file: file, documentTypeId: documentTypeId }));
        if (!allowedFileTypes.isAValidFileType(file)) {
            yield* put($PaperworkProcess.FileTypeNotSupported({ file: file, documentTypeId: documentTypeId }));
            return;
        }
        const formData = new FormData();
        formData.append('documentTypeId', documentTypeId);
        formData.append('document', file);
        const uploadFileEither = yield* call(paperworkService.uploadFile({ formData: formData, paperworkId: paperoworkId }));
        if (either.isLeft(uploadFileEither)) {
            yield* put($PaperworkProcess.FileNotUploaded({ file: file, documentTypeId: documentTypeId }));
            return;
        }
        yield* put($PaperworkProcess.FileUploaded({
            documentation: uploadFileEither.right,
            documentTypeId: documentTypeId,
        }));
    }));
}
function* fetchSigners(paperworkService, paperworkId) {
    yield* put($PaperworkProcess.SignersRequested());
    const signersEither = yield* call(paperworkService.getSigners({ paperworkId: paperworkId }));
    if (either.isLeft(signersEither)) {
        yield* put($PaperworkProcess.SignersNotFetched());
        return;
    }
    yield* put($PaperworkProcess.SignersFetched({ signers: signersEither.right }));
}
export function* PaperworkProcessSaga(paperworkService) {
    yield* takeLeading($PaperworkProcess.start, function* (startCommand) {
        const paperworkId = startCommand.payload.paperworkId;
        if (!paperworkId) {
            return;
        }
        yield* put($PaperworkProcess.Started());
        const task = yield* all([
            yield* takeLeading($PaperworkProcess.processPaperwork, function* (command) {
                yield* call(processPaperwork, paperworkService, paperworkId, command.payload.nextActionValue, command.payload.onSuccess);
            }),
            yield* takeLeading($PaperworkProcess.fetchInstitutes, function* (fetchInstitutesCommand) {
                yield* call(fetchInstitutes, paperworkService, {
                    channel: fetchInstitutesCommand.payload.channel,
                    productTypology: fetchInstitutesCommand.payload.productTypology,
                    paperworkId: fetchInstitutesCommand.payload.paperworkId,
                });
            }),
            yield* takeLeading($PaperworkProcess.fetchProducts, function* (fetchProductsCommand) {
                yield* call(fetchProducts, paperworkService, {
                    channel: fetchProductsCommand.payload.channel,
                    paperworkId: fetchProductsCommand.payload.paperworkId,
                    institute: fetchProductsCommand.payload.institute,
                    productTypology: fetchProductsCommand.payload.productTypology,
                });
            }),
            yield* takeLeading($PaperworkProcess.fillForm, function* (fillFormCommand) {
                yield* call(fillForm, paperworkService, fillFormCommand.payload.paperwork);
            }),
            yield* takeLeading($PaperworkProcess.fetchChannels, function* () {
                yield* call(fetchChannels, paperworkService);
            }),
            yield* takeLeading($PaperworkProcess.updatePaperwork, function* (updatePaperworkCommand) {
                yield* call(updatePaperwork, paperworkService, updatePaperworkCommand.payload.formData, paperworkId, updatePaperworkCommand.payload.paperworkData);
            }),
            yield* debounce(1000, $PaperworkProcess.fetchStores, function* (fetchStoreCommand) {
                yield* call(fetchStores, paperworkService, fetchStoreCommand.payload.search);
            }),
            yield* debounce(1000, $PaperworkProcess.fetchLocation, function* (fetchLocationCommand) {
                yield* call(fetchLocation, paperworkService, fetchLocationCommand.payload.search);
            }),
            yield* takeLeading($PaperworkProcess.signPaperworkWithDigitalSignature, function* () {
                yield* call(signPaperworkWithDigitalSignature, paperworkService, paperworkId);
            }),
            // yield* takeLeading($PaperworkProcess.cancelDigitalSignature, function* () {
            //     yield* call(cancelDigitalSignature, paperworkService, paperworkId)
            // }),
            yield* takeLeading($PaperworkProcess.fetchSigners, function* () {
                yield* call(fetchSigners, paperworkService, paperworkId);
            }),
            yield* takeEvery($PaperworkProcess.uploadFiles, function* (uploadFilesCommand) {
                yield* call(uploadFiles, paperworkService, uploadFilesCommand.payload.fileList, uploadFilesCommand.payload.documentTypeId, paperworkId);
            }),
        ]);
        const paperwork = yield* call(fetchPaperwork, paperworkService, {
            paperworkId,
        });
        if (!(paperwork instanceof Error)) {
            yield all([call(fetchCommissions, paperworkService, paperwork.details.id)]);
        }
        yield* take($PaperworkProcess.stop);
        yield* cancel(task);
        yield* put($PaperworkProcess.Stopped());
    });
}
