const ALLOWED_FILE_TYPES = [
    'text/csv',
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/bmp', // .bmp
    'application/rtf', // .rtf
    'application/vnd.ms-excel', // .xls
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xslx
    'text/plain', // .txt
    'application/vnd.oasis.opendocument.spreadsheet', // .ods
    'application/msword', // .doc
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
    'application/vnd.oasis.opendocument.text', // .odt
];
const isAValidFileType = (file) => ALLOWED_FILE_TYPES.includes(file.type);
export const allowedFileTypes = {
    isAValidFileType,
};
