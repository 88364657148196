import { either } from 'fp-ts';
import { all, call, put, takeLatest } from 'typed-redux-saga';
import { deleteInvoice } from '../../web/deleteInvoice';
import { $InvoiceDeletion } from './InvoiceDeletionState';
function* deleteAttempt(deleteAttemptAction) {
    yield* put($InvoiceDeletion.InvoiceDeleteRequested(deleteAttemptAction.payload));
    const invoiceDeletedIdEither = yield* call(deleteInvoice(deleteAttemptAction.payload));
    if (either.isLeft(invoiceDeletedIdEither)) {
        yield* put($InvoiceDeletion.InvoiceNotDeleted(deleteAttemptAction.payload));
    }
    else {
        yield* put($InvoiceDeletion.InvoiceDeleted(deleteAttemptAction.payload));
    }
}
function* invoiceDeletion() {
    yield* takeLatest($InvoiceDeletion.deleteInvoice, deleteAttempt);
}
export function* InvoiceDeletionSaga() {
    yield* all([takeLatest($InvoiceDeletion.startInvoiceDeletionCommand, invoiceDeletion)]);
}
