import { array, option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { Action } from '../../lib/redux/Action';
import { Reducer } from '../../lib/redux/Reducer';
import { NotificationAction } from './NotificationAction';
const reducer = Reducer(Action.is.any(NotificationAction.Push, NotificationAction.Pop))([])({
    [NotificationAction.Push.type]: (state, message) => [...state, message],
    [NotificationAction.Pop.type]: state => state.slice(1),
});
const notification = (state) => pipe(state, array.head, option.getOrElseW(() => null));
export const NotificationState = {
    reducer: reducer,
    selector: {
        notification,
    },
};
