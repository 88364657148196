import { createSlice } from '@reduxjs/toolkit';
import { constant, identity } from 'fp-ts/function';
import { tablePaginationStateMapper } from './mappers/tablePaginationMapper';
const PER_PAGE = 10;
const initialStateTablePagination = {
    perPage: PER_PAGE,
    skip: 0,
    currentPage: 0,
    isNextPageDisabled: false,
    isPreviousPageDisabled: false,
};
const filterInitialState = { firstName: '', lastName: '' };
export const initialState = {
    loading: { leads: false, quotes: {} },
    leadsTable: [],
    quoteRequestByLeadTable: {},
    tablePagination: initialStateTablePagination,
    appliedFilters: filterInitialState,
    leadSections: {},
};
const toggleLeadSection = (leadSections, leadId) => {
    const previousSectionState = leadSections[leadId];
    return Object.assign(Object.assign({}, leadSections), { [leadId]: previousSectionState === undefined ? true : !previousSectionState });
};
const collapseLeadSections = (leadSections) => Object.fromEntries(Object.entries(leadSections).map(([key]) => [key, false]));
export const processingQuotesSlice = createSlice({
    name: 'ProcessingQuotes',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        Stopped: constant(initialState),
        Started: identity,
        fetchNextPage: (state, _) => state,
        fetchPreviousPage: (state, _) => state,
        fetchFirstPage: (state, _) => state,
        getLeads: (state, _) => state,
        getQuoteRequestByLead: (state, _) => state,
        toggleLeadSection: (state, _) => state,
        deleteLead: (state, _) => state,
        LeadDeletionRequested: identity,
        LeadDeleted: (state, event) => (Object.assign(Object.assign({}, state), { leadsTable: event.payload.leads })),
        LeadNotDeleted: identity,
        deleteRequest: (state, _) => state,
        RequestDeletionRequested: identity,
        RequestDeleted: (state, event) => (Object.assign(Object.assign({}, state), { quoteRequestByLeadTable: Object.assign(Object.assign({}, state.quoteRequestByLeadTable), { [event.payload.leadId]: event.payload.quoteRequest }) })),
        RequestNotDeleted: identity,
        LeadSectionToggled: (state, event) => (Object.assign(Object.assign({}, state), { leadSections: toggleLeadSection(state.leadSections, event.payload.leadId) })),
        QuoteRequestByLeadRequested: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { quotes: Object.assign(Object.assign({}, state.loading.quotes), { [event.payload.leadId]: true }) }) })),
        QuoteRequestByLeadFetched: (state, event) => (Object.assign(Object.assign({}, state), { quoteRequestByLeadTable: Object.assign(Object.assign({}, state.quoteRequestByLeadTable), { [event.payload.leadId]: event.payload.quoteRequest }), loading: Object.assign(Object.assign({}, state.loading), { quotes: Object.assign(Object.assign({}, state.loading.quotes), { [event.payload.leadId]: false }) }) })),
        QuoteRequestByLeadNotFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { quotes: Object.assign(Object.assign({}, state.loading.quotes), { [event.payload.leadId]: false }) }) })),
        LeadsRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { leads: true }) })),
        LeadsFetched: (state, event) => (Object.assign(Object.assign({}, state), { leadSections: collapseLeadSections(state.leadSections), leadsTable: event.payload.leads, loading: Object.assign(Object.assign({}, state.loading), { leads: false }), appliedFilters: Object.assign(Object.assign({}, state.appliedFilters), event.payload.filter), tablePagination: tablePaginationStateMapper(event.payload.leads, event.payload.paginationModel) })),
        LeadsNotFetched: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { leads: false }) })),
    },
});
export const $ProcessingQuotes = processingQuotesSlice.actions;
export const $ProcessingQuotesSlice = {
    PAGE_LIMIT: PER_PAGE,
    selector: {
        pagination: (state) => state.processingQuotes.tablePagination,
        isLeadsTableLoading: (state) => state.processingQuotes.loading.leads,
        leadsTable: (state) => state.processingQuotes.leadsTable,
        quoteRequestByLeadTable: (state) => state.processingQuotes.quoteRequestByLeadTable,
        isQuoteRequestByLeadTableLoading: (state) => state.processingQuotes.loading.quotes,
        appliedFilters: (state) => state.processingQuotes.appliedFilters,
        leadSections: (state) => state.processingQuotes.leadSections,
    },
};
export const ProcessingQuotesState = processingQuotesSlice.reducer;
