import { either, option } from 'fp-ts';
import { isSome, none, some } from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { Refinement } from '../../lib/Refinement';
import { Process } from '../../lib/redux/Process';
import { Reducer } from '../../lib/redux/Reducer';
import { $identifier } from '../../model/Identifier';
import { RouteC } from '../../model/route/Route';
import { optics } from '../../utils/Optics';
import { ProcessState } from '../processes/ProcessState';
import { RecordAction } from './RecordAction';
import { DocumentAction } from './documents/DocumentAction';
const empty = none;
const reducer = Reducer(Refinement.any(RecordAction.Update.is.done, RecordAction.Detail.is.done, RecordAction.Cancel.is.done, DocumentAction.Hierarchy.is.done, DocumentAction.Upload.is.done))(empty)({
    [RecordAction.Detail.type.done]: (_, record) => some(record),
    [RecordAction.Update.type.done]: (_, record) => some(record),
    [RecordAction.Cancel.type.done]: (_, record) => some(record),
    [DocumentAction.Hierarchy.type.done]: (_, { record }) => some(record),
    [DocumentAction.Upload.type.done]: (state, newDocument) => option.isSome(state)
        ? option.some(Object.assign(Object.assign({}, state.value), { documents: [...state.value.documents, newDocument] }))
        : option.none,
});
export const getOrElse = (p, onNone) => (m) => isSome(m) ? m.value[p] : onNone;
const isRecordDetailLoading = (state) => ProcessState.selector.has.running(RecordAction.Detail)(state) ||
    ProcessState.selector.has.running(RecordAction.Duplicate)(state) ||
    state.commissions.loading.table ||
    ProcessState.selector.has.running(RecordAction.Update)(state);
const isPageLoading = (state) => state.pipelines.isUpdatingRecordStatus ||
    state.pipelines.isDigitalLoading ||
    state.pipelines.isConfigurationLoading ||
    ProcessState.selector.has.running(RecordAction.Cancel)(state) ||
    ProcessState.selector.has.running(DocumentAction.Download)(state) ||
    ProcessState.selector.has.running(RecordAction.Sign)(state);
const recordDetailProcessTs = (state) => ProcessState.selector
    .processes(RecordAction.Detail)(state)
    .filter(Process.is.failed)
    .reduce((acc, val) => val.timestamp, 0);
const idFromRoute = (props) => pipe(props, RouteC.decode, either.map(optics.get('match')), either.map(optics.get('params')), either.map(optics.get('id')), either.match(() => none, option.fromNullable));
const itemFromProps = (state) => (props) => pipe(props, idFromRoute, option.match(() => none, item(state)));
const item = (state) => (id) => pipe(state.record.item, option.filter(record => $identifier.Eq2.equals(id, record.id)));
export const RecordItemState = {
    reducer,
    selector: {
        idFromRoute,
        itemFromProps,
        isRecordDetailLoading,
        isPageLoading,
        recordDetailProcessTs,
        item,
        getOrElse,
        invalidParams: (state) => ProcessState.selector.error(RecordAction.Update)(state),
    },
};
