import { jsx as _jsx } from "react/jsx-runtime";
import { TextField } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { $RequestWithQuotes, $RequestWithQuotesSlice } from '../state/RequestWithQuotesState';
import { NotebarLayout } from './NotebarLayout';
const onSubmit = (notes, onClose, dispatch, stateSetter) => () => {
    stateSetter(notes);
    dispatch($RequestWithQuotes.updateRequest({ notes: notes, onSuccess: onClose }));
};
const handleClose = (notes, onClose, stateSetter) => () => {
    stateSetter(notes);
    onClose();
};
export const Notebar = ({ onClose, open, notesInitialValue }) => {
    const [notes, setNotes] = useState(notesInitialValue);
    const [tempNotes, setTempNotes] = useState(notes);
    const isRequestUpdating = useAppSelector($RequestWithQuotesSlice.selector.isRequestUpdating);
    const dispatch = useAppDispatch();
    return (_jsx(NotebarLayout, { isSubmitButtonDisabled: isRequestUpdating, open: open, onClose: handleClose(notes, onClose, setTempNotes), onSubmit: onSubmit(tempNotes, onClose, dispatch, setNotes), children: _jsx(TextField, { variant: "outlined", onChange: e => {
                setTempNotes(e.target.value);
            }, value: tempNotes, type: "text", multiline: true, minRows: 3, fullWidth: true }) }));
};
