import { either } from 'fp-ts';
import { all, call, cancel, put, take, takeLeading } from 'typed-redux-saga';
import { Session } from './Session';
import { $Session } from './SessionState';
function* refreshToken(authService) {
    try {
        yield* put($Session.TokenRequested());
        const refreshedTokenEither = yield* call(authService.refreshToken(Session.getToken()));
        if (either.isLeft(refreshedTokenEither)) {
            yield* put($Session.TokenNotRefreshed());
            return;
        }
        Session.setToken(refreshedTokenEither.right);
        yield* put($Session.TokenRefreshed());
    }
    catch (e) {
        yield* put($Session.TokenRefreshed());
    }
}
export function* SessionSaga(authService) {
    yield* takeLeading($Session.start, function* () {
        try {
            yield* put($Session.Started());
            const task = yield* all([
                yield* takeLeading($Session.refreshToken, function* () {
                    yield* call(refreshToken, authService);
                }),
            ]);
            yield* take($Session.stop);
            yield* cancel(task);
            yield* put($Session.Stopped());
        }
        catch (e) {
            yield* put($Session.Stopped());
        }
    });
}
