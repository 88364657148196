import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { Snackbar as SnackbarComponent } from '../Snackbar/Snackbar';
const TIMEOUT = 4000;
export const Notification = ({ popMessage, notification }) => {
    useEffect(() => {
        if (!notification) {
            return;
        }
        const timer = setTimeout(popMessage, TIMEOUT + 500);
        return () => clearTimeout(timer);
    }, [popMessage, notification]);
    return (_jsx(_Fragment, { children: _jsx(SnackbarComponent, { severity: notification === null || notification === void 0 ? void 0 : notification.severity, duration: TIMEOUT, children: (notification === null || notification === void 0 ? void 0 : notification.text) || '' }) }));
};
