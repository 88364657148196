export const messages = (fn) => (messageList, severity) => fn ? fn(messageList).map(m => ({ text: m, severity })) : messageList.map(m => ({ text: m, severity }));
export const cleanEmptyStrings = (arr) => arr.filter(m => m !== '');
const messageSelector = (...infoMessage) => (...errorMessage) => [
    ...messages(cleanEmptyStrings)(infoMessage, 'info'),
    ...messages(cleanEmptyStrings)(errorMessage, 'error'),
];
const message = (severity) => (text) => ({
    text: text,
    severity,
});
export const Notification = {
    message: messageSelector,
    info: message('info'),
    error: message('error'),
};
