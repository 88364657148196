export const documentationModelMapper = (documentation) => ({
    id: documentation.id,
    name: documentation.name,
    toBeFilled: documentation.toBeFilled,
    toBeSigned: documentation.toBeSigned,
    documentTypeId: documentation.documentTypeId,
    filled: documentation.filled,
    signed: documentation.signed,
    file: documentation.file,
    toBeUploaded: documentation.toBeUploaded,
    transitionsHistory: documentation.transitionsHistory,
    uploaded: documentation.uploaded === null ? false : documentation.uploaded,
    required: documentation.required,
});
