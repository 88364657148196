const getItem = key => localStorage.getItem(key);
const setItem = (key, value) => {
    if (typeof value === 'string') {
        localStorage.setItem(key, value);
    }
    else {
        localStorage.setItem(key, JSON.stringify(value));
    }
};
const deleteItem = key => {
    const hasKey = localStorage.getItem(key);
    if (hasKey) {
        localStorage.removeItem(key);
    }
};
export const LocalStorage = { getItem, setItem, deleteItem };
