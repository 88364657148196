import { array, option, readonlyArray } from 'fp-ts';
import { constant, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
const participantLocationList = (locationList) => pipe(locationList, array.map(location => ({
    name: location.name,
    postalCode: pipe(location.postalCodes, readonlyArray.head, option.getOrElse(constant(''))),
    province: { shortName: location.province.shortName },
})));
const realEstateLocationList = (locationList) => pipe(locationList, array.map(location => ({
    name: location.name,
    postalCode: pipe(location.postalCodes, readonlyArray.head, option.getOrElse(constant(''))),
    province: { shortName: location.province.shortName },
})));
const mpnParticipantLocationList = (locationList) => pipe(locationList, array.map(location => ({
    name: location.name,
    postalCode: '',
    province: { shortName: location.province.shortName },
})));
export const LocationCodec = t.type({
    name: t.string,
    postalCode: t.string,
    province: t.type({
        shortName: t.string,
    }),
}, 'Location');
export const LocationUtils = {
    participantLocationList,
    realEstateLocationList,
    mpnParticipantLocationList,
};
