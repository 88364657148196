var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { array, either, option, readonlyRecord } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { decode } from 'json-api-codec';
import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { call, select, take, takeLeading, call as typedCall } from 'typed-redux-saga';
import { getStatus, getUser } from '../../api/record';
import { ALL_FILTER, FILTER, FILTER_STATUS } from '../../constants';
import { LocalStorage } from '../../lib/localStorage';
import { $role } from '../../model/Role';
import { filter as filterUtils } from '../../utils/filter';
import { Notification } from '../../utils/notification';
import { isObjectEmpty } from '../../utils/object';
import { session } from '../../utils/session';
import { getUserHierachy } from '../../web/user/hierarchy';
import { AgentsRecordsFilterFetched, AgentsRecordsFilterNotFetched, AgentsRecordsFilterRequested, AssigneeFilterFailure, AssigneeFilterRequest, AssigneeFilterSuccess, RECORD_LIST_WITH_FILTERS_REQUEST, RecordListWithFiltersFailure, RecordListWithFiltersSuccess, SAVE_FILTER, SHOW_AGENTS_RECORDS_FILTER, SaveFilter, StatusFilterFailure, StatusFilterRequest, StatusFilterSuccess, UPDATE_FILTER_REQUEST, UpdateFilterFailure, UpdateFilterSuccess, } from '../actions/filter';
import { RecordListActions } from '../list/RecordListActions';
import { NotificationAction } from '../notification/NotificationAction';
import { ProcessState } from '../processes/ProcessState';
import { SecurityActions } from '../security/SecurityActions';
const USER_LIST_ERROR = 'Si è verificato un errore';
const getActiveRecords = (state) => state.filter.activeRecords;
const getClosedRecords = (state) => state.filter.closedRecords;
const getFilters = (state) => state.filter;
const getLoggedUser = (state) => state.security.user;
const getAgentsRecordsFilterError = (state) => state.filter.canViewAgentsRecordsFilter.hasError;
export const mapFilter = (filter) => {
    const filterWithStates = mapRecordStates(filter);
    const filterWithAssignee = mapAssigneeFilter(filterWithStates);
    const filterWithProductType = mapProductTypeFilter(filterWithAssignee);
    const filterWithPhone = mapFilterData(filterWithProductType);
    return mapStatusFilter(filterWithPhone);
};
const mapProductTypeFilter = (filter) => {
    const { tipo_di_prodotto_long } = filter, filterNoProductType = __rest(filter, ["tipo_di_prodotto_long"]);
    return tipo_di_prodotto_long === ALL_FILTER.PRODUCT || tipo_di_prodotto_long === ALL_FILTER.RECORDS
        ? filterNoProductType
        : Object.assign(Object.assign({}, filter), { tipo_di_prodotto_long });
};
const mapAssigneeFilter = (filter) => {
    const { agenteId } = filter, filterNoAssignee = __rest(filter, ["agenteId"]);
    return agenteId === ALL_FILTER.ASSIGNEE || agenteId === ALL_FILTER.RECORDS
        ? filterNoAssignee
        : Object.assign(Object.assign({}, filter), { agenteId });
};
const mapStatusFilter = (filter) => {
    const { states } = filter, filterNoStatus = __rest(filter, ["states"]);
    return states === ALL_FILTER.STATES || states === ALL_FILTER.RECORDS ? filterNoStatus : Object.assign(Object.assign({}, filter), { states });
};
export const mapFilterData = (filter) => {
    const { tipo_di_prodotto_long, digital } = filter, filterNoData = __rest(filter, ["tipo_di_prodotto_long", "digital"]);
    const maybeDigital = digital ? { digital: 1 } : {};
    const dataParam = tipo_di_prodotto_long ? { data: { tipo_di_prodotto_long } } : {};
    return Object.assign(Object.assign(Object.assign({}, maybeDigital), filterNoData), dataParam);
};
const mapRecordStates = (filter) => {
    if (!filter.states) {
        return filter;
    }
    let statesParam;
    switch (filter.states.toLowerCase()) {
        case 'erogate':
            statesParam = [FILTER_STATUS.EROGATA];
            break;
        case 'declinate':
            statesParam = [
                FILTER_STATUS.DECLINATA,
                FILTER_STATUS.MANCANZA_REQUISITI,
                FILTER_STATUS.ERRORE_CARICAMENTO,
                FILTER_STATUS.SEGNALATO_WIF,
                FILTER_STATUS.ARCHIVIATO,
            ];
            break;
        case 'ritirate dal cliente':
            statesParam = [FILTER_STATUS.RITIRATA];
            break;
        case ALL_FILTER.STATES.toLowerCase():
        case ALL_FILTER.RECORDS.toLowerCase():
            statesParam = [];
            break;
        default:
            statesParam = [filter.states];
            break;
    }
    return Object.assign(Object.assign({}, filter), { states: statesParam });
};
const hiddenOptionFromFilter = (key, hidden) => (filters) => pipe(filters, readonlyRecord.lookup(key), option.filter(recordFilter => undefined !== recordFilter), option.map(() => ({ hidden })));
export const hiddenQueryParam = (filters) => {
    if (option.isSome(hiddenOptionFromFilter('activeRecords', false)(filters)) &&
        option.isSome(hiddenOptionFromFilter('closedRecords', true)(filters))) {
        return option.none;
    }
    return pipe(filters, hiddenOptionFromFilter('activeRecords', false), option.alt(() => hiddenOptionFromFilter('closedRecords', true)(filters)));
};
function* updateRecordListWithFilters(action) {
    var _a;
    try {
        let allFilters = {};
        if (action.payload.activeRecords) {
            allFilters = yield* select(getActiveRecords);
        }
        else if (action.payload.closedRecords) {
            allFilters = yield* select(getClosedRecords);
        }
        // recupero anche i filtri presenti in altre aree di ricerca (groups)
        const activeRecords = Object.assign({}, filterUtils.fromValues(action.payload.activeRecords || {}));
        const closedRecords = Object.assign({}, filterUtils.fromValues(action.payload.closedRecords || {}));
        const filter = Object.assign(Object.assign(Object.assign({}, filterUtils.fromValues(allFilters)), activeRecords), closedRecords);
        let statusCode;
        let assigneeCode;
        const hiddenQueryParamOption = hiddenQueryParam({
            activeRecords: action.payload.activeRecords,
            closedRecords: action.payload.closedRecords,
        });
        yield put(StatusFilterRequest());
        const responseStatus = yield* call(getStatus, option.isSome(hiddenQueryParamOption) ? hiddenQueryParamOption.value : {});
        const status = decode(responseStatus.data);
        yield put(StatusFilterSuccess({ status }));
        if (!isObjectEmpty(action.payload.activeRecords)) {
            statusCode = status.find(s => s.code === filter.states);
            yield put(AssigneeFilterRequest());
            const userListEither = yield* typedCall(getUser);
            if (either.isRight(userListEither)) {
                yield put(AssigneeFilterSuccess({ assignee: userListEither.right }));
                assigneeCode = userListEither.right.find(s => 
                // `!=` checks both `null` and `undefined`.
                // eslint-disable-next-line eqeqeq, no-eq-null
                null != s.original_id &&
                    // eslint-disable-next-line eqeqeq, no-eq-null
                    null != filter.agenteId &&
                    s.original_id.toString() === filter.agenteId.toString());
            }
            else {
                yield put(AssigneeFilterFailure({ error: USER_LIST_ERROR }));
            }
        }
        else if (isObjectEmpty(action.payload.activeRecords) && isObjectEmpty(action.payload.closedRecords)) {
            yield put(AssigneeFilterRequest());
            const userListEither = yield* typedCall(getUser);
            if (either.isRight(userListEither)) {
                yield put(AssigneeFilterSuccess({ assignee: userListEither.right }));
            }
            else {
                yield put(AssigneeFilterFailure({ error: USER_LIST_ERROR }));
            }
        }
        else if (!isObjectEmpty(action.payload.closedRecords)) {
            statusCode = status.find(s => s.code === filter.states);
        }
        const maybeDigital = ((_a = action.payload.activeRecords) === null || _a === void 0 ? void 0 : _a.digital) ? { digital: 1 } : {};
        const p = action.payload.url
            ? { url: action.payload.url }
            : {
                filter: mapFilter(Object.assign(Object.assign(Object.assign({}, filter), maybeDigital), { states: statusCode ? statusCode.code : ALL_FILTER.STATES, agenteId: assigneeCode ? assigneeCode.original_id : ALL_FILTER.ASSIGNEE })),
                url: '',
            };
        yield put(RecordListActions.List({
            url: p.url,
            filter: p.filter || {},
        }));
        const recordListAction = yield* take([
            RecordListActions.List.type.done,
            RecordListActions.List.type.failed,
        ]);
        if (recordListAction._tag === 'Done') {
            yield put(RecordListWithFiltersSuccess(action.payload));
            yield put(SaveFilter());
            // Questo if serve ad aggiornare la localstorage
            // nel caso ci sia un valore non piu utilizzabile
            // il nuovo valore diventa quello di default
            if (!isObjectEmpty(action.payload.activeRecords)) {
                const records = JSON.parse(LocalStorage.getItem(FILTER.RECORDS));
                const active = (records && records.activeRecords) || {};
                yield call(LocalStorage.setItem, FILTER.RECORDS, Object.assign(Object.assign({}, records), { activeRecords: Object.assign(Object.assign({}, active), { states: statusCode ? statusCode.code : ALL_FILTER.STATES, agenteId: assigneeCode ? assigneeCode.original_id : ALL_FILTER.ASSIGNEE }) }));
            }
            else if (!isObjectEmpty(action.payload.closedRecords)) {
                const records = JSON.parse(LocalStorage.getItem(FILTER.RECORDS));
                const closed = (records && records.closedRecords) || {};
                yield call(LocalStorage.setItem, FILTER.RECORDS, Object.assign(Object.assign({}, records), { closedRecords: Object.assign(Object.assign({}, closed), { states: statusCode ? statusCode.code : ALL_FILTER.STATES }) }));
            }
        }
        else if (recordListAction._tag === 'Failed') {
            yield put(RecordListWithFiltersFailure({
                error: 'Si è verificato un errore durante la ricerca',
            }));
            yield put(NotificationAction.Push(Notification.error('Si è verificato un errore durante la ricerca')));
        }
    }
    catch (e) {
        if (e.response) {
            const { data: { error }, status, } = e.response;
            if (status === 401) {
                const state = yield select();
                if (!ProcessState.selector.has.running(SecurityActions.Logout)(state)) {
                    yield put(SecurityActions.Logout(session.fromError(error)));
                }
            }
        }
        else {
            yield put(RecordListWithFiltersFailure({ error: e.message }));
            yield put(StatusFilterFailure({ error: e.message }));
            yield put(AssigneeFilterFailure({ error: e.message }));
            yield put(NotificationAction.Push(Notification.error(e.message)));
        }
    }
}
function* updateFilterRequest(action) {
    const activeRecords = yield* select(getActiveRecords);
    const closedRecords = yield* select(getClosedRecords);
    try {
        const p = Object.assign({ activeRecords,
            closedRecords }, action.payload);
        yield put(UpdateFilterSuccess(p));
        yield call(LocalStorage.setItem, FILTER.RECORDS, p);
    }
    catch (e) {
        yield put(UpdateFilterFailure({
            error: 'Si è verificato un errore durante la ricerca',
        }));
        yield put(NotificationAction.Push(Notification.error('Si è verificato un errore durante la ricerca')));
    }
}
function* saveFilter() {
    const { url, activeRecords, closedRecords } = yield select(getFilters);
    yield call(LocalStorage.setItem, FILTER.RECORDS, {
        url,
        activeRecords,
        closedRecords,
    });
}
function* watchRecordListRequestWithFilters() {
    yield takeEvery(RECORD_LIST_WITH_FILTERS_REQUEST, updateRecordListWithFilters);
}
function* watchUpdateFilterRequest() {
    yield takeEvery(UPDATE_FILTER_REQUEST, updateFilterRequest);
}
function* watchSaveFilter() {
    yield takeEvery(SAVE_FILTER, saveFilter);
}
function* switchAgentsRecordsFilterVisibility() {
    const userOption = yield* select(getLoggedUser);
    if (option.isSome(userOption)) {
        const canViewAgentsRecordsFilterOption = pipe(userOption.value.roles, array.findFirst(role => role === $role.ROLE_NEXUS_AGENTE_HEAD_DIVISIONAL || role === $role.ROLE_NEXUS_AGENTE_HEAD_AGENCY));
        if (option.isSome(canViewAgentsRecordsFilterOption)) {
            const agentsRecordsFilterHasError = yield* select(getAgentsRecordsFilterError);
            if (agentsRecordsFilterHasError === true || agentsRecordsFilterHasError === null) {
                yield put(AgentsRecordsFilterRequested());
                const userHierarchyEither = yield* typedCall(getUserHierachy(userOption.value.id, { hierarchyBottom: 1 }));
                if (either.isRight(userHierarchyEither)) {
                    yield put(AgentsRecordsFilterFetched({
                        loggedUserId: userOption.value.id,
                        userHierarchy: userHierarchyEither.right,
                    }));
                }
                else {
                    yield put(AgentsRecordsFilterNotFetched());
                }
            }
        }
    }
}
export function* filterSaga() {
    yield all([
        fork(watchRecordListRequestWithFilters),
        fork(watchUpdateFilterRequest),
        fork(watchSaveFilter),
        takeLeading(SHOW_AGENTS_RECORDS_FILTER, switchAgentsRecordsFilterVisibility),
    ]);
}
