import * as Yup from 'yup';
import { CqsSchema } from './Cqs';
export const setNestedProperty = (nestedObject, path, value) => {
    const keys = path.split('.');
    let current = nestedObject;
    keys.forEach((key, index) => {
        if (index === keys.length - 1) {
            current[key] = value;
        }
        else {
            if (typeof current[key] !== 'object' || current[key] === null) {
                current[key] = {};
            }
            current = current[key];
        }
    });
};
export const validateCqsSchema = (products, canViewChannelField) => (values) => {
    var _a;
    const schema = CqsSchema;
    const errors = {};
    const context = {
        products: products,
        canViewChannelField: canViewChannelField,
    };
    try {
        schema.validateSync(values, {
            abortEarly: false,
            context: context,
        });
    }
    catch (u) {
        if (u instanceof Yup.ValidationError) {
            const validationErrors = ((_a = u.inner) === null || _a === void 0 ? void 0 : _a.length) ? u.inner : [u];
            validationErrors.forEach(e => {
                if (e.path) {
                    setNestedProperty(errors, e.path, e.message);
                }
            });
        }
    }
    return errors;
};
