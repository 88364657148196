import { format } from 'date-fns';
import { numericFormatter } from 'react-number-format';
const DATE_FORMAT = 'dd/MM/yyyy';
const currencyFormatterRules = {
    thousandSeparator: '.',
    allowNegative: false,
    decimalScale: 2,
    fixedDecimalScale: true,
    decimalSeparator: ',',
};
export const detailStateModelMapper = (paperwork, permissions) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6;
    return [
        [{ sectionTitle: 'Store di riferimento', rows: { Store: paperwork.data.store || null } }],
        [
            {
                sectionTitle: 'Rubinetto',
                hidden: !permissions.canViewChannel,
                rows: { Rubinetto: paperwork.data.rubinetto },
            },
        ],
        [
            {
                sectionTitle: 'Mandato',
                rows: {
                    'Data firma mandato': paperwork.data.data_firma_mandato
                        ? format(paperwork.data.data_firma_mandato, DATE_FORMAT)
                        : null,
                    'Data scadenza mandato': paperwork.data.data_scadenza_mandato
                        ? format(paperwork.data.data_scadenza_mandato, DATE_FORMAT)
                        : null,
                },
            },
        ],
        [
            {
                sectionTitle: 'Finanziamento',
                rows: {
                    'Data erogazione finanziamento': paperwork.data.data_erogazione_finanziamento
                        ? format(paperwork.data.data_erogazione_finanziamento, DATE_FORMAT)
                        : null,
                    'Mezzo di pagamento finanziamento': (_a = paperwork.data.mezzo_di_pagamento_finanziamento) !== null && _a !== void 0 ? _a : null,
                },
            },
        ],
        [
            {
                sectionTitle: `${paperwork.data.nome} ${paperwork.data.cognome}`,
                rows: {
                    'Indirizzo di residenza': (_b = paperwork.data.indirizzo_di_residenza) !== null && _b !== void 0 ? _b : null,
                    'Località di residenza': (_c = paperwork.data.localita_di_residenza) !== null && _c !== void 0 ? _c : null,
                    'CAP di residenza': (_d = paperwork.data.cap_di_residenza) !== null && _d !== void 0 ? _d : null,
                    'Comune di nascita': (_e = paperwork.data.comune_di_nascita) !== null && _e !== void 0 ? _e : null,
                    'Provincia di nascita': (_f = paperwork.data.provincia_di_nascita) !== null && _f !== void 0 ? _f : null,
                    'Data di nascita': paperwork.data.data_di_nascita
                        ? format(paperwork.data.data_di_nascita, DATE_FORMAT)
                        : null,
                    Sesso: (_g = paperwork.data.sesso) !== null && _g !== void 0 ? _g : '',
                    'Codice fiscale': paperwork.data.codice_fiscale,
                    'Tipo di documento': (_h = paperwork.data.tipo_di_documento) !== null && _h !== void 0 ? _h : null,
                    Cittadinanza: (_j = paperwork.data.cittadinanza) !== null && _j !== void 0 ? _j : null,
                    'N° di documento': (_k = paperwork.data.n_di_documento) !== null && _k !== void 0 ? _k : null,
                    'Rilasciato da': (_l = paperwork.data.rilasciato_da) !== null && _l !== void 0 ? _l : null,
                    'Luogo di rilascio': (_m = paperwork.data.luogo_di_rilascio) !== null && _m !== void 0 ? _m : null,
                    'Data di rilascio': paperwork.data.data_di_rilascio
                        ? format(paperwork.data.data_di_rilascio, DATE_FORMAT)
                        : null,
                    'Data di scadenza': paperwork.data.data_di_scadenza
                        ? format(paperwork.data.data_di_scadenza, DATE_FORMAT)
                        : null,
                },
            },
            {
                sectionTitle: 'Contatti',
                rows: {
                    'Telefono cellulare': paperwork.data.telefono_cellulare,
                    'Telefono fisso': (_o = paperwork.data.telefono_fisso) !== null && _o !== void 0 ? _o : null,
                    Email: paperwork.data.email,
                },
            },
            {
                sectionTitle: 'Dati reddituali',
                rows: {
                    'Stato civile': (_p = paperwork.data.stato_civile) !== null && _p !== void 0 ? _p : null,
                    Professione: (_q = paperwork.data.professione) !== null && _q !== void 0 ? _q : null,
                    'Tipo di azienda': (_r = paperwork.data.tipo_di_azienda) !== null && _r !== void 0 ? _r : null,
                    'P.IVA datore di lavoro': (_s = paperwork.data.partita_iva_datore_di_lavoro) !== null && _s !== void 0 ? _s : null,
                    'Reddito mensile netto': paperwork.data.reddito_mensile_netto
                        ? `${numericFormatter(paperwork.data.reddito_mensile_netto.toString(), currencyFormatterRules)} €`
                        : null,
                    'Numero di mensilità': (_u = (_t = paperwork.data.numero_di_mensilita) === null || _t === void 0 ? void 0 : _t.toString()) !== null && _u !== void 0 ? _u : null,
                    'Anzianità lavorativa': paperwork.data.anzianita_lavorativa
                        ? `${paperwork.data.anzianita_lavorativa} mesi`
                        : null,
                    'Componenti nucleo familiare': (_w = (_v = paperwork.data.componenti_nucleo_familiare) === null || _v === void 0 ? void 0 : _v.toString()) !== null && _w !== void 0 ? _w : null,
                },
            },
            {
                sectionTitle: 'Consensi',
                rows: {
                    "Consenso per l'invio di comunicazioni promozionali e di marketing": (_x = paperwork.data.consenso_invio_comunicazioni_promozionali_e_marketing) !== null && _x !== void 0 ? _x : false,
                    'Consenso per la cessione di dati a terzi': (_y = paperwork.data.consenso_cessione_dati_a_terzi) !== null && _y !== void 0 ? _y : false,
                },
            },
        ],
        [
            {
                sectionTitle: 'Cessione del quinto',
                rows: Object.assign(Object.assign({ Finalità: (_z = paperwork.data.finalita) !== null && _z !== void 0 ? _z : null, 'Tipo di operazione': (_0 = paperwork.data.cessione_del_quinto_tipo_di_operazione) !== null && _0 !== void 0 ? _0 : null, 'Tipo di dipendente': (_1 = paperwork.data.cessione_del_quinto_tipo_di_dipendente) !== null && _1 !== void 0 ? _1 : null, Finanziaria: (_2 = paperwork.data.istituto) !== null && _2 !== void 0 ? _2 : null, Prodotto: (_3 = paperwork.data.cessione_del_quinto_prodotto) !== null && _3 !== void 0 ? _3 : null, 'Importo richiesto': paperwork.data.importo_richiesto
                        ? `${numericFormatter(paperwork.data.importo_richiesto.toString(), currencyFormatterRules)} €`
                        : null, TAN: paperwork.data.cessione_del_quinto_taeg ? `${paperwork.data.cessione_del_quinto_taeg}%` : null, 'Punti applicati': (_5 = (_4 = paperwork.data.cessione_del_quinto_punti_applicati) === null || _4 === void 0 ? void 0 : _4.toString()) !== null && _5 !== void 0 ? _5 : null, Durata: paperwork.data.cessione_del_quinto_durata_mesi
                        ? `${paperwork.data.cessione_del_quinto_durata_mesi} mesi`
                        : null, Rata: paperwork.data.cessione_del_quinto_rata
                        ? `${numericFormatter(paperwork.data.cessione_del_quinto_rata.toString(), currencyFormatterRules)} €`
                        : null, Montante: paperwork.data.cessione_del_quinto_montante
                        ? `${numericFormatter(paperwork.data.cessione_del_quinto_montante.toString(), currencyFormatterRules)} €`
                        : null }, (paperwork.data.cessione_del_quinto_tipo_di_operazione === 'Rinnovo'
                    ? {
                        'Montante residuo': paperwork.data.cessione_del_quinto_montante_residuo
                            ? `${numericFormatter(paperwork.data.cessione_del_quinto_montante_residuo.toString(), currencyFormatterRules)} €`
                            : null,
                        'Delta montante': paperwork.data.cessione_del_quinto_delta_montante
                            ? `${numericFormatter(paperwork.data.cessione_del_quinto_delta_montante.toString(), currencyFormatterRules)} €`
                            : null,
                    }
                    : null)), { Note: (_6 = paperwork.data.note) !== null && _6 !== void 0 ? _6 : null }),
            },
        ],
    ];
};
