import { useEffect, useState } from 'react';
export const useActiveScroll = (nodeId) => {
    const [scroll, setScroll] = useState(false);
    useEffect(() => {
        var _a;
        const handleScroll = (e) => {
            if (e.target.scrollTop !== 0) {
                setScroll(true);
            }
            else {
                setScroll(false);
            }
        };
        (_a = document.getElementById(nodeId)) === null || _a === void 0 ? void 0 : _a.addEventListener('scroll', handleScroll);
        return window.removeEventListener('scroll', handleScroll);
    });
    return scroll;
};
