import { format } from 'date-fns';
import { numericFormatter } from 'react-number-format';
const currencyFormatterRules = {
    thousandSeparator: '.',
    allowNegative: false,
    decimalScale: 2,
    fixedDecimalScale: true,
    decimalSeparator: ',',
};
export const tableStateModelMapper = (paperworkList) => {
    return paperworkList.map(paperwork => {
        var _a, _b, _c;
        return ({
            amount: paperwork.data.cessione_del_quinto_importo_richiesto
                ? `${numericFormatter(paperwork.data.cessione_del_quinto_importo_richiesto.toString(), currencyFormatterRules)} €`
                : '',
            assignedTo: `${paperwork.assignedTo.firstName} ${paperwork.assignedTo.lastName}`,
            id: paperwork.id,
            createdAt: format(paperwork.createdAt, 'dd/MM/yyyy'),
            institute: (_a = paperwork.data.cessione_del_quinto_finanziaria) !== null && _a !== void 0 ? _a : '',
            name: `${paperwork.data.nome} ${paperwork.data.cognome}`,
            product: (_b = paperwork.data.cessione_del_quinto_prodotto) !== null && _b !== void 0 ? _b : '',
            purpose: (_c = paperwork.data.cessione_del_quinto_finalita) !== null && _c !== void 0 ? _c : '',
        });
    });
};
