import { option, readonlyArray, taskEither } from 'fp-ts';
import { identity, pipe } from 'fp-ts/function';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
const getParticipantType = (participantType) => {
    switch (participantType) {
        case 'garante':
            return option.some('guarantor');
        case 'richiedente':
            return option.some('applicant');
        default:
            return option.none;
    }
};
const getAdditionalParticipant = (participant) => {
    const participantType = getParticipantType(participant.type);
    if (option.isNone(participantType)) {
        return option.none;
    }
    return option.some(Object.assign({ type: participantType.value, currentDebtInstallment: Number(participant.rawOngoingDebtInstallment), employment: participant.job, age: participant.age, familyUnit: participant.household, netIncome: Number(participant.rawNetMonthlyIncome), isForeigner: participant.nationality !== 'italian' }, (participant.placeOfResidence !== undefined
        ? {
            residenceLocation: {
                city: participant.placeOfResidence.name,
                provinceCode: participant.placeOfResidence.province.shortName,
                postalCode: participant.placeOfResidence.postalCode,
            },
        }
        : {})));
};
const getParticipant = (participant) => {
    const participantType = getParticipantType(participant.type);
    if (option.isNone(participantType)) {
        return option.none;
    }
    return option.some({
        type: participantType.value,
        name: participant.firstName,
        surname: participant.lastName,
        gender: participant.gender,
        birthDate: participant.birthDate,
        birthCity: participant.birthCity,
        birthProvince: participant.birthProvince === '' ? null : participant.birthProvince,
        birthCountry: participant.birthCountry,
        maritalStatus: participant.maritalStatus,
        isForeigner: participant.nationality === 'other',
        isItalianResident: participant.isItalianResident === 'yes',
        taxCode: participant.taxCode,
        address: participant.residenceAddress,
        streetNumber: participant.residenceStreetNumber,
        residenceLocation: {
            city: participant.cityOfResidence.name,
            provinceCode: participant.cityOfResidence.province.shortName,
            postalCode: participant.cityOfResidencePostalCode,
        },
        mobilePhoneNumber: participant.mobilePhoneNumber,
        email: participant.email,
        familyUnit: participant.household,
        netIncome: participant.netMonthlyIncome,
        numberOfMonthlyPayment: participant.numberOfMonthlyPayment,
        employment: participant.job,
        currentDebtInstallment: participant.ongoingDebtInstallment,
    });
};
const getQuotePayload = (quotation, mpnSearch, fmpSearch) => {
    const applicantsOption = pipe(mpnSearch.applicants, readonlyArray.map(getParticipant), readonlyArray.traverse(option.Applicative)(identity));
    const guarantorsOption = pipe(mpnSearch.guarantors, readonlyArray.map(getParticipant), readonlyArray.traverse(option.Applicative)(identity));
    const additionalParticipantsOption = pipe(mpnSearch.additionalParticipants, readonlyArray.map(getAdditionalParticipant), readonlyArray.traverse(option.Applicative)(identity));
    if (option.isNone(applicantsOption) ||
        option.isNone(guarantorsOption) ||
        option.isNone(additionalParticipantsOption)) {
        return option.none;
    }
    return option.some({
        productId: quotation.productId,
        instituteId: quotation.instituteId,
        procedureStatus: fmpSearch.procedureStatus,
        propertyLocation: {
            city: fmpSearch.realEstateLocation.name,
            provinceCode: fmpSearch.realEstateLocation.province.shortName,
            postalCode: fmpSearch.realEstateLocation.postalCode,
        },
        applicants: applicantsOption.value,
        guarantors: guarantorsOption.value,
        additionalParticipants: additionalParticipantsOption.value,
        note: mpnSearch.note,
    });
};
export const selectMpnQuotation = (quotation, search, fmpSearch) => pipe(getQuotePayload(quotation, search, fmpSearch), taskEither.fromOption(() => new Error('Some participant information is missing')), taskEither.chain(payload => taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).post(`${BAZAAR_URL}/api/v1/mortgage/mpn/quotations/${quotation.quotationId}/select`, payload), e => e)));
