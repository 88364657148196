import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import EditIcon from '@mui/icons-material/Create';
import { TableBody } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import { Form, Formik } from 'formik';
import { readonlyArray } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { useEffect, useState } from 'react';
import { Authorization } from '../../authorization/Authorization';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { PERMISSION } from '../../store/security/SecurityState';
import { $ActiveInvoicing, $activeInvoicingSlice } from '../state/activeInvoicing/ActiveInvoicingState';
import { ActiveInvoiceCreationDialog } from './ActiveInvoiceCreationDialog';
import { EmptyList } from './EmptyList';
const onSubmit = (dispatch, fetchRecordCommand) => (formData) => {
    dispatch(fetchRecordCommand({ recordId: formData.recordId }));
};
const squareButtonStyle = {
    maxWidth: 36,
    maxHeight: 36,
    minWidth: 36,
    minHeight: 36,
};
export const ActiveInvoicingRecordListTab = () => {
    const [dialogState, setDialogState] = useState({ open: false });
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector($activeInvoicingSlice.selector.isLoading);
    const initialValues = useAppSelector($activeInvoicingSlice.selector.initialValues);
    const table = useAppSelector($activeInvoicingSlice.selector.table);
    const artooRecordAgents = useAppSelector($activeInvoicingSlice.selector.artooRecordAgents);
    const artooRecordData = useAppSelector($activeInvoicingSlice.selector.artooRecordData);
    useEffect(() => {
        dispatch($ActiveInvoicing.start());
        return () => {
            dispatch($ActiveInvoicing.stop());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(Box, { style: { marginBottom: 77 }, children: [dialogState.open && (_jsx(ActiveInvoiceCreationDialog, { recordId: dialogState.recordId, artooRecordAgents: dialogState.artooRecordAgents, artooRecordData: dialogState.artooRecordData, onClose: () => setDialogState({ open: false }) })), _jsx(Grid, { children: _jsx(Formik, { initialValues: initialValues, enableReinitialize: true, onSubmit: onSubmit(dispatch, $ActiveInvoicing.fetchRecord), children: ({ values, handleChange }) => (_jsx(Form, { children: _jsxs(Grid, { container: true, alignItems: "center", children: [_jsx(Grid, { item: true, children: _jsx(TextField, { variant: "outlined", size: "small", style: { backgroundColor: 'white', marginBottom: 0 }, label: "Cerca per ID", value: values.recordId, onChange: handleChange, name: "recordId" }) }), _jsx(Grid, { item: true, style: { marginLeft: 16 }, children: _jsx(Button, { size: "small", disabled: isLoading, type: "submit", variant: "contained", children: "Cerca" }) })] }) })) }) }), isLoading ? (_jsx(Grid, { children: _jsx(Grid, { style: { display: 'flex', justifyContent: 'center' }, children: _jsx(CircularProgress, {}) }) })) : (_jsx(Grid, { style: { marginTop: 40 }, children: artooRecordAgents === null ||
                    artooRecordData === null ||
                    table === null ||
                    readonlyArray.isEmpty(table.body) ? (_jsx(EmptyList, { message: "Nessuna pratica disponibile" })) : (_jsx(TableContainer, { component: Paper, style: { marginBottom: 16 }, children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: table.headers.clientFullName }), _jsx(TableCell, { children: table.headers.recordId }), _jsx(TableCell, { children: table.headers.brokerageAmount }), _jsx(TableCell, { children: table.headers.recordState }), _jsx(TableCell, {})] }) }), _jsx(TableBody, { children: pipe(table.body, readonlyArray.mapWithIndex((key, { clientFullName, recordId, brokerageAmount, recordState }) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: clientFullName }), _jsx(TableCell, { children: recordId }), _jsx(TableCell, { children: brokerageAmount }), _jsx(TableCell, { children: recordState }), _jsx(TableCell, { children: _jsx(Grid, { container: true, justifyContent: "flex-end", alignItems: "center", children: _jsx(Authorization, { permissions: [PERMISSION.CREATE_ACTIVE_INVOICE], children: _jsx(Grid, { item: true, children: _jsx(Button, { onClick: () => setDialogState({
                                                                open: true,
                                                                recordId,
                                                                artooRecordData,
                                                                artooRecordAgents,
                                                            }), style: squareButtonStyle, variant: "outlined", children: _jsx(EditIcon, {}) }) }) }) }) })] }, key)))) })] }) })) }))] }));
};
