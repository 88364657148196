import { either, option } from 'fp-ts';
import { constNull, pipe } from 'fp-ts/function';
import { all, call, cancel, debounce, put, select, take, takeLatest, takeLeading } from 'typed-redux-saga';
import { isUserAuthorized } from '../../authorization/useAuthorization';
import { NotificationAction } from '../../store/notification/NotificationAction';
import { PERMISSION, SecurityState } from '../../store/security/SecurityState';
import { Notification } from '../../utils/notification';
import { getPaperworks } from '../web/getPaperworks';
import { getUserListWithRole } from '../web/getUserList';
import { updatePaperwork } from '../web/updatePaperwork';
import { $Coassignee } from './CoassigneeState';
import { $CoassigneePaperwork } from './coassigneePaperwork/CoassigneePaperworkState';
function* fetchCoassigneeList(fetchCoassigneeListCommand) {
    yield* put($Coassignee.CoassigneeListRequested());
    const coassigneeListEither = yield* call(getUserListWithRole(fetchCoassigneeListCommand.payload.params));
    if (either.isLeft(coassigneeListEither)) {
        yield* put($Coassignee.CoassigneeListNotFetched());
    }
    else {
        yield* put($Coassignee.CoassegneeListFetched(coassigneeListEither.right));
    }
}
function* fetchPaperwork(legacyId) {
    yield* put($Coassignee.PaperworkRequested());
    const paperworkListEither = yield* call(getPaperworks({
        legacyId: legacyId,
    }));
    if (either.isLeft(paperworkListEither)) {
        yield* put($Coassignee.PaperworkNotFetched());
    }
    else {
        yield* put($Coassignee.PaperwordFetched(paperworkListEither.right));
    }
}
function* updateCoassignee(paperworkId, updateCoassigneeCommand) {
    yield* put($Coassignee.CoassigneeUpdateRequested());
    const coassigneeId = pipe(updateCoassigneeCommand.payload.coassigneeForm.coassigneeId, option.of, option.filter(id => id.toString().length > 0), option.getOrElseW(constNull));
    const coassigneeFullName = pipe(updateCoassigneeCommand.payload.coassigneeForm.coassigneeFullName, option.of, option.filter(fullName => fullName.length > 0), option.getOrElseW(constNull));
    let paperworkEither;
    if (coassigneeId === null || coassigneeFullName === null) {
        paperworkEither = yield* call(updatePaperwork(paperworkId, {
            coAssignee: null,
        }));
    }
    else {
        paperworkEither = yield* call(updatePaperwork(paperworkId, {
            coAssignee: { fullName: coassigneeFullName, legacyId: coassigneeId },
        }));
    }
    if (either.isLeft(paperworkEither)) {
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore. Riprova')));
        yield* put($Coassignee.CoassigneeNotUpdated());
    }
    else {
        yield* put($Coassignee.CoassigneeUpdated());
        yield* put($CoassigneePaperwork.fetchPaperworkById({ artooRecordId: paperworkId }));
        yield* put(NotificationAction.Push(Notification.info('Coassegnatario aggiornato correttamente')));
        if (updateCoassigneeCommand.payload.onSuccess) {
            yield* call(updateCoassigneeCommand.payload.onSuccess);
        }
    }
}
export function* CoassigneeSaga() {
    yield* takeLeading($Coassignee.start, function* (startCommand) {
        const userPermissionList = yield* select(SecurityState.selector.permissionList);
        if (isUserAuthorized(userPermissionList)(PERMISSION.UPDATE_COASSIGNEE)) {
            yield* put($Coassignee.Started());
            const task = yield* all([
                yield* debounce(1000, $Coassignee.fetchCoassigneeList, fetchCoassigneeList),
                yield* takeLatest($Coassignee.updateCoassignee, function* (updateCoassigneeCommand) {
                    yield* call(updateCoassignee, startCommand.payload.artooRecordId, updateCoassigneeCommand);
                }),
                yield* takeLatest($Coassignee.fetchPaperwork, function* () {
                    yield* call(fetchPaperwork, startCommand.payload.artooRecordId);
                }),
            ]);
            yield* put($Coassignee.fetchPaperwork());
            yield* take($Coassignee.stop);
            yield* cancel(task);
            yield* put($Coassignee.Stopped());
        }
    });
}
