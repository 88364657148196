import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()(theme => ({
    textfield: {
        '& .MuiFilledInput-root': {
            backgroundColor: '#FCD8DF',
            padding: '10px 12px 10px',
        },
        '& .MuiFilledInput-input': {
            textAlign: 'center',
            width: 385,
            fontSize: theme.typography.body1.fontSize,
        },
    },
    wrapper: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.grey.A100,
    },
    container: {
        paddingTop: 145,
        paddingBottom: 145,
        padding: '145px 200px',
        height: '100%',
        alignContent: 'space-evenly',
    },
    box: {
        display: 'block',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0 4px 14px 0 rgba(0, 0, 0, 0.1)',
        borderRadius: 20,
        width: 900,
        height: 600,
    },
}));
