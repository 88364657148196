import { DEFAULT_PAGE_SIZE } from '../../constants';
import { Refinement } from '../../lib/Refinement';
import { Process } from '../../lib/redux/Process';
import { Reducer } from '../../lib/redux/Reducer';
import { ProcessState } from '../processes/ProcessState';
import { RecordListActions } from './RecordListActions';
const empty = {
    items: [],
    links: { first: null, last: null, next: null, prev: null },
    meta: {
        page: {
            number: 0,
            size: DEFAULT_PAGE_SIZE,
            total: 0,
        },
    },
};
const reducer = Reducer(Refinement.any(RecordListActions.List.is.done))(empty)({
    [RecordListActions.List.type.done]: (state, { recordList, meta, links }) => (Object.assign(Object.assign({}, state), { items: recordList, meta,
        links })),
});
const recordListProcessTs = (state) => ProcessState.selector
    .processes(RecordListActions.List)(state)
    .filter(Process.is.done)
    .reduce((acc, val) => val.timestamp, 0);
export const RecordListState = {
    reducer: reducer,
    selector: {
        recordListProcessTs,
    },
};
