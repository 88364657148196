import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $PaperworkProcess, $PaperworkProcessSlice } from '../../state/PaperworkProcessState';
import { GenericError } from '../atoms/GenericError';
import { Loader } from '../atoms/Loader';
const copyDigitalSignatureLinkToClipboard = (signerUrl) => (setShowCopiedText) => () => {
    navigator.clipboard.writeText(signerUrl);
    setShowCopiedText(prevState => (Object.assign(Object.assign({}, prevState), { [signerUrl]: true })));
    setTimeout(() => {
        setShowCopiedText(prevState => (Object.assign(Object.assign({}, prevState), { [signerUrl]: false })));
    }, 1000);
};
export const Signers = () => {
    const [showCopiedText, setShowCopiedText] = useState({});
    const signers = useAppSelector($PaperworkProcessSlice.selector.signers);
    const isLoading = useAppSelector($PaperworkProcessSlice.selector.loading).signers;
    const hasError = useAppSelector($PaperworkProcessSlice.selector.errors).signers;
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch($PaperworkProcess.fetchSigners());
        return () => {
            dispatch($PaperworkProcess.clearSigners());
        };
    }, [dispatch]);
    return (_jsxs(_Fragment, { children: [!isLoading && hasError && (_jsx(GenericError, { title: "Si \u00E8 verificato un errore con il recupero della lista dei firmatari." })), isLoading && _jsx(Loader, {}), !isLoading &&
                !hasError &&
                signers.map((signer, index) => (_jsxs(Grid, { container: true, justifyContent: "space-between", children: [_jsxs(Grid, { item: true, children: [_jsxs(Typography, { children: [signer.firstName, " ", signer.lastName] }), _jsx(Typography, { fontWeight: "500", children: signer.email })] }), _jsxs(Grid, { item: true, children: [_jsx(Button, { onClick: copyDigitalSignatureLinkToClipboard(signer.link)(setShowCopiedText), startIcon: _jsx(ContentCopyIcon, {}), sx: { mb: '4px' }, children: "Copia link" }), showCopiedText[signer.link] && (_jsx(Typography, { sx: { textAlign: 'center' }, variant: "subtitle2", children: "Copiato!" }))] })] }, index)))] }));
};
