import { Lenses } from '../../lib/Lenses';
export const CategoryRequirement = {
    lens: Lenses.fromProps()({
        id: 'categoryId',
        code: 'code',
        name: 'name',
        parent: 'parent',
        required: 'required',
        fillable: 'fillable',
    }),
};
