import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()(theme => ({
    container: {
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap',
    },
    backgroundPrimaryDark: {
        backgroundColor: theme.palette.primary.main,
    },
    boxShadowNone: {
        boxShadow: 'none',
    },
    height60: {
        minHeight: 60,
        height: 60,
    },
    heightAll: {
        height: '100%',
    },
    mlr2: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
    ml20px: {
        marginLeft: 20,
    },
    justifyContentEnd: {
        justifyContent: 'flex-end',
    },
    alignItemsCenter: {
        alignItems: 'center',
    },
}));
