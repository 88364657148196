import * as t from 'io-ts';
import { SchemaVersionC } from './SchemaVersion';
export const RecordDataC = t.intersection([
    t.UnknownRecord,
    t.partial({
        id_egg: t.union([t.string, t.number]),
        _schema_version: SchemaVersionC,
        tipo_di_prodotto: t.string,
        store: t.string,
    }),
]);
