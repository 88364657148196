import { combineReducers } from '@reduxjs/toolkit';
import ActiveInvoiceCreationState from '../activeInvoicing/state/activeInvoiceCreation/ActiveInvoiceCreationState';
import ActiveInvoiceHistoryState from '../activeInvoicing/state/activeInvoiceHistory/ActiveInvoiceHistoryState';
import ActiveInvoicingState from '../activeInvoicing/state/activeInvoicing/ActiveInvoicingState';
import CoassigneeState from '../coassignee/state/CoassigneeState';
import CoassigneePaperworkState from '../coassignee/state/coassigneePaperwork/CoassigneePaperworkState';
import { CommissionPlanState } from '../commissionPlan/state/State';
import { EnasarcoState } from '../enasarco/state/State';
import { PaperworkListState } from '../paperworkProcess/state/PaperworkListState';
import { PaperworkProcessState } from '../paperworkProcess/state/PaperworkProcessState';
import InvoiceApprovalState from '../passiveInvoicing/state/invoiceApproval/InvoiceApprovalState';
import InvoiceCreationState from '../passiveInvoicing/state/invoiceCreation/InvoiceCreationState';
import InvoiceCreationFromMpnState from '../passiveInvoicing/state/invoiceCreationFromMpn/InvoiceCreationFromMpnState';
import InvoiceCreationFromRecordState from '../passiveInvoicing/state/invoiceCreationFromRecord/InvoiceCreationFromRecordState';
import InvoiceDeletionState from '../passiveInvoicing/state/invoiceDeletion/InvoiceDeletionState';
import InvoiceDetailState from '../passiveInvoicing/state/invoiceDetail/invoiceDetailState';
import InvoiceEditState from '../passiveInvoicing/state/invoiceEdit/InvoiceEditState';
import InvoiceHistoryState from '../passiveInvoicing/state/invoiceHistory/InvoiceHistoryState';
import InvoiceListState from '../passiveInvoicing/state/invoiceList/InvoiceListState';
import InvoiceListVisibilityState from '../passiveInvoicing/state/invoiceListVisibility/InvoiceListVisibilityState';
import InvoiceTransmissionState from '../passiveInvoicing/state/invoiceTransmission/InvoiceTransmissionState';
import PassiveInvoiceBulkEditActionsState from '../passiveInvoicing/state/passiveInvoiceBulkEditActions/PassiveInvoiceBulkEditActionsState';
import PayinRegistrationState from '../passiveInvoicing/state/payinRegistration/PayinRegistrationState';
import RecordCommissionsState from '../passiveInvoicing/state/recordCommissions/RecordCommissionsState';
import RecordListWithCommissionsState from '../passiveInvoicing/state/recordListWithCommissions/RecordListWithCommissionsState';
import RecordListWithPayinState from '../passiveInvoicing/state/recordListWithPayin/RecordListWithPayinState';
import { ProcessingQuotesState } from '../quotingTool/state/ProcessingQuotesState';
import { RequestWithQuotesState } from '../quotingTool/state/RequestWithQuotesState';
import { QuoteState } from '../quotingTool/state/State';
import { MpnQuotationState } from '../quotingTool/state/mpn/State';
import { SessionState } from '../session/state/SessionState';
import { CommissionsState } from './commissions/CommissionsState';
import { RecordListState } from './list/RecordListState';
import { NotificationState } from './notification/NotificationState';
import { ProcessState } from './processes/ProcessState';
import { RecordState } from './record/RecordState';
import { FilterReducer } from './reducers/filter';
import { PipelinesReducer } from './reducers/pipelines';
import { SecurityState } from './security/SecurityState';
export const State = {
    reducer: (routerReducer) => combineReducers({
        router: routerReducer,
        pipelines: PipelinesReducer,
        filter: FilterReducer,
        record: RecordState.reducer,
        processes: ProcessState.reducer,
        security: SecurityState.reducer,
        notification: NotificationState.reducer,
        list: RecordListState.reducer,
        commissions: CommissionsState,
        quote: QuoteState,
        mpnQuotation: MpnQuotationState,
        commissionPlan: CommissionPlanState,
        enasarco: EnasarcoState,
        invoiceHistory: InvoiceHistoryState,
        invoiceDetail: InvoiceDetailState,
        invoiceApproval: InvoiceApprovalState,
        invoiceEdit: InvoiceEditState,
        records: RecordListWithCommissionsState,
        invoiceCreationFromMpn: InvoiceCreationFromMpnState,
        invoiceListVisibility: InvoiceListVisibilityState,
        invoiceCreation: InvoiceCreationState,
        invoiceDeletion: InvoiceDeletionState,
        invoiceList: InvoiceListState,
        passiveInvoiceBulkEdit: PassiveInvoiceBulkEditActionsState,
        invoiceCreationFromRecord: InvoiceCreationFromRecordState,
        invoiceTransmission: InvoiceTransmissionState,
        payinRegistration: PayinRegistrationState,
        recordCommissions: RecordCommissionsState,
        recordListWithPayin: RecordListWithPayinState,
        activeInvoiceHistory: ActiveInvoiceHistoryState,
        activeInvoicing: ActiveInvoicingState,
        activeInvoiceCreation: ActiveInvoiceCreationState,
        coassignee: CoassigneeState,
        coassigneePaperwork: CoassigneePaperworkState,
        processingQuotes: ProcessingQuotesState,
        requestWithQuotes: RequestWithQuotesState,
        paperworkProcess: PaperworkProcessState,
        paperworkList: PaperworkListState,
        session: SessionState,
    }),
};
