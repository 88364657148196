import { either, taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { PooledClient, HttpClientPool } from '../../lib/http/PooledClient';
import { InvoiceListVisibilityCodec } from '../models/InvoiceListVisibility';
export const invoiceListVisibility = (payload) => {
    const visibility = payload.method === 'post' ? `/${payload.visibility}` : '';
    return pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool)[payload.method](`${BAZAAR_URL}/api/v1/config/invoice/agent/visibility${visibility}`), e => e), taskEither.chainEitherKW(response => pipe(response.data, t.type({ data: InvoiceListVisibilityCodec }).decode, either.map(({ data }) => data))));
};
