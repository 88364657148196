import { $type } from 'fortepiano';
import { readonlyRecord } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
export const ArtooRecordDataCodec = t.intersection([
    t.record(t.string, t.unknown),
    t.type({
        nome: t.string,
        cognome: t.string,
        codice_fiscale: t.string,
        indirizzo_di_residenza: t.string,
        cap_di_residenza: t.string,
        comune_di_residenza: t.string,
        provincia_di_residenza: t.string,
        tipo_di_prodotto: t.string,
    }),
    t.partial({
        intestatario_0_nome: t.string,
        intestatario_0_cognome: t.string,
        intestatario_0_codice_fiscale: t.string,
        intestatario_0_indirizzo_di_residenza: t.string,
        intestatario_0_cap_di_residenza: t.string,
        intestatario_0_comune_di_residenza: t.string,
        intestatario_0_provincia_di_residenza: t.string,
        intestatario_1_nome: t.string,
        intestatario_1_cognome: t.string,
        intestatario_1_codice_fiscale: t.string,
        intestatario_1_indirizzo_di_residenza: t.string,
        intestatario_1_cap_di_residenza: t.string,
        intestatario_1_comune_di_residenza: t.string,
        intestatario_1_provincia_di_residenza: t.string,
        intestatario_2_nome: t.string,
        intestatario_2_cognome: t.string,
        intestatario_2_codice_fiscale: t.string,
        intestatario_2_indirizzo_di_residenza: t.string,
        intestatario_2_cap_di_residenza: t.string,
        intestatario_2_comune_di_residenza: t.string,
        intestatario_2_provincia_di_residenza: t.string,
        garante_0_nome: t.string,
        garante_0_cognome: t.string,
        garante_0_codice_fiscale: t.string,
        garante_0_indirizzo_di_residenza: t.string,
        garante_0_cap_di_residenza: t.string,
        garante_0_comune_di_residenza: t.string,
        garante_0_provincia_di_residenza: t.string,
        garante_1_nome: t.string,
        garante_1_cognome: t.string,
        garante_1_codice_fiscale: t.string,
        garante_1_indirizzo_di_residenza: t.string,
        garante_1_cap_di_residenza: t.string,
        garante_1_comune_di_residenza: t.string,
        garante_1_provincia_di_residenza: t.string,
        datore_0_nome: t.string,
        datore_0_cognome: t.string,
        datore_0_codice_fiscale: t.string,
        datore_0_indirizzo_di_residenza: t.string,
        datore_0_cap_di_residenza: t.string,
        datore_0_comune_di_residenza: t.string,
        datore_0_provincia_di_residenza: t.string,
        datore_1_nome: t.string,
        datore_1_cognome: t.string,
        datore_1_codice_fiscale: t.string,
        datore_1_indirizzo_di_residenza: t.string,
        datore_1_cap_di_residenza: t.string,
        datore_1_comune_di_residenza: t.string,
        datore_1_provincia_di_residenza: t.string,
        mutuo_importo_di_mediazione: $type.numeric,
        mutuo_istituto: t.string,
        prestito_finanziaria: t.string,
        cessione_del_quinto_finanziaria: t.string,
        anticipo_tfs_finanziaria: t.string,
        polizza_assicurativa_compagnia_assicurativa: t.string,
    }),
], 'ArtooRecordData');
const getInstitute = (artooRecordData) => {
    switch (artooRecordData.tipo_di_prodotto) {
        case 'Mutuo':
            return artooRecordData.mutuo_istituto;
        case 'Prestito':
            return artooRecordData.prestito_finanziaria;
        case 'CQS':
            return artooRecordData.cessione_del_quinto_finanziaria;
        case 'Anticipo TFS':
            return artooRecordData.anticipo_tfs_finanziaria;
        case 'Polizza assicurativa':
            return artooRecordData.polizza_assicurativa_compagnia_assicurativa;
    }
};
const lookupArtooRecordDataValue = (artooRecordDataKey) => (artooRecordData) => pipe(artooRecordData, readonlyRecord.lookup(artooRecordDataKey));
export const ArtooRecordDataUtils = {
    lookup: lookupArtooRecordDataValue,
    getInstitute,
};
