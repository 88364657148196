import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import SettingsIcon from '@mui/icons-material/Settings';
import { Button, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { toggle } from '../../../common/state/toggle';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { Prompt } from '../../../ui/Prompt/PromptComponent';
import { $PaperworkProcessSlice } from '../../state/PaperworkProcessState';
import { DragDropFileUpload } from './DragDropFileUpload';
const useStyles = makeStyles()(() => ({
    title: {
        color: '#6F797A',
    },
}));
export const DocumentationItem = ({ title, documentTypeId, canUpload }) => {
    const { classes } = useStyles();
    const [showFileUploaderDialog, setShowFileUploaderDialog] = useState(false);
    const files = useAppSelector($PaperworkProcessSlice.selector.files)[documentTypeId];
    return (_jsxs(_Fragment, { children: [showFileUploaderDialog && (_jsx(Prompt, { isOpen: true, title: title, onCancelText: "Chiudi", onCancelAction: () => setShowFileUploaderDialog(false), children: _jsx(DragDropFileUpload, { files: files, canUpload: canUpload, documentTypeId: documentTypeId }) })), _jsxs(Grid, { container: true, justifyContent: "space-between", alignItems: "center", flexWrap: "nowrap", children: [_jsx(Grid, { item: true, children: _jsx(Typography, { fontWeight: 500, className: classes.title, children: title }) }), _jsx(Grid, { item: true, children: _jsx(Button, { onClick: toggle(setShowFileUploaderDialog), size: "small", variant: "text", startIcon: _jsx(SettingsIcon, {}), disabled: !canUpload && ((files && files.length === 0) || !files), children: "Gestisci" }) })] })] }));
};
