import axios from 'axios';
export class AxiosClient {
    addBearerAuthorization(token) {
        return { Authorization: `Bearer ${token}` };
    }
    constructor(url) {
        this.http = axios.create({
            baseURL: url,
            headers: { 'content-type': 'application/json' },
        });
    }
}
