import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
export const ScrollToTop = ({ children }) => {
    const location = useLocation();
    useEffect(() => {
        const scrollNavbarId = document.getElementById('scroll-navbar');
        if (scrollNavbarId) {
            scrollNavbarId.scrollTo(0, 0);
        }
    }, [location.pathname]);
    return _jsx(_Fragment, { children: children });
};
