import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import WarningIcon from '@mui/icons-material/Warning';
import { Avatar, Box, Grid, IconButton, LinearProgress, Typography } from '@mui/material';
import { FileDescription } from '../atoms/FileDescription';
import { TransitionTooltip } from '../atoms/TransitionTooltip';
export const FilePreview = ({ onDelete, file }) => {
    return (_jsxs(Grid, { container: true, justifyContent: "space-between", alignItems: "center", flexWrap: "nowrap", children: [_jsx(Grid, { item: true, children: _jsxs(Grid, { container: true, flexWrap: "nowrap", alignItems: "center", children: [_jsxs(Grid, { item: true, sx: { mr: '8px' }, children: [!file.hasError && (_jsx(Avatar, { sx: { bgcolor: '#D9E2FF' }, children: _jsx(InsertDriveFileIcon, { sx: { color: '#0057CD' } }) })), file.hasError && (_jsx(Avatar, { color: "error", children: _jsx(WarningIcon, { color: "error" }) }))] }), _jsxs(Grid, { container: true, children: [_jsxs(Grid, { container: true, item: true, alignItems: "center", children: [_jsx(Typography, { children: file.name }), _jsx(TransitionTooltip, { transitionsHistory: file.transitionsHistory })] }), file.isUploading && (_jsx(Box, { sx: { width: '100%' }, children: _jsx(LinearProgress, {}) })), file.isFileTypeSupported && (_jsx(Grid, { container: true, item: true, children: _jsx(Typography, { children: _jsx(FileDescription, { date: file.uploadedAt, fileSize: file.size, fileType: file.type }) }) })), !file.isFileTypeSupported && (_jsx(Grid, { container: true, item: true, children: _jsx(Typography, { color: "error", children: "Tipo di file non consentito" }) }))] })] }) }), onDelete && (_jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: onDelete, children: _jsx(DeleteOutlineIcon, { sx: { color: '#000' } }) }) }))] }));
};
