import { getYear } from 'date-fns';
import { $number, $string } from 'fortepiano';
import { boolean, number, option, readonlyArray } from 'fp-ts';
import { concatAll } from 'fp-ts/Monoid';
import { not } from 'fp-ts/Predicate';
import { constFalse, constant, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { readonlyNonEmptyArray } from 'io-ts-types';
import { $date } from '../Date';
import { IdentifierCodec } from '../Identifier.codec';
import { InvoiceApprovedCodec } from '../InvoiceApproved/InvoiceApproved.codec';
import { InvoiceItemCodec } from '../InvoiceItem/InvoiceItem';
import { InvoiceStatusCodeCodec, InvoiceStatusCodeUtils } from '../InvoiceStatusCode/InvoiceStatusCode';
export const InvoiceCodec = t.type({
    _id: IdentifierCodec,
    month: t.union([t.string, t.null]),
    userId: IdentifierCodec,
    blaineId: t.unknown,
    documentNumber: t.union([t.number, t.null]),
    hasEnasarco: t.union([t.null, t.undefined, t.boolean]),
    items: readonlyNonEmptyArray(InvoiceItemCodec),
    statusCode: InvoiceStatusCodeCodec,
    statusHistory: t.unknown,
    approved: InvoiceApprovedCodec,
}, 'Invoice');
const fromNullableBoolean = (value) => pipe(value, option.fromNullable, option.getOrElse(constFalse));
const enasarco = (value) => {
    switch (value) {
        case true:
            return 'si';
        case false:
            return 'no';
        default:
            return '';
    }
};
const month = (value) => pipe(value, option.fromNullable, option.chain($date.month), option.getOrElse(constant('-')));
const year = (invoice) => pipe(invoice.month, option.fromNullable, option.map(invoiceMonth => getYear(new Date(invoiceMonth))), option.filter(not(isNaN)));
const getAmount = (invoices) => pipe(invoices, readonlyArray.map(({ amount }) => amount), concatAll(number.MonoidSum));
const getEnasarcoDeficit = (enasarcoDeficit) => (invoice) => pipe(invoice.items, getAmount, amount => pipe(enasarcoDeficit, option.fromNullable, option.map(leftover => leftover - amount), option.map($number.round(2)), option.filter(leftover => leftover < 0), option.map(Math.abs)));
const invoiceListLength = (invoiceList) => invoiceList.length;
const isTransmitted = (invoice) => invoice.statusCode === InvoiceStatusCodeUtils.TRANSMITTED;
const getDocumentNumber = (value) => pipe(value, option.fromNullable, option.map(documentNumber => documentNumber.toString()), option.map($string.prefix('FMP-')), option.getOrElse(constant('-')));
const hasEnasarco = (hasInvoiceEnasarco) => pipe(hasInvoiceEnasarco, fromNullableBoolean, boolean.match(constant('No'), constant('Sì')));
export const InvoiceUtils = {
    invoiceListLength,
    amount: getAmount,
    year,
    month,
    enasarcoDeficit: getEnasarcoDeficit,
    enasarco,
    isTransmitted,
    documentNumber: getDocumentNumber,
    hasEnasarco,
};
