import { EmptyAction, PayloadedAction } from '../../lib/PayloadedAction';
/*
 * action types
 */
export const SHOW_AGENTS_RECORDS_FILTER = 'SHOW_AGENTS_RECORDS_FILTER';
export const AGENTS_RECORDS_FILTER_REQUESTED = 'AGENTS_RECORDS_FILTER_REQUESTED';
export const AGENTS_RECORDS_FILTER_FETCHED = 'AGENTS_RECORDS_FILTER_FETCHED';
export const AGENTS_RECORDS_FILTER_NOT_FETCHED = 'AGENTS_RECORDS_FILTER_NOT_FETCHED';
export const STATUS_FILTER_REQUEST = 'STATUS_FILTER_REQUEST';
export const STATUS_FILTER_SUCCESS = 'STATUS_FILTER_SUCCESS';
export const STATUS_FILTER_FAILURE = 'STATUS_FILTER_FAILURE';
export const ASSIGNEE_FILTER_REQUEST = 'ASSIGNEE_FILTER_REQUEST';
export const ASSIGNEE_FILTER_SUCCESS = 'ASSIGNEE_FILTER_SUCCESS';
export const ASSIGNEE_FILTER_FAILURE = 'ASSIGNEE_FILTER_FAILURE';
export const UPDATE_FILTER_REQUEST = 'UPDATE_FILTER_REQUEST';
export const UPDATE_FILTER_SUCCESS = 'UPDATE_FILTER_SUCCESS';
export const UPDATE_FILTER_FAILURE = 'UPDATE_FILTER_FAILURE';
export const RECORD_LIST_WITH_FILTERS_REQUEST = 'RECORD_LIST_WITH_FILTERS_REQUEST';
export const RECORD_LIST_WITH_FILTERS_SUCCESS = 'RECORD_LIST_WITH_FILTERS_SUCCESS';
export const RECORD_LIST_WITH_FILTERS_FAILURE = 'RECORD_LIST_WITH_FILTERS_FAILURE';
export const SAVE_FILTER = 'SAVE_FILTER';
/*
 * action creators
 */
export const ShowAgentsRecordsFilter = EmptyAction(SHOW_AGENTS_RECORDS_FILTER);
export const AgentsRecordsFilterRequested = EmptyAction(AGENTS_RECORDS_FILTER_REQUESTED);
export const AgentsRecordsFilterFetched = PayloadedAction(AGENTS_RECORDS_FILTER_FETCHED);
export const AgentsRecordsFilterNotFetched = EmptyAction(AGENTS_RECORDS_FILTER_NOT_FETCHED);
export const StatusFilterRequest = EmptyAction(STATUS_FILTER_REQUEST);
export const StatusFilterSuccess = PayloadedAction(STATUS_FILTER_SUCCESS);
export const StatusFilterFailure = PayloadedAction(STATUS_FILTER_FAILURE);
export const AssigneeFilterRequest = EmptyAction(ASSIGNEE_FILTER_REQUEST);
export const AssigneeFilterSuccess = PayloadedAction(ASSIGNEE_FILTER_SUCCESS);
export const AssigneeFilterFailure = PayloadedAction(ASSIGNEE_FILTER_FAILURE);
export const UpdateFilterRequest = PayloadedAction(UPDATE_FILTER_REQUEST);
export const UpdateFilterSuccess = PayloadedAction(UPDATE_FILTER_SUCCESS);
export const UpdateFilterFailure = PayloadedAction(UPDATE_FILTER_FAILURE);
export const RecordListWithFiltersRequest = PayloadedAction(RECORD_LIST_WITH_FILTERS_REQUEST);
export const RecordListWithFiltersSuccess = PayloadedAction(RECORD_LIST_WITH_FILTERS_SUCCESS);
export const RecordListWithFiltersFailure = PayloadedAction(RECORD_LIST_WITH_FILTERS_FAILURE);
export const SaveFilter = EmptyAction(SAVE_FILTER);
