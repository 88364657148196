import { createSlice } from '@reduxjs/toolkit';
import { $http } from 'fortepiano';
import { either, monoid, number, option, readonlyArray, string } from 'fp-ts';
import { constant, constUndefined, identity, pipe } from 'fp-ts/function';
import { Eq } from 'fp-ts/string';
import { BazaarErrorCodec } from '../../models/BazaarError';
import { $currency } from '../../models/Currency/Currency';
import { $httpError } from '../../models/HttpError/HttpError';
import { CommissionsForm } from '../commissionsForm/CommissionsForm';
const EMPTY = '-';
const GENERIC_COMMISSIONS_ERROR_MESSAGE = 'Si è verificato un errore durante il caricamento delle provvigioni';
const headerLabel = (profile) => {
    switch (profile) {
        case 'FMP':
            return 'FMP';
        case 'Head divisional':
        case 'HD super':
        case 'HD basic':
            return 'Head divisional';
        case 'AM special':
        case 'HA super':
        case 'HA ad hoc':
        case 'HA junior':
        case 'HA special':
        case 'Head agency':
            return 'Head agency';
        case 'CPA super':
        case 'CPA':
        case 'CPA basic':
        case 'CPA junior':
        case 'CPB':
        case 'CPC':
        case 'CPC basic':
        case 'CPC super':
        case 'CPD':
        case 'CPE':
        case 'CPF':
        case 'CPG':
        case 'CPH':
        case 'CPI':
        case 'Neofita':
        case 'Assi':
            return 'Credit planner';
        default:
            return profile;
    }
};
const initialState = {
    loading: { table: false, editing: false },
    error: undefined,
    table: null,
    initialValues: [],
};
const headers = (commissions) => pipe(commissions, readonlyArray.head, option.map(readonlyArray.map(({ profile, role }) => role !== null && role !== void 0 ? role : profile)), option.map(readonlyArray.map(headerLabel)), option.map(readonlyArray.map(profile => (profile === 'FMP' ? 'Marginalità FMP' : profile))), option.map(header => readonlyArray.concat(header)(['Payin'])), option.map(readonlyArray.toArray), option.getOrElseW(constant([])));
const firstColumnTable = (groupedCommissionsMatrix) => pipe(groupedCommissionsMatrix, readonlyArray.traverse(option.Applicative)(readonlyArray.head), option.map(readonlyArray.map(({ productType }) => productType)), option.map(readonlyArray.toArray), option.getOrElseW(() => []));
const formattedLeftoverPayin = (leftoverPayinAmount) => leftoverPayinAmount === 0 ? $currency.toEuros(leftoverPayinAmount) : $currency.toEuros(leftoverPayinAmount, '-');
const leftoverPayinAndRegisteredPayinSum = (payins) => pipe(payins, monoid.concatAll(monoid.struct({
    leftoverPayinAmount: number.MonoidSum,
    registeredPayinAmount: number.MonoidSum,
    productType: string.Monoid,
})), payin => (Object.assign(Object.assign({}, payin), { leftoverPayin: formattedLeftoverPayin(payin.leftoverPayinAmount), registeredPayin: $currency.toEuros(payin.registeredPayinAmount) })));
const payinByProductType = (payins) => (productType) => productType === 'TOTALE' && payins.length !== 0
    ? leftoverPayinAndRegisteredPayinSum(payins)
    : pipe(payins, readonlyArray.findFirst(payin => Eq.equals(productType, payin.productType)), option.map(payin => ({
        productType: payin.productType,
        leftoverPayin: formattedLeftoverPayin(payin.leftoverPayinAmount),
        registeredPayin: $currency.toEuros(payin.registeredPayinAmount),
    })), option.getOrElse(constant({
        leftoverPayin: EMPTY,
        registeredPayin: EMPTY,
        productType,
    })));
const payinColumn = (groupedCommissionsMatrix, payins) => pipe(groupedCommissionsMatrix, readonlyArray.traverse(option.Applicative)(readonlyArray.head), option.map(readonlyArray.map(({ productType }) => productType)), option.map(readonlyArray.map(payinByProductType(payins))), option.map(readonlyArray.toArray), option.getOrElse(constant([])));
const commissionsTableWithPayins = (commissionsTable, payins) => ({
    headers: headers(commissionsTable),
    firstColumn: firstColumnTable(commissionsTable),
    payinColumn: payinColumn(commissionsTable, payins),
});
const errorMessage = (maybeEventPayloadError) => maybeEventPayloadError !== undefined
    ? pipe(maybeEventPayloadError.payins, clientErrorMessage, option.alt(() => pipe(maybeEventPayloadError.commissions, clientErrorMessage)), option.getOrElseW(constUndefined))
    : undefined;
const clientErrorMessage = (maybeEventTypeError) => pipe(maybeEventTypeError, $http.HttpErrorC().decode, option.fromEither, option.filter(httpError => $httpError.isClientError(httpError.response.status)), option.chain(httpError => pipe(httpError.response.body, BazaarErrorCodec.decode, either.map(bazaarError => bazaarError.detail), option.fromEither)));
export const RecordCommissionsSlice = createSlice({
    name: 'RecordCommissionsSaga',
    initialState,
    reducers: {
        start: (state, _) => state,
        stop: identity,
        Started: identity,
        Stopped: constant(initialState),
        fetchCommissions: identity,
        editCommissions: (state, _) => state,
        CommissionsEditRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { editing: true }) })),
        CommissionsEdited: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { editing: false }), error: event.payload.error, table: commissionsTableWithPayins(event.payload.commissions, event.payload.payins), initialValues: CommissionsForm.withFormProps(event.payload.commissions) })),
        CommissionsNotEdited: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { editing: false }), error: event.payload })),
        CommissionsRequested: state => (Object.assign(Object.assign({}, state), { error: undefined, loading: Object.assign(Object.assign({}, state.loading), { table: true }), table: initialState.table })),
        CommissionsFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { table: false }), error: event.payload.error, table: commissionsTableWithPayins(event.payload.commissions, event.payload.payins), initialValues: CommissionsForm.withFormProps(event.payload.commissions) })),
        CommissionsNotFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { table: false }), error: event.payload, table: null })),
    },
});
export const $RecordCommissions = RecordCommissionsSlice.actions;
const isLoading = (state) => state.recordCommissions.loading;
const table = (state) => state.recordCommissions.table;
const error = (state) => state.recordCommissions.error;
const initialValues = (state) => state.recordCommissions.initialValues;
export const $RecordCommissionsSlice = {
    headerLabel,
    payinColumn,
    firstColumnTable,
    GENERIC_COMMISSIONS_ERROR_MESSAGE,
    errorMessage,
    clientErrorMessage,
    selector: { isLoading, table, error, initialValues },
};
export default RecordCommissionsSlice.reducer;
