var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createSlice } from '@reduxjs/toolkit';
import { boolean, monoid, option, readonlyArray, readonlyNonEmptyArray, readonlyRecord, semigroup, string } from 'fp-ts';
import { not } from 'fp-ts/Predicate';
import { constant, identity, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { ArtooRecordDataUtils } from '../../models/ArtooRecordData/ArtooRecordData';
const customerMortgageTypologyList = ['intestatario', 'garante', 'datore'];
const initialState = {
    loading: { isTransmitting: false, isFetchingInvoiceList: false },
    customersRegistry: [],
    initialValues: {
        customerFullNameWithTier: '',
        invoiceTypology: 'MEDIAZIONE',
        invoiceDescription: 'Provvigioni per mediazione creditizia',
        invoiceAmount: '',
        issueDate: new Date(),
    },
    minTransmittableDate: new Date(),
    minTransmittableDateError: null,
};
const ordinal = (n) => {
    switch (n) {
        case 1:
            return option.some('1º');
        case 2:
            return option.some('2º');
        case 3:
            return option.some('3º');
        case 4:
            return option.some('4º');
        default:
            return option.none;
    }
};
const intercalate = (separator) => (s0, s1) => pipe(string.Semigroup, semigroup.intercalate(separator), ({ concat }) => concat(s0, s1));
const customerTierOption = (customerTier) => (customerTypology) => pipe(customerTier, ordinal, option.map(ordinalLabel => pipe(customerTypology, string.replace('datore', "datore d'ipoteca"), customer => intercalate(' ')(ordinalLabel, customer))));
const invoiceAmount = (maybeInvoiceAmount) => pipe(maybeInvoiceAmount, option.fromNullable, option.match(constant(''), amount => amount.toString()));
const customerFullNameWithCustomerTierOption = (customerFirstName, customerLastName, customerTypology, customerTier) => pipe(intercalate(' ')(customerFirstName, customerLastName), customerFullName => pipe(customerTypology, customerTierOption(customerTier), option.map(customerTierWithTypology => pipe(intercalate(' - ')(customerFullName, customerTierWithTypology)))));
const firstHolderCustomer = (artooRecordData, artooRecordAgents) => ({
    firstName: artooRecordData.nome,
    lastName: artooRecordData.cognome,
    taxCode: artooRecordData.codice_fiscale,
    address: artooRecordData.indirizzo_di_residenza,
    zipCode: artooRecordData.cap_di_residenza,
    city: artooRecordData.comune_di_residenza,
    province: artooRecordData.provincia_di_residenza,
    agentFullName: intercalate(' ')(artooRecordAgents[0].first_name, artooRecordAgents[0].last_name),
    agentId: artooRecordAgents[0].id,
    fullNameWithTier: pipe(customerFullNameWithCustomerTierOption(artooRecordData.nome, artooRecordData.cognome, 'intestatario', 1), option.getOrElse(constant(''))),
});
const customersRegistry = (customerTypologyList) => (artooRecordData, artooRecordAgents) => pipe(customerTypologyList, readonlyArray.chain(customerTypology => pipe(readonlyArray.makeBy(4, customerTier => pipe(artooRecordData, ArtooRecordDataUtils.lookup(`${customerTypology}_${customerTier}_nome`), option.filter(t.string.is), option.bindTo('firstName'), option.bind('lastName', () => pipe(artooRecordData, ArtooRecordDataUtils.lookup(`${customerTypology}_${customerTier}_cognome`), option.filter(t.string.is))), option.bind('taxCode', () => pipe(artooRecordData, ArtooRecordDataUtils.lookup(`${customerTypology}_${customerTier}_codice_fiscale`), option.filter(t.string.is))), option.bind('address', () => pipe(artooRecordData, ArtooRecordDataUtils.lookup(`${customerTypology}_${customerTier}_indirizzo_di_residenza`), option.filter(t.string.is))), option.bind('zipCode', () => pipe(artooRecordData, ArtooRecordDataUtils.lookup(`${customerTypology}_${customerTier}_cap_di_residenza`), option.filter(t.string.is))), option.bind('city', () => pipe(artooRecordData, ArtooRecordDataUtils.lookup(`${customerTypology}_${customerTier}_comune_di_residenza`), option.filter(t.string.is))), option.bind('province', () => pipe(artooRecordData, ArtooRecordDataUtils.lookup(`${customerTypology}_${customerTier}_provincia_di_residenza`), option.filter(t.string.is))), option.bind('agent', () => pipe(artooRecordAgents, readonlyNonEmptyArray.head, option.of)), option.map((_a) => {
    var { agent } = _a, customer = __rest(_a, ["agent"]);
    return (Object.assign(Object.assign({}, customer), { agentFullName: intercalate(' ')(agent.first_name, agent.last_name), agentId: agent.id, fullNameWithTier: pipe(customerFullNameWithCustomerTierOption(customer.firstName, customer.lastName, customerTypology, customerTypology === 'intestatario' ? customerTier + 2 : customerTier + 1), option.getOrElse(constant(''))) }));
}))))), readonlyArray.compact, readonlyArray.prepend(firstHolderCustomer(artooRecordData, artooRecordAgents)));
const canSave = (activeInvoiceCreationForm) => pipe(activeInvoiceCreationForm, readonlyRecord.map(value => value instanceof Date ? value.getTime() : value), readonlyRecord.map(value => value.toString()), readonlyRecord.every(not(string.isEmpty)));
const minTransmittableDate = (fallbackDate) => (activeInvoiceList) => pipe(activeInvoiceList, option.fromNullable, option.filter(readonlyArray.isNonEmpty), option.map(readonlyNonEmptyArray.head), option.map(({ issueDate }) => issueDate), option.getOrElse(constant(fallbackDate)));
export const activeInvoiceCreationSlice = createSlice({
    name: 'ActiveInvoiceCreationSaga',
    initialState,
    reducers: {
        start: (state, _) => state,
        stop: identity,
        transmitInvoice: (state, _) => state,
        ActiveInvoiceListRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { isFetchingInvoiceList: true }), minTransmittableDateError: null })),
        ActiveInvoiceListFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { isFetchingInvoiceList: false }), minTransmittableDate: minTransmittableDate(new Date())(event.payload), minTransmittableDateError: null })),
        ActiveInvoiceListNotFetched: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { isFetchingInvoiceList: false }), minTransmittableDateError: 'Si è verificato un errore.' })),
        InvoiceTransmissionRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { isTransmitting: true }) })),
        InvoiceTransmitted: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { isTransmitting: false }) })),
        InvoiceNotTransmitted: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { isTransmitting: false }) })),
        Started: (state, event) => {
            const registry = customersRegistry(customerMortgageTypologyList)(event.payload.artooRecordData, event.payload.artooRecordAgents);
            return Object.assign(Object.assign({}, state), { customersRegistry: pipe(registry, readonlyArray.toArray), initialValues: Object.assign(Object.assign({}, state.initialValues), { invoiceAmount: invoiceAmount(event.payload.artooRecordData.mutuo_importo_di_mediazione), customerFullNameWithTier: pipe(registry, readonlyNonEmptyArray.head, customer => customer.fullNameWithTier) }) });
        },
        Stopped: constant(initialState),
    },
});
export const $ActiveInvoiceCreation = activeInvoiceCreationSlice.actions;
export const $activeInvoiceCreationSlice = {
    invoiceAmount,
    minTransmittableDate,
    canSave,
    customerFullNameWithCustomerTierOption,
    customersRegistry: customersRegistry(customerMortgageTypologyList),
    selector: {
        isMinTransmittableDateEnabled: (state) => boolean.MonoidAll.concat(!state.activeInvoiceCreation.loading.isFetchingInvoiceList, state.activeInvoiceCreation.minTransmittableDateError === null),
        minTransmittableDate: (state) => state.activeInvoiceCreation.minTransmittableDate,
        minTransmittableDateError: (state) => state.activeInvoiceCreation.minTransmittableDateError,
        canSave: (state) => (activeInvoiceCreationForm) => monoid.concatAll(boolean.MonoidAll)([
            !state.activeInvoiceCreation.loading.isFetchingInvoiceList,
            !state.activeInvoiceCreation.loading.isTransmitting,
            canSave(activeInvoiceCreationForm),
        ]),
        initialValues: (state) => state.activeInvoiceCreation.initialValues,
        customersRegistry: (state) => state.activeInvoiceCreation.customersRegistry,
    },
};
export default activeInvoiceCreationSlice.reducer;
