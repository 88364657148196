export const mortgagePurposeView = (purpose) => {
    switch (purpose) {
        case 'purchaseWithLiquidity':
            return 'Acquisto + Liquidità';
        case 'purchaseWithRestoration':
            return 'Acquisto + Ristrutturazione';
        case 'greenBuildingPurchase':
            return 'Acquisto Bioedilizia';
        case 'purchaseWithReplacement':
            return 'Acquisto + Sostituzione';
        case 'garagePurchase':
            return 'Acquisto Box';
        case 'firstHomePurchase':
            return 'Acquisto Prima Casa';
        case 'secondHomePurchase':
            return 'Acquisto Seconda Casa';
        case 'officeBuildingPurchase':
            return 'Acquisto ad uso ufficio';
        case 'auctionPurchase':
            return 'Acquisto in Asta';
        case 'foreignResidentsPurchase':
            return 'Acquisto per Residenti Estero';
        case 'constructionCompletion':
            return 'Completamento Costruzione';
        case 'consolidation':
            return 'Consolidamento';
        case 'bonusCasaUnsecuredFinancing':
            return 'Finanziamento chirografario Bonus Casa';
        case 'commercialProperty':
            return 'Immobile commerciale';
        case 'liquidity':
            return 'Liquidità';
        case 'refinancing':
            return 'Rifinanziamento';
        case 'restoration':
            return 'Ristrutturazione';
        case 'replacement':
            return 'Sostituzione';
        case 'replacementWithConsolidation':
            return 'Sostituzione + Consolidamento';
        case 'replacementWithLiquidity':
            return 'Sostituzione + Liquidità';
        case 'replacementWithRestoration':
            return 'Sostituzione + Ristrutturazione';
        case 'subrogation':
            return 'Surroga';
        case 'subrogationWithLiquidity':
            return 'Surroga + liquidità';
        default:
            return '';
    }
};
