import { isLeft } from 'fp-ts/Either';
import { isSome } from 'fp-ts/Option';
import { decode } from 'json-api-codec';
import { put } from 'redux-saga/effects';
import { all, call, takeEvery, takeLatest } from 'typed-redux-saga';
import { BASE_URL_V2, DOCUMENT, DOCUMENT_HIERARCHY, MERGE_V2, RECORDS } from '../../../api/constants';
import { Either } from '../../../lib/Either';
import { StorageCache } from '../../../lib/cache/StorageCache';
import { TypedCache } from '../../../lib/cache/TypedCache';
import { AuthClient } from '../../../lib/http/AuthClient';
import { AxiosClient } from '../../../lib/http/AxiosClient';
import { ClientError } from '../../../lib/http/ClientError';
import { JsonClient } from '../../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../../lib/http/PooledClient';
import { Process } from '../../../lib/redux/Process';
import { RecordC } from '../../../model/Record';
import { Notification } from '../../../utils/notification';
import { NotificationAction } from '../../notification/NotificationAction';
import { RecordAction } from '../RecordAction';
import { DocumentAction } from './DocumentAction';
export const attachBlob = (filenameWithExtension, blob) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filenameWithExtension);
    document.body.appendChild(link);
    link.click();
};
function* invalidateDocument(action) {
    try {
        if (isSome(action.payload.documentId)) {
            const response = yield* call(PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).post, `${DOCUMENT}/${action.payload.documentId.value}/invalidate`);
            yield put(Process.Done(action)(response.data));
            yield put(NotificationAction.Push(Notification.info('Documento invalidato correttamente')));
            yield put(DocumentAction.Hierarchy({
                recordId: action.payload.recordId.toString(),
                pooled: true,
            }));
        }
        else {
            throw new Error('document id not provided');
        }
    }
    catch (e) {
        yield put(NotificationAction.Push(Notification.error('Si è verificato un errore')));
        if (e.response) {
            yield put(Process.Failed(action)(ClientError(e, e.response)));
        }
        else {
            yield put(Process.Failed(action)(ClientError(e)));
        }
    }
}
function* downloadDocument(action) {
    try {
        const response = yield* call(PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get, `${MERGE_V2}/${action.payload.id}`, {}, {}, 'blob');
        yield call(attachBlob, `Documenti_compilati_${action.payload.lead.last_name}.pdf`, new Blob([response.data], { type: 'application/pdf' }));
        yield put(Process.Done(action)(action.payload));
    }
    catch (e) {
        yield put(NotificationAction.Push(Notification.error("C'è stato un errore nel download dei documenti")));
        if (e.response) {
            yield put(Process.Failed(action)(ClientError(e, e.response)));
        }
        else {
            yield put(Process.Failed(action)(ClientError(e)));
        }
    }
}
function* deleteFile(action) {
    try {
        const response = yield* call(PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).delete, `${DOCUMENT}/${action.payload.file.id}`);
        yield put(Process.Done(action)(response.data));
        yield put(NotificationAction.Push(Notification.info('File eliminato correttamente')));
        yield put(RecordAction.Detail({ recordId: action.payload.recordId, pooled: true }));
    }
    catch (e) {
        yield put(NotificationAction.Push(Notification.error('Si è verificato un errore')));
        if (e.response) {
            yield put(Process.Failed(action)(ClientError(e, e.response)));
        }
        else {
            yield put(Process.Failed(action)(ClientError(e)));
        }
    }
}
function* uploadFile(action) {
    const { file, recordId, categoryCode } = action.payload;
    try {
        if (null === file.raw) {
            throw new Error('Missing file');
        }
        const fileData = new FormData();
        fileData.append('files[]', file.raw);
        fileData.append('category', categoryCode);
        const response = yield* call(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer').post, `${BASE_URL_V2}/pratica/${recordId}/document`, fileData, {
            'Content-type': 'multipart/form-data',
        });
        yield put(Process.Done(action)(response.data));
    }
    catch (e) {
        yield put(NotificationAction.Push(Notification.error('Si è verificato un errore')));
        if (e.response) {
            yield put(Process.Failed(action)(ClientError({ name: e.name, message: e.message, stack: e.stack }, e.response)));
        }
        else {
            yield put(Process.Failed(action)(ClientError(e)));
        }
    }
}
function* fetchHierarchy(action) {
    var _a;
    try {
        const recordId = action.payload.recordId;
        const response = action.payload.pooled
            ? yield* call(PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get, `${RECORDS}/${recordId}`)
            : yield* call(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer').get, `${RECORDS}/${recordId}`);
        const record = Either.fromValidation(RecordC.decode(decode(response.data)));
        if (isLeft(record)) {
            throw record.left;
        }
        const DOCUMENT_JOB = /^\\?Facile\\Artoo\\Services\\Loops\\Job\\DocumentUploadValidatorJob$/;
        const reduceSupportedPipeline = (pipelines = []) => pipelines
            .filter(({ job }) => DOCUMENT_JOB.test(job))
            // eslint-disable-next-line eqeqeq, no-eq-null
            .filter(({ supported }) => supported === true || supported == null)
            .map(({ pipeline }) => pipeline.toString());
        const reducePipeline = (pipelines = []) => pipelines
            .filter(({ job }) => DOCUMENT_JOB.test(job))
            .map(({ pipeline }) => pipeline.toString());
        const recordPipelinesMetadata = (_a = response.data.meta) === null || _a === void 0 ? void 0 : _a.pipelinesMetadata;
        const instanceIds = reduceSupportedPipeline(recordPipelinesMetadata === null || recordPipelinesMetadata === void 0 ? void 0 : recordPipelinesMetadata.pipelines);
        const historyIds = reducePipeline(recordPipelinesMetadata === null || recordPipelinesMetadata === void 0 ? void 0 : recordPipelinesMetadata.history);
        if (0 === instanceIds.length && 0 === historyIds.length) {
            throw new Error('Impossibile visualizzare i documenti');
        }
        const hierarchyResponse = yield* call(PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get, `${DOCUMENT_HIERARCHY}`, {
            ids: 0 < instanceIds.length ? instanceIds : historyIds,
        });
        const data = hierarchyResponse.data.data;
        yield put(Process.Done(action)({
            record: record.right,
            loops: data.map(hierarchyResponseData => hierarchyResponseData.attributes.stages[0].job),
        }));
    }
    catch (e) {
        if (e.response) {
            yield put(Process.Failed(action)(ClientError({ name: e.name, message: e.message, stack: e.stack }, e.response)));
        }
        else if (e instanceof Array) {
            const errorMessage = 'Si è verificato un errore nella ricezione';
            yield put(Process.Failed(action)(ClientError(new Error(errorMessage))));
            NotificationAction.Push(Notification.error(errorMessage));
        }
        else {
            yield put(Process.Failed(action)(ClientError(e)));
        }
    }
}
export function* DocumentSaga() {
    yield* all([
        yield* takeEvery(DocumentAction.Upload.type.fired, uploadFile),
        yield* takeEvery(DocumentAction.Delete.type.fired, deleteFile),
        yield* takeEvery(DocumentAction.Hierarchy.type.fired, fetchHierarchy),
        yield* takeEvery(DocumentAction.Download.type.fired, downloadDocument),
        yield* takeLatest(DocumentAction.Invalidate.type.fired, invalidateDocument),
    ]);
}
