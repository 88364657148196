import { jsx as _jsx } from "react/jsx-runtime";
import { Alert, Snackbar as MUISnackbar } from '@mui/material';
import React, { useEffect, useState } from 'react';
const TIMEOUT = 10000;
const onClose = (setOpen) => (_, reason) => reason === 'clickaway' ? false : setOpen(false);
export const Snackbar = ({ children, severity, duration }) => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [messageSeverity, setMessageSeverity] = useState();
    useEffect(() => {
        setOpen(Boolean(children));
    }, [children, setOpen]);
    useEffect(() => {
        if (children) {
            setMessage(children);
            setMessageSeverity(severity);
        }
    }, [children, setMessage, setMessageSeverity, severity]);
    return (_jsx(MUISnackbar, { anchorOrigin: { vertical: 'bottom', horizontal: 'left' }, open: open, autoHideDuration: duration || TIMEOUT, onClose: onClose(setOpen), children: _jsx(Alert, { severity: messageSeverity, children: message }) }));
};
