import * as t from 'io-ts';
import { lead } from './Lead';
const RATE_VALUES = {
    fixed: 'fixed',
    floating: 'floating',
    mixed: 'mixed',
    capped: 'capped',
};
const rate = t.keyof(RATE_VALUES);
const PROCEDURE_STATUS_VALUES = {
    searchingProperty: 'searchingProperty',
    propertyIdentified: 'propertyIdentified',
    compromiseSigned: 'compromiseSigned',
    ownedProperty: 'ownedProperty',
    purchaseProposalMade: 'purchaseProposalMade',
    ongoingNegotiation: 'ongoingNegotiation',
    notarialActEstablished: 'notarialActEstablished',
};
const procedureStatus = t.keyof(PROCEDURE_STATUS_VALUES);
const JOB_VALUES = {
    permanentEmployee: 'permanentEmployee',
    fixedTermEmployee: 'fixedTermEmployee',
    selfEmployedWithVatNumber: 'selfEmployedWithVatNumber',
    selfEmployed: 'selfEmployed',
    freelance: 'freelance',
    pensioner: 'pensioner',
    homemaker: 'homemaker',
    student: 'student',
    indemonstrableIncome: 'indemonstrableIncome',
    unemployed: 'unemployed',
    director: 'director',
    domestic: 'domestic',
    borderWorker: 'borderWorker',
    other: 'other',
};
const job = t.keyof(JOB_VALUES);
export const request = t.type({
    id: t.string,
    purpose: t.string,
    productType: t.string,
    requestedAmount: t.union([t.null, t.number]),
    leftoverAmount: t.union([t.null, t.number]),
    submittedAt: t.string,
});
export const requestWithQuotes = t.intersection([
    request,
    t.type({
        notes: t.union([t.string, t.null]),
        rate: rate,
        procedureStatus: procedureStatus,
        property: t.type({
            value: t.number, // valore dell'immobile
            location: t.type({ city: t.string, provinceCode: t.string, postalCode: t.string }),
        }),
        duration: t.number,
        applicants: t.array(t.type({
            age: t.number,
            currentDebtInstallment: t.number,
            employment: job,
            familyUnit: t.number,
            isForeigner: t.boolean,
            netIncome: t.number,
            residenceLocation: t.union([
                t.null,
                t.type({ city: t.string, provinceCode: t.string, postalCode: t.string }),
            ]),
            type: t.literal('applicant'),
        })),
        guarantors: t.array(t.type({
            age: t.number,
            currentDebtInstallment: t.number,
            employment: job,
            familyUnit: t.number,
            isForeigner: t.boolean,
            netIncome: t.number,
            residenceLocation: t.null,
            type: t.literal('guarantor'),
        })),
        submittedBy: t.type({ firstName: t.string, lastName: t.string }),
        _embedded: t.type({
            lead: lead,
            quotations: t.array(t.type({
                id: t.string,
                rate: rate,
                product: t.type({ externalId: t.string, name: t.string }),
                institute: t.type({ externalId: t.string, name: t.string }),
                appraisal: t.number, // perizia
                tan: t.number,
                spread: t.number,
                installment: t.number, // rata
                taeg: t.number,
                investigation: t.number, // istruttoria
                alternativeTax: t.number, // imposta sostitutiva
                feasibilityConditionsAndOutcomes: t.type({
                    ltvRatio: t.union([
                        t.null,
                        t.type({
                            threshold: t.number,
                            value: t.number,
                            outcome: t.boolean,
                        }),
                    ]),
                    netIncomeInstallmentRatio: t.union([
                        t.null,
                        t.type({
                            threshold: t.number,
                            value: t.number,
                            outcome: t.boolean,
                        }),
                    ]),
                    age: t.type({ value: t.number }),
                    duration: t.type({ value: t.number }),
                    mediationAmount: t.type({ value: t.number, outcome: t.boolean }),
                }),
                attributes: t.array(t.string),
                submittedAt: t.string,
            })),
        }),
    }),
]);
