import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Grid, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { NotAvailable } from '../NotAvailable';
export const useStyles = makeStyles()(() => ({
    icon: {
        width: '16px',
        height: '16px',
    },
    title: {
        textTransform: 'uppercase',
    },
    list: {
        '&>.MuiGrid-root': {
            marginBottom: '8px',
            '&:last-child': {
                marginBottom: 0,
            },
        },
    },
    label: {
        color: '#6F797A',
    },
    buttonsGroup: {
        '&>*': {
            marginRight: '4px',
            '&:last-child': {
                marginRight: 0,
            },
        },
    },
}));
export const CardSection = ({ title, quoteDetails }) => {
    const { classes } = useStyles();
    return (_jsxs(Grid, { children: [_jsx(Grid, { container: true, justifyContent: "center", children: _jsx(Typography, { classes: { root: classes.title }, children: title }) }), _jsx(Grid, { classes: { root: classes.list }, children: quoteDetails.map(({ label, value, tooltipText, isApproved }, index) => (_jsxs(Grid, { container: true, justifyContent: "space-between", alignItems: "center", children: [_jsx(Grid, { item: true, children: _jsx(Typography, { classes: { root: classes.label }, children: label }) }), _jsxs(Grid, { xs: 6, classes: { root: classes.buttonsGroup }, container: true, item: true, alignItems: "center", justifyContent: "flex-end", children: [_jsx(Grid, { item: true, children: value === null ? _jsx(NotAvailable, {}) : _jsx(Typography, { children: value }) }), isApproved === true && (_jsx(CheckCircleIcon, { className: classes.icon, sx: {
                                        color: '#0057CD',
                                    } })), isApproved === false && _jsx(CancelIcon, { className: classes.icon, color: "error" }), tooltipText && (_jsx(Tooltip, { title: tooltipText, sx: { cursor: 'pointer' }, placement: "right", children: _jsx(InfoOutlinedIcon, { className: classes.icon }) }))] })] }, index))) })] }));
};
