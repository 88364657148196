import { none, some } from 'fp-ts/Option';
import { Process } from '../../lib/redux/Process';
const fromProcess = (process) => [process];
const reducer = (state = instance.empty, action) => Process.is(action) ? instance.concat(state, fromProcess(action)) : state;
const instance = {
    empty: [],
    concat: (xs, ys) => xs
        .map(x => ys.reduce((process, y) => Process.matches(process, y)
        ? Object.assign(Object.assign({}, y), { timestamps: {
                idle: y.timestamps.idle > -Infinity ? y.timestamps.idle : process.timestamps.idle,
                running: y.timestamps.running > -Infinity
                    ? y.timestamps.running
                    : process.timestamps.running,
                failed: y.timestamps.failed > -Infinity
                    ? y.timestamps.failed
                    : process.timestamps.failed,
                done: y.timestamps.done > -Infinity ? y.timestamps.done : process.timestamps.done,
            } }) : process, x))
        .concat(ys.filter(y => xs.reduce((mismatch, x) => mismatch && !Process.matches(y, x), true))),
};
function processes(...processList) {
    return (state) => {
        if (processList.length > 0) {
            const names = processList.map(process => process.name);
            return state.processes.filter(process => names.indexOf(process.name) > -1);
        }
        else {
            return state.processes;
        }
    };
}
const has = (tag) => (process, filter) => (state) => {
    const _processes = processes(process)(state);
    return filter
        ? Boolean(_processes
            .filter(Process.is[tag])
            .find(filter))
        : Boolean(_processes.find(Process.is[tag]));
};
export const ProcessState = Object.assign(Object.assign({}, instance), { fromProcess,
    reducer, selector: {
        processes,
        has: {
            idle: has('idle'),
            running: has('running'),
            failed: has('failed'),
            done: has('done'),
        },
        error: (process, filter) => (state) => {
            const _processes = processes(process)(state);
            const _process = filter
                ? _processes.filter(Process.is.failed).find(filter)
                : _processes.find(Process.is.failed);
            return _process ? some(_process.error) : none;
        },
    } });
