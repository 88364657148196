import { either, option } from 'fp-ts';
import { all, call, put, takeLatest } from 'typed-redux-saga';
import { InvoiceUtils } from '../../models/Invoice/Invoice.codec';
import { InvoiceOutputUtils } from '../../models/InvoiceOutput/InvoiceOutput.codec';
import { getInvoiceList } from '../../web/getInvoiceList';
import { getUser } from '../../web/getUser';
import { getUserEnasarco } from '../../web/getUserEnasarco';
import { $InvoiceList } from './InvoiceListState';
function* fetchInvoiceList(startInvoiceListAction, fetchInvoiceTableAction) {
    try {
        yield* put($InvoiceList.InvoiceTableRequested(fetchInvoiceTableAction.payload ? fetchInvoiceTableAction.payload.withLoader : true));
        const invoices = yield* call(getInvoiceList(startInvoiceListAction.payload.filter));
        if (either.isLeft(invoices)) {
            throw invoices.left;
        }
        if (startInvoiceListAction.payload.withAssignee) {
            const userIdList = InvoiceOutputUtils.uniqueUserIdList(invoices.right);
            const users = [];
            for (let i = 0; i <= userIdList.length - 1; i++) {
                const user = yield* call(getUser(userIdList[i]));
                if (either.isLeft(user)) {
                    throw user.left;
                }
                else {
                    users.push(user.right);
                }
            }
            const invoiceWithEnasarco = [];
            if (startInvoiceListAction.payload.withEnasarcoOverview) {
                for (let i = 0; i <= invoices.right.length - 1; i++) {
                    const invoice = invoices.right[i];
                    const yearOption = InvoiceUtils.year(invoice);
                    if (invoice.hasEnasarco === true && option.isSome(yearOption)) {
                        const enasarcoEither = yield* call(getUserEnasarco(invoice.userId, { year: yearOption.value }));
                        if (either.isLeft(enasarcoEither)) {
                            invoiceWithEnasarco.push({
                                invoiceId: invoice._id,
                                enasarco: option.none,
                            });
                        }
                        else {
                            invoiceWithEnasarco.push({
                                invoiceId: invoice._id,
                                enasarco: option.some(enasarcoEither.right),
                            });
                        }
                    }
                }
            }
            yield* put($InvoiceList.InvoiceTableFetched({
                users,
                invoices: invoices.right,
                enasarcoOverview: invoiceWithEnasarco,
            }));
        }
        else {
            yield* put($InvoiceList.InvoiceTableFetched({
                users: [],
                invoices: invoices.right,
                enasarcoOverview: [],
            }));
        }
    }
    catch (e) {
        yield* put($InvoiceList.InvoiceTableNotFetched(e));
    }
}
export function* InvoiceListSaga() {
    yield* takeLatest($InvoiceList.startInvoiceList, function* (startCommand) {
        yield* all([
            yield* takeLatest($InvoiceList.fetchInvoiceTable, function* refreshInvoiceList(fetchInvoiceTableCommand) {
                yield* call(fetchInvoiceList, startCommand, fetchInvoiceTableCommand);
            }),
        ]);
        yield* put($InvoiceList.fetchInvoiceTable());
    });
}
