import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Container } from '@mui/material';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $PaperworkList, $PaperworkListSlice } from '../../state/PaperworkListState';
import { PageTitle } from '../atoms/PageTitle';
import { PaperworkTable } from '../molecules/PaperworkTable';
import { PaperworkListFilter } from '../organisms/PaperworkListFilter';
export const PaperworkListPage = () => {
    const dispatch = useAppDispatch();
    const table = useAppSelector($PaperworkListSlice.selector.table);
    const isTableLoading = useAppSelector($PaperworkListSlice.selector.isPaperworkListLoading);
    useEffect(() => {
        dispatch($PaperworkList.start());
        return () => {
            dispatch($PaperworkList.stop());
        };
    }, [dispatch]);
    return (_jsxs(_Fragment, { children: [_jsx(PageTitle, { title: "Pratiche in lavorazione" }), _jsxs(Container, { component: Box, sx: { minHeight: '100vh' }, children: [_jsx(PaperworkListFilter, {}), _jsx(PaperworkTable, { isLoading: isTableLoading, table: table })] })] }));
};
