import { isLeft } from 'fp-ts/Either';
import { decode } from 'json-api-codec';
import { all, put } from 'redux-saga/effects';
import { call, takeLatest } from 'typed-redux-saga';
import { RECORDS } from '../../../api/constants';
import { Either } from '../../../lib/Either';
import { StorageCache } from '../../../lib/cache/StorageCache';
import { TypedCache } from '../../../lib/cache/TypedCache';
import { AuthClient } from '../../../lib/http/AuthClient';
import { AxiosClient } from '../../../lib/http/AxiosClient';
import { ClientError } from '../../../lib/http/ClientError';
import { JsonClient } from '../../../lib/http/JsonClient';
import { Process } from '../../../lib/redux/Process';
import { HistoryTimelineC } from '../../../model/io/HistoryTimelineC';
import { HistoryAction } from './HistoryAction';
function* fetchTimeline(action) {
    try {
        const response = yield* call(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer').get, `${RECORDS}/${action.payload}/statuses`);
        const history = Either.fromValidation(HistoryTimelineC.decode(decode(response.data)));
        if (isLeft(history)) {
            throw history.left;
        }
        yield put(Process.Done(action)(history.right));
    }
    catch (e) {
        if (e.response) {
            yield put(Process.Failed(action)(ClientError({ name: e.name, message: e.message, stack: e.stack }, e.response)));
        }
        else {
            yield put(Process.Failed(action)(ClientError({
                message: 'Impossibile visualizzare lo storico',
                stack: e.stack,
                name: e.name,
            })));
        }
    }
}
export function* HistorySaga() {
    yield all([yield* takeLatest(HistoryAction.Timeline.type.fired, fetchTimeline)]);
}
