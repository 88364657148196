import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import qs from 'qs';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { PooledClient, HttpClientPool } from '../../lib/http/PooledClient';
import { PaperworkCodec } from '../models/Paperwork/Paperwork';
export const getPaperworks = (queryParams) => {
    return pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get(`${BAZAAR_URL}/api/v1/records?${qs.stringify(queryParams)}`), e => e), taskEither.chainEitherKW(response => t.type({ data: t.readonlyArray(PaperworkCodec) }).decode(response.data)), taskEither.map(({ data }) => data));
};
