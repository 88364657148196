import { either, option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { all, call, put, take, takeEvery, takeLatest } from 'typed-redux-saga';
import { NotificationAction } from '../../../store/notification/NotificationAction';
import { Notification } from '../../../utils/notification';
import { InvoiceUtils } from '../../models/Invoice/Invoice.codec';
import { PassiveInvoiceInputCodec } from '../../models/PassiveInvoiceInput/PassiveInvoiceInput';
import { getInvoice } from '../../web/getInvoiceItem';
import { getUserEnasarco } from '../../web/getUserEnasarco';
import { getUserList } from '../../web/getUserList';
import { updateInvoice } from '../../web/updateInvoice';
import { $InvoiceEdit } from './InvoiceEditState';
function* updateAttempt(formData, invoiceId) {
    yield* put($InvoiceEdit.InvoiceUpdateRequested());
    const invoiceInputEither = pipe(formData, PassiveInvoiceInputCodec.decode, either.map(passiveInvoiceInput => (Object.assign(Object.assign({}, passiveInvoiceInput), { userId: formData.userId.toString(), hasEnasarco: formData.hasEnasarco === 'si' }))));
    if (either.isLeft(invoiceInputEither)) {
        yield* put($InvoiceEdit.InvoiceNotUpdated(invoiceInputEither.left));
    }
    else {
        const invoiceUpdateEither = yield* call(updateInvoice({
            formData: invoiceInputEither.right,
            invoiceId,
        }));
        if (either.isLeft(invoiceUpdateEither)) {
            yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore. Riprova')));
            yield* put($InvoiceEdit.InvoiceNotUpdated(invoiceUpdateEither.left));
        }
        else {
            yield* put(NotificationAction.Push(Notification.info('La fattura è stata modificata')));
            yield* put($InvoiceEdit.InvoiceUpdated());
        }
    }
}
function* refreshUserList(action) {
    yield* put($InvoiceEdit.UserListRequested());
    const users = yield* call(getUserList(action.payload));
    if (either.isLeft(users)) {
        yield* put($InvoiceEdit.UserListNotFetched(users.left));
    }
    else {
        yield* put($InvoiceEdit.UserListFetched(users.right));
    }
}
function* fetchUserEnasarco(invoice) {
    yield* put($InvoiceEdit.UserEnasarcoRequested());
    const yearOption = pipe(invoice, InvoiceUtils.year);
    if (option.isNone(yearOption)) {
        yield* put($InvoiceEdit.UserEnasarcoNotFetched('missing year'));
    }
    else {
        const userEnasarco = yield* call(getUserEnasarco(invoice.userId, {
            year: yearOption.value,
        }));
        if (either.isLeft(userEnasarco)) {
            yield* put($InvoiceEdit.UserEnasarcoNotFetched(userEnasarco.left));
        }
        else {
            yield* put($InvoiceEdit.UserEnasarcoFetched({
                invoice: invoice,
                enasarco: userEnasarco.right,
            }));
        }
    }
}
function* invoiceEdit(startInvoiceEditAction) {
    yield* put($InvoiceEdit.InvoiceRequested());
    const invoiceEither = yield* call(getInvoice(startInvoiceEditAction.payload));
    if (either.isLeft(invoiceEither)) {
        yield* put($InvoiceEdit.InvoiceNotFetched(invoiceEither.left));
    }
    else {
        yield* put($InvoiceEdit.fetchUserList({ id: invoiceEither.right.userId }));
        const { payload: users } = yield* take($InvoiceEdit.UserListFetched);
        yield* put($InvoiceEdit.InvoiceFetched({
            invoice: invoiceEither.right,
            users: either.right(users),
        }));
        yield* takeLatest($InvoiceEdit.fetchUserEnasarco, function* userEnasarco() {
            yield* call(fetchUserEnasarco, invoiceEither.right);
        });
        if (invoiceEither.right.hasEnasarco === true) {
            yield* put($InvoiceEdit.fetchUserEnasarco());
        }
        yield* takeEvery($InvoiceEdit.updateInvoice, function* tryUpdateInvoice(updateInvoiceAction) {
            yield* call(updateAttempt, updateInvoiceAction.payload, startInvoiceEditAction.payload);
        });
    }
}
export function* InvoiceEditSaga() {
    yield* all([
        takeLatest($InvoiceEdit.startInvoiceEdit, invoiceEdit),
        takeLatest($InvoiceEdit.fetchUserList, refreshUserList),
    ]);
}
