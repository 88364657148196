import { taskEither } from 'fp-ts';
import { identity, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { AxiosClient } from '../../common/clients/AxiosClient';
export class AuthClient extends AxiosClient {
    refreshToken(token) {
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/security/session`, { headers: this.addBearerAuthorization(token) }), identity), taskEither.map(response => {
            const refreshedToken = response.headers.authorization.split(' ');
            return refreshedToken.length > 1 ? refreshedToken[1] : new Error('invalid authorization');
        }), taskEither.chainEitherKW(t.string.decode));
    }
}
