import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Paper, styled, TableCell, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useNumericFormat } from 'react-number-format';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $PaperworkProcessSlice } from '../../state/PaperworkProcessState';
const StyledTableRow = styled(TableRow)(() => ({
    border: 0,
    '&>:first-child': {
        color: '#405AA9',
        minWidth: '188px',
        '&>p': {
            fontWeight: 500,
        },
    },
    '&>:nth-child(n+2)': { textAlign: 'right' },
    '&:last-child td, &:last-child th': {
        backgroundColor: '#405AA9',
        borderColor: '#405AA9',
        color: '#fff',
    },
}));
const StyledFirstRow = styled(TableRow)(() => ({
    textTransform: 'capitalize',
    backgroundColor: '#DCE1FF',
    '& th': {
        color: '#405AA9',
    },
    '&:last-child td, &:last-child th': {
        borderColor: '#DCE1FF',
    },
}));
const PaperComponent = (paperProps) => _jsx(Paper, Object.assign({ elevation: 0, square: true }, paperProps));
export const CommissionsTable = () => {
    const commissions = useAppSelector($PaperworkProcessSlice.selector.commissions);
    const { format } = useNumericFormat({
        thousandSeparator: '.',
        allowNegative: false,
        decimalScale: 2,
        decimalSeparator: ',',
        fixedDecimalScale: true,
    });
    return (format && (_jsx(TableContainer, { component: PaperComponent, children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsx(StyledFirstRow, { children: commissions.table.headers.map((header, key) => (_jsx(TableCell, { align: "right", children: _jsx(Typography, { children: _jsx(Box, { fontWeight: "fontWeightMedium", display: "inline", children: header }) }) }, key))) }) }), _jsx(TableBody, { children: commissions.table.body.map((row, index0) => (_jsx(StyledTableRow, { children: row.map((commissionItem, index1) => (_jsx(TableCell, { children: _jsx(Typography, { children: typeof commissionItem === 'number'
                                    ? `${format(commissionItem.toString())} €`
                                    : commissionItem }) }, index1))) }, index0))) })] }) })));
};
