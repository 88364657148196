import { createSlice } from '@reduxjs/toolkit';
import { option } from 'fp-ts';
import { constant } from 'fp-ts/function';
const initialState = {
    loading: false,
    error: option.none,
    users: [],
    initialValues: {
        month: new Date(),
        userId: '',
        items: [
            {
                description: '',
                amount: '',
                customerFullName: '',
                isDescriptionDisabled: false,
            },
        ],
        hasEnasarco: '',
        userFullName: '',
    },
};
export const invoiceCreationSlice = createSlice({
    name: 'InvoiceCreation',
    initialState,
    reducers: {
        startInvoiceCreation: constant(initialState),
        endInvoiceCreation: constant(initialState),
        fetchUserList: (state, _) => state,
        createInvoice: (state, _) => state,
        UserListRequested: state => (Object.assign(Object.assign({}, state), { error: option.none, loading: true })),
        UserListFetched: (state, action) => (Object.assign(Object.assign({}, state), { users: action.payload, error: option.none, loading: false })),
        UserListNotFetched: (state, action) => (Object.assign(Object.assign({}, state), { error: option.some(action.payload), loading: false, users: [] })),
        InvoiceCreationRequested: state => (Object.assign(Object.assign({}, state), { loading: true, error: option.none })),
        InvoiceCreated: state => (Object.assign(Object.assign({}, state), { loading: false, error: option.none })),
        InvoiceNotCreated: (state, action) => (Object.assign(Object.assign({}, state), { loading: false, error: option.some(action.payload) })),
    },
});
const assignees = (state) => state.invoiceCreation.users;
const initialValues = (state) => state.invoiceCreation.initialValues;
export const $InvoiceCreation = invoiceCreationSlice.actions;
export const $invoiceCreationSlice = {
    selector: { assignees, initialValues },
};
export default invoiceCreationSlice.reducer;
