import { either, readonlyArray, taskEither } from 'fp-ts';
import { constant, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
import { PurposeCodec } from '../models/Purpose.codec';
const getMortgagePurpose = (mortgagePurposeField) => pipe(mortgagePurposeField, PurposeCodec.decode, either.match(constant(''), mortgagePurpose => {
    switch (mortgagePurpose) {
        case 'Acquisto + Liquidità':
            return 'purchaseWithLiquidity';
        case 'Acquisto + Ristrutturazione':
            return 'purchaseWithRestoration';
        case 'Acquisto Bioedilizia':
            return 'greenBuildingPurchase';
        case 'Acquisto + Sostituzione':
            return 'purchaseWithReplacement';
        case 'Acquisto Box':
            return 'garagePurchase';
        case 'Acquisto Prima Casa':
            return 'firstHomePurchase';
        case 'Acquisto Seconda Casa':
            return 'secondHomePurchase';
        case 'Acquisto ad uso ufficio':
            return 'officeBuildingPurchase';
        case 'Acquisto in Asta':
            return 'auctionPurchase';
        case 'Acquisto per Residenti Estero':
            return 'foreignResidentsPurchase';
        case 'Completamento Costruzione':
            return 'constructionCompletion';
        case 'Consolidamento':
            return 'consolidation';
        case 'Finanziamento chirografario Bonus Casa':
            return 'bonusCasaUnsecuredFinancing';
        case 'Immobile commerciale':
            return 'commercialProperty';
        case 'Liquidità':
            return 'liquidity';
        case 'Rifinanziamento':
            return 'refinancing';
        case 'Ristrutturazione':
            return 'restoration';
        case 'Sostituzione':
            return 'replacement';
        case 'Sostituzione + Consolidamento':
            return 'replacementWithConsolidation';
        case 'Sostituzione + Liquidità':
            return 'replacementWithLiquidity';
        case 'Sostituzione + Ristrutturazione':
            return 'replacementWithRestoration';
        case 'Surroga':
            return 'subrogation';
        case 'Surroga + liquidità':
            return 'subrogationWithLiquidity';
    }
}));
export const getQuotePayload = (formData) => ({
    leadId: formData.leadId,
    rate: formData.rate,
    notes: formData.notes,
    procedureStatus: formData.procedureStatus,
    purpose: getMortgagePurpose(formData.mortgagePurpose),
    property: {
        value: Number(formData.rawRealEstateActualValue),
        location: {
            city: formData.realEstateLocation.name,
            provinceCode: formData.realEstateLocation.province.shortName,
            postalCode: formData.realEstateLocation.postalCode,
        },
    },
    leftoverAmount: formData.rawLeftover === '' ? null : Number(formData.rawLeftover),
    requestedAmount: formData.rawRealEstateRequestedAmount === '' ? null : Number(formData.rawRealEstateRequestedAmount),
    duration: formData.mortgageDuration,
    guarantors: pipe(formData.participants, readonlyArray.filter(participant => participant.type === 'garante'), readonlyArray.map(guarantor => ({
        type: 'guarantor',
        currentDebtInstallment: Number(guarantor.rawOngoingDebtInstallment),
        employment: guarantor.job,
        age: guarantor.age,
        familyUnit: guarantor.household,
        netIncome: Number(guarantor.rawNetMonthlyIncome),
        isForeigner: guarantor.nationality !== 'italian',
    }))),
    applicants: pipe(formData.participants, readonlyArray.filter(participant => participant.type === 'richiedente'), readonlyArray.map(applicant => (Object.assign({ type: 'applicant', currentDebtInstallment: Number(applicant.rawOngoingDebtInstallment), employment: applicant.job, age: applicant.age, familyUnit: applicant.household, netIncome: Number(applicant.rawNetMonthlyIncome), isForeigner: applicant.nationality !== 'italian' }, (applicant.placeOfResidence !== undefined
        ? {
            residenceLocation: {
                city: applicant.placeOfResidence.name,
                provinceCode: applicant.placeOfResidence.province.shortName,
                postalCode: applicant.placeOfResidence.postalCode,
            },
        }
        : {}))))),
});
export const saveMortgageRequest = (searchFormData) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).post(`${BAZAAR_URL}/api/v1/mortgage/requests`, getQuotePayload(searchFormData)), e => e), taskEither.chainEitherKW(response => t.type({ id: t.string }).decode(response.data)));
