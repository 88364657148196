import { createSlice } from '@reduxjs/toolkit';
import { constant, identity } from 'fp-ts/function';
const initialState = {};
export const recordDataTabSlice = createSlice({
    name: 'RecordDataTabSaga',
    initialState,
    reducers: {
        start: (state, _) => state,
        stop: identity,
        Started: identity,
        Stopped: constant(initialState),
    },
});
export const $RecordDataTab = recordDataTabSlice.actions;
export const $recordDataTabSlice = {
    selector: {},
};
export default recordDataTabSlice.reducer;
