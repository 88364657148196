import { record as R, option } from 'fp-ts';
import { array, getMonoid, getSemigroup } from 'fp-ts/Array';
import { fold, getOrElse } from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { Optional, fromFoldable, fromTraversable } from 'monocle-ts';
import { indexRecord } from 'monocle-ts/lib/Index/Record';
import { Lenses } from './Lenses';
const Optionals = {
    fromNullableProps: () => (props) => Object.keys(props).reduce((optionals, k) => (Object.assign(Object.assign({}, optionals), { [k]: Optional.fromNullableProp()(props[k]) })), {}),
};
const leafTraversal = () => fromTraversable(array)();
const monoid = () => R.getMonoid(getSemigroup());
export const Leaf = {
    lens: () => Lenses.fromProps()({
        id: 'id',
    }),
    optional: () => Optionals.fromNullableProps()({
        ref: 'ref',
        value: 'value',
    }),
    traversal: () => ({ self: leafTraversal() }),
    fold: () => ({ self: leafTraversal().asFold() }),
};
const nodeFromLeaf = (refs = {}) => (leaf) => ({
    nodes: pipe(indexRecord().index(Leaf.lens().id.get(leaf)).getOption(refs), pipe(option).map(leaves => leaves.map(nodeFromLeaf(refs))), getOrElse(() => [])),
    value: pipe(Leaf.optional().value.getOption(leaf), getOrElse(() => undefined)),
});
export const Node = {
    fromLeaf: nodeFromLeaf,
    lens: () => Lenses.fromProps()({
        nodes: 'nodes',
    }),
    optional: () => Optionals.fromNullableProps()({
        value: 'value',
    }),
    fold: () => ({ self: fromFoldable(array)() }),
};
export const Tree = {
    fromLeaves: (leaves) => {
        const ids = Leaf.fold().self.composeLens(Leaf.lens().id).getAll(leaves);
        const refs = Leaf.fold().self.foldMap(monoid())(leaf => pipe(Leaf.optional().ref.getOption(leaf), fold(() => ({}), ref => ({ [ref]: [leaf] }))))(leaves);
        const nodes = Leaf.traversal()
            .self.filter(leaf => pipe(Leaf.optional().ref.getOption(leaf), fold(() => true, ref => !ids.includes(ref))))
            .asFold()
            .foldMap(getMonoid())(leaf => [leaf].map(nodeFromLeaf(refs)))(leaves);
        return { nodes };
    },
    lens: () => Lenses.fromProps()({
        nodes: 'nodes',
    }),
};
