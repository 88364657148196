import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { $PaperworkProcess } from '../../state/PaperworkProcessState';
import { SelectField } from '../atoms/SelectField';
export const ChannelField = ({ isLoading, context, channels, handleChange, onBlur, setFieldValue, valueInput, error = '', isTouched, }) => {
    const dispatch = useAppDispatch();
    return (_jsx(_Fragment, { children: _jsx(SelectField, { label: "Rubinetto", name: "channelInput", disabled: isLoading || channels.length === 0, onBlur: onBlur, onChange: e => {
                handleChange(e);
                const channel = channels.find(({ label }) => label === e.target.value);
                if (!channel) {
                    return;
                }
                dispatch($PaperworkProcess.fetchInstitutes({
                    channel: channel.value,
                    paperworkId: context.artooId,
                    productTypology: context.productTypology,
                }));
                setFieldValue('channel', channel.value);
            }, options: channels, value: valueInput, required: true, error: error, helperText: "La modifica di questo valore pu\u00F2 condizionare i campi relativi ai prodotti", isTouched: isTouched }) }));
};
