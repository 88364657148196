import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Grid, Paper } from '@mui/material';
import { Form, Formik } from 'formik';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $PaperworkList, $PaperworkListSlice } from '../../state/PaperworkListState';
import { InputField } from '../atoms/InputField';
const onSubmit = (dispatch) => (data) => {
    dispatch($PaperworkList.fetchFirstPage({ filter: data }));
};
export const PaperworkListFilter = () => {
    const appliedFilters = useAppSelector($PaperworkListSlice.selector.appliedFilters);
    const dispatch = useAppDispatch();
    return (_jsx(Formik, { initialValues: appliedFilters, onSubmit: onSubmit(dispatch), children: ({ handleChange, handleBlur, values, resetForm }) => (_jsx(Form, { children: _jsxs(Grid, { container: true, component: Paper, elevation: 0, sx: { mb: '8px', p: '16px' }, children: [_jsxs(Grid, { flexWrap: "nowrap", gap: "16px", alignItems: "center", container: true, children: [_jsx(Grid, { item: true, xs: 4, children: _jsx(InputField, { label: "Nome", name: "customerFirstName", onChange: handleChange, onBlur: handleBlur, value: values.customerFirstName }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(InputField, { label: "Cognome", name: "customerLastName", onChange: handleChange, onBlur: handleBlur, value: values.customerLastName }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(InputField, { label: "ID pratica", name: "id", onChange: handleChange, onBlur: handleBlur, value: values.id }) })] }), _jsxs(Grid, { item: true, container: true, gap: "16px", justifyContent: "flex-end", children: [_jsx(Button, { variant: "outlined", onClick: () => {
                                    resetForm();
                                    dispatch($PaperworkList.fetchFirstPage({
                                        filter: {
                                            customerFirstName: '',
                                            customerLastName: '',
                                            id: '',
                                            product: '',
                                            productType: '',
                                        },
                                    }));
                                }, children: "Cancella" }), _jsx(Button, { variant: "contained", color: "secondary", type: "submit", children: "Cerca" })] })] }) })) }));
};
