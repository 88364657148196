import { boolean, eq, option, readonlyRecord } from 'fp-ts';
import { constant, constUndefined, not, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { nonEmptyArray } from 'io-ts-types';
import { optics } from '../utils/Optics';
import { readonlyArray } from '../utils/ReadonlyArray';
import { DocumentC } from './Document';
import { $identifier, IdentifierC } from './Identifier';
import { LeadC } from './Lead';
import { RecordDataC } from './RecordData';
import { RecordLinkC } from './RecordLink';
import { RecordLinkLegacyC } from './RecordLinkLegacy';
import { RecordStateC } from './RecordState';
import { $role } from './Role';
import { $signature, SignatureC } from './Signature';
import { SignedEnvelopeC } from './SignedEnvelope';
import { $tag, TagC } from './Tag';
import { UserC } from './User';
export const RecordC = t.recursion('Record', () => t.intersection([
    t.type({
        id: IdentifierC,
        created_at: t.string,
        updated_at: t.string,
        data: RecordDataC,
        lead: LeadC,
        agenti: nonEmptyArray(UserC),
        status: RecordStateC,
        tags: t.array(TagC),
        documents: t.array(DocumentC),
    }),
    t.partial({
        signature: SignatureC,
        signed_envelope: SignedEnvelopeC,
        destinationRelationships: t.union([t.array(RecordLinkC), t.array(RecordLinkLegacyC)]),
    }),
]));
const hasTags = (tag, ...tags) => (record) => readonlyArray.allElems($tag.Eq)(tag, ...tags)(record.tags);
const pendingSignature = (record) => pipe(record, optics.getOption('signature'), option.filter(not($signature.isComplete)));
const _Eq = pipe($identifier.Eq, eq.contramap(optics.get('id')));
const datum = (key, codec) => (record) => pipe(record.data, option.of, option.filter(t.UnknownRecord.is), option.chain(readonlyRecord.lookup(key)), option.filter(codec.is));
const renderDatum = (key, record) => pipe(record, option.chain($record.datum(key, t.string)), option.getOrElse(constant('')));
const isChannelEditable = (user) => (record) => {
    if ($record.hasTags('closed')(record)) {
        return false;
    }
    if (!$role.hasRole($role.ROLE_NEXUS_BERGAMO_AMMINISTRATORE)(user)) {
        return false;
    }
    if (record.status.code === 'fatturazione') {
        return false;
    }
    return true;
};
const isEditable = (user) => (record) => {
    if ($record.hasTags('closed')(record)) {
        return false;
    }
    return ('verifica_ordinaria_antiriciclaggio' !== record.status.code &&
        (('inserimento_dati_e_raccolta_documenti' === record.status.code &&
            $role.hasRole($role.ROLE_NEXUS_AGENTE_COLLABORATORE, $role.ROLE_NEXUS_AGENTE_HEAD_AGENCY, $role.ROLE_NEXUS_AGENTE_HEAD_DIVISIONAL, $role.ROLE_NEXUS_BERGAMO_AMMINISTRATORE, $role.ROLE_NEXUS_ADMIN)(user)) ||
            ('richiesta_approvazione_ha' === record.status.code &&
                $role.hasRole($role.ROLE_NEXUS_AGENTE_HEAD_AGENCY, $role.ROLE_NEXUS_AGENTE_HEAD_DIVISIONAL, $role.ROLE_NEXUS_BERGAMO_AMMINISTRATORE, $role.ROLE_NEXUS_ADMIN)(user)) ||
            ($record.hasTags('tirana')(record) &&
                !$record.hasTags('new')(record) &&
                $role.hasRole($role.ROLE_NEXUS_TIRANA_OPERATORE, $role.ROLE_NEXUS_TIRANA_SUPERVISORE, $role.ROLE_NEXUS_BERGAMO_OPERATORE, $role.ROLE_NEXUS_BERGAMO_AMMINISTRATORE, $role.ROLE_NEXUS_ADMIN)(user)) ||
            ($record.hasTags('bergamo')(record) &&
                !$record.hasTags('new')(record) &&
                $role.hasRole($role.ROLE_NEXUS_TIRANA_OPERATORE, $role.ROLE_NEXUS_TIRANA_SUPERVISORE, $role.ROLE_NEXUS_BERGAMO_OPERATORE, $role.ROLE_NEXUS_BERGAMO_AMMINISTRATORE, $role.ROLE_NEXUS_ADMIN)(user))));
};
const invalidDocument = (data) => (documents) => documents.filter(obj => Object.entries(obj.data).reduce((acc, [key, value]) => acc ||
    (data[key] !== value &&
        // eslint-disable-next-line eqeqeq
        value != undefined &&
        !/^ *$/.test('string' === typeof value ? value : '') &&
        key !== 'pratica_id'), false));
const hasTag = (_tag) => (record) => pipe(record, option.filter($record.hasTags(_tag)), option.matchW(constUndefined, constant(_tag)));
const canInvalidate = (recordOption, userOption) => pipe(recordOption, option.exists(record => record.status.code === 'inserimento_dati_e_raccolta_documenti'), boolean.match(constant(false), constant(boolean.SemigroupAny.concat($role.isAdmin(userOption), $role.isAgent(userOption)))));
export const $record = {
    datum,
    hasTags,
    pendingSignature,
    isEditable,
    isChannelEditable,
    Eq: _Eq,
    invalidDocument,
    hasTag,
    canInvalidate,
    renderDatum,
};
