import * as t from 'io-ts';
import { BazaarQuotationUtils } from './BazaarQuotation.codec';
const CAPPED = 'cap';
const FIXED = 'fisso';
const FLOATING = 'variabile';
const MIXED = 'misto';
export const RateLabelCodec = t.union([
    t.literal('cap'),
    t.literal('fisso'),
    t.literal('variabile'),
    t.literal('misto'),
]);
export const RateCodec = t.union([t.literal('fixed'), t.literal('mixed'), t.literal('floating')]);
const getRateValue = (rate) => {
    switch (rate) {
        case CAPPED:
            return BazaarQuotationUtils.rate.CAPPED;
        case FIXED:
            return BazaarQuotationUtils.rate.FIXED;
        case FLOATING:
            return BazaarQuotationUtils.rate.FLOATING;
        case MIXED:
            return BazaarQuotationUtils.rate.MIXED;
    }
};
const getRateLabel = (bazaarLocation) => {
    switch (bazaarLocation) {
        case BazaarQuotationUtils.rate.CAPPED:
            return CAPPED;
        case BazaarQuotationUtils.rate.FIXED:
            return FIXED;
        case BazaarQuotationUtils.rate.FLOATING:
            return FLOATING;
        case BazaarQuotationUtils.rate.MIXED:
            return MIXED;
    }
};
export const RateUtils = { getRateValue, getRateLabel };
