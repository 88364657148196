import { createSlice } from '@reduxjs/toolkit';
import { constant, identity } from 'fp-ts/function';
import { InvoiceOutputUtils } from '../../models/InvoiceOutput/InvoiceOutput.codec';
const initialState = {
    loading: false,
    error: { invoicesItemsNumber: null },
    invoicesItemsNumber: null,
};
export const invoiceCreationFromRecordSlice = createSlice({
    name: 'InvoiceCreationFromRecordSaga',
    initialState,
    reducers: {
        start: (state, _) => state,
        stop: identity,
        Started: identity,
        Stopped: constant(initialState),
        createInvoice: (state, _) => state,
        fetchPayins: identity,
        fetchInvoice: identity,
        InvoiceRequested: state => (Object.assign(Object.assign({}, state), { loading: true, invoicesItemsNumber: null, error: { invoicesItemsNumber: null } })),
        InvoiceFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: false, invoicesItemsNumber: InvoiceOutputUtils.invoiceListLength(event.payload), error: { invoicesItemsNumber: null } })),
        InvoiceNotFetched: state => (Object.assign(Object.assign({}, state), { loading: false, invoicesItemsNumber: null, error: {
                invoicesItemsNumber: 'Si è verificato un errore. Riprova.',
            } })),
        InvoiceCreationRequested: state => (Object.assign(Object.assign({}, state), { loading: true, error: { invoicesItemsNumber: null } })),
        InvoiceCreated: constant(initialState),
        InvoiceNotCreated: state => (Object.assign(Object.assign({}, state), { loading: false })),
    },
});
export const $InvoiceCreationFromRecord = invoiceCreationFromRecordSlice.actions;
export const $invoiceCreationFromRecordSlice = {
    selector: {
        isLoading: (state) => state.invoiceCreationFromRecord.loading,
        invoicesItemsNumber: (state) => state.invoiceCreationFromRecord.invoicesItemsNumber,
        invoicesItemsNumberError: (state) => state.invoiceCreationFromRecord.error.invoicesItemsNumber,
    },
};
export default invoiceCreationFromRecordSlice.reducer;
