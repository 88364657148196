import { number } from 'fp-ts';
import * as t from 'io-ts';
export const PaginationCodec = t.type({
    size: t.number,
    offset: t.number,
}, 'Pagination');
const canGoBack = (pagination) => pagination.offset > 0;
const decreaseOffset = (n) => (pagination) => pagination.offset - n;
const increaseOffset = (n) => (pagination) => number.MonoidSum.concat(pagination.offset, n);
const currentPage = (pagination) => pagination.offset / pagination.size + 1;
export const PaginationUtils = {
    canGoBack,
    decreaseOffset,
    increaseOffset,
    currentPage,
};
