import { readonlyArray } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { useAppSelector } from '../hooks/useAppSelector';
import { SecurityState } from '../store/security/SecurityState';
export const hasPermission = (userPermissionList) => (permission) => pipe(userPermissionList, readonlyArray.exists(userPermission => userPermission === permission));
export const isUserAuthorized = (userPemissionList) => (...permissionList) => pipe(permissionList, readonlyArray.map(hasPermission(userPemissionList)), readonlyArray.every(isAuthorized => isAuthorized === true));
export const useAuthorization = (permissions) => {
    const userPermissionList = useAppSelector(SecurityState.selector.permissionList);
    return isUserAuthorized(userPermissionList)(...permissions);
};
