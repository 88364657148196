import { either } from 'fp-ts';
import { all, call, cancel, put, take, takeEvery, takeLeading } from 'typed-redux-saga';
import { Session } from '../../session/state/Session';
import { NotificationAction } from '../../store/notification/NotificationAction';
import { Notification } from '../../utils/notification';
import { $RequestWithQuotes } from './RequestWithQuotesState';
function* getRequestWithQuotes(quoteService, quoteRequestId) {
    yield* put($RequestWithQuotes.RequestWithQuotesRequested());
    const token = yield* call(Session.getToken);
    const requestWithQuotesEither = yield* call(quoteService.getRequestWithQuotesByQuoteRequestId({ token: token, quoteRequestId: quoteRequestId }));
    if (either.isRight(requestWithQuotesEither)) {
        yield* put($RequestWithQuotes.RequestWithQuotesFetched({
            quotes: requestWithQuotesEither.right,
        }));
    }
}
function* updateRequest(quoteService, quoteRequestId, command) {
    yield* put($RequestWithQuotes.UpdateRequestRequested());
    const token = yield* call(Session.getToken);
    const updatedRequestWithQuotesEither = yield* call(quoteService.updateRequest({
        token: token,
        requestId: quoteRequestId,
        payload: { notes: command.payload.notes },
    }));
    if (either.isRight(updatedRequestWithQuotesEither)) {
        const onSuccess = command.payload.onSuccess;
        if (onSuccess) {
            yield* call(onSuccess);
        }
        yield* put($RequestWithQuotes.RequestUpdated({ notes: command.payload.notes }));
    }
    else {
        yield* put($RequestWithQuotes.RequestNotUpdated());
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore nel salvataggio della nota')));
    }
}
export function* RequestWithQuotesSaga(quoteService) {
    yield* takeEvery($RequestWithQuotes.start, function* (startCommand) {
        yield* put($RequestWithQuotes.Started());
        yield* call(getRequestWithQuotes, quoteService, startCommand.payload.requestId);
        const task = yield* all([
            yield* takeLeading($RequestWithQuotes.updateRequest, command => {
                return updateRequest(quoteService, startCommand.payload.requestId, command);
            }),
        ]);
        yield* take($RequestWithQuotes.stop);
        yield* cancel(task);
        yield* put($RequestWithQuotes.Stopped());
    });
}
