import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()(theme => ({
    sidebarBorderRadius: {
        borderRadius: `0px 16px 16px 0px`,
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    mr4: {
        marginRight: theme.spacing(4),
    },
    mt2: { marginTop: theme.spacing(2) },
    mtb5: { marginTop: theme.spacing(5), marginBottom: theme.spacing(5) },
    paperRoot: {
        minWidth: '16.666%',
        backgroundColor: theme.palette.grey.A100,
    },
    ml4: {
        marginLeft: theme.spacing(4),
    },
    listItem: {
        borderRadius: 100,
        '&:hover': {
            backgroundColor: 'transparent',
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.light,
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
            },
        },
    },
}));
