import { endOfDay } from 'date-fns';
import { either } from 'fp-ts';
import { all, call, cancel, put, take, takeLatest, takeLeading } from 'typed-redux-saga';
import { NotificationAction } from '../../../store/notification/NotificationAction';
import { Notification } from '../../../utils/notification';
import { $intl } from '../../models/Intl';
import { updateInvoice } from '../../web/updateInvoice';
import { $PassiveInvoiceBulkEditActions } from './PassiveInvoiceBulkEditActionsState';
function* updateInvoices(updateInvoicesCommand) {
    const { invoiceListId } = updateInvoicesCommand.payload;
    let invoiceIdListWithError = [];
    let invoiceIdListUpdated = [];
    yield* put($PassiveInvoiceBulkEditActions.InvoiceListUpdateRequested());
    for (const invoiceId of invoiceListId) {
        const formattedFormData = Object.assign(Object.assign({}, (updateInvoicesCommand.payload.formData.hasEnasarco !== null && {
            hasEnasarco: updateInvoicesCommand.payload.formData.hasEnasarco === 'si',
        })), (updateInvoicesCommand.payload.formData.month !== null && {
            month: endOfDay(updateInvoicesCommand.payload.formData.month),
        }));
        const updateInvoiceResponse = yield* call(updateInvoice({
            invoiceId: invoiceId,
            formData: formattedFormData,
        }));
        if (either.isLeft(updateInvoiceResponse)) {
            invoiceIdListWithError = [...invoiceIdListWithError, invoiceId];
        }
        else {
            invoiceIdListUpdated = [...invoiceIdListUpdated, invoiceId];
        }
    }
    if (invoiceIdListWithError.length === 0) {
        yield* put($PassiveInvoiceBulkEditActions.InvoiceListUpdated());
        if (updateInvoicesCommand.payload.onSuccess) {
            yield* call(updateInvoicesCommand.payload.onSuccess);
        }
        yield* put(NotificationAction.Push(Notification.info(`${$intl.fromGenre(invoiceIdListUpdated.length, 'È stata correttamente modificata', 'Sono state correttamente modificate')} ${invoiceIdListUpdated.length} ${$intl.fromGenre(invoiceIdListUpdated.length, 'fattura', 'fatture')}`)));
    }
    else if (invoiceIdListWithError.length > 0) {
        yield* put($PassiveInvoiceBulkEditActions.InvoiceListNotUpdated());
        yield* put(NotificationAction.Push(Notification.error(`Si è verificato un errore. Riprova`)));
    }
}
export function* PassiveInvoiceBulkEditSaga() {
    yield* takeLeading($PassiveInvoiceBulkEditActions.start, function* () {
        yield* put($PassiveInvoiceBulkEditActions.Started());
        const task = yield* all([
            takeLatest($PassiveInvoiceBulkEditActions.updateInvoices, updateInvoices),
        ]);
        yield* take($PassiveInvoiceBulkEditActions.stop);
        yield* cancel(task);
        yield* put($PassiveInvoiceBulkEditActions.Stopped());
    });
}
