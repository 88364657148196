import { jsx as _jsx } from "react/jsx-runtime";
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import { useEffect } from 'react';
import { MODAL_OPEN_CLASS } from '../../constants';
import { useStyles } from './FullPageLoader.styles';
export const FullPageLoader = ({ rightSidebar, background = true }) => {
    const { classes } = useStyles();
    useEffect(() => {
        document.body.classList.add(MODAL_OPEN_CLASS);
        return () => {
            document.body.classList.remove(MODAL_OPEN_CLASS);
        };
    }, []);
    return (_jsx("div", { className: clsx(classes.loader, background && classes.background), children: _jsx("div", { className: clsx(classes.circularProgress, rightSidebar && classes.transformTranslateSidebar), children: _jsx(CircularProgress, {}) }) }));
};
