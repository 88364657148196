import * as Yup from 'yup';
const isRenewalPurpose = (purpose) => purpose === 'Rinnovo';
export const CqsSchema = Yup.object().shape({
    store: Yup.string(),
    storeInput: Yup.string(),
    channelInput: Yup.string().when('$canViewChannelField', {
        is: (canViewChannelField) => canViewChannelField === true,
        then: schema => schema.required('Indica il rubinetto'),
    }),
    channel: Yup.string().when('$canViewChannelField', {
        is: (canViewChannelField) => {
            return canViewChannelField === true;
        },
        then: schema => schema.required('Indica il rubinetto'),
    }),
    warrantEndDate: Yup.string(),
    warrantStartDate: Yup.string(),
    warrantEndLabel: Yup.string(),
    lendingDate: Yup.string(),
    lendingPaymentMethod: Yup.string(),
    participant: Yup.object().shape({
        firstName: Yup.string().required('Indica il nome'),
        lastName: Yup.string().required('Indica il cognome'),
        residence: Yup.object().shape({
            locationInput: Yup.string().required('Indica la località di residenza'),
            address: Yup.string().required("Indica l'indirizzo di residenza"),
            location: Yup.object().shape({
                name: Yup.string().required('Indica la località di residenza'),
                province: Yup.object().shape({
                    shortName: Yup.string().required('Indica la località di residenza'),
                }),
            }),
            postalCode: Yup.string().required('Indica il codice postale'),
        }),
        cityOfBirth: Yup.string().required('Indica il comune di nascita'),
        provinceOfBirth: Yup.string().required('Indica la provincia di nascita'),
        birthday: Yup.string().required('Indica la data di nascita'),
        gender: Yup.string().required('Indica il sesso'),
        fiscalCode: Yup.string()
            .matches(/^(?:[A-Za-z][AEIOUaeiou][AEIOUXaeioux]|[B-DF-HJ-NP-TV-Zb-df-hj-np-tv-z]{2}[A-Za-z]){2}(?:[\dLMNP-Vlmnp-v]{2}(?:[A-EHLMPR-Ta-ehlmpr-t](?:[04LQlq][1-9MNP-Vmnp-v]|[15MRmr][\dLMNP-Vlmnp-v]|[26NSns][0-8LMNP-Ulmnp-u])|[DHPSdhps][37PTpt][0Ll]|[ACELMRTacelmrt][37PTpt][01LMlm]|[AC-EHLMPR-Tac-ehlmpr-t][26NSns][9Vv])|(?:[02468LNQSUlnqsu][048LQUlqu]|[13579MPRTVmprtv][26NSns])[Bb][26NSns][9Vv])(?:[A-MZa-mz][1-9MNP-Vmnp-v][\dLMNP-Vlmnp-v]{2}|[A-Ma-m][0Ll](?:[1-9MNP-Vmnp-v][\dLMNP-Vlmnp-v]|[0Ll][1-9MNP-Vmnp-v]))[A-Za-z]$/, 'Indica un codice fiscale valido')
            .required('Indica il codice fiscale'),
        identityDocument: Yup.object().shape({
            type: Yup.string().required('Indica il tipo di documento'),
            code: Yup.string().required('Indica il numero di documento'),
            issuedBy: Yup.string().required('Indica il valore'),
            placeOfIssue: Yup.string().required('Indica il luogo di rilascio'),
            issueDate: Yup.string().required('Indica la data di rilascio'),
            expiryDate: Yup.string().required('Indica la data di scadenza'),
        }),
        citizenship: Yup.string().required('Indica la cittadinanza'),
        contacts: Yup.object().shape({
            mobilePhone: Yup.string().required('Indica il telefono cellulare'),
            landline: Yup.string(),
            email: Yup.string().email('Indica una email valida').required("Indica l'email"),
        }),
        maritalStatus: Yup.string().required('Indica lo stato civile'),
        job: Yup.string().required('Indica la professione'),
        companyType: Yup.string().required('Indica il tipo di azienda'),
        employerVatNumber: Yup.string().matches(/^[0-9]{11}$|^$/, {
            message: 'Indica una partita IVA valida',
        }),
        netIncome: Yup.string().required('Indica il reddito mensile netto'),
        monthlyInstallment: Yup.string().required('Indica il numero di mensilità'),
        lengthOfService: Yup.string().required("Indica l'anzianità lavorativa"),
        household: Yup.string().required('Indica il numero di componenti nucleo familiare'),
        marketing: Yup.string(),
        thirdParties: Yup.string(),
    }),
    cqs: Yup.object().shape({
        purpose: Yup.string().required('Indica la finalità'),
        type: Yup.string().required('Indica il tipo di operazione'),
        employeeType: Yup.string(),
        institute: Yup.string().required('Indica la finanziaria'),
        product: Yup.string().when('$products', {
            is: (products) => {
                return products && products.length > 0;
            },
            then: schema => schema.required('Indica il prodotto'),
        }),
        requestedAmount: Yup.string().required("Indica l'importo"),
        tan: Yup.string().required('Indica il TAN'),
        points: Yup.number()
            .typeError('Indica dei punti applicati validi')
            .positive('I punti applicati devono essere maggiori di zero')
            .required('Indica i punti applicati'),
        duration: Yup.string().required('Indica la durata'),
        installment: Yup.string().required('Indica la rata'),
        interest: Yup.string().required('Indica il montante'),
        interestLeft: Yup.string().when('type', {
            is: isRenewalPurpose,
            then: schema => schema.required('Indica il montante residuo'),
        }),
        interestDifference: Yup.string().when('type', {
            is: isRenewalPurpose,
            then: schema => schema.required('Indica il delta montante'),
        }),
    }),
});
