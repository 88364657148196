import { $type } from 'fortepiano';
const channelList = [
    'Mio cliente',
    'Facile massiva',
    'Facile CC',
    'Banca Store',
    'Ingresso store',
    'Store Cross Selling',
    'Campagna Facebook',
    'Interessato Store da sito',
    'Fissato appuntamento Store da sito',
    'Fredde assicurazioni',
    'Prestiti Compass',
    'Prestiti CQS',
    'Ex Siseco',
    'Wikicasa',
    'Facile.it Sorridi',
    'Repeat business',
    'Recall',
    'Appuntamento da Sito Assi',
    'Rinnovi',
    'Promo E-mail',
    'Promo SMS',
    'Google',
    'Cliente ha contattato lo store',
    'CC',
    'Ingresso Store',
    'Passaparola',
    'Amico/Parente collaboratore',
    'Mr CQ',
    'Mutuo Facile',
    'Dove.it',
    'Appuntamento da Sito',
];
export const ChannelCodec = $type.literalUnion(channelList, 'Channel');
export const ChannelUtils = { channelList };
