import { createSlice } from '@reduxjs/toolkit';
import { $number } from 'fortepiano';
import { option, readonlyArray } from 'fp-ts';
import { constant, identity, pipe } from 'fp-ts/function';
import { Eq } from 'fp-ts/string';
import { ClientError } from '../../lib/http/ClientError';
import { optics } from '../../utils/Optics';
const FMP = 'Ricavo FMP';
const TOTAL = 'TOTALE';
const initialState = {
    loading: { table: false },
    error: { table: null },
    table: [],
};
const isProfileCommissionsTotal = (productType) => Eq.equals(productType, TOTAL);
const isProductCommissionsTotal = (profile) => Eq.equals(profile, FMP);
export const commissionsSlice = createSlice({
    name: 'Commissions',
    initialState,
    reducers: {
        start: (state, _) => state,
        stop: identity,
        Stopped: constant(initialState),
        Started: identity,
        fetchCommissionTable: identity,
        CommissionTableRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { table: true }), table: [], error: Object.assign(Object.assign({}, state.error), { table: null }) })),
        CommissionTableFetched: (state, action) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { table: false }), table: pipe(action.payload.table), error: Object.assign(Object.assign({}, state.error), { table: null }) })),
        CommissionTableNotFetched: (state, action) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { table: false }), table: [], error: Object.assign(Object.assign({}, state.error), { table: action.payload }) })),
    },
});
export const $Commissions = commissionsSlice.actions;
export const $CommissionsSlice = {
    selector: {
        commissionsHttpError: (state) => state.commissions.error.table === null ? false : ClientError.is.httpError(state.commissions.error.table),
        commissions: (state) => state.commissions.table,
        total: (state) => pipe(state.commissions.table, readonlyArray.findFirst(([tableRow]) => tableRow === undefined ? false : isProfileCommissionsTotal(tableRow.productType)), option.map(readonlyArray.filter(({ profile }) => !isProductCommissionsTotal(profile))), option.chain(readonlyArray.head), option.map(commission => (Object.assign(Object.assign({}, commission), { value: `${$number.round(2)(commission.value)}` }))), option.map(optics.get('value'))),
    },
};
export const CommissionsState = commissionsSlice.reducer;
