import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useState } from 'react';
import { toggle } from '../../common/state/toggle';
import { useStyles } from './Prompt.styles';
import { Warning } from './WarningComponent';
const onCancelActionClick = (warnBeforeCancel) => (onCancelAction) => (setWarning) => () => warnBeforeCancel ? toggle(setWarning)() : onCancelAction();
export const Prompt = ({ onConfirmAction, onCancelAction, onConfirmText = 'ok', onCancelText = 'annulla', onCloseAction, title = '', isOpen, isLoading = false, children, PaperProps, titleBackground = false, warnBeforeCancel = false, disableContentPadding = false, denseTitle = false, beforeCancelNode, contentSeparator = false, disabledConfirmButton = false, maxWidth = 'sm', }) => {
    const [warning, setWarning] = useState(false);
    const { classes } = useStyles();
    return (_jsxs(Dialog, { open: isOpen, disableEscapeKeyDown: true, className: classes.dialog, maxWidth: maxWidth, PaperProps: Object.assign({ style: {
                minWidth: 450,
            }, square: true }, PaperProps), children: [_jsx(DialogTitle, { classes: { root: classes.dialogTitleRoot }, className: clsx(denseTitle ? classes.plr20px : classes.plr50px, titleBackground && classes.titleBackground), children: _jsxs(Grid, { container: true, style: {
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }, children: [_jsx(Typography, { variant: "h2", color: "primary", children: title }), isLoading
                            ? onCloseAction && _jsx(CloseIcon, { className: classes.iconSize, style: { opacity: '0.5' } })
                            : onCloseAction && (_jsx(CloseIcon, { className: classes.iconSize, onClick: onCancelActionClick(warnBeforeCancel)(onCloseAction)(setWarning), style: { cursor: 'pointer' } }))] }) }), contentSeparator && _jsx("div", { className: clsx(classes.separator, classes.mt10px) }), _jsx(DialogContent, { classes: { root: classes.dialogContentRoot }, className: clsx(disableContentPadding ? classes.plr0 : classes.plr50px), children: _jsx(Grid, { container: true, children: isLoading ? (_jsx(Grid, { container: true, style: { justifyContent: 'center' }, children: _jsx(CircularProgress, {}) })) : (_jsx("div", { style: { width: '100%', zIndex: 1 }, children: children })) }) }), contentSeparator && _jsx("div", { className: clsx(classes.separator, classes.mbNegative10px) }), (undefined !== onConfirmAction || undefined !== onCancelAction) && (_jsx(DialogActions, { className: `${classes.plr50px} ${classes.pt30px}`, style: { paddingBottom: 30 }, children: warning && onCancelAction && warnBeforeCancel ? (_jsx(Warning, { isLoading: isLoading, onConfirm: onCancelAction, setWarning: setWarning, beforeCancelNode: beforeCancelNode })) : (_jsxs(Grid, { container: true, style: { justifyContent: 'center' }, children: [onCancelAction && (_jsx(Button, { variant: "text", color: "primary", disabled: isLoading, onClick: onCancelActionClick(warnBeforeCancel)(onCancelAction)(setWarning), children: onCancelText })), onConfirmAction && (_jsx(Button, { className: classes.btn, variant: "contained", color: "secondary", disableElevation: true, disabled: isLoading || disabledConfirmButton, onClick: onConfirmAction, children: onConfirmText }))] })) }))] }));
};
