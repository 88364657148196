var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { factSheetCode } from '../store/sagas/pipelines';
export const flatDocument = (categories, level = 1, parent = null) => {
    return categories.reduce((prev, _a) => {
        var { children } = _a, category = __rest(_a, ["children"]);
        const curr = {
            [`level${level}`]: [
                Object.assign(Object.assign({}, category), { required: category.code === factSheetCode ? true : category.required, parent }),
            ],
        };
        const next = flatDocument(children || [], level + 1, (category || {}).id);
        return [prev, curr, next]
            .reduce((keys, documents) => [...keys, ...Object.keys(documents)], [])
            .reduce((documents, key) => (Object.assign(Object.assign({}, documents), { [key]: [...(prev[key] || []), ...(curr[key] || []), ...(next[key] || [])] })), {});
    }, {});
};
