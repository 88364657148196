import { createSlice } from '@reduxjs/toolkit';
import { identity } from 'fp-ts/function';
import { channelStateMapper } from './mappers/channelStateModelMapper';
import { commissionsTableModelMapper } from './mappers/commissionsTableModelMapper';
import { commissionsTotalStateMapper } from './mappers/commissionsTotalStateMapper';
import { detailStateModelMapper } from './mappers/detailStateModelMapper';
import { documentSectionStateModelMapper } from './mappers/documentSectionStateMapper';
import { filesStateModelMapper } from './mappers/filesStateModelMapper';
import { formStateModelMapper } from './mappers/formStateModelMapper';
import { paperworkDetailStateMapper } from './mappers/paperworkDetailMapper';
import { uploadedDocumentsMapper } from './mappers/uploadedDocumentsMapper';
import { addFile } from './utils/addFile';
const initialState = {
    initialValues: null,
    loading: {
        store: false,
        channel: false,
        institute: false,
        product: false,
        participantPlaceOfResidence: false,
        paperwork: false,
        commissions: false,
        processPaperwork: false,
        signature: false,
        signers: false,
    },
    errors: { signers: false },
    fieldErrors: {
        store: undefined,
        channel: undefined,
        institute: undefined,
        product: undefined,
        participantPlaceOfResidence: undefined,
    },
    stores: [],
    channels: [],
    institutes: [],
    products: [],
    participantPlaceOfResidence: [],
    commissions: { table: { body: [], headers: [] }, total: null, error: null },
    paperworkDetail: null,
    detail: null,
    isDigitalSignRequested: null,
    isHandwrittenSignRequested: null,
    files: {},
    signers: [],
    documentSection: { documentTypes: [], mandatoryDocuments: 0, uploadedDocuments: 0 },
};
const GENERIC_ERROR = 'Si è verificato un errore';
const isDigitalSignRequestedStateModelMapper = (statusModelCode) => statusModelCode === 'digitalSignRequested';
const isHandwrittenSignRequestedStateModelMapper = (statusModelCode) => statusModelCode === 'handwrittenSignRequested';
export const paperworkProcessSlice = createSlice({
    name: 'PaperworkProcessSaga',
    initialState,
    reducers: {
        start: (state, _) => state,
        stop: identity,
        fetchChannels: identity,
        fetchLocation: (state, _) => state,
        fetchStores: (state, _) => state,
        fetchInstitutes: (state, _) => state,
        fetchProducts: (state, _) => state,
        updatePaperwork: (state, _) => state,
        fetchPaperwork: identity,
        fillForm: (state, _) => state,
        fetchCommissions: identity,
        processPaperwork: (state, _) => state,
        signPaperworkWithDigitalSignature: identity,
        fetchSigners: identity,
        SignersRequested: state => (Object.assign(Object.assign({}, state), { errors: Object.assign(Object.assign({}, state.errors), { signers: false }), loading: Object.assign(Object.assign({}, state.loading), { signers: true }) })),
        SignersFetched: (state, event) => (Object.assign(Object.assign({}, state), { signers: event.payload.signers, loading: Object.assign(Object.assign({}, state.loading), { signers: false }), errors: Object.assign(Object.assign({}, state.errors), { signers: false }) })),
        SignersNotFetched: state => (Object.assign(Object.assign({}, state), { errors: Object.assign(Object.assign({}, state.errors), { signers: true }), loading: Object.assign(Object.assign({}, state.loading), { signers: false }) })),
        clearSigners: identity,
        SignersCleared: state => (Object.assign(Object.assign({}, state), { signers: [] })),
        DigitalSignatureRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: true }) })),
        DigitalSignatureRequestDone: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: false }), paperworkDetail: paperworkDetailStateMapper(event.payload.paperwork), documentSection: documentSectionStateModelMapper(event.payload.paperwork.details.documentTypes, event.payload.paperwork.details.documentation), files: Object.assign(Object.assign({}, state.files), filesStateModelMapper(event.payload.paperwork.details.documentation)), isHandwrittenSignRequested: isHandwrittenSignRequestedStateModelMapper(event.payload.paperwork.details.currentStatus.code), isDigitalSignRequested: isDigitalSignRequestedStateModelMapper(event.payload.paperwork.details.currentStatus.code) })),
        DigitalSignatureRequestFailed: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: false }) })),
        cancelDigitalSignature: identity,
        DigitalSignatureCancellationRequested: identity,
        DigitalSignatureCancelled: identity,
        DigitalSignatureNotCancelled: identity,
        uploadFiles: (state, _) => state,
        FileUploadRequested: (state, event) => (Object.assign(Object.assign({}, state), { files: Object.assign(Object.assign({}, state.files), { [event.payload.documentTypeId]: addFile({
                    type: event.payload.file.type,
                    size: event.payload.file.size,
                    name: event.payload.file.name,
                    isUploading: true,
                    hasError: false,
                    isUploaded: false,
                    uploadedAt: null,
                    isFileTypeSupported: true,
                    transitionsHistory: [],
                }, state.files[event.payload.documentTypeId] || []) }) })),
        FileUploaded: (state, event) => (Object.assign(Object.assign({}, state), { documentSection: Object.assign(Object.assign({}, state.documentSection), { uploadedDocuments: state.documentSection.uploadedDocuments +
                    uploadedDocumentsMapper(state.files, event.payload.documentTypeId) }), files: Object.assign(Object.assign({}, state.files), { [event.payload.documentTypeId]: addFile({
                    type: event.payload.documentation.file.mimeType,
                    size: event.payload.documentation.file.size,
                    name: event.payload.documentation.file.name,
                    isUploading: false,
                    hasError: false,
                    isUploaded: true,
                    uploadedAt: new Date().toISOString(),
                    isFileTypeSupported: true,
                    transitionsHistory: event.payload.documentation.transitionsHistory,
                }, state.files[event.payload.documentTypeId] || []) }) })),
        FileTypeNotSupported: (state, event) => (Object.assign(Object.assign({}, state), { files: Object.assign(Object.assign({}, state.files), { [event.payload.documentTypeId]: addFile({
                    type: event.payload.file.type,
                    size: event.payload.file.size,
                    name: event.payload.file.name,
                    isUploading: false,
                    hasError: true,
                    isUploaded: false,
                    uploadedAt: null,
                    isFileTypeSupported: false,
                    transitionsHistory: [],
                }, state.files[event.payload.documentTypeId] || []) }) })),
        FileNotUploaded: (state, event) => (Object.assign(Object.assign({}, state), { files: Object.assign(Object.assign({}, state.files), { [event.payload.documentTypeId]: addFile({
                    type: event.payload.file.type,
                    size: event.payload.file.size,
                    name: event.payload.file.name,
                    isUploading: false,
                    hasError: true,
                    isUploaded: false,
                    uploadedAt: null,
                    isFileTypeSupported: true,
                    transitionsHistory: [],
                }, state.files[event.payload.documentTypeId] || []) }) })),
        ProcessPaperworkRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: true }) })),
        PaperworkProcessed: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: false }), paperworkDetail: paperworkDetailStateMapper(event.payload.paperwork), documentSection: documentSectionStateModelMapper(event.payload.paperwork.details.documentTypes, event.payload.paperwork.details.documentation), files: Object.assign(Object.assign({}, state.files), filesStateModelMapper(event.payload.paperwork.details.documentation)), isHandwrittenSignRequested: isHandwrittenSignRequestedStateModelMapper(event.payload.paperwork.details.currentStatus.code), isDigitalSignRequested: isDigitalSignRequestedStateModelMapper(event.payload.paperwork.details.currentStatus.code) })),
        PaperworkNotProcessed: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { processPaperwork: false }) })),
        CommissionsRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { commissions: true }) })),
        CommissionsFetched: (state, event) => (Object.assign(Object.assign({}, state), { commissions: Object.assign(Object.assign({}, state.commissions), { table: commissionsTableModelMapper(event.payload.commissions), total: commissionsTotalStateMapper(event.payload.commissions) }), loading: Object.assign(Object.assign({}, state.loading), { commissions: false }) })),
        CommissionsNotFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { commissions: false }), commissions: { table: { body: [], headers: [] }, total: null, error: event.payload.error.message } })),
        FormFillRequested: identity,
        FormNotFilled: identity,
        FormFilled: (state, event) => (Object.assign(Object.assign({}, state), { initialValues: formStateModelMapper(event.payload.paperwork.data) })),
        PaperworkRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: true }) })),
        PaperworkFetched: (state, event) => {
            return Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }), paperworkDetail: paperworkDetailStateMapper(event.payload.paperwork), detail: detailStateModelMapper(event.payload.paperwork, event.payload.permissions), documentSection: documentSectionStateModelMapper(event.payload.paperwork.details.documentTypes, event.payload.paperwork.details.documentation), files: Object.assign(Object.assign({}, state.files), filesStateModelMapper(event.payload.paperwork.details.documentation)), isHandwrittenSignRequested: isHandwrittenSignRequestedStateModelMapper(event.payload.paperwork.details.currentStatus.code), isDigitalSignRequested: isDigitalSignRequestedStateModelMapper(event.payload.paperwork.details.currentStatus.code) });
        },
        PaperworkNotFetched: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }) })),
        PaperworkUpdateRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: true }) })),
        PaperworkUpdated: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }), paperworkDetail: paperworkDetailStateMapper(event.payload.paperwork), detail: detailStateModelMapper(event.payload.paperwork, event.payload.permissions), documentSection: documentSectionStateModelMapper(event.payload.paperwork.details.documentTypes, event.payload.paperwork.details.documentation), files: Object.assign(Object.assign({}, state.files), filesStateModelMapper(event.payload.paperwork.details.documentation)), isHandwrittenSignRequested: isHandwrittenSignRequestedStateModelMapper(event.payload.paperwork.details.currentStatus.code), isDigitalSignRequested: isDigitalSignRequestedStateModelMapper(event.payload.paperwork.details.currentStatus.code) })),
        PaperworkNotUpdated: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }) })),
        LocationRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { participantPlaceOfResidence: undefined }), loading: Object.assign(Object.assign({}, state.loading), { participantResidenceLocation: true }) })),
        LocationFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { participantResidenceLocation: false }), participantPlaceOfResidence: event.payload.participantPlaceOfResidence })),
        LocationNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { participantPlaceOfResidence: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { participantResidenceLocation: false }) })),
        ProductsRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { product: undefined }), loading: Object.assign(Object.assign({}, state.loading), { product: true }) })),
        ProductsFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { product: false }), products: event.payload.products })),
        ProductsNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { product: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { product: false }), products: [] })),
        InstitutesRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { institute: undefined }), loading: Object.assign(Object.assign({}, state.loading), { institute: true }) })),
        InstitutesFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { institute: false }), institutes: event.payload.institutes })),
        InstitutesNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { institute: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { institute: false }), institutes: [] })),
        ChannelsRequested: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { channel: undefined }), loading: Object.assign(Object.assign({}, state.loading), { channel: true }) })),
        ChannelsFetched: (state, event) => (Object.assign(Object.assign({}, state), { channels: event.payload.channels.map(channelStateMapper), loading: Object.assign(Object.assign({}, state.loading), { channel: false }) })),
        ChannelsNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { channel: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { channel: false }), channels: [] })),
        StoresRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { store: true }), fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { store: undefined }) })),
        StoresFetched: (state, event) => (Object.assign(Object.assign({}, state), { stores: event.payload.stores, loading: Object.assign(Object.assign({}, state.loading), { store: false }) })),
        StoresNotFetched: state => (Object.assign(Object.assign({}, state), { fieldErrors: Object.assign(Object.assign({}, state.fieldErrors), { store: GENERIC_ERROR }), loading: Object.assign(Object.assign({}, state.loading), { store: false }) })),
        Started: identity,
        Stopped: identity,
    },
});
export const $PaperworkProcess = paperworkProcessSlice.actions;
export const $PaperworkProcessSlice = {
    selector: {
        detail: (state) => state.paperworkProcess.detail,
        paperwork: (state) => state.paperworkProcess.paperworkDetail,
        initialValues: (state) => state.paperworkProcess.initialValues,
        loading: (state) => state.paperworkProcess.loading,
        errors: (state) => state.paperworkProcess.errors,
        stores: (state) => state.paperworkProcess.stores,
        channels: (state) => state.paperworkProcess.channels,
        institutes: (state) => state.paperworkProcess.institutes,
        products: (state) => state.paperworkProcess.products,
        participantPlaceOfResidence: (state) => state.paperworkProcess.participantPlaceOfResidence,
        commissions: (state) => state.paperworkProcess.commissions,
        isPageLoading: (state) => state.paperworkProcess.loading.processPaperwork ||
            state.paperworkProcess.loading.commissions ||
            state.paperworkProcess.loading.paperwork,
        documentSection: (state) => state.paperworkProcess.documentSection,
        fieldErrors: (state) => state.paperworkProcess.fieldErrors,
        isHandwrittenSignRequested: (state) => state.paperworkProcess.isHandwrittenSignRequested,
        isDigitalSignRequested: (state) => state.paperworkProcess.isDigitalSignRequested,
        files: (state) => state.paperworkProcess.files,
        signers: (state) => state.paperworkProcess.signers,
    },
};
export const PaperworkProcessState = paperworkProcessSlice.reducer;
