import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TimelineConnector, TimelineContent, TimelineItem, TimelineSeparator } from '@mui/lab';
import { Grid, Typography } from '@mui/material';
import { DoneDot } from '../atoms/DoneDot';
import { OngoingDot } from '../atoms/OngoingDot';
export const HistoryItem = ({ subtitle, title, variant, withConnector = true }) => {
    return (_jsxs(TimelineItem, { sx: {
            '&::before': {
                flex: 0,
                padding: 0,
            },
        }, children: [_jsxs(TimelineSeparator, { children: [variant === 'ongoing' && _jsx(OngoingDot, {}), variant === 'done' && _jsx(DoneDot, {}), withConnector && _jsx(TimelineConnector, { sx: { width: '1px' } })] }), _jsx(TimelineContent, { children: _jsxs(Grid, { container: true, children: [_jsx(Grid, { container: true, item: true, children: _jsx(Typography, { variant: "h3", children: title }) }), _jsx(Grid, { container: true, item: true, children: _jsx(Typography, { color: "#6F797A", children: subtitle }) })] }) })] }));
};
