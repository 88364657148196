import * as t from 'io-ts';
export const lead = t.type({
    id: t.string,
    firstName: t.string,
    lastName: t.string,
    email: t.string,
    channel: t.string,
    phoneNumber: t.string,
    assignedTo: t.type({ firstName: t.string, lastName: t.string }),
}, 'lead');
