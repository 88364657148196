import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { BAZAAR_URL } from '../../../api/constants';
import { StorageCache } from '../../../lib/cache/StorageCache';
import { TypedCache } from '../../../lib/cache/TypedCache';
import { AuthClient } from '../../../lib/http/AuthClient';
import { AxiosClient } from '../../../lib/http/AxiosClient';
import { JsonClient } from '../../../lib/http/JsonClient';
import { PooledClient, HttpClientPool } from '../../../lib/http/PooledClient';
import { PayinCodec } from '../../models/Payin';
export const getPayinsByRecordId = (recordId) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get(`${BAZAAR_URL}/api/v1/records/${recordId}/payins`), e => e), taskEither.chainEitherKW(response => pipe(response.data, t.type({ data: t.readonlyArray(PayinCodec) }).decode)), taskEither.map(({ data }) => data));
