import { pipe } from 'fp-ts/function';
export const _getKeys = (obj, parentKey = '', result = []) => {
    Object.entries(obj).forEach(([key, value]) => {
        const newKey = parentKey ? `${parentKey}.${key}` : key;
        if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
            _getKeys(value, newKey, result);
        }
        else {
            result.push(newKey);
        }
    });
    return result;
};
export const _getErrorsLabel = (errorsKeys) => {
    const errorsLabelByFieldName = {
        channelInput: 'rubinetto',
        channel: 'rubinetto',
        'participant.firstName': 'nome',
        'participant.lastName': 'cognome',
        'participant.residence.address': 'indirizzo di residenza',
        'participant.residence.locationInput': 'località di residenza',
        'participant.residence.location.name': 'località di residenza',
        'participant.residence.location.province.shortName': 'località di residenza',
        'participant.residence.postalCode': 'codice postale',
        'participant.cityOfBirth': 'comune di nascita',
        'participant.provinceOfBirth': 'provincia di nascita',
        'participant.birthday': 'data di nascita',
        'participant.gender': 'sesso',
        'participant.fiscalCode': 'codice fiscale',
        'participant.identityDocument.type': 'tipo di documento',
        'participant.identityDocument.code': 'numero di documento',
        'participant.identityDocument.issuedBy': 'rilasciato da',
        'participant.identityDocument.placeOfIssue': 'luogo di rilascio',
        'participant.identityDocument.issueDate': 'data di rilascio',
        'participant.identityDocument.expiryDate': 'data di scadenza',
        'participant.contacts.mobilePhone': 'telefono cellulare',
        'participant.contacts.email': 'email',
        'participant.citizenship': 'cittadinanza',
        'participant.maritalStatus': 'stato civile',
        'participant.job': 'professione',
        'participant.companyType': 'tipo di azienda',
        'participant.netIncome': 'reddito mensile netto',
        'participant.monthlyInstallment': 'numero di mensilità',
        'participant.lengthOfService': 'anzianità lavorativa',
        'participant.household': 'componenti nucleo familiare',
        'cqs.purpose': 'finalità',
        'cqs.type': 'tipo di operazione',
        'cqs.institute': 'finanziaria',
        'cqs.product': 'prodotto',
        'cqs.requestedAmount': 'importo richiesto',
        'cqs.tan': 'TAN',
        'cqs.points': 'punti applicati',
        'cqs.duration': 'durata',
        'cqs.installment': 'rata',
        'cqs.interest': 'montante',
        'cqs.interestLeft': 'montante residuo',
        'cqs.interestDifference': 'delta montante',
    };
    return errorsKeys.reduce((errorsLabel, errorKey) => {
        const matchedErrorKey = Object.entries(errorsLabelByFieldName).find(([key]) => key === errorKey);
        if (!matchedErrorKey) {
            return errorsLabel;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, value] = matchedErrorKey;
        if (errorsLabel.includes(value)) {
            return errorsLabel;
        }
        return [...errorsLabel, value];
    }, []);
};
export const fieldsWithErrorView = (httpErrorsStateModel, fieldErrors) => {
    const flatHttpErrors = Object.fromEntries(Object.entries({
        store: httpErrorsStateModel.store,
        channel: httpErrorsStateModel.channel,
        channelInput: httpErrorsStateModel.channel,
        'participant.residence.locationInput': httpErrorsStateModel.participantPlaceOfResidence,
        'cqs.institute': httpErrorsStateModel.institute,
        'cqs.product': httpErrorsStateModel.product,
    }).filter(([_, value]) => value !== undefined));
    return pipe(Object.assign(Object.assign({}, flatHttpErrors), fieldErrors), _getKeys, _getErrorsLabel);
};
