import * as t from 'io-ts';
const FIXED = 'fixed';
const FLOATING = 'floating';
const MIXED = 'mixed';
const CAPPED = 'capped';
export const BazaarQuotationCodec = t.type({
    externalId: t.union([t.null, t.string]),
    rate: t.union([t.literal(FIXED), t.literal(FLOATING), t.literal(MIXED), t.literal(CAPPED)]),
    product: t.type({ externalId: t.string, name: t.string }),
    institute: t.type({ externalId: t.string, name: t.string }),
    provider: t.union([t.literal('mpn'), t.literal('egg')]),
    attributes: t.array(t.string),
    appraisal: t.number,
    tan: t.number,
    spread: t.number,
    installment: t.number,
    taeg: t.number,
    investigation: t.number,
    subsistenceThreshold: t.union([t.null, t.boolean]),
    alternativeTax: t.number,
    installmentExpense: t.union([t.null, t.number]),
    feasibilityConditionsAndOutcomes: t.type({
        netIncomeInstallmentRatio: t.union([
            t.null,
            t.type({
                threshold: t.number,
                value: t.number,
                outcome: t.boolean,
            }),
        ], 'netIncomeInstallmentRatio'),
        ltvRatio: t.union([
            t.null,
            t.type({
                threshold: t.number,
                value: t.number,
                outcome: t.boolean,
            }),
        ]),
    }),
});
export const BazaarQuotationUtils = {
    rate: { FIXED, FLOATING, MIXED, CAPPED },
};
