import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { readonlyNonEmptyArray } from 'io-ts-types';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { PooledClient, HttpClientPool } from '../../lib/http/PooledClient';
import { InvoiceCodec, InvoiceUtils } from '../models/Invoice/Invoice.codec';
export const createInvoiceFromRecord = (recordId) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).post(`${BAZAAR_URL}/api/v1/records/${recordId}/invoices`), e => e), taskEither.map(httpResponse => httpResponse.data), taskEither.chainEitherKW(t.type({ data: readonlyNonEmptyArray(InvoiceCodec) }).decode), taskEither.map(({ data }) => InvoiceUtils.invoiceListLength(data)));
