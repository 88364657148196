import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Formik } from 'formik';
import { boolean, option } from 'fp-ts';
import { constant, pipe } from 'fp-ts/function';
import { useEffect } from 'react';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $InvoiceCreation, $invoiceCreationSlice } from '../../state/invoiceCreation/InvoiceCreationState';
import { $InvoiceEdit, $invoiceEditSlice } from '../../state/invoiceEdit/InvoiceEditState';
import { ApproveButton } from '../ApproveButton';
import { DialogTitle } from '../DialogTitle';
import { NewInvoiceDialogForm } from './NewInvoiceDialogForm';
const handleClose = (setIsOpen, setEditMode) => () => {
    setIsOpen(false);
    setEditMode(false);
};
const onSubmit = (editMode, invoiceId, setIsOpen, setEditMode, createInvoiceAttempt, invoiceUpdateAttempt, dispatch) => (formData) => {
    editMode && option.isSome(invoiceId)
        ? dispatch(invoiceUpdateAttempt(formData))
        : dispatch(createInvoiceAttempt(formData));
    setIsOpen(false);
    setEditMode(false);
};
const formikInitialValues = (selector, editMode = false) => pipe(editMode, boolean.match(constant(selector($invoiceCreationSlice.selector.initialValues)), constant(pipe(selector($invoiceEditSlice.selector.initialValues), option.getOrElse(constant(selector($invoiceCreationSlice.selector.initialValues)))))));
export const NewInvoiceDialog = ({ open, setIsOpen, editMode = false, setEditMode }) => {
    const dispatch = useAppDispatch();
    const initialValues = formikInitialValues(useAppSelector, editMode);
    const invoiceIdOption = useAppSelector($invoiceEditSlice.selector.invoiceId);
    const isEditModalLoading = useAppSelector($invoiceEditSlice.selector.is.loading);
    useEffect(() => {
        editMode ? null : dispatch($InvoiceCreation.startInvoiceCreation());
        return () => {
            editMode ? dispatch($InvoiceEdit.endInvoiceEdit()) : dispatch($InvoiceCreation.endInvoiceCreation());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(Dialog, { open: open, fullWidth: true, maxWidth: "sm", children: [_jsx(DialogTitle, { title: editMode ? 'Modifica fattura' : 'Crea fattura', onClose: handleClose(setIsOpen, setEditMode) }), isEditModalLoading ? (_jsx(Box, { style: { height: 300, margin: 'auto' }, children: _jsx(CircularProgress, {}) })) : (_jsx(Formik, { initialValues: initialValues, enableReinitialize: true, onSubmit: onSubmit(editMode, invoiceIdOption, setIsOpen, setEditMode, $InvoiceCreation.createInvoice, $InvoiceEdit.updateInvoice, dispatch), children: _jsxs(DialogContent, { children: [editMode && option.isSome(invoiceIdOption) && (_jsx(ApproveButton, { invoiceId: invoiceIdOption.value })), _jsx(NewInvoiceDialogForm, { editMode: editMode })] }) }))] }));
};
