import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $PaperworkProcess, $PaperworkProcessSlice } from '../../state/PaperworkProcessState';
import { CancelDigitalSignature } from '../molecules/CancelDigitalSignature';
import { DigitalSignature } from '../molecules/DigitalSignature';
import { HandwrittenSignature } from '../molecules/HandwrittenSignature';
const signWithDigitalSignature = (dispatch) => () => {
    dispatch($PaperworkProcess.signPaperworkWithDigitalSignature());
};
const cancelDigitalSignature = (dispatch) => () => {
    dispatch($PaperworkProcess.cancelDigitalSignature());
};
export const Signature = ({ isDigitalSignRequested, isHandwrittenSignRequested }) => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector($PaperworkProcessSlice.selector.loading).signature;
    return (_jsxs(Grid, { container: true, flexWrap: "nowrap", gap: "16px", children: [isDigitalSignRequested && (_jsx(Grid, { item: true, xs: 12, children: _jsx(CancelDigitalSignature, { isDisabled: isLoading, onClick: cancelDigitalSignature(dispatch) }) })), !isDigitalSignRequested && !isHandwrittenSignRequested && (_jsxs(_Fragment, { children: [_jsx(Grid, { item: true, xs: 6, children: _jsx(DigitalSignature, { isDisabled: isLoading, onClick: signWithDigitalSignature(dispatch) }) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(HandwrittenSignature, {}) })] }))] }));
};
