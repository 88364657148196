import { option, readonlyArray, readonlyRecord, string } from 'fp-ts';
import { isSome, none, some } from 'fp-ts/Option';
import { constant, pipe } from 'fp-ts/function';
import { Refinement } from '../../lib/Refinement';
import { ClientError } from '../../lib/http/ClientError';
import { Process } from '../../lib/redux/Process';
import { Reducer } from '../../lib/redux/Reducer';
import { $role } from '../../model/Role';
import { session } from '../../utils/session';
import { ProcessState } from '../processes/ProcessState';
import { SecurityActions } from './SecurityActions';
export const PERMISSION = {
    VIEW_RECORD_LIST: 'viewRecordList',
    VIEW_PASSIVE_INVOICE_HISTORY_FILTER: 'viewPassiveInvoiceHistoryFilter',
    VIEW_ASSIGNEE_TABLE_ROW: 'viewAssigneeTableRow',
    VIEW_ENASARCO_TABLE_ROW: 'viewEnasarcoTableRow',
    FETCH_USER_FROM_INVOICE_DETAIL: 'fetchUserFromInvoiceDetail',
    TRANSMIT_INVOICE: 'transmitInvoice',
    CREATE_INVOICE: 'createInvoice',
    VIEW_EMPTY_LIST_IMG: 'viewEmptyListImg',
    VIEW_INVOICE_DETAIL: 'viewInvoiceDetail',
    DELETE_INVOICE: 'deleteInvoice',
    EDIT_INVOICE: 'editInvoice',
    VIEW_INVOICE_LIST_SWITCH: 'viewInvoiceListSwitch',
    VIEW_MY_INVOICES_TEXT: 'viewMyInvoicesText',
    VIEW_PASSIVE_INVOICE_TEXT: 'viewPassiveInvoiceText',
    VIEW_SIDEBAR_PERSONAL_AREA: 'viewSidebarPersonalArea',
    VIEW_SIDEBAR_BACK_OFFICE: 'viewSidebarBackOffice',
    EDIT_ENASARCO: 'editEnasarco',
    UPLOAD_COMMISSION_PLAN: 'uploadCommissionPlan',
    VIEW_PASSIVE_INVOICE_ROUTE: 'viewPassiveInvoiceRoute',
    VIEW_RECORD_COMMISSIONS: 'viewRecordCommissions',
    EDIT_COMMISSIONS: 'editCommissions',
    REGISTER_PAYIN: 'registerPayin',
    VIEW_RECORD_LIST_WITH_PAYIN: 'viewRecordListWithPayin',
    VIEW_ACTIVE_INVOICE_ROUTE: 'viewActiveInvoiceRoute',
    CREATE_ACTIVE_INVOICE: 'createActiveInvoice',
    VIEW_ACTIVE_INVOICING_RECORD_LIST: 'viewActiveInvoicingRecordList',
    VIEW_ACTIVE_INVOICE_HISTORY: 'viewActiveInvoiceHistory',
    UPDATE_COASSIGNEE: 'updateCoassingnee',
    VIEW_PAPERWORK: 'viewPaperwork',
    VIEW_ASSIGNED_TO_COLUMN_LEAD_LIST: 'viewAssignedToColumnLeadList',
    CAN_VIEW_CHANNEL_FIELD: 'canViewChannelField',
};
const adminPermissionList = [
    PERMISSION.VIEW_RECORD_LIST,
    PERMISSION.VIEW_ASSIGNEE_TABLE_ROW,
    PERMISSION.VIEW_ENASARCO_TABLE_ROW,
    PERMISSION.FETCH_USER_FROM_INVOICE_DETAIL,
    PERMISSION.VIEW_PASSIVE_INVOICE_HISTORY_FILTER,
    PERMISSION.CREATE_INVOICE,
    PERMISSION.TRANSMIT_INVOICE,
    PERMISSION.DELETE_INVOICE,
    PERMISSION.EDIT_INVOICE,
    PERMISSION.VIEW_INVOICE_LIST_SWITCH,
    PERMISSION.VIEW_INVOICE_DETAIL,
    PERMISSION.VIEW_SIDEBAR_BACK_OFFICE,
    PERMISSION.VIEW_PASSIVE_INVOICE_TEXT,
    PERMISSION.EDIT_ENASARCO,
    PERMISSION.UPLOAD_COMMISSION_PLAN,
    PERMISSION.VIEW_RECORD_COMMISSIONS,
    PERMISSION.EDIT_COMMISSIONS,
    PERMISSION.REGISTER_PAYIN,
    PERMISSION.VIEW_RECORD_LIST_WITH_PAYIN,
    PERMISSION.VIEW_PASSIVE_INVOICE_ROUTE,
    PERMISSION.VIEW_ACTIVE_INVOICE_ROUTE,
    PERMISSION.CREATE_ACTIVE_INVOICE,
    PERMISSION.VIEW_ACTIVE_INVOICING_RECORD_LIST,
    PERMISSION.VIEW_ACTIVE_INVOICE_HISTORY,
    PERMISSION.UPDATE_COASSIGNEE,
    PERMISSION.VIEW_PAPERWORK,
    PERMISSION.VIEW_ASSIGNED_TO_COLUMN_LEAD_LIST,
    PERMISSION.CAN_VIEW_CHANNEL_FIELD,
];
const operatorPermissionList = [
    PERMISSION.VIEW_RECORD_LIST,
    PERMISSION.VIEW_ASSIGNEE_TABLE_ROW,
    PERMISSION.VIEW_ENASARCO_TABLE_ROW,
    PERMISSION.FETCH_USER_FROM_INVOICE_DETAIL,
    PERMISSION.VIEW_PASSIVE_INVOICE_HISTORY_FILTER,
    PERMISSION.CREATE_INVOICE,
    PERMISSION.TRANSMIT_INVOICE,
    PERMISSION.DELETE_INVOICE,
    PERMISSION.EDIT_INVOICE,
    PERMISSION.VIEW_INVOICE_LIST_SWITCH,
    PERMISSION.VIEW_INVOICE_DETAIL,
    PERMISSION.VIEW_SIDEBAR_BACK_OFFICE,
    PERMISSION.VIEW_PASSIVE_INVOICE_TEXT,
    PERMISSION.EDIT_ENASARCO,
    PERMISSION.UPLOAD_COMMISSION_PLAN,
    PERMISSION.VIEW_RECORD_COMMISSIONS,
    PERMISSION.EDIT_COMMISSIONS,
    PERMISSION.REGISTER_PAYIN,
    PERMISSION.VIEW_RECORD_LIST_WITH_PAYIN,
    PERMISSION.VIEW_PASSIVE_INVOICE_ROUTE,
    PERMISSION.VIEW_ACTIVE_INVOICE_ROUTE,
    PERMISSION.CREATE_ACTIVE_INVOICE,
    PERMISSION.VIEW_ACTIVE_INVOICING_RECORD_LIST,
    PERMISSION.VIEW_ACTIVE_INVOICE_HISTORY,
    PERMISSION.VIEW_ASSIGNED_TO_COLUMN_LEAD_LIST,
];
const agentPermissionList = [];
const billableAgentPermissionList = [
    PERMISSION.VIEW_INVOICE_DETAIL,
    PERMISSION.VIEW_EMPTY_LIST_IMG,
    PERMISSION.VIEW_MY_INVOICES_TEXT,
    PERMISSION.VIEW_SIDEBAR_PERSONAL_AREA,
    PERMISSION.VIEW_PASSIVE_INVOICE_ROUTE,
    PERMISSION.VIEW_ACTIVE_INVOICE_ROUTE,
    PERMISSION.VIEW_ACTIVE_INVOICE_HISTORY,
];
const permissionListByRoles = {
    [$role.ROLE_NEXUS_QUOTING_TOOL__MORTGAGE]: [],
    [$role.ROLE_NEXUS_BERGAMO_AMMINISTRATORE]: adminPermissionList,
    [$role.ROLE_NEXUS_AGENTE_COLLABORATORE]: agentPermissionList,
    [$role.ROLE_NEXUS_AGENTE_HEAD_AGENCY]: agentPermissionList,
    [$role.ROLE_NEXUS_AGENTE_HEAD_DIVISIONAL]: agentPermissionList,
    [$role.ROLE_NEXUS_BERGAMO_OPERATORE]: operatorPermissionList,
    [$role.ROLE_NEXUS_BILLABLE]: billableAgentPermissionList,
};
const PermissionItemEq = {
    equals: (firstPermissionItem, secondPermissionItem) => string.Eq.equals(firstPermissionItem, secondPermissionItem),
};
const userPermissionList = (user) => pipe(user.roles, readonlyArray.chain(role => pipe(permissionListByRoles, readonlyRecord.lookup(role), option.getOrElseW(constant([])))), readonlyArray.uniq(PermissionItemEq));
const empty = { user: none, message: none, permissionList: [] };
const reducer = Reducer(Refinement.any(SecurityActions.Session.is.done, SecurityActions.Login.is.done, SecurityActions.Logout.is.done, SecurityActions.RefreshSource.is.done))(empty)({
    [SecurityActions.Session.type.done]: (state, user) => (Object.assign(Object.assign({}, state), { user: some(user), message: none, permissionList: userPermissionList(user) })),
    [SecurityActions.Login.type.done]: (state, user) => (Object.assign(Object.assign({}, state), { user: some(user), message: none, permissionList: userPermissionList(user) })),
    [SecurityActions.RefreshSource.type.done]: (state, user) => (Object.assign(Object.assign({}, state), { user: some(user), message: none, permissionList: userPermissionList(user) })),
    [SecurityActions.Logout.type.done]: (state, message) => (Object.assign(Object.assign({}, state), { user: none, message: message ? some(message) : none, permissionList: [] })),
});
const firstRun = (state) => !ProcessState.selector.has.running(SecurityActions.Session)(state) &&
    !ProcessState.selector.has.done(SecurityActions.Session)(state) &&
    !ProcessState.selector.has.failed(SecurityActions.Session)(state);
const isErrorProcess = (e) => typeof e === 'object';
const auth = (state) => ProcessState.selector
    .processes()(state)
    .filter(Process.is.failed)
    .filter(p => {
    if (!isErrorProcess(p.error)) {
        return false;
    }
    const lastLoginProcessTimestamp = ProcessState.selector
        .processes(SecurityActions.Login, SecurityActions.Session)(state)
        .filter(({ _tag }) => _tag === 'Done')
        .reduce((acc, process) => (acc < process.timestamp ? process.timestamp : acc), -Infinity);
    if (p.timestamp > lastLoginProcessTimestamp) {
        return ClientError.is.unauthorized(p.error);
    }
    return false;
})
    .reduce((acc, p) => {
    if (isErrorProcess(p.error)) {
        if (ClientError.is.httpError(p.error)) {
            return some(session.fromError(p.error.response.data.error));
        }
    }
    return acc;
}, none);
const isForbidden = (state) => pipe(ProcessState.selector.error(SecurityActions.Login)(state), option.exists(ClientError.is.forbidden));
const loginErrorLabel = (state) => pipe(ProcessState.selector.error(SecurityActions.Login)(state), option.filter(ClientError.is.httpError), option.map(error => error.response.data.error), option.map(session.fromError), option.alt(() => state.security.message));
export const SecurityState = {
    reducer: reducer,
    selector: {
        permissionList: (state) => state.security.permissionList,
        loginErrorLabel: (state) => loginErrorLabel(state),
        isLoggedIn: (state) => isSome(state.security.user),
        isForbidden: (state) => isForbidden(state),
        firstRun: (state) => firstRun(state),
        auth: (state) => auth(state),
        user: (state) => state.security.user,
    },
};
