import { ProcessState } from '../../store/processes/ProcessState';
import { HistoryAction } from '../../store/record/history/HistoryAction';
import { RecordHistoryState } from '../../store/record/history/HistoryState';
export const mapState = (state, props) => ({
    history: RecordHistoryState.selectors.matchHistory(state, props),
    isHistoryLoading: ProcessState.selector.has.running(HistoryAction.Timeline)(state),
    error: ProcessState.selector.has.failed(HistoryAction.Timeline)(state),
});
export const mapDispatch = {
    fetchHistory: HistoryAction.Timeline,
};
