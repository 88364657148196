import { option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { push } from 'redux-first-history';
import { call, put, takeLeading } from 'typed-redux-saga';
import { LocationStateC } from '../../model/route/LocationState';
import { $Navigation, $NavigationSlice } from './NavigationState';
function* goBack(recordStatusCode, location) {
    const locationStateEither = pipe(location, option.fromNullable, option.map(({ state }) => state), option.chainEitherK(LocationStateC.decode));
    if (option.isNone(locationStateEither)) {
        return;
    }
    const redirectRoute = $NavigationSlice.redirectRoute(recordStatusCode)(locationStateEither.value);
    yield* put(push(redirectRoute));
}
export function* NavigationSaga() {
    yield* takeLeading($Navigation.goBack, function* (goBackCommand) {
        const recordOption = goBackCommand.payload.record;
        if (option.isSome(recordOption)) {
            yield* call(goBack, recordOption.value.status.code, goBackCommand.payload.location);
        }
    });
}
