import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import { Grid } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { number } from 'fp-ts';
import { getOrElse } from 'fp-ts/Option';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TimelineConnectorIcon from '../../../assets/icons/timelineConnector1.svg';
import { useActiveScroll } from '../../hooks/useActiveScroll';
import { activeRecordsError } from '../../utils/error';
import { convertToString } from '../../utils/object';
import { Styled, useStyles } from './History.styles';
import { HistoryCard } from './HistoryCard/HistoryCardComponent';
const withHistory = (historyOption) => getOrElse(() => [])(historyOption);
const errorBoundary = (error) => (isLoading) => (id) => (fetchHistory) => activeRecordsError(error, () => fetchHistory(id), isLoading, 'Al momento non è possibile visualizzare lo storico delle attività.');
export const History = ({ isHistoryLoading, error, history, fetchHistory }) => {
    const { classes } = useStyles();
    const scroll = useActiveScroll('scroll-history');
    const { id } = useParams();
    const recordId = convertToString(id);
    const errorMessage = error ? 'Impossibile visualizzare lo storico' : '';
    const Error = errorBoundary(errorMessage)(isHistoryLoading)(recordId)(fetchHistory);
    useEffect(() => {
        if (recordId !== 'none') {
            fetchHistory(recordId);
        }
    }, [recordId, fetchHistory]);
    return (_jsxs("div", { id: "scroll-history", className: clsx(classes.height100, classes.overflowAuto, classes.backgroundWhite), children: [_jsx(Typography, { className: classes.title, variant: "h4", color: "primary", "data-scroll": scroll, children: "Storico attivit\u00E0" }), _jsx("div", { "data-scroll": scroll, className: classes.separator }), _jsx(Error, { children: isHistoryLoading ? (_jsx(Grid, { container: true, justifyContent: "center", className: classes.mt5, children: _jsx(CircularProgress, {}) })) : (_jsx(Styled.Timeline, { children: withHistory(history).map((historyCard, i, { length }) => {
                        return (_jsxs(Styled.TimelineItem, { children: [_jsxs(TimelineSeparator, { children: [_jsx(Styled.TimelineDot, { children: _jsx(TimelineConnectorIcon, { style: { width: 30, height: 30 } }) }), !number.Eq.equals(i, length - 1) && _jsx(Styled.TimelineConnector, {})] }), _jsx(Styled.TimelineContent, { children: _jsx(HistoryCard, { history: historyCard }) })] }, i));
                    }) })) })] }));
};
