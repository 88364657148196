var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { init } from '@sentry/react';
import { it } from 'date-fns/locale';
import { option } from 'fp-ts';
import { not } from 'fp-ts/Predicate';
import { constNull, pipe } from 'fp-ts/function';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import './gtm';
import { TssCacheProvider } from 'tss-react';
import { AppRoutes } from './routes/routes';
import * as serviceWorker from './serviceWorker';
import { TokenProvider } from './session/ui/TokenProvider';
import { Store } from './store/Store';
import './theme/index.css';
import { theme } from './theme/theme';
import Notification from './ui/Notification';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
const container = document.getElementById('root');
const root = createRoot(container);
const muiCache = createCache({
    key: 'mui',
    prepend: true,
});
const tssCache = createCache({
    key: 'tss',
});
const tokenRefreshIntervalMilliseconds = pipe(process.env.TOKEN_REFRESH_INTERVAL_MINUTES, option.fromNullable, option.map(Number), option.filter(not(isNaN)), option.map(minutes => minutes * 60 * 1000), option.getOrElseW(constNull));
root.render(_jsx(LocalizationProvider, { dateAdapter: AdapterDateFns, adapterLocale: it, children: _jsx(Provider, { store: Store(), children: _jsx(GoogleOAuthProvider, { clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '', children: _jsx(CacheProvider, { value: muiCache, children: _jsx(TssCacheProvider, { value: tssCache, children: _jsx(StyledEngineProvider, { injectFirst: true, children: _jsx(ThemeProvider, { theme: theme, children: _jsxs(TokenProvider, { tokenRefreshIntervalMilliseconds: tokenRefreshIntervalMilliseconds, children: [_jsx(AppRoutes, {}), _jsx(Notification, {})] }) }) }) }) }) }) }) }));
if ('production' !== process.env.NODE_ENV && module.hot) {
    module.hot.accept('./');
}
init({
    dsn: process.env.NODE_ENV !== 'production' ? undefined : process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    normalizeDepth: 10,
    release: (_a = process.env.REACT_APP_RELEASE) === null || _a === void 0 ? void 0 : _a.replace(/\//g, '_'),
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
