import { $type } from 'fortepiano';
import * as t from 'io-ts';
export const PayinCodec = t.type({
    productType: t.string,
    registeredPayinAmount: $type.numeric,
    leftoverPayinAmount: $type.numeric,
    billablePayinAmount: $type.numeric,
    isTotallyEmitted: t.boolean,
    totalCommissionAmount: $type.numeric,
}, 'Payin');
