import { createSlice } from '@reduxjs/toolkit';
import { option, readonlyArray, readonlyRecord } from 'fp-ts';
import { constant, identity, pipe } from 'fp-ts/function';
import { LocationUtils } from '../../models/Location.codec';
import { QuotationUtils } from '../../models/Quotation.codec';
const initialState = {
    fmpSearch: null,
    participantsLocationList: {},
    loading: { participantLocation: {}, sendToFmc: false, quotationList: false },
    quotation: null,
    initialValues: {
        note: '',
        guarantors: [],
        applicants: [],
        additionalParticipants: [],
    },
    quotationList: [],
    quotationListLength: 0,
    quotationParticipants: [],
};
const getFirstAndSecondParticipant = (applicantType) => (fmpParticipants) => pipe(fmpParticipants, readonlyArray.filter(participant => participant.type === applicantType), participants => [readonlyArray.lookup(0)(participants), readonlyArray.lookup(1)(participants)], readonlyArray.compact);
export const getAdditionalParticipants = (type) => (fmpParticipants) => {
    const applicants = fmpParticipants.filter(participant => participant.type === type);
    return applicants.length > 2 ? applicants.slice(2) : [];
};
const getInitialParticipants = (fmpParticipants) => {
    return pipe(fmpParticipants, readonlyArray.map(participant => ({
        type: participant.type,
        household: participant.household,
        job: participant.job,
        netMonthlyIncome: participant.rawNetMonthlyIncome,
        ongoingDebtInstallment: participant.rawOngoingDebtInstallment,
        nationality: participant.nationality,
        firstName: '',
        lastName: '',
        gender: '',
        birthDate: null,
        birthCity: '',
        birthProvince: '',
        birthCountry: '',
        maritalStatus: '',
        isItalianResident: '',
        taxCode: '',
        residenceAddress: '',
        residenceStreetNumber: '',
        cityOfResidenceInput: participant.placeOfResidenceInput || '',
        cityOfResidencePostalCode: '',
        cityOfResidence: participant.placeOfResidence
            ? {
                name: participant.placeOfResidence.name,
                postalCode: '',
                province: {
                    shortName: participant.placeOfResidence.province.shortName,
                },
            }
            : {
                name: '',
                postalCode: '',
                province: {
                    shortName: '',
                },
            },
        mobilePhoneNumber: '',
        email: '',
        numberOfMonthlyPayment: '',
    })));
};
const getApplicantsByType = (type) => (participants) => pipe(participants, getFirstAndSecondParticipant(type), getInitialParticipants, readonlyArray.toArray);
const getInitialValues = (participants, quotation, search) => {
    return {
        procedureStatus: search.procedureStatus,
        quotationId: quotation.quotationId,
        productId: quotation.productId,
        instituteId: quotation.instituteId,
        note: '',
        applicants: getApplicantsByType('richiedente')(participants),
        guarantors: getApplicantsByType('garante')(participants),
        additionalParticipants: pipe([
            ...getAdditionalParticipants('richiedente')(participants),
            ...getAdditionalParticipants('garante')(participants),
        ], readonlyArray.toArray),
    };
};
export const mpnQuotationSlice = createSlice({
    name: 'MpnQuotation',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        selectQuotation: (state, commandAction) => (Object.assign(Object.assign({}, state), { fmpSearch: commandAction.payload.search })),
        fetchQuotation: (state, _) => state,
        QuotationRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { quotationList: true }) })),
        QuotationFetched: (state, event) => (Object.assign(Object.assign({}, state), { quotationList: QuotationUtils.quotationList(event.payload.bazaarQuotation), loading: Object.assign(Object.assign({}, state.loading), { quotationList: false }), quotationListLength: event.payload.bazaarQuotation.length, quotationParticipants: pipe(event.payload.participants, readonlyArray.toArray) })),
        QuotationNotFetched: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { quotationList: false }) })),
        fetchLocation: (state, _) => state,
        sendToFmc: (state, _) => state,
        SendToFmcRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { sendToFmc: true }) })),
        SentToFmc: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { sendToFmc: false }) })),
        SendToFmcFailed: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { sendToFmc: false }) })),
        Stopped: constant(initialState),
        Started: identity,
        LocationRequested: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { participantLocation: Object.assign(Object.assign({}, state.loading.participantLocation), { [event.payload.participantIndex]: true }) }) })),
        LocationFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { participantLocation: Object.assign(Object.assign({}, state.loading.participantLocation), { [event.payload.participantIndex]: false }) }), participantsLocationList: Object.assign(Object.assign({}, state.participantsLocationList), { [event.payload.participantIndex]: LocationUtils.mpnParticipantLocationList(event.payload.bazaarLocation) }) })),
        LocationNotFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { participantLocation: Object.assign(Object.assign({}, state.loading.participantLocation), { [event.payload.participantIndex]: false }) }) })),
        QuotationSelected: (state, event) => (Object.assign(Object.assign({}, state), { quotation: event.payload.quotation, initialValues: getInitialValues(event.payload.search.participants, event.payload.quotation, event.payload.search), fmpSearch: event.payload.search })),
    },
});
export const $MpnQuotation = mpnQuotationSlice.actions;
export const $MpnQuotationSlice = {
    selector: {
        initialValues: (state) => state.mpnQuotation.initialValues,
        isSendingToFmc: (state) => state.mpnQuotation.loading.sendToFmc,
        quotation: (state) => state.mpnQuotation.quotation,
        fmpSearch: (state) => state.mpnQuotation.fmpSearch,
        quotationList: (state) => state.mpnQuotation.quotationList,
        quotationListLength: (state) => state.mpnQuotation.quotationListLength,
        isQuotationListLoading: (state) => state.mpnQuotation.loading.quotationList,
        isParticipantLocationLoading: (state) => (participantIndex) => state.mpnQuotation.loading.participantLocation[participantIndex],
        participantLocationList: (state) => (participantIndex) => pipe(state.mpnQuotation.participantsLocationList, readonlyRecord.lookup(participantIndex.toString()), option.getOrElseW(() => [])),
    },
};
export const MpnQuotationState = mpnQuotationSlice.reducer;
