import { isObjectEmpty } from '../utils/object';
import { session } from '../utils/session';
const emptyState = {
    role: '',
    commission_profile: '',
    store: '',
};
const some = (roles) => (user) => ({
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    role: session.matchRole(roles)(user),
    user_id: user.username,
    commission_profile: session.userDataValue(user)('profilo_provvigionale'),
    store: session.userDataValue(user)('store'),
});
const restart = (appId) => (u) => (w) => {
    on(appId)('shutdown')()(w);
    on(appId)('boot')(u)(w);
};
const on = (appId) => (command) => (user = emptyState) => (w) => {
    if (!w.Intercom) {
        return;
    }
    if (isObjectEmpty(user)) {
        if (command === 'boot') {
            w.Intercom(command, {
                app_id: appId,
            });
        }
        else {
            w.Intercom(command);
        }
    }
    else {
        w.Intercom(command, Object.assign({ app_id: appId }, user));
    }
};
export const Intercom = (appId) => ({
    on: on(appId),
    user: { some },
    restart: restart(appId),
});
