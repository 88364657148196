import * as t from 'io-ts';
import { IdentifierCodec } from '../Identifier/Identifier';
export const ActiveInvoiceItemCodec = t.type({
    agentId: IdentifierCodec,
    agentFullName: t.string,
    amount: t.number,
    recordId: IdentifierCodec,
    typology: t.string,
    description: t.string,
}, 'ActiveInvoiceItem');
