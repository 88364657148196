import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { Form, Formik } from 'formik';
import React, { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { $mpnInvoiceForm } from '../models/MpnInvoiceForm/MpnInvoiceForm';
import { $InvoiceCreationFromMpn, $invoiceCreationFromMpnSlice, } from '../state/invoiceCreationFromMpn/InvoiceCreationFromMpnState';
import { DialogTitle } from './DialogTitle';
const onInputChange = (dispatch, userListFetchAttemptAction) => (_, userValue) => {
    if (userValue.length > 2) {
        dispatch(userListFetchAttemptAction({ params: { fullName: userValue } }));
    }
};
const handleAutocompleteChange = (setFieldValue) => (_, assignee) => {
    if (!assignee) {
        return;
    }
    setFieldValue('userFullName', assignee.fullName);
    setFieldValue('userId', assignee.id);
};
const onSubmit = (dispatch, createInvoice, onClose) => (formData) => {
    dispatch(createInvoice({
        formData: formData,
        onSuccess: onClose,
    }));
};
export const MpnDialog = ({ open, onClose }) => {
    const dispatch = useAppDispatch();
    const assignees = useAppSelector($invoiceCreationFromMpnSlice.selector.assignees);
    const isUsersLoading = useAppSelector($invoiceCreationFromMpnSlice.selector.isUsersLoading);
    const initialValues = useAppSelector($invoiceCreationFromMpnSlice.selector.initialValues);
    const radio = _jsx(Radio, { size: "small" });
    useEffect(() => {
        dispatch($InvoiceCreationFromMpn.start());
        return () => {
            dispatch($InvoiceCreationFromMpn.stop());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(Dialog, { open: open, fullWidth: true, maxWidth: "sm", children: [_jsx(DialogTitle, { title: "Crea fattura MPN", onClose: onClose }), _jsx(Formik, { initialValues: initialValues, enableReinitialize: true, onSubmit: onSubmit(dispatch, $InvoiceCreationFromMpn.createInvoice, onClose), children: ({ values, handleChange, setFieldValue }) => (_jsx(Form, { children: _jsxs(DialogContent, { children: [_jsx(Autocomplete, { loading: isUsersLoading, loadingText: "Caricamento...", autoSelect: true, disableClearable: true, options: assignees, getOptionLabel: option => option.fullName, onInputChange: onInputChange(dispatch, $InvoiceCreationFromMpn.fetchUserList), isOptionEqualToValue: (option, value) => option.id === value.id, onChange: handleAutocompleteChange(setFieldValue), renderInput: params => (_jsx(TextField, Object.assign({ variant: "standard" }, params, { required: true, value: values.userFullName, onChange: handleChange, name: "userFullName", label: "Collaboratore", inputProps: Object.assign({}, params.inputProps) }))) }), _jsxs(FormControl, { variant: "standard", style: { marginBottom: 4 }, component: "fieldset", required: true, fullWidth: true, children: [_jsx(FormLabel, { children: "Tipo" }), _jsxs(RadioGroup, { name: "purpose", value: values.purpose, onChange: handleChange, row: true, children: [_jsx(FormControlLabel, { value: "Acquisto", control: radio, label: "Acquisto", labelPlacement: "end" }), _jsx(FormControlLabel, { value: "Surroga", control: radio, label: "Surroga", labelPlacement: "end" })] })] }), _jsx(TextField, { variant: "standard", name: "amount", required: true, style: { marginBottom: 16, width: 'inherit' }, label: "Importo erogato", InputProps: {
                                    startAdornment: _jsx(InputAdornment, { position: "start", children: "\u20AC" }),
                                }, type: "number", onChange: handleChange, value: values.amount }), _jsx(Grid, { container: true, justifyContent: "center", children: _jsx(Button, { type: "submit", variant: "contained", disabled: !$mpnInvoiceForm.canSave(values), children: "Crea" }) })] }) })) })] }));
};
