import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TextField } from '@mui/material';
import { addMonths, format } from 'date-fns';
import { DateField } from '../atoms/DateField';
const WARRANT_END_DATE = 'warrantEndDate';
const WARRANT_START_DATE = 'warrantStartDate';
const WARRANT_END_LABEL = 'warrantEndLabel';
export const WarrantSection = ({ setFieldValue, warrantStartDateValue, warrantEndLabelValue, handleBlur, }) => {
    return (_jsxs(_Fragment, { children: [_jsx(DateField, { label: "Data firma mandato", name: WARRANT_START_DATE, value: new Date(warrantStartDateValue), onClear: () => {
                    setFieldValue(WARRANT_START_DATE, '');
                    setFieldValue(WARRANT_END_DATE, '');
                    setFieldValue(WARRANT_END_LABEL, '');
                }, onChange: newWarrantStartDate => {
                    if (!newWarrantStartDate) {
                        setFieldValue(WARRANT_START_DATE, '');
                        setFieldValue(WARRANT_END_LABEL, '');
                        setFieldValue(WARRANT_END_DATE, '');
                        return;
                    }
                    setFieldValue(WARRANT_START_DATE, newWarrantStartDate.toISOString());
                    const sixMonthsLater = addMonths(newWarrantStartDate, 6);
                    setFieldValue(WARRANT_END_LABEL, format(sixMonthsLater, 'dd/MM/yyyy'));
                    setFieldValue(WARRANT_END_DATE, sixMonthsLater);
                }, onBlur: handleBlur }), _jsx(TextField, { fullWidth: true, label: "Data scadenza mandato", name: WARRANT_END_LABEL, value: warrantEndLabelValue, disabled: true })] }));
};
