import { either } from 'fp-ts';
import { all, call, cancel, put, select, take, takeLatest, takeLeading } from 'typed-redux-saga';
import { isUserAuthorized } from '../../../authorization/useAuthorization';
import { PERMISSION, SecurityState } from '../../../store/security/SecurityState';
import { getPaperworks } from '../../web/getPaperworks';
import { getRecordById } from '../../web/getRecordById';
import { $CoassigneePaperwork } from './CoassigneePaperworkState';
function* fetchPaperwork(id) {
    yield* put($CoassigneePaperwork.PaperworkRequested());
    const result = yield* all([call(getRecordById(id)), call(getPaperworks({ legacyId: id }))]);
    const artooRecordEither = result[0];
    const paperworkListEither = result[1];
    if (either.isLeft(artooRecordEither) || either.isLeft(paperworkListEither)) {
        yield* put($CoassigneePaperwork.PaperworkNotFetched());
    }
    else {
        yield* put($CoassigneePaperwork.PaperworkFetched({
            artooRecord: artooRecordEither.right,
            paperworkList: paperworkListEither.right,
        }));
    }
}
export function* CoassigneePaperworkSaga() {
    yield* takeLeading($CoassigneePaperwork.start, function* () {
        const userPermissionList = yield* select(SecurityState.selector.permissionList);
        if (isUserAuthorized(userPermissionList)(PERMISSION.VIEW_PAPERWORK)) {
            yield* put($CoassigneePaperwork.Started());
            const task = yield* all([
                yield* takeLatest($CoassigneePaperwork.fetchPaperworkById, function* (fetchPaperworkCommand) {
                    yield* call(fetchPaperwork, fetchPaperworkCommand.payload.artooRecordId);
                }),
            ]);
            yield* take($CoassigneePaperwork.stop);
            yield* cancel(task);
            yield* put($CoassigneePaperwork.Stopped());
        }
    });
}
