import { array, either, option } from 'fp-ts';
import { constUndefined, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { IdentifierC } from './Identifier';
import { RecordLinkLegacyC } from './RecordLinkLegacy';
export const RecordLinkC = t.recursion('RecordLink', () => t.type({
    label: t.string,
    source_pratica_id: IdentifierC,
}));
export const parentRecordId = (record) => pipe(record.destinationRelationships, option.fromNullable, option.chainEitherK(destinationRelationships => pipe(destinationRelationships, t.array(RecordLinkC).decode, either.map(recordLink => pipe(recordLink, array.filter(({ label }) => label === 'clones'), array.last, option.map(({ source_pratica_id }) => source_pratica_id), option.getOrElseW(constUndefined))), either.alt(() => pipe(destinationRelationships, t.array(RecordLinkLegacyC).decode, either.map(recordLink => pipe(recordLink, array.filter(({ label }) => label === 'clones'), array.last, option.map(({ sourceRecord }) => sourceRecord.id), option.getOrElseW(constUndefined))))))), option.getOrElseW(constUndefined));
export const $recordLink = { parentRecordId };
