import { either } from 'fp-ts';
import { all, call, cancel, put, take, takeEvery, takeLeading } from 'typed-redux-saga';
import { PaginationDto } from '../../common/dto/PaginationDto';
import { Session } from '../../session/state/Session';
import { NotificationAction } from '../../store/notification/NotificationAction';
import { Notification } from '../../utils/notification';
import { $ProcessingQuotes, $ProcessingQuotesSlice } from './ProcessingQuotesState';
function* getLead(quoteService, pagination, filter) {
    yield* put($ProcessingQuotes.LeadsRequested());
    const token = yield* call(Session.getToken);
    const leadsEither = yield* call(quoteService.getLeads({ pagination: pagination, filter: filter, token: token }));
    if (either.isRight(leadsEither)) {
        yield* put($ProcessingQuotes.LeadsFetched({
            leads: leadsEither.right,
            paginationModel: pagination,
            filter: filter,
        }));
    }
    else {
        yield* put($ProcessingQuotes.LeadsNotFetched());
    }
}
function* fetchNextPage(quoteService, pagination, filter) {
    pagination.nextPage();
    yield* call(getLead, quoteService, pagination, filter);
}
function* fetchPreviousPage(quoteService, pagination, filter) {
    if (pagination.canGoToPreviousPage()) {
        pagination.previousPage();
        yield* call(getLead, quoteService, pagination, filter);
    }
}
function* fetchFirstPage(quoteService, pagination, filter) {
    pagination.firstPage();
    yield* call(getLead, quoteService, pagination, filter);
}
function* getQuoteRequestsByLeadId(quoteService, leadId, quoteRequestByLeadTable) {
    if (quoteRequestByLeadTable[leadId] !== undefined) {
        yield* put($ProcessingQuotes.QuoteRequestByLeadNotFetched({ leadId: leadId }));
    }
    else {
        yield* put($ProcessingQuotes.QuoteRequestByLeadRequested({ leadId: leadId }));
        const token = yield* call(Session.getToken);
        const quoteRequestEither = yield* call(quoteService.getQuoteRequestsByLeadId({ leadId: leadId, token: token }));
        if (either.isRight(quoteRequestEither)) {
            yield* put($ProcessingQuotes.QuoteRequestByLeadFetched({
                leadId: leadId,
                quoteRequest: quoteRequestEither.right,
            }));
        }
        else {
            yield* put($ProcessingQuotes.QuoteRequestByLeadNotFetched({ leadId: leadId }));
        }
    }
}
function* toggleLeadSection(leadId) {
    yield* put($ProcessingQuotes.LeadSectionToggled({ leadId: leadId }));
}
function* deleteRequest(quoteService, requestId, leadId) {
    yield* put($ProcessingQuotes.RequestDeletionRequested());
    const token = yield* call(Session.getToken);
    const deleteRequestEither = yield* call(quoteService.deleteRequest({ requestId: requestId, token: token }));
    if (either.isLeft(deleteRequestEither)) {
        return yield* put($ProcessingQuotes.RequestNotDeleted());
    }
    const quoteRequestEither = yield* call(quoteService.getQuoteRequestsByLeadId({ leadId: leadId, token: token }));
    if (either.isRight(quoteRequestEither)) {
        yield* put(NotificationAction.Push(Notification.info('Richiesta eliminata con successo')));
        yield* put($ProcessingQuotes.RequestDeleted({ quoteRequest: quoteRequestEither.right, leadId: leadId }));
    }
}
function* deleteLead(quoteService, leadId, pagination, filter) {
    yield* put($ProcessingQuotes.LeadDeletionRequested());
    const token = Session.getToken();
    const deleteLeadEither = yield* call(quoteService.deleteLead({ leadId: leadId, token: token }));
    if (either.isLeft(deleteLeadEither)) {
        return yield* put($ProcessingQuotes.LeadNotDeleted());
    }
    const leadsEither = yield* call(quoteService.getLeads({ pagination: pagination, filter: filter, token: token }));
    if (either.isRight(leadsEither)) {
        yield* put(NotificationAction.Push(Notification.info('Cliente eliminato con successo')));
        yield* put($ProcessingQuotes.LeadDeleted({ leads: leadsEither.right }));
    }
}
export function* ProcessingQuotesSaga(quoteService) {
    yield* takeEvery($ProcessingQuotes.start, function* () {
        const pagination = new PaginationDto($ProcessingQuotesSlice.PAGE_LIMIT);
        yield* put($ProcessingQuotes.Started());
        yield* call(getLead, quoteService, pagination);
        const task = yield* all([
            yield* takeLeading($ProcessingQuotes.deleteLead, command => {
                return deleteLead(quoteService, command.payload.leadId, pagination, command.payload.filter);
            }),
            yield* takeLeading($ProcessingQuotes.deleteRequest, command => {
                return deleteRequest(quoteService, command.payload.requestId, command.payload.leadId);
            }),
            yield* takeLeading($ProcessingQuotes.toggleLeadSection, command => {
                return toggleLeadSection(command.payload.leadId);
            }),
            yield* takeEvery($ProcessingQuotes.getQuoteRequestByLead, command => {
                return getQuoteRequestsByLeadId(quoteService, command.payload.leadId, command.payload.quoteRequestByLeadTable);
            }),
            yield* takeLeading($ProcessingQuotes.getLeads, command => {
                pagination.firstPage();
                return getLead(quoteService, pagination, command.payload.filter);
            }),
            yield* takeLeading($ProcessingQuotes.fetchFirstPage, command => fetchFirstPage(quoteService, pagination, command.payload.filter)),
            yield* takeLeading($ProcessingQuotes.fetchNextPage, command => fetchNextPage(quoteService, pagination, command.payload.filter)),
            yield* takeLeading($ProcessingQuotes.fetchPreviousPage, command => fetchPreviousPage(quoteService, pagination, command.payload.filter)),
        ]);
        yield* take($ProcessingQuotes.stop);
        yield* cancel(task);
        yield* put($ProcessingQuotes.Stopped());
    });
}
