import { all, call, cancel, put, take, takeLatest, takeLeading } from 'typed-redux-saga';
import { $Commissions } from '../commissions/CommissionsState';
import { RecordAction } from '../record/RecordAction';
import { $RecordDataTab } from './RecordDataTabState';
function* endSagas() {
    yield* put($Commissions.stop());
}
function* startSagas(startDataTabAction) {
    yield* put($Commissions.start({ recordId: startDataTabAction.payload.recordId }));
}
export function* RecordDataTabSaga() {
    yield* takeLeading($RecordDataTab.start, function* (startDataTabAction) {
        yield* put($RecordDataTab.Started());
        yield* call(startSagas, startDataTabAction);
        const task = yield* all([
            yield* takeLatest(RecordAction.Update.type.done, function* () {
                yield* put($Commissions.fetchCommissionTable());
            }),
        ]);
        yield* take($RecordDataTab.stop);
        yield* cancel(task);
        yield* call(endSagas);
        yield* put($RecordDataTab.Stopped());
    });
}
