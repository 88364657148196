import { createSlice } from '@reduxjs/toolkit';
import { constant, identity, pipe } from 'fp-ts/function';
const initialState = {
    loading: {},
};
export const invoiceDeletionSlice = createSlice({
    name: 'InvoiceDeleteSaga',
    initialState,
    reducers: {
        startInvoiceDeletionCommand: identity,
        endInvoiceDeletionCommand: constant(initialState),
        deleteInvoice: (state, _) => state,
        InvoiceDeleteRequested: (state, action) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { [action.payload]: true }) })),
        InvoiceDeleted: (state, action) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { [action.payload]: false }) })),
        InvoiceNotDeleted: (state, action) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { [action.payload]: false }) })),
    },
});
const invoiceIdDeleting = (state) => pipe(state.invoiceDeletion.loading);
export const $InvoiceDeletion = invoiceDeletionSlice.actions;
export const $invoiceDeletionSlice = {
    selector: { invoiceIdDeleting },
};
export default invoiceDeletionSlice.reducer;
