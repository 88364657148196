import { documentationModelMapper } from './documentationModelMapper';
import { productTypologyModelMapper } from './productTypologyModelMapper';
export const paperworkModelMapper = (paperwork) => ({
    data: paperwork.data,
    details: {
        id: paperwork.id,
        productTypology: productTypologyModelMapper(paperwork.productType),
        assignedTo: paperwork.assignedTo,
        createdAt: paperwork.createdAt,
        createdBy: paperwork.createdBy,
        artooId: paperwork.artooId,
        transitionsHistory: paperwork.transitionsHistory.map(transition => ({
            status: transition.status,
            triggeredAt: transition.triggeredAt,
            triggeredBy: transition.triggeredBy,
        })),
        nextActions: paperwork.nextActions.map(nextAction => ({
            value: nextAction.code,
            label: nextAction.label,
        })),
        currentStatus: paperwork.currentStatus,
        documentTypes: paperwork.documentTypes,
        documentation: paperwork.documents.map(documentationModelMapper),
    },
});
