import { createSlice } from '@reduxjs/toolkit';
import { constant, identity } from 'fp-ts/function';
const initialState = {
    loading: false,
};
export const invoiceFromRecordTabSlice = createSlice({
    name: 'InvoiceFromRecordTabSaga',
    initialState,
    reducers: {
        startInvoiceFromRecord: identity,
        endInvoiceFromRecord: constant(initialState),
    },
});
export const $InvoiceFromRecordTab = invoiceFromRecordTabSlice.actions;
export const $invoiceFromRecordTabSlice = {
    selector: {},
};
export default invoiceFromRecordTabSlice.reducer;
