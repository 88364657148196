import { format } from 'date-fns';
import { $string } from 'fortepiano';
import { pipe } from 'fp-ts/function';
import { currencyView } from '../view/CurrencyView';
import { rateView } from './RateView';
const mediationAmount = (netIncomeInstallmentRatioModel) => ({
    outcome: netIncomeInstallmentRatioModel.outcome,
    value: currencyView(netIncomeInstallmentRatioModel.value),
});
const installmentSustainabilitySection = (quoteModel) => {
    const netIncomeInstallment = quoteModel.feasibilityConditionsAndOutcomes.netIncomeInstallmentRatio;
    const ltv = quoteModel.feasibilityConditionsAndOutcomes.ltvRatio;
    const netIncomeInstallmentLabel = 'Rapporto rata/reddito';
    const ltvLabel = 'LTV';
    return pipe([
        netIncomeInstallment === null
            ? { label: netIncomeInstallmentLabel, value: null, isApproved: null, tooltipText: undefined }
            : {
                label: netIncomeInstallmentLabel,
                value: currencyView(netIncomeInstallment.value),
                isApproved: netIncomeInstallment.outcome,
                tooltipText: `Massimo ${netIncomeInstallment.threshold} %`,
            },
        ltv === null
            ? { label: ltvLabel, value: null, isApproved: null, tooltipText: undefined }
            : {
                label: ltvLabel,
                value: `${ltv.value} %`,
                isApproved: ltv.outcome,
                tooltipText: `Massimo ${ltv.threshold} %`,
            },
    ]);
};
const oneTimeCostsSection = (quoteModel) => {
    return [
        {
            label: 'Istruttoria',
            value: currencyView(quoteModel.investigation),
            isApproved: null,
        },
        {
            label: 'Perizia',
            value: currencyView(quoteModel.appraisal),
            isApproved: null,
        },
        {
            label: 'Mediazione',
            value: currencyView(quoteModel.feasibilityConditionsAndOutcomes.mediationAmount.value),
            isApproved: quoteModel.feasibilityConditionsAndOutcomes.mediationAmount.outcome,
        },
    ];
};
const paymentSection = (quoteModel) => {
    return [
        {
            label: 'Tasso',
            value: $string.capitalize(rateView(quoteModel.rate)),
            isApproved: null,
        },
        {
            label: 'TAN',
            value: `${quoteModel.tan} %`,
            isApproved: null,
        },
        {
            label: 'Spread',
            value: `${quoteModel.spread} %`,
            isApproved: null,
        },
        {
            label: 'TAEG',
            value: `${quoteModel.taeg} %`,
            isApproved: null,
        },
    ];
};
export const quoteView = (quoteModel) => {
    return Object.assign(Object.assign({}, quoteModel), { instituteName: quoteModel.institute.name, productName: quoteModel.product.name, rate: rateView(quoteModel.rate), appraisal: currencyView(quoteModel.appraisal), tan: `${quoteModel.tan} %`, spread: `${quoteModel.spread} %`, installment: currencyView(quoteModel.installment), taeg: `${quoteModel.taeg} %`, investigation: currencyView(quoteModel.investigation), alternativeTax: currencyView(quoteModel.alternativeTax), attributes: quoteModel.attributes, submittedAt: format(new Date(quoteModel.submittedAt), 'dd/MM/yyyy - HH:mm'), sections: {
            payment: paymentSection(quoteModel),
            oneTimeCosts: oneTimeCostsSection(quoteModel),
            installmentSustainability: installmentSustainabilitySection(quoteModel),
        }, feasibilityConditionsAndOutcomes: {
            age: `${quoteModel.feasibilityConditionsAndOutcomes.age}`,
            duration: `${quoteModel.feasibilityConditionsAndOutcomes.duration}`,
            mediationAmount: mediationAmount(quoteModel.feasibilityConditionsAndOutcomes.mediationAmount),
        } });
};
