import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import qs from 'qs';
import { BASE_URL_V1 } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
import { UserC } from '../../model/User';
export const getUserHierachy = (userId, queryParams) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get(`${BASE_URL_V1}/user/${userId}/hierarchy?${qs.stringify(queryParams)}`), e => e), taskEither.chainEitherKW(response => t.type({ items: t.readonlyArray(UserC) }).decode(response.data)), taskEither.map(({ items }) => items));
