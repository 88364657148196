import { combineReducers } from '@reduxjs/toolkit';
import { isNone } from 'fp-ts/Option';
import { Process } from '../../../lib/redux/Process';
import { ProcessState } from '../../processes/ProcessState';
import { RecordAction } from '../RecordAction';
import { DocumentAction } from './DocumentAction';
import { DocumentLoopsState } from './DocumentLoopsState';
const reducer = combineReducers({
    loops: DocumentLoopsState.reducer,
});
const fileWithError = (categoryCode) => (state) => {
    return ProcessState.selector
        .processes(DocumentAction.Upload, DocumentAction.Delete)(state)
        .filter(p => {
        if (p.name === DocumentAction.Delete.name && Process.is.failed(p)) {
            return p.input.relative.indexOf(categoryCode) !== -1;
        }
        else if (p.name === DocumentAction.Upload.name && Process.is.failed(p)) {
            return p.input.file.relative.indexOf(categoryCode) !== -1;
        }
        return false;
    });
};
const collection = (categoryCode) => (state) => {
    // report antiriciclaggio
    var _a;
    if (isNone(state.record.item)) {
        return [];
    }
    const record = state.record.item.value;
    const documentFiles = record.documents
        .filter(document => document.category.code === categoryCode)
        .filter(document => document.status.code !== 'partial' &&
        document.status.code !== 'invalid' &&
        ((document.status.code !== 'generated' && document.status.code !== 'generated_done') ||
            /bergamo.documenti_da_allegare$/.test(document.category.code)));
    const recordProcess = ProcessState.selector.processes(RecordAction.Detail)(state);
    if (recordProcess.length === 0) {
        return documentFiles;
    }
    const tsRecord = (_a = recordProcess[0]) === null || _a === void 0 ? void 0 : _a.timestamps.done;
    const recentUploadingProcesses = ProcessState.selector
        .processes(DocumentAction.Upload)(state)
        .filter(p => p.input.recordId === record.id.toString() && p.input.file.categoryCode === categoryCode)
        .filter(p => Process.is.running(p) || Process.is.failed(p));
    const recentDeletingProcesses = ProcessState.selector
        .processes(DocumentAction.Delete)(state)
        .filter(p => p.input.recordId === record.id.toString() && p.input.file.category.code === categoryCode)
        .filter(p => tsRecord && (p.timestamp > tsRecord || Process.is.failed(p)));
    // Creo un oggetto per poter più comodamente trovare i processi di eliminazione.
    const deleteProcessIndex = recentDeletingProcesses.map(p => {
        return {
            id: p.input.file.id,
            process: p,
        };
    });
    // Costruisco l'elenco _quasi_ finale dei file, mettendo quelli locali in coda a quelli remoti.
    const allFiles = [...documentFiles, ...recentUploadingProcesses];
    // Sostituisco gli elementi che corrispondono a un processo di eliminazione con il processo stesso.
    const allFinalFiles = allFiles.map(f => {
        if (isRawDocumentFile(f)) {
            const d = deleteProcessIndex.find(({ id }) => id === f.id);
            if (d !== undefined) {
                return d.process;
            }
            return f;
        }
        return f;
    });
    return allFinalFiles;
};
const isRawDocumentFile = (p) => {
    return p.id !== undefined;
};
export const DocumentState = {
    reducer,
    selector: {
        fileWithError: (categoryCode) => (state) => fileWithError(categoryCode)(state),
        collection: (categoryCode) => (state) => collection(categoryCode)(state),
    },
};
