import { $string } from 'fortepiano';
import { option } from 'fp-ts';
import { not } from 'fp-ts/Predicate';
import { constant, pipe } from 'fp-ts/function';
const formatEuroDecimalCommaSeparator = (amount) => new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
}).format(amount);
const euro = (amount) => pipe(amount, Number, option.of, option.filter(not(isNaN)), option.map(formatEuroDecimalCommaSeparator));
const toEuros = (amount, prefix = '') => pipe(amount, $currency.euro, option.match(constant('-'), $string.prefix(prefix)));
export const $currency = { euro, toEuros };
