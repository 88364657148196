import { makeStyles } from 'tss-react/mui';
import { flagTheme } from '../../../theme/flag';
export const useStyles = makeStyles()(theme => ({
    cardContent: {
        padding: `5px 10px ${theme.spacing(2)} 10px`,
        '&:last-child': {
            paddingBottom: theme.spacing(2),
        },
    },
    mr15px: {
        marginRight: 15,
    },
    mb10px: {
        marginBottom: 10,
    },
    alignItemsCenter: {
        alignItems: 'center',
    },
    grey: {
        color: theme.palette.grey.A400,
    },
    card: {
        borderRadius: 0,
    },
    borderColorGrey: {
        borderColor: theme.palette.grey.A200,
    },
    ptb5px: { paddingTop: 5, paddingBottom: 5 },
    plr10px: { paddingLeft: 10, paddingRight: 10 },
    statusBox: {
        display: 'inline-block',
        borderRadius: 4,
    },
    ml2: {
        marginLeft: theme.spacing(2),
    },
    unknown: {
        backgroundColor: flagTheme.main.unknown,
    },
    success: {
        backgroundColor: flagTheme.main.success,
    },
    error: {
        backgroundColor: flagTheme.main.error,
    },
    warning: {
        backgroundColor: flagTheme.main.warning,
    },
}));
