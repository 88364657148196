import { Timeline, TimelineDot } from '@mui/lab';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineItem from '@mui/lab/TimelineItem';
import { makeStyles, withStyles } from 'tss-react/mui';
import { theme } from '../../theme/theme';
export const useStyles = makeStyles()(theme0 => ({
    mt5: {
        marginTop: theme0.spacing(5),
    },
    backgroundWhite: { backgroundColor: theme0.palette.common.white },
    overflowAuto: {
        overflow: 'auto',
    },
    height100: {
        height: '100%',
    },
    separator: {
        margin: 0,
        borderTop: `1px solid ${theme0.palette.primary.light}`,
        marginLeft: 10,
        marginRight: 10,
    },
    title: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15)',
        textTransform: 'uppercase',
        position: 'sticky',
        top: 0,
        height: 80,
        display: 'flex',
        alignItems: 'center',
        zIndex: 1,
        backgroundColor: theme0.palette.common.white,
        paddingLeft: 24,
        "&:not([data-scroll='true'])": {
            boxShadow: 'none',
        },
    },
}));
const marginLeftTriangle = 1;
const triangleSize = 4;
const StyledTimelineDot = withStyles(TimelineDot, theme1 => ({
    root: {
        border: 0,
        boxShadow: 'none',
        backgroundColor: 'inherit',
        padding: 0,
        marginTop: 0,
        marginBottom: 0,
        '&:after': {
            content: '""',
            position: 'relative',
            width: 0,
            height: 0,
            marginLeft: marginLeftTriangle,
            borderTop: `${triangleSize}px solid transparent`,
            borderBottom: `${triangleSize}px solid transparent`,
            borderRight: `${triangleSize}px solid ${theme1.palette.grey.A200}`,
            top: '50%',
            transform: 'translateY(-50%)',
        },
    },
}));
const StyledTimelineConnector = withStyles(TimelineConnector, theme2 => ({
    root: {
        width: 1,
        backgroundColor: theme2.palette.primary.light,
        marginRight: marginLeftTriangle + triangleSize,
    },
}));
const StyledTimelineItem = withStyles(TimelineItem, {
    root: {
        '&:before': {
            padding: 0,
            flex: 'inherit',
        },
    },
});
const StyledTimeline = withStyles(Timeline, theme3 => ({
    root: {
        padding: '20px 10px',
        marginBottom: 40 + theme3.spacing(5),
        // ultima card
        '&>.MuiTimelineItem-root': {
            '&:last-child': {
                '&>.MuiTimelineContent-root': {
                    marginBottom: 0,
                },
            },
        },
    },
}));
const StyledTimelineContent = withStyles(TimelineContent, {
    root: {
        padding: 0,
        marginBottom: theme.spacing(3),
    },
});
export const Styled = {
    TimelineDot: StyledTimelineDot,
    TimelineConnector: StyledTimelineConnector,
    TimelineItem: StyledTimelineItem,
    Timeline: StyledTimeline,
    TimelineContent: StyledTimelineContent,
};
