import { $type } from 'fortepiano';
const purposeList = [
    'Acquisto Prima Casa',
    'Acquisto Seconda Casa',
    'Acquisto + Ristrutturazione',
    'Acquisto + Sostituzione',
    'Acquisto Box',
    'Acquisto in Asta',
    'Acquisto Bioedilizia',
    'Acquisto ad uso ufficio',
    'Acquisto per Residenti Estero',
    'Acquisto + Liquidità',
    'Immobile commerciale',
    'Completamento Costruzione',
    'Consolidamento',
    'Liquidità',
    'Ristrutturazione',
    'Sostituzione',
    'Sostituzione + Consolidamento',
    'Sostituzione + Liquidità',
    'Sostituzione + Ristrutturazione',
    'Surroga',
    'Surroga + liquidità',
    'Rifinanziamento',
    'Finanziamento chirografario Bonus Casa',
];
const isSubrogation = (purpose) => purpose === 'Surroga';
export const PurposeCodec = $type.literalUnion(purposeList, 'Purpose');
export const PurposeUtils = { purposeList, isSubrogation };
