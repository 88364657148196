import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { Grid, Typography } from '@mui/material';
import { boolean, record } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import styled from 'styled-components';
const Circle = styled('div') `
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72px;
    height: 72px;
    border-radius: 50%;
    border: 2px solid #e2e3de;
`;
export const LeadProfile = ({ email, firstName, lastName, phoneNumber }) => {
    return (_jsxs(Grid, { container: true, wrap: "nowrap", style: { marginBottom: 48 }, children: [_jsx(Grid, { item: true, children: _jsx(Circle, { children: _jsx(PersonOutlineOutlinedIcon, {}) }) }), _jsx(Grid, { container: true, item: true, alignItems: "center", style: { paddingLeft: 28, paddingTop: 2, paddingBottom: 2 }, children: pipe({ email, firstName, lastName, phoneNumber }, record.every(value => value === ''), boolean.match(() => (_jsxs(Grid, { container: true, children: [_jsx(Grid, { item: true, xs: 12, children: _jsxs(Typography, { variant: "h1", children: [firstName, " ", lastName] }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { children: phoneNumber }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { children: email }) })] })), () => (_jsx(Grid, { container: true, item: true, justifyContent: "center", children: _jsx(Typography, { variant: "h1", children: "Nuova anagrafica" }) })))) })] }));
};
