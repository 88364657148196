import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
const BazaarLocationCodec = t.type({
    name: t.string,
    postalCodes: t.array(t.string),
    province: t.type({
        shortName: t.string,
    }),
});
export const getLocation = (locationSearch) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get(`${BAZAAR_URL}/api/v1/localities`, {
    search: locationSearch,
    'filter[type]': 'city',
}), e => e), taskEither.chainEitherKW(response => t.array(BazaarLocationCodec).decode(response.data)));
