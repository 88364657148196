import { either, option } from 'fp-ts';
import { constant, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { IdentifierCodec } from '../Identifier.codec';
const IdentifierFromNullableCodec = new t.Type('IdentifierFromNullable', t.union([IdentifierCodec, t.undefined, t.null]).is, (u, c) => {
    if (either.isRight(t.union([t.undefined, t.null]).validate(u, c))) {
        return t.success(null);
    }
    if (IdentifierCodec.is(u)) {
        return t.success(u);
    }
    return t.failure(u, c);
}, t.identity);
export const InvoiceItemCodec = t.intersection([
    t.type({
        description: t.string,
        amount: t.number,
        recordId: IdentifierFromNullableCodec,
    }),
    t.partial({ customerFullName: t.string }),
], 'InvoiceItem');
const customerFullName = (invoiceItem) => pipe(invoiceItem.customerFullName, option.fromNullable, option.getOrElse(constant('')));
const hasRecordId = (invoice) => pipe(invoice.recordId, option.fromNullable, option.isSome);
export const InvoiceItemUtils = {
    hasRecordId,
    customerFullName,
};
