import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import WarningIcon from '@mui/icons-material/WarningRounded';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { option, readonlyArray } from 'fp-ts';
import { constant, pipe } from 'fp-ts/function';
import { useState } from 'react';
import { InvoiceFromRecordDialog } from './InvoiceFromRecordDialog';
const onClick = (recordRow, setDialogProps) => () => setDialogProps(option.some({
    recordId: recordRow.recordId,
    userFullName: recordRow.fullName,
    payins: recordRow.payins,
    isTransmitted: recordRow.isTransmitted,
}));
const onClose = (setDialogProps) => () => setDialogProps(option.none);
export const RecordList = ({ items, emptyListText = '' }) => {
    const [dialogProps, setDialogProps] = useState(option.none);
    return (_jsxs(_Fragment, { children: [option.isSome(dialogProps) && (_jsx(InvoiceFromRecordDialog, Object.assign({}, dialogProps.value, { onClose: onClose(setDialogProps) }))), pipe(items, readonlyArray.match(constant([_jsx(Typography, { children: emptyListText }, 0)]), readonlyArray.mapWithIndex((key, item) => (_jsxs(Card, { elevation: 0, square: true, children: [_jsx(CardContent, { children: _jsxs(Grid, { container: true, children: [_jsx(Grid, { item: true, children: _jsxs(Box, { style: { marginBottom: 16 }, children: [_jsx(Typography, { display: "inline", style: { color: '#3377F6', marginRight: 8 }, children: item.fullName }), _jsxs(Typography, { variant: "body2", display: "inline", children: ["ID: ", _jsx("strong", { children: item.recordId })] })] }) }), _jsxs(Grid, { container: true, justifyContent: "space-between", wrap: "nowrap", children: [_jsx(Grid, { container: true, item: true, children: _jsxs(Typography, { children: [_jsx("strong", { children: "Istituto:" }), " ", item.institute] }) }), _jsx(Grid, { container: true, item: true, children: _jsxs(Typography, { children: [_jsx("strong", { children: "Prodotto:" }), " ", item.productType] }) }), _jsx(Grid, { container: true, item: true, children: _jsxs(Typography, { children: [_jsx("strong", { children: "Ricavo FMP:" }), " ", item.totalCommissionAmount] }) }), _jsx(Grid, { container: true, item: true, children: _jsxs(Typography, { children: [_jsx("strong", { children: "Creata da:" }), " ", item.author] }) }), item.hasError ? (_jsx(Grid, { container: true, item: true, justifyContent: "flex-end", children: _jsx(WarningIcon, {}) })) : (_jsx(Grid, { container: true, item: true, justifyContent: "flex-end", onClick: onClick(item, setDialogProps), children: _jsx(KeyboardArrowRightIcon, { style: { color: '#3377F6', cursor: 'pointer' } }) }))] })] }) }), _jsx(Divider, {})] }, key)))))] }));
};
