import { either, taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { decode } from 'json-api-codec';
import qs from 'qs';
import { BASE_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { PooledClient, HttpClientPool } from '../../lib/http/PooledClient';
import { ArtooRecordCodec } from '../models/ArtooRecord/ArtooRecord';
export const getRecordList = (queryParams = {}) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get(`${BASE_URL}/api/v2/records?${qs.stringify(Object.assign({}, queryParams))}`), e => e), taskEither.chainEitherKW(response => pipe(response.data, t.type({ data: t.readonlyArray(t.unknown) }).decode, either.map(decode), either.chain(t.readonlyArray(ArtooRecordCodec).decode))));
export function getInvoicingRecordList(queryParams = {}) {
    return getRecordList(Object.assign(Object.assign({}, queryParams), { page: { size: 100 }, states: ['fatturazione'] }));
}
