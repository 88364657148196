import { jsx as _jsx } from "react/jsx-runtime";
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { makeStyles } from 'tss-react/mui';
import { toggle } from '../../../common/state/toggle';
export const useStyles = makeStyles()(() => ({
    icon: {
        cursor: 'pointer',
        marginRight: '8px',
    },
}));
export const Visibility = ({ isVisible, stateSetter }) => {
    const { classes } = useStyles();
    return isVisible ? (_jsx(VisibilityOutlinedIcon, { onClick: toggle(stateSetter), className: classes.icon })) : (_jsx(VisibilityOffOutlinedIcon, { onClick: toggle(stateSetter), className: classes.icon }));
};
