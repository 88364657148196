import { either, number, option, ord, readonlyArray, readonlyNonEmptyArray, string } from 'fp-ts';
import { not } from 'fp-ts/Predicate';
import { pipe } from 'fp-ts/function';
import { all, call, cancel, put, select, take, takeLeading } from 'typed-redux-saga';
import { isUserAuthorized } from '../../../authorization/useAuthorization';
import { NotificationAction } from '../../../store/notification/NotificationAction';
import { PERMISSION, SecurityState } from '../../../store/security/SecurityState';
import { Notification } from '../../../utils/notification';
import { ActiveInvoiceTransmisionInputCodec } from '../../models/ActiveInvoiceTransmissionInput/ActiveInvoiceTransmissionInput';
import { InvoiceStatusCodeUtils } from '../../models/InvoiceStatusCode/InvoiceStatusCode';
import { getActiveInvoiceList } from '../../web/activeInvoice/getActiveInvoiceList';
import { transmitActiveInvoice } from '../../web/activeInvoice/transmitActiveInvoice';
import { $ActiveInvoiceCreation } from './ActiveInvoiceCreationState';
function* tryTransmitInvoice(recordId, customersRegistry, activeInvoiceCreationForm, onSuccessAction) {
    const amount = pipe(activeInvoiceCreationForm.invoiceAmount, option.of, option.map(Number), option.filter(n => not(isNaN)(n) && ord.gt(number.Ord)(n, 0)) // n === 0 when value === ''
    );
    if (option.isSome(amount)) {
        const transmitActiveInvoicePayloadOption = pipe(customersRegistry, readonlyArray.findFirst(customer => string.Eq.equals(customer.fullNameWithTier, activeInvoiceCreationForm.customerFullNameWithTier)), option.map(customer => ({
            issueDate: activeInvoiceCreationForm.issueDate,
            customer: {
                firstName: customer.firstName,
                lastName: customer.lastName,
                taxCode: customer.taxCode,
                zipCode: customer.zipCode,
                city: customer.city,
                address: customer.address,
                province: customer.province,
            },
            items: pipe(readonlyNonEmptyArray.of({
                agentId: customer.agentId.toString(),
                agentFullName: customer.agentFullName,
                amount: amount.value,
                recordId: recordId.toString(),
                typology: activeInvoiceCreationForm.invoiceTypology,
                description: activeInvoiceCreationForm.invoiceDescription,
            })),
        })), option.chainEitherK(ActiveInvoiceTransmisionInputCodec.decode));
        if (option.isSome(transmitActiveInvoicePayloadOption)) {
            yield* put($ActiveInvoiceCreation.InvoiceTransmissionRequested());
            const invoiceTransmissionEither = yield* call(transmitActiveInvoice(transmitActiveInvoicePayloadOption.value));
            if (either.isRight(invoiceTransmissionEither)) {
                yield* put($ActiveInvoiceCreation.InvoiceTransmitted());
                yield* put(NotificationAction.Push(Notification.info('Fattura emessa correttamente')));
                if (onSuccessAction) {
                    yield* call(onSuccessAction);
                }
            }
            else {
                yield* put($ActiveInvoiceCreation.InvoiceNotTransmitted());
                yield* put(NotificationAction.Push(Notification.error("Si è verificato un errore durante l'emissione della fattura")));
            }
        }
        else {
            yield* put($ActiveInvoiceCreation.InvoiceNotTransmitted());
        }
    }
    else {
        yield* put($ActiveInvoiceCreation.InvoiceNotTransmitted());
    }
}
function* fetchLatestIssuedInvoice() {
    yield* put($ActiveInvoiceCreation.ActiveInvoiceListRequested());
    const activeInvoiceListEither = yield* call(getActiveInvoiceList({
        statusCode: InvoiceStatusCodeUtils.TRANSMITTED,
        page: { limit: '1' },
        sort: '-issueDate',
    }));
    if (either.isRight(activeInvoiceListEither)) {
        yield* put($ActiveInvoiceCreation.ActiveInvoiceListFetched(activeInvoiceListEither.right));
    }
    else {
        yield* put($ActiveInvoiceCreation.ActiveInvoiceListNotFetched());
    }
}
export function* ActiveInvoiceCreationSaga() {
    yield* takeLeading($ActiveInvoiceCreation.start, function* (startCommand) {
        const userPermissionList = yield* select(SecurityState.selector.permissionList);
        if (isUserAuthorized(userPermissionList)(PERMISSION.CREATE_ACTIVE_INVOICE)) {
            yield* put($ActiveInvoiceCreation.Started({
                artooRecordData: startCommand.payload.artooRecordData,
                artooRecordAgents: startCommand.payload.artooRecordAgents,
            }));
            const task = yield* all([
                yield* takeLeading($ActiveInvoiceCreation.transmitInvoice, function* (transmitInvoiceCommand) {
                    yield* call(tryTransmitInvoice, startCommand.payload.recordId, transmitInvoiceCommand.payload.customersRegistry, transmitInvoiceCommand.payload.activeInvoiceCreationForm, transmitInvoiceCommand.payload.onSuccess);
                }),
            ]);
            yield* call(fetchLatestIssuedInvoice);
            yield* take($ActiveInvoiceCreation.stop);
            yield* cancel(task);
            yield* put($ActiveInvoiceCreation.Stopped());
        }
    });
}
