import { createTheme } from '@mui/material';
import { palette } from './palette';
const spacing = 4;
const h1 = {
    fontSize: '1.4rem',
    lineHeight: 1.5,
    fontWeight: 500,
};
const h2 = {
    fontSize: '1.27rem',
    fontWeight: 500,
};
const h3 = {
    fontSize: '1.15rem',
    fontWeight: 500,
};
const h4 = {
    fontSize: '1.08rem',
    fontWeight: 500,
};
const h5 = {
    fontSize: '1.02rem',
    fontWeight: 500,
};
const h6 = {
    fontSize: '0.89rem',
    fontWeight: 500,
};
const caption = {
    fontSize: '0.77rem',
    fontWeight: 500,
};
const body1 = {
    fontSize: '0.85rem',
};
const button = {
    fontSize: '0.77rem',
    fontWeight: 500,
    letterSpacing: '0.10em',
};
export const theme = createTheme({
    spacing,
    palette,
    typography: {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        body1,
        caption,
        subtitle1: {
            fontSize: '0.7rem',
            fontWeight: 500,
            letterSpacing: '0.15em',
            textTransform: 'uppercase',
        },
        subtitle2: {
            fontSize: '0.565rem',
            fontWeight: 500,
            letterSpacing: '0.19em',
            textTransform: 'uppercase',
        },
        button,
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    fontSize: '0.875rem',
                    lineHeight: 1.43,
                    letterSpacing: '0.01071em',
                },
            },
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    cursor: 'pointer',
                    color: palette.primary.main,
                    '&:hover': {
                        color: palette.primary.dark,
                    },
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
                extended: {
                    '&.MuiFab-sizeSmall': {
                        paddingTop: spacing * 3,
                        paddingBottom: spacing * 3,
                        paddingLeft: spacing * 4,
                        paddingRight: spacing * 4,
                    },
                    '&.MuiFab-sizeMedium': {
                        paddingTop: spacing * 7,
                        paddingBottom: spacing * 7,
                        paddingLeft: spacing * 4,
                        paddingRight: spacing * 4,
                        borderRadius: 28,
                    },
                },
                sizeMedium: {
                    paddingTop: spacing * 7,
                    paddingBottom: spacing * 7,
                    paddingLeft: spacing * 4,
                    paddingRight: spacing * 4,
                    borderRadius: 28,
                },
                sizeSmall: {
                    paddingTop: spacing * 3,
                    paddingBottom: spacing * 3,
                    paddingLeft: spacing * 4,
                    paddingRight: spacing * 4,
                },
            },
        },
        MuiCardHeader: {
            styleOverrides: {
                root: {
                    paddingTop: 12,
                    paddingBottom: 12,
                    '&:last-child': {
                        paddingBottom: 12,
                    },
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    padding: '12px 16px 12px 16px',
                    '&:last-child': {
                        paddingBottom: 12,
                    },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paperWidthSm: {
                    maxWidth: 750,
                },
                paperWidthMd: {
                    maxWidth: 824,
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    '&.MuiPaper-elevation24': {
                        boxShadow: '0 10px 30px 0 rgba(0, 0, 0, 0.1)',
                    },
                    '&.MuiPaper-elevation0': {
                        boxShadow: 'none',
                        border: `1px solid ${palette.grey.A100}`,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    paddingTop: 12,
                    paddingBottom: 12,
                    paddingRight: 16,
                    paddingLeft: 16,
                },
                text: {
                    paddingTop: 12,
                    paddingBottom: 12,
                    paddingRight: 16,
                    paddingLeft: 16,
                },
                outlined: {
                    paddingTop: 12,
                    paddingBottom: 12,
                    paddingRight: 16,
                    paddingLeft: 16,
                },
                sizeSmall: {
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 16,
                    paddingRight: 16,
                },
                sizeLarge: {
                    paddingTop: 20,
                    paddingBottom: 20,
                    paddingLeft: 16,
                    paddingRight: 16,
                },
                outlinedPrimary: {
                    border: 'none',
                    backgroundColor: palette.common.white,
                    '&:hover': {
                        border: 'none',
                        backgroundColor: palette.common.white,
                    },
                },
                containedPrimary: {
                    backgroundColor: palette.primary.light,
                    color: palette.primary.main,
                    '&:hover': {
                        backgroundColor: palette.primary.light,
                    },
                },
                containedSecondary: {
                    background: palette.secondary.main,
                    color: palette.primary.main,
                    '&:hover': {
                        backgroundColor: palette.secondary.main,
                    },
                },
                textPrimary: {
                    color: palette.primary.main,
                    backgroundColor: palette.common.white,
                    '&:hover': {
                        backgroundColor: palette.primary.light,
                    },
                },
            },
        },
        MuiList: {
            styleOverrides: {
                padding: { paddingTop: 0, paddingBottom: 0 },
            },
        },
        MuiInput: {
            styleOverrides: {
                input: Object.assign({}, h5),
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                input: Object.assign({}, h5),
            },
        },
        MuiSelect: {
            styleOverrides: {
                filled: Object.assign({}, h5),
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: Object.assign({}, h5),
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    marginBottom: 20,
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                textColorPrimary: {
                    color: palette.primary.main,
                    '&.Mui-selected': {
                        backgroundColor: palette.primary.light,
                    },
                },
            },
        },
    },
});
// declare module '@mui/system' {
//     interface DefaultTheme extends Theme {}
// }
