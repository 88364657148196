import { either, taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { readonlyNonEmptyArray } from 'io-ts-types';
import { decode } from 'json-api-codec';
import qs from 'qs';
import { BASE_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
const ArtooPaperworkCodec = t.type({
    id: t.number,
    data: t.type({ tipo_di_prodotto: t.string }),
    agenti: readonlyNonEmptyArray(t.type({ first_name: t.string, last_name: t.string })),
}, 'ArtooPaperwork');
export const getPaperworks = (queryParams = {}) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get(`${BASE_URL}/api/v2/records?${qs.stringify(Object.assign({}, queryParams))}`), e => e), taskEither.chainEitherKW(response => pipe(response.data, t.type({ data: t.readonlyArray(t.unknown) }).decode, either.map(decode), either.chain(t.readonlyArray(ArtooPaperworkCodec).decode))));
