import { $mock } from 'fortepiano';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { ExternalIdentifierCodec } from '../../models/ExternalIdentifier/ExternalIdentifier';
import { ExternalIdentifierMock } from '../../models/ExternalIdentifier/ExternalIdentifier.mock';
export const CommissionItemCodec = t.intersection([
    t.type({
        productType: t.string,
        profile: t.string,
        value: t.number,
    }),
    t.partial({
        userId: t.union([t.null, ExternalIdentifierCodec]),
        role: t.union([t.string, t.null]),
    }),
], 'CommissionItem');
export const CommissionItemMock = pipe($mock.struct({
    userId: $mock.union($mock.null, ExternalIdentifierMock()),
    role: $mock.union($mock.null, $mock.string),
    productType: $mock.string,
    profile: $mock.string,
    value: $mock.number(0),
}));
