import * as t from 'io-ts';
const KEY_API_HOST = 'API_HOST';
const KEY_API_BAZAAR = 'API_BAZAAR';
const checkUrl = (url) => t.string.is(url) && /^https?:\/\/.+\.k8s-facile.it$/.test(url.trim());
const params = new URLSearchParams(window.location.search);
const queryUrl = params.get(KEY_API_HOST);
const bazaarUrl = params.get(KEY_API_BAZAAR);
if (checkUrl(queryUrl)) {
    sessionStorage.setItem(KEY_API_HOST, queryUrl);
}
if (checkUrl(bazaarUrl)) {
    sessionStorage.setItem(KEY_API_BAZAAR, bazaarUrl);
}
const cachedBazaarUrl = sessionStorage.getItem(KEY_API_BAZAAR);
const cachedUrl = sessionStorage.getItem(KEY_API_HOST);
export const BASE_URL = checkUrl(cachedUrl) ? cachedUrl.trim() : process.env.REACT_APP_BASE_URL;
export const BAZAAR_URL = checkUrl(cachedBazaarUrl) ? cachedBazaarUrl.trim() : process.env.REACT_APP_BAZAAR_URL;
export const BASE_URL_V1 = `${BASE_URL}/api/v1`;
export const BASE_URL_V2 = `${BASE_URL}/api/v2`;
export const SECURITY_SESSION = `${BASE_URL_V1}/security/session`;
export const RECORDS = `${BASE_URL_V2}/records`;
export const RECORDS_V1 = `${BASE_URL_V1}/pratica`;
export const RECORDS_STATES = `${BASE_URL_V2}/records/statuses`;
export const RECORDS_USERS = `${BASE_URL_V2}/records/users`;
export const PIPELINES = `${BASE_URL_V2}/pipelines`;
export const DOCUMENT_HIERARCHY = PIPELINES;
export const DOCUMENT = `${BASE_URL_V1}/document`;
export const MERGE_V2 = `${BASE_URL_V2}/document/merge`;
