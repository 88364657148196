import * as t from 'io-ts';
export const documentation = t.type({
    id: t.string,
    name: t.string,
    toBeFilled: t.boolean,
    toBeSigned: t.boolean,
    toBeUploaded: t.boolean,
    filled: t.boolean,
    signed: t.boolean,
    documentTypeId: t.string,
    required: t.boolean,
    uploaded: t.union([t.boolean, t.null]),
    file: t.type({ mimeType: t.string, name: t.string, size: t.number }),
    transitionsHistory: t.array(t.type({ status: t.type({ code: t.string, label: t.string }), triggeredAt: t.string })),
}, 'Documentation');
