import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Grid from '@mui/material/Grid';
import ArtooText1 from '../../../../assets/artooText.png';
import ArtooText2 from '../../../../assets/artooText@2x.png';
import ArtooText3 from '../../../../assets/artooText@3x.png';
import LogoIcon1 from '../../../../assets/logoIcon.png';
import LogoIcon2 from '../../../../assets/logoIcon@2x.png';
import LogoIcon3 from '../../../../assets/logoIcon@3x.png';
import LogoTextIcon1 from '../../../../assets/logoWithTextNegative.png';
import LogoTextIcon2 from '../../../../assets/logoWithTextNegative@2x.png';
import LogoTextIcon3 from '../../../../assets/logoWithTextNegative@3x.png';
import SquareLogoIcon1 from '../../../../assets/squareLogoWithText.png';
import SquareLogoIcon2 from '../../../../assets/squareLogoWithText@2x.png';
import SquareLogoIcon3 from '../../../../assets/squareLogoWithText@3x.png';
export const Logo = () => {
    return (_jsx("div", { style: { display: 'flex', justifyContent: 'center' }, children: _jsx("img", { src: LogoTextIcon1, alt: "logo", srcSet: `${LogoTextIcon1} 1x, ${LogoTextIcon2} 2x, ${LogoTextIcon3} 3x`, style: { maxWidth: '150px', maxHeight: 30 } }) }));
};
export const LogoWithText = () => {
    return (_jsxs(Grid, { container: true, item: true, xs: 12, justifyContent: "center", children: [_jsx(Grid, { container: true, item: true, xs: 12, justifyContent: "center", children: _jsx("img", { style: { marginBottom: 24, width: 70, height: 70 }, src: LogoIcon1, alt: "Artoo logo", srcSet: `${LogoIcon1} 1x, ${LogoIcon2} 2x, ${LogoIcon3} 3x` }) }), _jsx(Grid, { container: true, item: true, xs: 12, justifyContent: "center", children: _jsx("img", { src: ArtooText1, alt: "Artoo text", srcSet: `${ArtooText1} 1x, ${ArtooText2} 2x, ${ArtooText3} 3x` }) })] }));
};
export const SquareLogoWithText = () => (_jsx("img", { src: SquareLogoIcon1, alt: "Artoo text", srcSet: `${SquareLogoIcon1} 1x, ${SquareLogoIcon2} 2x, ${SquareLogoIcon3} 3x` }));
