import { jsx as _jsx } from "react/jsx-runtime";
import { Box, CircularProgress, Grid } from '@mui/material';
import { tss } from 'tss-react';
const useStyles = tss.create(() => ({
    box: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0,0,0,0.2)',
            zIndex: 2,
        },
    },
}));
export const Loader = () => {
    const { classes } = useStyles();
    return (_jsx(Box, { className: classes.box, children: _jsx(Grid, { container: true, justifyContent: "center", alignItems: "center", sx: { height: '100%' }, children: _jsx(CircularProgress, { style: { zIndex: 1 } }) }) }));
};
