import { jsx as _jsx } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()(() => ({
    container: {
        borderRadius: '8px',
        padding: '1px 8px',
        backgroundColor: '#E1E2E5',
    },
}));
export const NotAvailable = () => {
    const { classes } = useStyles();
    return (_jsx("div", { className: classes.container, children: _jsx(Typography, { children: "N/D" }) }));
};
