import { createSlice } from '@reduxjs/toolkit';
import { option, readonlyArray } from 'fp-ts';
import { constant, identity, pipe } from 'fp-ts/function';
const initialState = {
    loading: { table: false, filter: false },
    table: null,
    initialValues: { artooRecordId: '' },
};
const coassigneeFullName = (paperworkList) => pipe(paperworkList, readonlyArray.lookup(0), option.chain(({ coAssignee }) => option.fromNullable(coAssignee)), option.match(constant('-'), coAssignee => coAssignee.fullName));
const artooRecordTable = (artooRecord, paperworkList) => pipe({
    headers: {
        clientFullName: 'Nome e cognome',
        artooRecordId: 'ID pratica',
        coassigneeFullName: 'Coassegnatario',
    },
    body: [
        {
            clientFullName: `${artooRecord.data.nome} ${artooRecord.data.cognome}`,
            artooRecordId: artooRecord.id,
            coassigneeFullName: coassigneeFullName(paperworkList),
        },
    ],
});
export const coassigneePaperworkSlice = createSlice({
    name: 'CoassigneePaperworkSaga',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        fetchPaperworkById: (state, _) => state,
        PaperworkRequested: state => (Object.assign(Object.assign({}, state), { table: null, loading: Object.assign(Object.assign({}, state.loading), { table: true, filter: true }) })),
        PaperworkFetched: (state, event) => (Object.assign(Object.assign({}, state), { table: artooRecordTable(event.payload.artooRecord, event.payload.paperworkList), loading: Object.assign(Object.assign({}, state.loading), { table: false, filter: false }) })),
        PaperworkNotFetched: state => (Object.assign(Object.assign({}, state), { table: null, loading: Object.assign(Object.assign({}, state.loading), { table: false }) })),
        Started: identity,
        Stopped: constant(initialState),
    },
});
export const $CoassigneePaperwork = coassigneePaperworkSlice.actions;
export const $coassigneePaperworkSlice = {
    coassigneeFullName,
    selector: {
        table: (state) => state.coassigneePaperwork.table,
        isTableLoading: (state) => state.coassigneePaperwork.loading.table,
        isFilterLoading: (state) => state.coassigneePaperwork.loading.table,
        initialValues: (state) => state.coassigneePaperwork.initialValues,
    },
};
export default coassigneePaperworkSlice.reducer;
