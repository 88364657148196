import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Box, Button, Container, Grid, Typography } from '@mui/material';
import { push } from 'redux-first-history';
import { makeStyles } from 'tss-react/mui';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { $routes } from '../../routes/routes';
import { requestWithQuotesView } from '../view/RequestWithQuotesView';
import { EmptyList } from './EmptyList';
import { QuoteCard } from './leads/QuoteCard';
const useStyles = makeStyles()(() => ({
    container: {
        paddingTop: '32px',
    },
    mb16px: {
        marginBottom: '16px',
    },
}));
export const QuotesContainer = ({ requestWithQuotes }) => {
    const { classes } = useStyles();
    const dispatch = useAppDispatch();
    const formattedRequestWithQuotes = requestWithQuotesView(requestWithQuotes);
    return (_jsxs("div", { className: classes.container, children: [_jsxs(Grid, { className: classes.mb16px, container: true, flexWrap: "nowrap", alignItems: "center", justifyContent: "space-between", children: [_jsx(Grid, { item: true, children: _jsxs(Typography, { variant: "h1", children: ["Richiesta del ", formattedRequestWithQuotes.submittedAt] }) }), _jsx(Grid, { item: true, children: _jsx(Button, { variant: "contained", onClick: () => {
                                dispatch(push($routes.QUOTE_MORTGAGE, {
                                    validationSchema: 'mortgageSchema',
                                    requestWithQuotes,
                                    from: $routes.PROCESSING_QUOTES,
                                }));
                            }, children: "Ricalcola" }) })] }), _jsx(Container, { maxWidth: "xs", children: requestWithQuotes.quotations.length === 0 ? (_jsx(EmptyList, { message: "Non sono presenti preventivi per la richiesta selezionata" })) : (requestWithQuotes.quotations.map((quote, key) => (_jsx(Box, { className: classes.mb16px, children: _jsx(QuoteCard, { quote: quote }) }, key)))) })] }));
};
