export const currencyView = (numStr) => {
    if (numStr === '') {
        return '';
    }
    const n = Number(numStr);
    if (isNaN(n)) {
        return '';
    }
    if (n === 0) {
        return new Intl.NumberFormat('it-IT', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0,
        }).format(n);
    }
    return new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    }).format(n);
};
