import { createSlice } from '@reduxjs/toolkit';
import { array, option, readonlyArray, readonlyRecord, string } from 'fp-ts';
import { constant, identity, pipe } from 'fp-ts/function';
import { $currency } from '../../models/Currency/Currency';
const isAnyFieldEmpty = (formData) => readonlyRecord.isEmpty(formData) ||
    pipe(formData, readonlyRecord.some(datum => datum.toString() === ''));
const initialState = {
    loading: { registration: false, payins: false },
    error: undefined,
    productTypeWithLeftoverPayinAmountList: [],
    initialValues: {
        productType: '',
        amount: '',
    },
};
const productTypeWithLeftoverPayinAmountList = (payins) => pipe(payins, readonlyArray.map(payin => ({
    productType: payin.productType,
    leftoverPayinAmount: pipe(payin.leftoverPayinAmount, $currency.euro, option.getOrElse(constant('-'))),
})), readonlyArray.toArray);
const leftoverPayinAmountByProductType = (productType) => (productTypeWithLeftoverPayinAmountListState) => pipe(productTypeWithLeftoverPayinAmountListState, array.findFirst(payin => string.Eq.equals(payin.productType, productType)), option.map(payin => payin.leftoverPayinAmount));
export const PayinRegistrationSlice = createSlice({
    name: 'PayinRegistrationSaga',
    initialState,
    reducers: {
        start: (state, _) => state,
        stop: identity,
        Started: identity,
        Stopped: constant(initialState),
        fetchPayins: identity,
        PayinsRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { payins: true }), error: undefined, productTypeWithLeftoverPayinAmountList: [] })),
        PayinsFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { payins: false }), error: undefined, productTypeWithLeftoverPayinAmountList: productTypeWithLeftoverPayinAmountList(event.payload) })),
        PayinsNotFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { payins: false }), productTypeWithLeftoverPayinAmountList: [], error: event.payload.message })),
        registerPayin: (state, _) => state,
        PayinRegistrationRequested: state => (Object.assign(Object.assign({}, state), { error: undefined, loading: Object.assign(Object.assign({}, state.loading), { registration: true }) })),
        PayinRegistered: state => (Object.assign(Object.assign({}, state), { error: undefined, loading: Object.assign(Object.assign({}, state.loading), { registration: false }) })),
        PayinNotRegistered: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { registration: false }) })),
    },
});
export const $PayinRegistration = PayinRegistrationSlice.actions;
const isPayinsLoading = (state) => state.payinRegistration.loading.payins;
const isPayinRegistrationLoading = (state) => state.payinRegistration.loading.registration;
const initialValues = (state) => state.payinRegistration.initialValues;
const error = (state) => state.payinRegistration.error;
const leftoverPayinAmount = (state) => (productType) => leftoverPayinAmountByProductType(productType)(state.payinRegistration.productTypeWithLeftoverPayinAmountList);
export const $PayinRegistrationSlice = {
    isAnyFieldEmpty,
    leftoverPayinAmountByProductType,
    productTypeWithLeftoverPayinAmountList,
    selector: {
        leftoverPayinAmount,
        isPayinRegistrationLoading,
        isPayinsLoading,
        productTypeWithLeftoverPayinAmountList: (state) => state.payinRegistration.productTypeWithLeftoverPayinAmountList,
        error,
        initialValues,
    },
};
export default PayinRegistrationSlice.reducer;
