import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, Drawer, IconButton, Typography } from '@mui/material';
import { tss } from 'tss-react';
const useStyles = tss.create(() => ({
    container: { padding: 12 },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    footer: {
        display: 'flex',
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
    },
    content: { display: 'flex', flexDirection: 'column' },
}));
export const NotebarLayout = ({ open, onClose, onSubmit, children, isSubmitButtonDisabled }) => {
    const { classes, cx } = useStyles();
    return (_jsx(Drawer, { open: open, anchor: "right", children: _jsxs(Box, { sx: { width: 332, height: '100%' }, className: classes.box, children: [_jsxs("div", { className: classes.content, children: [_jsxs("div", { children: [_jsx("div", { className: classes.container, children: _jsxs("div", { className: classes.title, children: [_jsx(Typography, { variant: "h2", children: "Aggiungi nota" }), _jsx(IconButton, { onClick: onClose, children: _jsx(CloseIcon, {}) })] }) }), _jsx(Divider, {})] }), _jsx("div", { className: classes.container, children: children })] }), _jsxs("div", { children: [_jsx(Divider, {}), _jsxs("div", { className: cx(classes.container, classes.footer), children: [_jsx(Button, { sx: { marginRight: '8px' }, variant: "contained", disabled: isSubmitButtonDisabled, onClick: onSubmit, children: "Salva" }), _jsx(Button, { onClick: onClose, children: "Annulla" })] })] })] }) }));
};
