import { all } from 'typed-redux-saga';
import { ActiveInvoiceCreationSaga } from '../activeInvoicing/state/activeInvoiceCreation/ActiveInvoiceCreationSaga';
import { ActiveInvoiceHistorySaga } from '../activeInvoicing/state/activeInvoiceHistory/ActiveInvoiceHistorySaga';
import { ActiveInvoicingSaga } from '../activeInvoicing/state/activeInvoicing/ActiveInvoicingSaga';
import { CoassigneeSaga } from '../coassignee/state/CoassigneeSaga';
import { CoassigneePaperworkSaga } from '../coassignee/state/coassigneePaperwork/CoassigneePaperworkSaga';
import { CommissionPlanSaga } from '../commissionPlan/state/Saga';
import { EnasarcoSaga } from '../enasarco/state/Saga';
import { BazaarClient as PaperworkProcessClient } from '../paperworkProcess/clients/bazaar/BazaarClient';
import { PaperworkListSaga } from '../paperworkProcess/state/PaperworkListSaga';
import { PaperworkProcessSaga } from '../paperworkProcess/state/PaperworkProcessSaga';
import { InvoiceApproval } from '../passiveInvoicing/state/invoiceApproval/InvoiceApprovalSaga';
import { InvoiceCreationSaga } from '../passiveInvoicing/state/invoiceCreation/InvoiceCreationSaga';
import { InvoiceCreationFromMpn } from '../passiveInvoicing/state/invoiceCreationFromMpn/InvoiceCreationFromMpnSaga';
import { InvoiceCreationFromRecordSaga } from '../passiveInvoicing/state/invoiceCreationFromRecord/InvoiceCreationFromRecordSaga';
import { InvoiceDeletionSaga } from '../passiveInvoicing/state/invoiceDeletion/InvoiceDeletionSaga';
import { InvoiceDetailSaga } from '../passiveInvoicing/state/invoiceDetail/invoiceDetailSaga';
import { InvoiceEditSaga } from '../passiveInvoicing/state/invoiceEdit/InvoiceEditSaga';
import { InvoiceHistorySaga } from '../passiveInvoicing/state/invoiceHistory/InvoiceHistorySaga';
import { InvoiceListSaga } from '../passiveInvoicing/state/invoiceList/InvoiceListSaga';
import { InvoiceListVisibility } from '../passiveInvoicing/state/invoiceListVisibility/InvoiceListVisibilitySaga';
import { InvoiceTransmissionSaga } from '../passiveInvoicing/state/invoiceTransmission/InvoiceTransmissionSaga';
import { PassiveInvoiceBulkEditSaga } from '../passiveInvoicing/state/passiveInvoiceBulkEditActions/PassiveInvoiceBulkEditActionsSaga';
import { PayinRegistrationSaga } from '../passiveInvoicing/state/payinRegistration/PayinRegistrationSaga';
import { RecordCommissionsSaga } from '../passiveInvoicing/state/recordCommissions/RecordCommissionsSaga';
import { RecordListWithCommissionsSaga } from '../passiveInvoicing/state/recordListWithCommissions/RecordListWithCommissionsSaga';
import { RecordListWithPayinSaga } from '../passiveInvoicing/state/recordListWithPayin/RecordListWithPayinSaga';
import { InvoiceFromRecordTabSaga } from '../passiveInvoicing/state/tabs/invoiceFromRecordTab/InvoiceFromRecordTabSaga';
import { InvoiceToTransmitTabSaga } from '../passiveInvoicing/state/tabs/invoiceToTransmitTab/InvoiceToTransmitTabSaga';
import { BazaarClient } from '../quotingTool/clients/bazaar/BazaarClient';
import { ProcessingQuotesSaga } from '../quotingTool/state/ProcessingQuotesSaga';
import { RequestWithQuotesSaga } from '../quotingTool/state/RequestWithQuotesSaga';
import { QuoteSaga } from '../quotingTool/state/Saga';
import { MpnQuotationSaga } from '../quotingTool/state/mpn/Saga';
import { AuthClient } from '../session/clients/AuthClient';
import { SessionSaga } from '../session/state/SessionSaga';
import { EnvironmentFactory } from './EnvironmentFactory/EnvironmentFactory';
import { CommissionsSaga } from './commissions/CommissionsSaga';
import { recordListSaga } from './list/RecordListSaga';
import { NavigationSaga } from './navigation/NavigationSaga';
import { RecordSaga } from './record/RecordSaga';
import { DocumentSaga } from './record/documents/DocumentSaga';
import { HistorySaga } from './record/history/HistorySaga';
import { RecordDataTabSaga } from './recordDataTab/RecordDataTabSaga';
import { filterSaga } from './sagas/filter';
import { pipelinesSaga } from './sagas/pipelines';
import { SecuritySaga } from './security/SecuritySaga';
export function* Saga() {
    const environment = new EnvironmentFactory(sessionStorage);
    const bazaarClient = new BazaarClient(environment.createBazaar().getUrl());
    const authClient = new AuthClient(environment.createArtoo().getUrl());
    const paperworkClient = new PaperworkProcessClient(environment.createBazaar().getUrl());
    yield* all([
        SecuritySaga(),
        recordListSaga(),
        DocumentSaga(),
        pipelinesSaga(),
        filterSaga(),
        RecordSaga(),
        HistorySaga(),
        CommissionsSaga(),
        RecordDataTabSaga(),
        NavigationSaga(),
        QuoteSaga(bazaarClient),
        MpnQuotationSaga(),
        CommissionPlanSaga(),
        EnasarcoSaga(),
        InvoiceHistorySaga(),
        InvoiceApproval(),
        InvoiceDetailSaga(),
        InvoiceEditSaga(),
        RecordListWithCommissionsSaga(),
        PassiveInvoiceBulkEditSaga(),
        InvoiceTransmissionSaga(),
        InvoiceListVisibility(),
        InvoiceListSaga(),
        InvoiceDeletionSaga(),
        InvoiceCreationFromRecordSaga(),
        InvoiceCreationFromMpn(),
        InvoiceCreationSaga(),
        InvoiceFromRecordTabSaga(),
        InvoiceToTransmitTabSaga(),
        PayinRegistrationSaga(),
        RecordCommissionsSaga(),
        RecordListWithPayinSaga(),
        ActiveInvoiceCreationSaga(),
        ActiveInvoiceHistorySaga(),
        ActiveInvoicingSaga(),
        CoassigneeSaga(),
        CoassigneePaperworkSaga(),
        ProcessingQuotesSaga(bazaarClient),
        RequestWithQuotesSaga(bazaarClient),
        SessionSaga(authClient),
        PaperworkProcessSaga(paperworkClient),
        PaperworkListSaga(paperworkClient),
    ]);
}
