import { either, option } from 'fp-ts';
import { all, call, cancel, put, select, take, takeLeading } from 'typed-redux-saga';
import { NotificationAction } from '../../store/notification/NotificationAction';
import { Notification } from '../../utils/notification';
import { PaginationUtils } from '../models/Pagination/Pagination.codec';
import { getCommissionPlans } from '../web/getCommissionPlans';
import { getPaperworks } from '../web/getPaperworks';
import { updateCommissionPlan } from '../web/updateCommissionPlan';
import { uploadCommissionPlan } from '../web/uploadCommissionPlan';
import { $CommissionPlan, $CommissionPlanSlice } from './State';
const getCommissionPlanPayload = (commissionPlanForm) => {
    if (commissionPlanForm.file === null) {
        return option.none;
    }
    const formData = new FormData();
    formData.append('commissionPlan', commissionPlanForm.file);
    formData.append('validFrom', commissionPlanForm.effectiveDate.toISOString());
    formData.append('description', commissionPlanForm.notes);
    return option.some(formData);
};
function* tryUploadNewCommissionPlan(addNewCommissionPlanCommand) {
    const commissionPayloadOption = getCommissionPlanPayload(addNewCommissionPlanCommand.payload.commissionPlanForm);
    if (option.isSome(commissionPayloadOption)) {
        yield* put($CommissionPlan.NewCommissionPlanRequested());
        const newCommissionPlanEither = yield* call(uploadCommissionPlan(commissionPayloadOption.value));
        if (either.isRight(newCommissionPlanEither)) {
            const onSuccess = addNewCommissionPlanCommand.payload.onSuccess;
            if (onSuccess) {
                yield* call(onSuccess);
            }
            yield* put($CommissionPlan.NewCommissionPlanUploaded());
            yield* call(tryGetCommissionPlanTable, { size: $CommissionPlanSlice.PAGE_LIMIT, offset: 0 });
        }
        else {
            yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore. Riprova')));
            yield* put($CommissionPlan.NewCommissionPlanNotUploaded());
        }
    }
}
function* fetchNextCommissionPlanPage() {
    const pagination = yield* select($CommissionPlanSlice.selector.pagination);
    yield* call(tryGetCommissionPlanTable, {
        size: pagination.size,
        offset: PaginationUtils.increaseOffset($CommissionPlanSlice.PAGE_LIMIT)(pagination),
    });
}
function* fetchPreviousCommissionPlanPage() {
    const pagination = yield* select($CommissionPlanSlice.selector.pagination);
    const offset = PaginationUtils.decreaseOffset($CommissionPlanSlice.PAGE_LIMIT)(pagination);
    if (offset >= 0) {
        yield* call(tryGetCommissionPlanTable, {
            size: pagination.size,
            offset: offset,
        });
    }
}
function* fetchFirstCommissionPlanPage() {
    yield* call(tryGetCommissionPlanTable, {
        size: $CommissionPlanSlice.PAGE_LIMIT,
        offset: 0,
    });
}
function* tryGetCommissionPlanTable(pagination) {
    yield* put($CommissionPlan.CommissionPlansTableRequested());
    const commissionPlansEither = yield* call(getCommissionPlans({ page: pagination, sort: '-uploadedAt' }));
    if (either.isRight(commissionPlansEither) && commissionPlansEither.right.length === 0 && pagination.offset > 0) {
        yield put($CommissionPlan.EmptyCommissionPlansTableFetched());
    }
    else if (either.isRight(commissionPlansEither)) {
        yield put($CommissionPlan.CommissionPlansTableFetched({
            commissionPlans: commissionPlansEither.right,
            pagination: pagination,
        }));
    }
    else {
        yield* put($CommissionPlan.CommissionPlansTableNotFetched());
    }
}
function* tryUpdateCommissionPlan(updateCommissionPlanCommand) {
    if (updateCommissionPlanCommand.payload.paperworkIds.length > 0) {
        yield* put($CommissionPlan.CommissionPlanUpdateRequested());
        const paperworksEither = yield* call(updateCommissionPlan(updateCommissionPlanCommand.payload.commissionPlanId, updateCommissionPlanCommand.payload.paperworkIds));
        if (either.isRight(paperworksEither)) {
            yield* put($CommissionPlan.CommissionPlanUpdated());
            yield* put(NotificationAction.Push(Notification.info('Pratiche associate correttamente')));
            const onSuccess = updateCommissionPlanCommand.payload.onSuccess;
            if (onSuccess) {
                yield* call(onSuccess);
            }
            yield* call(tryGetCommissionPlanTable, { size: $CommissionPlanSlice.PAGE_LIMIT, offset: 0 });
        }
        else {
            yield* put($CommissionPlan.CommissionPlanNotUpdated());
            yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore. Riprova')));
        }
    }
}
function* tryFetchPaperworks(fetchPaperworksByPaperworkIdCommand) {
    if (fetchPaperworksByPaperworkIdCommand.payload.paperworkId.length > 0) {
        yield* put($CommissionPlan.PaperworksRequested());
        const paperworksEither = yield* call(getPaperworks({ id: fetchPaperworksByPaperworkIdCommand.payload.paperworkId }));
        if (either.isRight(paperworksEither)) {
            yield* put($CommissionPlan.PaperworksFetched({
                paperworksSearch: fetchPaperworksByPaperworkIdCommand.payload.paperworksSearch,
                artooPaperworks: paperworksEither.right,
                paperworkIds: fetchPaperworksByPaperworkIdCommand.payload.paperworkIds,
                paperworksToLink: fetchPaperworksByPaperworkIdCommand.payload.paperworksToLink,
            }));
        }
        else {
            yield* put($CommissionPlan.PaperworksNotFetched());
            yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore. Riprova')));
        }
    }
}
function* toggleLinkedPaperwork(fetchPaperworksByPaperworkIdCommand) {
    yield* put($CommissionPlan.PaperworkToggled({
        paperworkToToggle: fetchPaperworksByPaperworkIdCommand.payload.paperworkToToggle,
        paperworks: fetchPaperworksByPaperworkIdCommand.payload.paperworks,
    }));
}
function* resetLinkedPaperworkSearch() {
    yield* put($CommissionPlan.LinkedPaperworkSearchResetted());
}
function* selectCommissionPlan(command) {
    yield* put($CommissionPlan.CommissionPlanSelected({ commissionPlan: command.payload.commissionPlan }));
}
function* replaceCommissionPlan(command) {
    yield* put($CommissionPlan.CommissionPlanReplacementRequested());
    const updatedCommissionPlanEither = yield* call(updateCommissionPlan(command.payload.commissionPlanId, command.payload.paperworkIds, command.payload.commissionPlanFile, command.payload.notes));
    if (either.isLeft(updatedCommissionPlanEither)) {
        yield* put($CommissionPlan.CommissionPlanNotReplaced());
        return;
    }
    yield* put($CommissionPlan.CommissionPlanReplaced());
    const onSuccess = command.payload.onSuccess;
    if (onSuccess) {
        yield* call(onSuccess);
    }
    yield* put(NotificationAction.Push(Notification.info('Piano provvigionale aggiornato correttamente')));
    yield* call(tryGetCommissionPlanTable, { size: $CommissionPlanSlice.PAGE_LIMIT, offset: 0 });
    return;
}
export function* CommissionPlanSaga() {
    yield* takeLeading($CommissionPlan.start, function* () {
        yield* put($CommissionPlan.Started());
        yield* call(tryGetCommissionPlanTable, { size: $CommissionPlanSlice.PAGE_LIMIT, offset: 0 });
        const task = yield* all([
            yield* takeLeading($CommissionPlan.replaceCommissionPlan, replaceCommissionPlan),
            yield* takeLeading($CommissionPlan.selectCommissionPlan, selectCommissionPlan),
            yield* takeLeading($CommissionPlan.fetchFirstCommissionPlanPage, fetchFirstCommissionPlanPage),
            yield* takeLeading($CommissionPlan.fetchNextCommissionPlanPage, fetchNextCommissionPlanPage),
            yield* takeLeading($CommissionPlan.fetchPreviousCommissionPlanPage, fetchPreviousCommissionPlanPage),
            yield* takeLeading($CommissionPlan.uploadNewCommissionPlan, tryUploadNewCommissionPlan),
            yield* takeLeading($CommissionPlan.fetchPaperworksByPaperworkId, tryFetchPaperworks),
            yield* takeLeading($CommissionPlan.updateCommissionPlan, tryUpdateCommissionPlan),
            yield* takeLeading($CommissionPlan.togglePaperwork, toggleLinkedPaperwork),
            yield* takeLeading($CommissionPlan.resetLinkedPaperworkSearch, resetLinkedPaperworkSearch),
        ]);
        yield* take($CommissionPlan.stop);
        yield* cancel(task);
        yield* put($CommissionPlan.Stopped());
    });
}
