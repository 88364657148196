const PooledRequest = (request, pool) => (url, data, headers, responseType) => {
    const key = `${url}?${btoa(unescape(encodeURIComponent(JSON.stringify(data) + JSON.stringify(headers))))}`;
    if (pool.has(key)) {
        // console.log(`Request "${key}" retrieved from pool`)
    }
    else {
        const promise = request(url, data, headers, responseType)
            .then(response => {
            pool.delete(key);
            return response;
        })
            .catch(error => {
            pool.delete(key);
            throw error;
        });
        pool.set(key, promise);
    }
    return pool.get(key);
};
export const PooledClient = (client, pool) => (Object.assign(Object.assign({}, client), { delete: PooledRequest(client.delete, pool), get: PooledRequest(client.get, pool), post: PooledRequest(client.post, pool), put: PooledRequest(client.put, pool) }));
export const HttpClientPool = new Map();
