import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { DateField } from '../atoms/DateField';
import { SelectField } from '../atoms/SelectField';
const LENDING_DATE = 'lendingDate';
export const LendingSection = ({ values, setFieldValue, handleBlur, errors, handleChange, touched }) => {
    return (_jsxs(_Fragment, { children: [_jsx(DateField, { label: "Data erogazione finanziamento", name: LENDING_DATE, value: new Date(values.lendingDate), onChange: newDate => {
                    if (!newDate) {
                        setFieldValue(LENDING_DATE, '');
                        return;
                    }
                    setFieldValue(LENDING_DATE, newDate.toISOString());
                }, onBlur: handleBlur, onClear: () => {
                    setFieldValue(LENDING_DATE, '');
                } }), _jsx(SelectField, { label: "Mezzo di pagamento finanziamento", name: "lendingPaymentMethod", onChange: handleChange, onBlur: handleBlur, options: [
                    'Accredito su conto corrente',
                    'Assegno circolare',
                    'Bonifico bancario',
                    'Disposizione irrevocabile',
                ], value: values.lendingPaymentMethod, error: errors.lendingPaymentMethod, isTouched: touched.lendingPaymentMethod, clearable: true })] }));
};
