import { SESSION_ERROR } from '../../utils/session';
const httpError = ({ name, stack, message }, response) => {
    return {
        name,
        stack,
        message,
        response,
    };
};
const defaultError = ({ name, stack, message }) => ({
    name,
    stack,
    message,
});
export function ClientError(error, response) {
    return undefined === response ? defaultError(error) : httpError(error, response);
}
const refineHttpError = (e) => e.response !== undefined;
const refineDefaultError = (e) => e.response === undefined;
const is = {
    unauthorized: (e) => refineHttpError(e) && e.response.status === 401,
    expiredSession: (e) => refineHttpError(e) && e.response.status === 401 && e.response.data.error === SESSION_ERROR.EXPIRED_CREDENTIALS,
    forbidden: (e) => refineHttpError(e) && e.response.status === 403,
    notFound: (e) => refineHttpError(e) && e.response.status === 404,
    httpError: (e) => refineHttpError(e),
    defaultError: (e) => refineDefaultError(e),
};
ClientError.is = is;
