import { IteratorResult } from './IteratorResult';
const iteration = Symbol('Iteration');
const recursion = Symbol('Recursion');
export const Iterable = {
    Fold: {
        Strategy: {
            Iteration: iteration,
            Recursion: recursion,
        },
    },
    fromIterator: (iterator) => (Object.assign(Object.assign({}, iterator), { [Symbol.iterator]: () => iterator })),
    foldl: (acc, f, strategy = iteration) => (as) => {
        const iterator = as[Symbol.iterator]();
        switch (strategy) {
            case iteration:
                // eslint-disable-next-line no-case-declarations
                let b = acc();
                for (const a of Iterable.fromIterator(iterator)) {
                    // eslint-disable-next-line no-loop-func
                    b = f(() => b, a);
                }
                return b;
            case recursion:
                return IteratorResult.fold(acc, (value) => Iterable.foldl(() => f(acc, value), f, strategy)(Iterable.fromIterator(iterator)))(iterator.next());
        }
    },
    foldr: (z, f, strategy = iteration) => (as) => {
        const iterator = as[Symbol.iterator]();
        switch (strategy) {
            case iteration:
                return Iterable.foldl(() => [], (lazyArray, a) => lazyArray().concat(a), strategy)(Iterable.fromIterator(iterator)).reduceRight((y, a) => f(a, () => y), z());
            case recursion:
                return IteratorResult.fold(z, (value) => f(value, () => Iterable.foldr(z, f, strategy)(Iterable.fromIterator(iterator))))(iterator.next());
        }
    },
};
