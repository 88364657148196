export const guarantorStateMapper = (guarantorModel) => ({
    nationality: guarantorModel.isForeigner === false ? 'italian' : 'other',
    age: guarantorModel.age,
    household: guarantorModel.familyUnit,
    job: guarantorModel.employment,
    formattedNetMonthlyIncome: guarantorModel.netIncome,
    rawNetMonthlyIncome: guarantorModel.netIncome,
    formattedOngoingDebtInstallment: guarantorModel.currentDebtInstallment,
    rawOngoingDebtInstallment: guarantorModel.currentDebtInstallment,
    type: 'garante',
});
