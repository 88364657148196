import { addFile } from '../utils/addFile';
export const filesStateModelMapper = (documentationModel) => {
    return documentationModel.reduce((acc, document) => {
        var _a, _b;
        acc[document.documentTypeId] = addFile({
            type: document.file.mimeType,
            size: document.file.size,
            name: document.file.name,
            isUploading: false,
            hasError: false,
            isUploaded: document.uploaded,
            uploadedAt: (_b = (_a = document.transitionsHistory.find(({ status }) => status.code === 'uploaded')) === null || _a === void 0 ? void 0 : _a.triggeredAt) !== null && _b !== void 0 ? _b : '',
            isFileTypeSupported: true,
            transitionsHistory: document.transitionsHistory,
        }, acc[document.documentTypeId] || []);
        return acc;
    }, {});
};
