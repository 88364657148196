import { either } from 'fp-ts';
import { all, call, cancel, put, take, takeLatest, takeLeading } from 'typed-redux-saga';
import { ClientError } from '../../lib/http/ClientError';
import { getCommissionTable } from '../../web/record/getCommissionTable';
import { $Commissions } from './CommissionsState';
function* fetchCommissionTable(recordId) {
    try {
        yield* put($Commissions.CommissionTableRequested());
        const commissionsEither = yield* call(getCommissionTable(recordId));
        if (either.isLeft(commissionsEither)) {
            throw commissionsEither.left;
        }
        yield* put($Commissions.CommissionTableFetched({
            table: commissionsEither.right,
        }));
    }
    catch (e) {
        if (e.response) {
            yield put($Commissions.CommissionTableNotFetched(ClientError({ name: e.name, message: e.message, stack: e.stack }, e.response)));
        }
        else if (e instanceof Array) {
            yield put($Commissions.CommissionTableNotFetched(ClientError(new Error('Si è verificato un errore nella ricezione'))));
        }
        else {
            yield put($Commissions.CommissionTableNotFetched(ClientError(e)));
        }
    }
}
export function* CommissionsSaga() {
    yield* takeLeading($Commissions.start, function* (startAction) {
        yield* put($Commissions.Started());
        const task = yield* all([
            yield* takeLatest($Commissions.fetchCommissionTable, function* () {
                yield* call(fetchCommissionTable, startAction.payload.recordId);
            }),
        ]);
        yield* put($Commissions.fetchCommissionTable());
        yield* take($Commissions.stop);
        yield* cancel(task);
        yield* put($Commissions.Stopped());
    });
}
