import { MonoidOr } from 'fortepiano/Binary';
import { array, eq, monoid, number, option, readonlyArray, string } from 'fp-ts';
import { isNone, none, some } from 'fp-ts/Option';
import { flow, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { Refinement } from '../../../lib/Refinement';
import { Reducer } from '../../../lib/redux/Reducer';
import { CategoryHierarchy } from '../../../utils/Documents/CategoryHierarchy';
import { optics } from '../../../utils/Optics';
import { flatDocument } from '../../../utils/document';
import { DocumentAction } from './DocumentAction';
const empty = none;
const reducer = Reducer(Refinement.any(DocumentAction.Hierarchy.is.done))(empty)({
    [DocumentAction.Hierarchy.type.done]: (state, payload) => (Object.assign(Object.assign({}, state), some(payload.loops))),
});
const isDocumentGenerated = (file) => file.status.code === 'generated_done' || file.status.code === 'generated';
const isDocumentReady = (fillable) => (file) => t.type({ status: t.type({ code: t.string }) }).is(file)
    ? (isDocumentGenerated(file) && !fillable) || file.status.code === 'loaded'
    : true;
const count = (filter, countMonoid, map = () => 1) => (as) => pipe(as, readonlyArray.filter(filter), readonlyArray.foldMap(countMonoid)(map));
const countMandatoryDocuments = (fillable = false) => (files, id) => pipe(files, count(file => isDocumentReady(fillable)(file) && file.parent === id && file.required, number.MonoidSum));
export const countFilledDocuments = (files, id) => pipe(files, count(file => file.parent === id, number.MonoidSum, optics.get('totalFilled')));
export const _countFilledDocuments = (files, id) => files.filter(f => f.parent === id).reduce((acc, f) => acc + f.totalFilled, 0);
export const countFilledUploadedDocuments = (files, id) => pipe(files, count(f => f.parent === id && f.totalFilledUploaded > 0, number.MonoidSum));
export const _countFilledUploadedDocuments = (files, id) => files.filter(f => f.parent === id && f.totalFilledUploaded > 0).length;
export const countMandatoryUploadedDocuments = (fillable = false) => (files, id) => pipe(files, count(file => isDocumentReady(fillable)(file) &&
    !file.signed &&
    file.parent === id &&
    file.required &&
    file.totalFiles > 0, number.MonoidSum));
export const _countMandatoryUploadedDocuments = (fillable = false) => (files, id) => files
    .filter(isDocumentReady(fillable))
    .filter(f => !f.signed)
    .filter(f => f.parent === id && f.required && f.totalFiles > 0).length;
const countTypesWithDocuments = (fillable = false) => (files, id) => pipe(files, count(file => file.totalFiles > 0 && file.parent === id && isDocumentReady(fillable)(file), number.MonoidSum));
const sumDocumentTypesTotalMandatoryUploaded = (fillable = false) => (files, id) => pipe(files, count(file => file.parent === id && isDocumentReady(fillable)(file), number.MonoidSum, optics.get('totalMandatoryUploaded')));
const sumDocumentTypesTotalFilledUploaded = (files, id) => pipe(files, count(file => file.parent === id, number.MonoidSum, optics.get('totalFilledUploaded')));
const sumDocumentTypesTotalFilled = (files, id) => pipe(files, count(file => file.parent === id, number.MonoidSum, optics.get('totalFilled')));
const sumDocumentTypesTotalMandatory = (fillable = false) => (files, id) => pipe(files, count(file => file.parent === id && isDocumentReady(fillable)(file), number.MonoidSum, optics.get('totalMandatory')));
const countFilesPerCategory = (fillable = false) => (files, id) => pipe(files, count(file => file.parent === id && isDocumentReady(fillable)(file) && !file.signed, number.MonoidSum, file => ('totalFiles' in file ? file.totalFiles || 0 : 1)));
export const countFilledFilesPerCategory = (files, id) => pipe(files, count(file => file.parent === id && isDocumentGenerated(file), MonoidOr));
export const _countFilledFilesPerCategory = (files, id) => {
    const file = files.filter(({ parent }) => parent === id).find(isDocumentGenerated);
    return file ? 1 : 0;
};
const countSizePerCategory = (fillable = false) => (files, id) => pipe(files, count(file => file.categoryId === id && isDocumentReady(fillable)(file), number.MonoidSum, optics.get('size')));
export const generatedDocumentTimestamp = (files, id) => pipe(files, count(file => file.categoryId === id && isDocumentGenerated(file), monoid.max(number.Bounded), flow(optics.get('updatedAt'), updatedAt => Number(new Date(updatedAt)))), timestamp => Math.max(0, timestamp));
const mapFiles = (files) => files.map((document) => ({
    parent: document.category.id,
    id: document.id,
    categoryId: document.category.id,
    updatedAt: document.updated_at,
    isUploading: false,
    hasError: false,
    title: document.file_name,
    size: document.file_size,
    categoryCode: document.category.code,
    type: document.mime_type,
    token: document.token,
    raw: null,
    relative: [],
    status: {
        code: document.status.code,
    },
}));
const filterCategories = (arr, id) => arr.filter(c => c.parent && c.parent.toString() === id.toString());
export const flattenDocuments = (jobs) => pipe(jobs, array.map(optics.get('parameters')), array.map(array.head), array.compact, array.chain(optics.get('categories')), array.uniq(pipe(string.Eq, eq.contramap(optics.get('code')))), CategoryHierarchy.fromRequirements, flatDocument);
const getClassProps = (document) => document.map(d => (Object.assign(Object.assign({}, d), { categoryCode: d.code })));
export const sumKeyValues = (documents, id) => (getter) => pipe(documents, count(document => document.parent === id && t.number.is(getter(document)), number.MonoidSum, getter));
export const _sumKeyValues = (documents, id) => (key) => {
    return parentDocument(documents)(id).reduce((acc, document) => {
        const prop = document[key];
        if (t.number.is(prop)) {
            return acc + prop;
        }
        else {
            throw new Error('sumKeyValues: NaN provided');
        }
    }, 0);
};
const getCategoriesProps = (typeDocument, document) => document.map(d => {
    const isFillable = Boolean(typeDocument.filter(f => f.parent === d.id).find(({ fillable }) => fillable));
    return Object.assign(Object.assign({}, d), { fillable: isFillable, categoryCode: d.code, hasError: false, isUploading: false, totalFiles: countFilesPerCategory(isFillable)(typeDocument, d.id), totalMandatoryUploaded: sumDocumentTypesTotalMandatoryUploaded(isFillable)(typeDocument, d.id) +
            sumKeyValues(typeDocument, d.id)(optics.get('totalSigned')), totalFilledUploaded: isFillable ? sumDocumentTypesTotalFilledUploaded(typeDocument, d.id) : 0, totalMandatory: sumDocumentTypesTotalMandatory(isFillable)(typeDocument, d.id), totalFilled: isFillable ? sumDocumentTypesTotalFilled(typeDocument, d.id) : 0, totalSigned: sumKeyValues(typeDocument, d.id)(optics.get('totalSigned')), totalToBeSigned: sumKeyValues(typeDocument, d.id)(optics.get('totalToBeSigned')) });
});
export const countTypesWithDocumentsBySignedState = (getter) => (documentLastLevel, id) => {
    return documentLastLevel
        .filter(document => document.parent === id)
        .reduce((acc, document) => acc + (getter(document) ? 1 : 0), 0);
};
const getTypeProps = (documentLastLevel, document) => {
    return document.map(d => {
        const isFillable = Boolean(documentLastLevel.filter(f => f.parent === d.id).find(({ fillable }) => fillable));
        return Object.assign(Object.assign({}, d), { fillable: isFillable, categoryCode: d.code, totalFilled: isFillable ? countFilledDocuments(documentLastLevel, d.id) : 0, totalFilledUploaded: isFillable ? countFilledUploadedDocuments(documentLastLevel, d.id) : 0, totalMandatory: countMandatoryDocuments(isFillable)(documentLastLevel, d.id), totalMandatoryUploaded: countMandatoryUploadedDocuments(isFillable)(documentLastLevel, d.id), totalFiles: countTypesWithDocuments(isFillable)(documentLastLevel, d.id), totalSigned: countTypesWithDocumentsBySignedState(optics.get('signed'))(documentLastLevel, d.id), totalToBeSigned: countTypesWithDocumentsBySignedState(optics.get('signable'))(documentLastLevel, d.id) });
    });
};
export const parentDocument = (documents) => (id) => documents.filter((document) => document.parent === id);
const isCategoryNonEmpty = (files) => (id) => pipe(files, readonlyArray.filter(file => file.parent === id), readonlyArray.isNonEmpty);
const isCategorySigned = (files) => (id) => pipe(files, readonlyArray.filter(file => file.parent === id), readonlyArray.some(file => file.status.code === 'signed'));
export const isDocumentSignable = (recordDocuments) => (files) => (id) => isCategoryNonEmpty(files)(id) || isCategorySigned(recordDocuments)(id);
export const _isDocumentSignable = (recordDocuments) => (files) => (id) => parentDocument(files)(id).length > 0 ||
    Boolean(parentDocument(recordDocuments)(id).find(f => f.status.code === 'signed'));
export const isDocumentSigned = (recordDocuments) => (signatures) => (id) => isCategorySigned(signatures)(id) || isCategorySigned(recordDocuments)(id);
export const _isDocumentSigned = (recordDocuments) => (signatures) => (id) => Boolean(parentDocument(signatures)(id).find(f => f.status.code === 'signed') ||
    parentDocument(recordDocuments)(id).find(f => f.status.code === 'signed'));
const signedDocumentId = (documents, id) => pipe(documents, readonlyArray.filter(document => document.parent === id), readonlyArray.findFirst(document => document.status.code === 'signed'), option.map(document => document.id));
const getDocumentProps = (document, files, signature) => document.map(d => (Object.assign(Object.assign({}, d), { fillable: d.fillable || false, categoryCode: d.code, hasError: false, isUploading: false, totalFilledUploaded: d.fillable ? countFilledFilesPerCategory(files, d.id) : 0, totalFilled: d.fillable ? 1 : 0, totalFiles: countFilesPerCategory(d.fillable)(files, d.id), totalSize: countSizePerCategory(d.fillable)(files, d.id), filledDocumentTimestamp: generatedDocumentTimestamp(files, d.id), signedDocumentId: isDocumentSigned(files)(signature)(d.id) ? signedDocumentId(files, d.id) : none, signed: isDocumentSigned(files)(signature)(d.id), signable: isDocumentSignable(files)(signature)(d.id) })));
const classDocumentSelector = (state) => {
    if (isNone(state.record.documents.loops)) {
        return [];
    }
    const flatDocuments = flattenDocuments(state.record.documents.loops.value);
    return flatDocuments.level1 === undefined ? [] : getClassProps(flatDocuments.level1);
};
const documentSelector = (categoryId = null, typeId = null, documentId = null) => (state) => {
    var _a;
    if (isNone(state.record.documents.loops)) {
        return {
            categoryProps: [],
            typeProps: [],
            documentProps: [],
        };
    }
    const flatDocuments = flattenDocuments(state.record.documents.loops.value);
    if (isNone(state.record.item)) {
        return {
            documentProps: [],
            typeProps: [],
            categoryProps: [],
        };
    }
    const files = mapFiles(state.record.item.value.documents);
    const signature = mapFiles(((_a = state.record.item.value.signature) === null || _a === void 0 ? void 0 : _a.documents) || []);
    const documentProps = flatDocuments.level4 === undefined ? [] : getDocumentProps(flatDocuments.level4, files, signature);
    const documentWithFilter = documentId ? filterCategories(documentProps, documentId) : [];
    const typeProps = flatDocuments.level3 === undefined ? [] : getTypeProps(documentProps, flatDocuments.level3);
    const typeWithFilter = typeId ? filterCategories(typeProps, typeId) : [];
    const categoryProps = flatDocuments.level2 === undefined ? [] : getCategoriesProps(typeProps, flatDocuments.level2);
    const categoryWithFilter = categoryId ? filterCategories(categoryProps, categoryId) : [];
    return {
        documentProps: documentWithFilter,
        typeProps: typeWithFilter,
        categoryProps: categoryWithFilter,
    };
};
const fileSelector = (id) => (state) => {
    if (isNone(state.record.item)) {
        return [];
    }
    const files = mapFiles(state.record.item.value.documents);
    return filterCategories(files, id);
};
export const DocumentLoopsState = {
    reducer,
    selectors: {
        class: classDocumentSelector,
        document: documentSelector,
        file: fileSelector,
    },
};
