import { ProcessState } from '../../store/processes/ProcessState';
import { SecurityActions } from '../../store/security/SecurityActions';
import { SecurityState } from '../../store/security/SecurityState';
export const mapState = (state) => ({
    error: SecurityState.selector.loginErrorLabel(state),
    isLoading: ProcessState.selector.has.running(SecurityActions.Login)(state),
});
export const mapDispatch = {
    submitCredentials: SecurityActions.Login,
};
