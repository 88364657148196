import * as t from 'io-ts';
import { RecordStateC } from '../RecordState';
import { UserC } from '../User';
export const HistoryItemC = t.type({
    id: t.number,
    created_at: t.string,
    updated_at: t.string,
    status: RecordStateC,
    user: UserC,
}, 'HistoryItem');
