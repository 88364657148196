import { either } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { all, call, cancel, debounce, put, take, takeLeading } from 'typed-redux-saga';
import { NotificationAction } from '../../../store/notification/NotificationAction';
import { Notification } from '../../../utils/notification';
import { $mpnInvoiceForm } from '../../models/MpnInvoiceForm/MpnInvoiceForm';
import { getUserList } from '../../web/getUserList';
import { createInvoice } from '../../web/mpn/createInvoice';
import { $InvoiceCreationFromMpn } from './InvoiceCreationFromMpnState';
function* fetchUserList(action) {
    yield* put($InvoiceCreationFromMpn.UserListRequested());
    const users = yield* call(getUserList(action.payload.params));
    if (either.isLeft(users)) {
        yield* put($InvoiceCreationFromMpn.UserListNotFetched(users.left));
    }
    else {
        yield* put($InvoiceCreationFromMpn.UserListFetched(users.right));
    }
}
function* createMpnInvoice(action) {
    try {
        yield* put($InvoiceCreationFromMpn.InvoiceCreationRequested());
        const formData = pipe(action.payload.formData, t.exact($mpnInvoiceForm.codec).decode);
        if (either.isLeft(formData)) {
            throw formData.left;
        }
        const response = yield* call(createInvoice(formData.right));
        if (either.isLeft(response)) {
            throw response.left;
        }
        yield* put($InvoiceCreationFromMpn.InvoiceCreated());
        if (action.payload.onSuccess) {
            yield* call(action.payload.onSuccess);
        }
        yield* put(NotificationAction.Push(Notification.info('Componente MPN aggiunta correttamente')));
    }
    catch (e) {
        yield* put($InvoiceCreationFromMpn.InvoiceNotCreated(e));
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore nella creazione della fattura MPN')));
    }
}
export function* InvoiceCreationFromMpn() {
    yield* takeLeading($InvoiceCreationFromMpn.start, function* () {
        yield* put($InvoiceCreationFromMpn.Started());
        const task = yield* all([
            yield* debounce(1000, $InvoiceCreationFromMpn.fetchUserList, fetchUserList),
            yield* takeLeading($InvoiceCreationFromMpn.createInvoice, createMpnInvoice),
        ]);
        yield* put($InvoiceCreationFromMpn.fetchUserList({}));
        yield* take($InvoiceCreationFromMpn.stop);
        yield* cancel(task);
        yield* put($InvoiceCreationFromMpn.Stopped());
    });
}
