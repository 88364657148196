import { createSlice } from '@reduxjs/toolkit';
import { constant, identity } from 'fp-ts/function';
const initialState = {};
export const sessionSlice = createSlice({
    name: 'Session',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        refreshToken: identity,
        TokenRefreshed: identity,
        TokenRequested: identity,
        TokenNotRefreshed: identity,
        Stopped: constant(initialState),
        Started: identity,
    },
});
export const $Session = sessionSlice.actions;
export const $SessionSlice = {
    selector: {},
};
export const SessionState = sessionSlice.reducer;
