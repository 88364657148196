import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import { NumericFormat } from 'react-number-format';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(() => ({
    label: { backgroundColor: '#fff', paddingRight: 5 },
}));
export const CurrencyField = ({ fieldLabel, required = false, style = {}, disabled = false, allowNegative = false, name, value, onValueChange, error = '', isTouched = false, onBlur, }) => {
    const { classes } = useStyles();
    return (_jsx(_Fragment, { children: _jsx(NumericFormat, { onBlur: onBlur, name: name, error: isTouched && error !== '', value: value, fullWidth: true, style: Object.assign({}, style), disabled: disabled, customInput: TextField, variant: "outlined", margin: "dense", label: fieldLabel, InputProps: {
                startAdornment: _jsx(InputAdornment, { position: "start", children: "\u20AC" }),
            }, helperText: isTouched && error !== '' ? error : undefined, InputLabelProps: {
                error: isTouched && error !== '',
                required: required,
                classes: {
                    root: classes.label,
                },
            }, thousandSeparator: ".", allowNegative: allowNegative, decimalSeparator: ",", onValueChange: onValueChange }) }));
};
