import { either } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { date, NonEmptyString, readonlyNonEmptyArray } from 'io-ts-types';
export const PassiveInvoiceInputCodec = t.type({
    month: date,
    hasEnasarco: t.union([t.literal('si'), t.literal('no')]),
    userId: t.union([t.number, NonEmptyString]),
    userFullName: NonEmptyString,
    items: readonlyNonEmptyArray(t.type({
        description: NonEmptyString,
        amount: t.union([t.number, NonEmptyString]),
    }, 'PassiveInvoiceInputItem'), 'PassiveInvoiceInputItems'),
}, 'PassiveInvoiceInput');
const isPassiveInvoiceInput = (u) => pipe(u, PassiveInvoiceInputCodec.decode, either.isRight);
export const PassiveInvoiceInputUtils = { isPassiveInvoiceInput };
