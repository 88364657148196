import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Grid, TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { GoogleLogin } from '@react-oauth/google';
import { isSome } from 'fp-ts/Option';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import WarningIcon from '../../../assets/icons/redTriangle.svg';
import { SESSION_RESPONSE_ERROR } from '../../utils/session';
import { DevLogin } from '../DevLogin/DevLoginComponent';
import { SquareLogoWithText } from '../Topbar/Logo/LogoComponent';
import { useStyles } from './Login.styles';
import { withPageLoader } from './WithPageLoader';
const ErrorLabel = ({ value }) => {
    const { classes } = useStyles();
    return (_jsx(TextField, { multiline: true, classes: { root: classes.textfield }, InputProps: {
            startAdornment: (_jsx(InputAdornment, { position: "start", style: { marginTop: 0 }, children: _jsx(WarningIcon, {}) })),
        }, error: true, value: value, variant: "filled", disabled: true }));
};
const onSuccess = (submitCredentials, setGoogleError, location) => (credentialResponse) => {
    if (credentialResponse.credential === undefined) {
        return;
    }
    setGoogleError(false);
    submitCredentials({
        credentials: { provider: 'google', token: credentialResponse.credential },
        location: location,
    });
};
const LoginComponent = ({ submitCredentials, error }) => {
    const location = useLocation();
    const [googleError, setGoogleError] = useState(false);
    const { classes } = useStyles();
    return (_jsxs(_Fragment, { children: [process.env.REACT_APP_LEGACY_LOGIN === 'true' && _jsx(DevLogin, { submitCredentials: submitCredentials }), _jsx(Box, { className: classes.wrapper, children: _jsx(Box, { className: classes.box, children: _jsxs(Grid, { container: true, className: classes.container, justifyContent: "center", children: [_jsx(Grid, { container: true, item: true, xs: 12, justifyContent: "center", children: _jsx(SquareLogoWithText, {}) }), googleError && _jsx(ErrorLabel, { value: SESSION_RESPONSE_ERROR.GENERIC }), !googleError && isSome(error) && _jsx(ErrorLabel, { value: error.value }), _jsx(Grid, { container: true, item: true, xs: 12, justifyContent: "center", children: _jsx(GoogleLogin, { onSuccess: onSuccess(submitCredentials, setGoogleError, location), onError: () => setGoogleError(true), ux_mode: "popup", theme: "filled_blue", size: "large", locale: "it_IT", use_fedcm_for_prompt: false }) })] }) }) })] }));
};
export const Login = withPageLoader(LoginComponent);
