import { createSlice } from '@reduxjs/toolkit';
import { constant, identity } from 'fp-ts/function';
const initialState = {
    loading: { requestWithQuotes: false, updateRequest: false },
    quotes: null,
};
export const requestWithQuotesSlice = createSlice({
    name: 'RequestWithQuotes',
    initialState,
    reducers: {
        start: (state, _) => state,
        stop: identity,
        Stopped: constant(initialState),
        Started: identity,
        updateRequest: (state, _) => state,
        UpdateRequestRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { updateRequest: true }) })),
        RequestUpdated: (state, event) => (Object.assign(Object.assign({}, state), { quotes: state.quotes ? Object.assign(Object.assign({}, state.quotes), { notes: event.payload.notes }) : null, loading: Object.assign(Object.assign({}, state.loading), { updateRequest: false }) })),
        RequestNotUpdated: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { updateRequest: false }) })),
        RequestWithQuotesRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { requestWithQuotes: true }) })),
        RequestWithQuotesFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { requestWithQuotes: false }), quotes: event.payload.quotes })),
        RequestWithQuotesFailed: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { requestWithQuotes: false }) })),
    },
});
export const $RequestWithQuotes = requestWithQuotesSlice.actions;
export const $RequestWithQuotesSlice = {
    selector: {
        requestWithQuotes: (state) => state.requestWithQuotes.quotes,
        isRequestUpdating: (state) => state.requestWithQuotes.loading.updateRequest,
    },
};
export const RequestWithQuotesState = requestWithQuotesSlice.reducer;
