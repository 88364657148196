import { ArtooEnvironment } from './ArtooEnvironment';
import { BazaarEnvironment } from './BazaarEnvironment';
export class EnvironmentFactory {
    constructor(sessionStorage) {
        this.sessionStorage = sessionStorage;
    }
    createArtoo() {
        return new ArtooEnvironment(this.sessionStorage);
    }
    createBazaar() {
        return new BazaarEnvironment(this.sessionStorage);
    }
}
