import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Container, Grid, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import { useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { $ProcessingQuotes } from '../state/ProcessingQuotesState';
import { Filter } from './Filter';
import { LeadTable } from './LeadTable';
const useStyles = makeStyles()(theme => ({
    sticky: {
        background: theme.palette.common.white,
        position: 'sticky',
        top: 0,
        zIndex: 1,
        height: 80,
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(5),
    },
    mb4: { marginBottom: theme.spacing(4) },
    zIndex2: { zIndex: 2 },
    container: {
        paddingTop: '26px',
        backgroundColor: '#fff',
    },
    filter: {
        marginBottom: '36px',
    },
}));
export const ProcessingQuotesLayout = () => {
    const { classes } = useStyles();
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch($ProcessingQuotes.start());
        return () => {
            dispatch($ProcessingQuotes.stop());
        };
    }, [dispatch]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: clsx(classes.sticky, classes.mb4, classes.zIndex2), children: _jsx(Typography, { variant: "h1", children: "Lista clienti" }) }), _jsxs(Container, { className: classes.container, component: Paper, children: [_jsx(Grid, { container: true, item: true, justifyContent: "center", className: classes.filter, children: _jsx(Filter, {}) }), _jsx(LeadTable, {})] })] }));
};
