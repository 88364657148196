import * as t from 'io-ts';
import { MatchC } from './Match';
export const RouteC = t.intersection([
    t.type({
        history: t.unknown,
        location: t.unknown,
        match: MatchC,
    }),
    t.partial({ staticContext: t.unknown }),
], 'Route');
