import { eq, string } from 'fp-ts';
import * as t from 'io-ts';
export const LeadCodec = t.type({
    firstName: t.string,
    lastName: t.string,
    email: t.string,
    channel: t.string,
    phoneNumber: t.string,
    id: t.string,
});
const Eq = eq.struct({
    firstName: string.Eq,
    lastName: string.Eq,
    email: string.Eq,
    channel: string.Eq,
    phoneNumber: string.Eq,
});
export const LeadUtils = { Eq };
