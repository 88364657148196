import { taskEither, either } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import qs from 'qs';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { PooledClient, HttpClientPool } from '../../lib/http/PooledClient';
import { EnasarcoCodec } from '../models/Enasarco';
export const getUserEnasarco = (id, filter = {}) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get(`${BAZAAR_URL}/api/v1/users/${id}/overview?${qs.stringify(filter)}`), e => e), taskEither.chainEitherKW(response => pipe(response.data, t.type({ data: EnasarcoCodec }).decode, either.map(({ data }) => data))));
