import * as t from 'io-ts';
import { DomainC } from './Domain';
export const LeadC = t.intersection([
    t.type({
        source: DomainC,
        first_name: t.string,
        last_name: t.string,
    }),
    t.partial({
        mobile_phone: t.string,
    }),
], 'Lead');
