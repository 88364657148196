import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import DrawOutlinedIcon from '@mui/icons-material/DrawOutlined';
import { Box, Button, Grid, Link, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { toggle } from '../../../common/state/toggle';
import { Prompt } from '../../../ui/Prompt/PromptComponent';
import { Signers } from '../organisms/Signers';
export const CancelDigitalSignature = ({ isDisabled = false, onClick }) => {
    const [showSignatureModal, setShowSignatureModal] = useState(false);
    return (_jsxs(_Fragment, { children: [showSignatureModal && (_jsx(Prompt, { isOpen: true, title: "Recupera link per la firma digitale", onCancelText: "Chiudi", onCancelAction: toggle(setShowSignatureModal), children: _jsx(Signers, {}) })), _jsx(Box, { component: Paper, sx: { p: '16px 32px', border: '1px solid #F2F2F2' }, elevation: 0, children: _jsxs(Grid, { container: true, justifyContent: "space-between", alignItems: "center", flexWrap: "nowrap", children: [_jsxs(Grid, { item: true, children: [_jsx(Typography, { fontWeight: 500, children: "Hai richiesto la firma digitale" }), _jsx(Link, { variant: "body1", underline: "none", onClick: toggle(setShowSignatureModal), children: "Non hai ricevuto l'e-mail con la firma digitale?" })] }), _jsx(Grid, { item: true, children: _jsx(Button, { onClick: onClick, disabled: isDisabled, variant: "contained", startIcon: _jsx(DrawOutlinedIcon, {}), children: "Revoca firma" }) })] }) })] }));
};
