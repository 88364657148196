import { array, readonlyArray, taskEither } from 'fp-ts';
import { identity, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import qs from 'qs';
import { AxiosClient } from '../../../common/clients/AxiosClient';
import { leadModelMapper } from './mappers/leadModelMapper';
import { requestModelMapper } from './mappers/requestModelMapper';
import { requestWithQuotesModelMapper } from './mappers/requestWithQuotesModelMapper';
import { lead } from './models/Lead';
import { request, requestWithQuotes } from './models/RequestWithQuotes';
export class BazaarClient extends AxiosClient {
    paginate(paginationDto) {
        return { page: { size: paginationDto.perPage, offset: paginationDto.skip } };
    }
    sanitizeFilter(filter) {
        const filterWithValidValue = {};
        for (const [filterStateKey, filterStateValue] of Object.entries(filter)) {
            if (typeof filterStateValue === 'string' && filterStateValue !== '') {
                filterWithValidValue[filterStateKey] = filterStateValue;
            }
        }
        return { filter: Object.assign({}, filterWithValidValue) };
    }
    getLeads({ pagination, filter, token }) {
        const page = this.paginate(pagination);
        const filterQueryParams = filter ? this.sanitizeFilter(filter) : {};
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/leads?sort=-registeredAt&${qs.stringify(Object.assign(Object.assign({}, page), filterQueryParams))}`, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.readonlyArray(lead).decode), taskEither.map(leads => pipe(leads, readonlyArray.map(leadModelMapper), readonlyArray.toArray)));
    }
    getQuoteRequestsByLeadId({ leadId, token }) {
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/mortgage/requests?${qs.stringify({ filter: { leadId: leadId } })}`, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(t.array(request).decode), taskEither.map(array.map(requestModelMapper)));
    }
    getRequestWithQuotesByQuoteRequestId({ quoteRequestId, token }) {
        return pipe(taskEither.tryCatch(() => this.http.get(`/api/v1/mortgage/requests/${quoteRequestId}`, {
            headers: this.addBearerAuthorization(token),
        }), identity), taskEither.map(response => response.data), taskEither.chainEitherKW(requestWithQuotes.decode), taskEither.map(requestWithQuotesModelMapper));
    }
    deleteLead({ leadId, token }) {
        return pipe(taskEither.tryCatch(() => this.http.delete(`/api/v1/leads/${leadId}`, {
            headers: this.addBearerAuthorization(token),
        }), identity));
    }
    updateRequest({ payload, requestId, token }) {
        return pipe(taskEither.tryCatch(() => this.http.patch(`/api/v1/mortgage/requests/${requestId}`, payload, {
            headers: this.addBearerAuthorization(token),
        }), identity));
    }
    deleteRequest({ requestId, token }) {
        return pipe(taskEither.tryCatch(() => this.http.delete(`/api/v1/mortgage/requests/${requestId}`, {
            headers: this.addBearerAuthorization(token),
        }), identity));
    }
}
