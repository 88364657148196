export const channelStateMapper = (channelModel) => {
    let value = 'fmp';
    switch (channelModel) {
        case 'Segnalazione direzione FMP':
        case 'Mutuo Facile':
            value = 'sd_fmp';
            break;
        case 'Accordi immobiliari prequalifica FMP':
            value = 'aip';
            break;
        case 'Mio cliente':
        case 'Ex Siseco':
        case 'Passaparola':
        case 'Amico/Parente collaboratore':
            value = 'propria';
            break;
        default:
            value = 'fmp';
    }
    return { value, label: channelModel };
};
