import * as t from 'io-ts';
import { Prism } from 'monocle-ts';
export const SESSION_ERROR = {
    ACCOUNT_DISABLED: 'account is disabled.',
    UNAUTHORIZED: 'unauthorized',
    INVALID_CREDENTIALS: 'invalid credentials.',
    AUTHENTICATION_CREDENTIALS: 'authentication credentials could not be found.',
    USERNAME_NOT_FOUND: 'username could not be found.',
    EXPIRED_CREDENTIALS: 'credentials have expired.',
    NETWORK_ERROR: 'network error',
    NETWORK_ERROR2: 'network_error',
};
export const SESSION_RESPONSE_ERROR = {
    RETRY_LOGIN: "Sessione scaduta. Effettua di nuovo l'accesso",
    GENERIC: 'Si è verificato un errore. Riprova',
};
export const prismRole = (roles) => Prism.fromPredicate(user => roles.reduce((acc, val) => acc || user.roles.indexOf(val) >= 0, false));
const fromError = (text) => {
    switch (text.toLowerCase()) {
        case SESSION_ERROR.EXPIRED_CREDENTIALS:
            return SESSION_RESPONSE_ERROR.RETRY_LOGIN;
        case SESSION_ERROR.INVALID_CREDENTIALS:
        case SESSION_ERROR.AUTHENTICATION_CREDENTIALS:
        case SESSION_ERROR.USERNAME_NOT_FOUND:
        case SESSION_ERROR.NETWORK_ERROR2:
        case SESSION_ERROR.NETWORK_ERROR:
        case SESSION_ERROR.UNAUTHORIZED:
        case SESSION_ERROR.ACCOUNT_DISABLED:
            return SESSION_RESPONSE_ERROR.GENERIC;
        default:
            return SESSION_RESPONSE_ERROR.GENERIC;
    }
};
const matchRole = (roles) => (user) => {
    return Object.keys(roles).reduce((result, userRole) => '' !== result || user.roles.indexOf(userRole) < 0 ? result : roles[userRole], '');
};
const userDataValue = (user) => (userDataKey) => {
    let maybeUserDataValue;
    if (t.undefined.is(user.data)) {
        maybeUserDataValue = '';
    }
    else if (t.array(t.unknown).is(user.data)) {
        maybeUserDataValue = '';
    }
    else {
        maybeUserDataValue = user.data[userDataKey];
    }
    return t.string.is(maybeUserDataValue) ? maybeUserDataValue : '';
};
export const session = {
    fromError,
    matchRole,
    userDataValue,
    role: { match: { any: prismRole } },
};
