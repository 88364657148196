import { all, put, takeLatest } from 'typed-redux-saga';
import { $RecordListWithCommissions } from '../../recordListWithCommissions/RecordListWithCommissionsState';
import { $InvoiceFromRecordTab } from './InvoiceFromRecordTabState';
function* endRecordListWithCommissions() {
    yield* put($RecordListWithCommissions.stop());
}
function* startRecordListWithCommissions() {
    yield* put($RecordListWithCommissions.start());
}
export function* InvoiceFromRecordTabSaga() {
    yield* all([
        yield* takeLatest($InvoiceFromRecordTab.startInvoiceFromRecord.type, startRecordListWithCommissions),
        yield* takeLatest($InvoiceFromRecordTab.endInvoiceFromRecord.type, endRecordListWithCommissions),
    ]);
}
