import { createSlice } from '@reduxjs/toolkit';
import { getYear } from 'date-fns';
import { option, readonlyArray } from 'fp-ts';
import { constant, identity, pipe } from 'fp-ts/function';
import { numericFormatter } from 'react-number-format';
const initialState = {
    initialValues: null,
    loading: false,
};
const initialFormState = { formattedCeilingAmount: '', rawCeilingAmount: '' };
const getCurrentCeiling = (ceilings) => {
    const currentYear = getYear(new Date());
    return pipe(ceilings, readonlyArray.findFirst(({ year }) => year === currentYear), option.map(({ amount }) => amount));
};
export const enasarcoSlice = createSlice({
    name: 'Enasarco',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        addCeiling: (state, _) => state,
        CeilingsRequested: state => (Object.assign(Object.assign({}, state), { loading: true })),
        CeilingsNotFetched: state => (Object.assign(Object.assign({}, state), { initialValues: initialFormState, loading: false })),
        CeilingsFound: (state, event) => {
            const currentEnasarcoOption = getCurrentCeiling(event.payload);
            return option.isSome(currentEnasarcoOption)
                ? Object.assign(Object.assign({}, state), { initialValues: {
                        rawCeilingAmount: currentEnasarcoOption.value,
                        formattedCeilingAmount: numericFormatter(currentEnasarcoOption.value.toString(), {
                            thousandSeparator: '.',
                            allowNegative: false,
                            decimalScale: 2,
                            fixedDecimalScale: true,
                            decimalSeparator: ',',
                        }),
                    }, loading: false }) : Object.assign(Object.assign({}, state), { initialValues: initialFormState, loading: false });
        },
        CeilingAdditionRequested: state => (Object.assign(Object.assign({}, state), { loading: true })),
        CeilingAdded: state => (Object.assign(Object.assign({}, state), { loading: false })),
        CeilingNotAdded: state => (Object.assign(Object.assign({}, state), { loading: false })),
        Stopped: constant(initialState),
        Started: identity,
    },
});
export const $Enasarco = enasarcoSlice.actions;
export const $EnasarcoSlice = {
    selector: {
        loading: (state) => state.enasarco.loading,
        initialValues: (state) => state.enasarco.initialValues,
    },
};
export const EnasarcoState = enasarcoSlice.reducer;
