export const rateView = (rate) => {
    switch (rate) {
        case 'capped':
            return 'cap';
        case 'fixed':
            return 'fisso';
        case 'floating':
            return 'variabile';
        case 'mixed':
            return 'misto';
    }
};
