import { createSlice } from '@reduxjs/toolkit';
import { option, readonlyArray, readonlyRecord } from 'fp-ts';
import { constant, identity, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { $date } from '../../models/Date';
import { RecordDataInstituteUtils } from '../../models/RecordDataInstitute';
const initialState = {
    loading: false,
    error: option.none,
    table: { body: [], headers: [] },
    initialValues: { firstName: '', lastName: '' },
};
const userFullName = (record) => pipe(record.data, readonlyRecord.lookup('nome_e_cognome'), option.filter(t.string.is), option.getOrElse(constant('')));
const emittedDate = (record) => pipe(record.data, readonlyRecord.lookup('data_erogazione_finanziamento'), option.filter(t.string.is), option.chain($date.dayMonthYear), option.getOrElse(constant('')));
export const RecordListWithPayinSlice = createSlice({
    name: 'RecordListWithPayinSaga',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        Started: identity,
        Stopped: constant(initialState),
        fetchRecordList: (state, _) => state,
        RecordListRequested: state => (Object.assign(Object.assign({}, state), { error: option.none, loading: true, table: initialState.table })),
        RecordListFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: false, error: option.none, table: pipe(event.payload, readonlyArray.map(record => ({
                id: record.id,
                userFullName: userFullName(record),
                productType: record.data.tipo_di_prodotto,
                institute: RecordDataInstituteUtils.institute(record),
                emittedDate: emittedDate(record),
            })), tableBody => ({
                body: tableBody,
                headers: [
                    {
                        id: 'ID pratica',
                        userFullName: 'Cliente',
                        productType: 'Prodotto',
                        institute: 'Istituto',
                        emittedDate: 'Data erogazione',
                    },
                ],
            })) })),
        RecordListNotFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: false, error: option.some(event.payload), table: initialState.table })),
    },
});
export const $RecordListWithPayin = RecordListWithPayinSlice.actions;
const initialValues = (state) => state.recordListWithPayin.initialValues;
const isLoading = (state) => state.recordListWithPayin.loading;
const table = (state) => state.recordListWithPayin.table;
export const $RecordListWithPayinSlice = {
    selector: { initialValues, isLoading, table },
};
export default RecordListWithPayinSlice.reducer;
