import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(() => ({
    label: {
        paddingRight: '10px',
        backgroundColor: 'white',
    },
}));
export const AutocompleteField = ({ isDisabled = false, isLoading = false, error = '', freeSolo = false, options, value, inputValue, setFieldValue, label, name, inputName, onBlur, onInputChange, isOptionEqualToValue, getOptionLabel, noOptionsText, required = false, isTouched = false, }) => {
    const { classes } = useStyles();
    return (_jsx(Autocomplete, { freeSolo: freeSolo, forcePopupIcon: true, disabled: isDisabled, getOptionLabel: (option) => (typeof option === 'string' ? option : getOptionLabel(option)), isOptionEqualToValue: isOptionEqualToValue, disableClearable: true, loading: isLoading, loadingText: "Caricamento", filterOptions: x => x, options: options, noOptionsText: noOptionsText, value: value, inputValue: inputValue, onChange: (_, newValue) => {
            if (newValue !== null) {
                setFieldValue(name, newValue);
            }
        }, onInputChange: (_, newInputValue) => {
            if (!onInputChange) {
                return;
            }
            setFieldValue(inputName, newInputValue);
            onInputChange(newInputValue);
        }, renderInput: params => (_jsx(TextField, Object.assign({}, params, { onBlur: onBlur, error: isTouched && error !== '', helperText: isTouched && error !== '' ? error : undefined, required: required, name: name, InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(_Fragment, { children: [isLoading ? _jsx(CircularProgress, { color: "inherit", size: 20 }) : null, params.InputProps.endAdornment] })) }), variant: "outlined", label: label, InputLabelProps: { className: classes.label }, fullWidth: true }))) }));
};
