import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AddIcon from '@mui/icons-material/Add';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { $RecordListWithCommissions, $recordListWithCommissionsSlice, } from '../state/recordListWithCommissions/RecordListWithCommissionsState';
import { $InvoiceFromRecordTab } from '../state/tabs/invoiceFromRecordTab/InvoiceFromRecordTabState';
import { MpnDialog } from './MpnDialog';
import { RecordList } from './RecordList';
const onSubmit = (dispatch, fetchRecordWithCommissions) => (formData) => {
    dispatch(fetchRecordWithCommissions({
        formData: formData,
    }));
};
const onInputChange = (dispatch, userListFetchAttemptAction) => (_, userValue) => {
    if (userValue.length > 2) {
        dispatch(userListFetchAttemptAction({ params: { fullName: userValue } }));
    }
};
const handleAutocompleteChange = (setFieldValue) => (_, assignee) => {
    if (!assignee) {
        return;
    }
    setFieldValue('userFullName', assignee.fullName);
    setFieldValue('userId', assignee.id);
};
const onClick = (setMpnDialogOpen) => () => setMpnDialogOpen(prevState => !prevState);
export const RecordPanel = () => {
    const dispatch = useAppDispatch();
    const [mpnDialogOpen, setMpnDialogOpen] = useState(false);
    const itemsToTransmit = useAppSelector($recordListWithCommissionsSlice.selector.itemsToTransmit);
    const transmittedItems = useAppSelector($recordListWithCommissionsSlice.selector.transmittedItems);
    const isLoading = useAppSelector($recordListWithCommissionsSlice.selector.isLoading);
    const initialValues = useAppSelector($recordListWithCommissionsSlice.selector.filterInitialValues);
    const userList = useAppSelector($recordListWithCommissionsSlice.selector.assignees);
    const isUserListLoading = useAppSelector($recordListWithCommissionsSlice.selector.isUserListLoading);
    const userListError = useAppSelector($recordListWithCommissionsSlice.selector.assigneeError);
    useEffect(() => {
        dispatch($InvoiceFromRecordTab.startInvoiceFromRecord());
        return () => {
            dispatch($InvoiceFromRecordTab.endInvoiceFromRecord());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(Box, { style: { marginBottom: 77 }, children: [mpnDialogOpen && _jsx(MpnDialog, { open: mpnDialogOpen, onClose: onClick(setMpnDialogOpen) }), _jsx(Grid, { children: _jsx(Formik, { initialValues: initialValues, enableReinitialize: true, onSubmit: onSubmit(dispatch, $RecordListWithCommissions.fetchRecordWithCommissions), children: ({ values, handleChange, setFieldValue }) => (_jsx(Form, { children: _jsxs(Grid, { container: true, wrap: "nowrap", children: [_jsx(Grid, { item: true, xs: 3, children: _jsx(Autocomplete, { size: "small", style: { marginRight: 16 }, loading: isUserListLoading, loadingText: "Caricamento...", autoSelect: true, disableClearable: true, options: userList, getOptionLabel: option => option.fullName, onInputChange: onInputChange(dispatch, $RecordListWithCommissions.fetchUserList), isOptionEqualToValue: (option, value) => option.id === value.id, onChange: handleAutocompleteChange(setFieldValue), renderInput: params => (_jsx(TextField, Object.assign({}, params, { required: true, error: userListError, variant: "outlined", style: {
                                                backgroundColor: 'white',
                                            }, value: values.userFullName, onChange: handleChange, name: "userFullName", label: "Collaboratore", inputProps: Object.assign({}, params.inputProps) }))) }) }), _jsx(Grid, { item: true, children: _jsx(Button, { disabled: isLoading, type: "submit", variant: "contained", size: "small", children: "Cerca" }) })] }) })) }) }), isLoading ? (_jsx(Grid, { children: _jsx(Grid, { style: { display: 'flex', justifyContent: 'center' }, children: _jsx(CircularProgress, {}) }) })) : (_jsxs(_Fragment, { children: [_jsxs(Grid, { children: [_jsx(Typography, { style: { marginTop: 40, marginBottom: 20 }, children: "Pratiche fatturabili" }), _jsx(RecordList, { items: itemsToTransmit, emptyListText: "Non sono presenti pratiche fatturabili." })] }), _jsxs(Grid, { children: [_jsx(Typography, { style: { marginTop: 40, marginBottom: 20 }, children: "Pratiche fatturate" }), _jsx(RecordList, { items: transmittedItems, emptyListText: "Non sono presenti pratiche fatturate." })] })] })), _jsx(Grid, { container: true, style: {
                    paddingBottom: 20,
                    paddingTop: 20,
                    position: 'fixed',
                    height: 77,
                    bottom: 0,
                }, children: _jsx(Button, { variant: "contained", startIcon: _jsx(AddIcon, {}), onClick: onClick(setMpnDialogOpen), children: "Crea MPN" }) })] }));
};
