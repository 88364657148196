import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Grid } from '@mui/material';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $PaperworkProcessSlice } from '../../state/PaperworkProcessState';
import { Loader } from '../atoms/Loader';
import { DocumentationCard } from '../organisms/DocumentationCard';
import { Signature } from '../organisms/Signature';
export const DocumentationPage = () => {
    const isPageLoading = useAppSelector($PaperworkProcessSlice.selector.isPageLoading);
    const documentSection = useAppSelector($PaperworkProcessSlice.selector.documentSection);
    const isDigitalSignRequested = useAppSelector($PaperworkProcessSlice.selector.isDigitalSignRequested);
    const isHandwrittenSignRequested = useAppSelector($PaperworkProcessSlice.selector.isHandwrittenSignRequested);
    return (_jsxs(_Fragment, { children: [isPageLoading && _jsx(Loader, {}), isDigitalSignRequested !== null && isHandwrittenSignRequested !== null && (_jsx(Grid, { sx: { mb: '16px' }, children: _jsx(Signature, { isDigitalSignRequested: isDigitalSignRequested, isHandwrittenSignRequested: isHandwrittenSignRequested }) })), _jsx(DocumentationCard, { title: "Documentazione", documentSection: documentSection })] }));
};
