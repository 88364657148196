var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { $number } from 'fortepiano';
import { option } from 'fp-ts';
import { getOrElse, none, some } from 'fp-ts/Option';
import { not } from 'fp-ts/Predicate';
import { constNull, pipe } from 'fp-ts/function';
import React from 'react';
const LOCALE_DEFAULT = 'en-US';
const currentLocale = () => {
    return null !== Intl && 'object' === typeof Intl && 'function' === typeof Intl.NumberFormat
        ? some(Intl.NumberFormat().resolvedOptions().locale)
        : none;
};
const round = (digits) => (reactNode) => {
    if ('number' !== typeof reactNode && 'string' !== typeof reactNode) {
        return option.none;
    }
    return pipe(reactNode, Number, option.of, option.filter(not(isNaN)), option.map($number.round(digits)));
};
const format = (locale, digits, _a) => {
    var { showSymbol = true } = _a, options = __rest(_a, ["showSymbol"]);
    return null !== Intl && 'object' === typeof Intl && 'function' === typeof Intl.NumberFormat
        ? new Intl.NumberFormat(locale, Object.assign({ style: showSymbol ? 'currency' : 'decimal', minimumFractionDigits: digits, maximumFractionDigits: digits }, options)).format
        : (data) => (showSymbol ? `${options.currency} ${data}` : `${data}`);
};
export const Currency = (_a) => {
    var { children: data, locale, digits = 2 } = _a, options = __rest(_a, ["children", "locale", "digits"]);
    const selectedLocale = 'string' === typeof locale && '' !== locale
        ? locale
        : pipe(currentLocale(), getOrElse(() => LOCALE_DEFAULT));
    return pipe(data, round(2), option.match(constNull, value => _jsx(_Fragment, { children: format(selectedLocale, digits, options)(value) })));
};
export const $currency = { round };
