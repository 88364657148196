import { jsx as _jsx } from "react/jsx-runtime";
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { $PaperworkProcess } from '../../state/PaperworkProcessState';
import { AutocompleteField } from '../atoms/AutocompleteField';
const onInputChange = (stores, dispatch) => (value) => {
    if (stores.find(store => store === value)) {
        return;
    }
    return dispatch($PaperworkProcess.fetchStores({ search: value }));
};
export const StoreField = ({ onBlur, stores, isLoading, inputValue, setFieldValue, value, error = '', isTouched, }) => {
    const dispatch = useAppDispatch();
    return (_jsx(AutocompleteField, { name: "store", inputName: "storeInput", label: "Store", noOptionsText: "Nessuno store disponibile", options: stores, setFieldValue: setFieldValue, isOptionEqualToValue: (option1, currentValue) => option1 === currentValue, getOptionLabel: store => store, onInputChange: onInputChange(stores, dispatch), value: value, inputValue: inputValue, onBlur: onBlur, isLoading: isLoading, isTouched: isTouched, error: error }));
};
