import { createSlice } from '@reduxjs/toolkit';
import { constant, identity } from 'fp-ts/function';
const initialState = {
    loading: false,
};
export const invoiceToTransmitTabSlice = createSlice({
    name: 'InvoiceToTransmitSaga',
    initialState,
    reducers: {
        startInvoiceToTransmitTab: identity,
        endInvoiceToTransmitTab: constant(initialState),
    },
});
export const $InvoiceToTransmitTab = invoiceToTransmitTabSlice.actions;
export const $invoiceToTransmitTabSlice = {
    selector: {},
};
export default invoiceToTransmitTabSlice.reducer;
