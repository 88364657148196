export const IteratorResult = {
    fold: (onReturn, onYield) => (result) => {
        switch (result.done) {
            case undefined:
            case false:
                return onYield(result.value);
            case true:
                return onReturn(result.value);
        }
    },
};
