import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Card, CardContent, CardHeader, Divider } from '@mui/material';
import { Badge } from '../atoms/Badge';
import { DocumentationItem } from './DocumentionItem';
export const DocumentationCard = ({ documentSection, title }) => {
    const badgeTitle = documentSection.mandatoryDocuments === 1
        ? `${documentSection.uploadedDocuments}/1 documento obbligatorio`
        : `${documentSection.uploadedDocuments}/${documentSection.mandatoryDocuments} documenti obbligatori`;
    return (_jsxs(Card, { elevation: 0, children: [_jsx(CardHeader, { titleTypographyProps: { variant: 'h3' }, title: title }), _jsxs(Box, { sx: { mr: '16px', ml: '16px' }, children: [_jsx(Box, { sx: { mb: '8px' }, children: _jsx(Badge, { variant: "light2", title: badgeTitle, fontWeight: "bold" }) }), _jsx(Divider, {})] }), _jsx(CardContent, { children: documentSection.documentTypes.map((documentType, key) => (_jsx(Box, { sx: { mb: '8px' }, children: _jsx(DocumentationItem, { title: `${documentType.name} ${documentType.required ? '*' : ''}`, documentTypeId: documentType.id, canUpload: documentType.toBeUploaded }) }, key))) })] }));
};
