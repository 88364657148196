import { createSlice } from '@reduxjs/toolkit';
import { identity } from 'fp-ts/function';
const initialState = {
    initialValues: { month: null, hasEnasarco: null },
    isUpdating: false,
};
export const passiveInvoiceBulkEditSlice = createSlice({
    name: 'PassiveInvoiceBulkEditSaga',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        updateInvoices: (state, _) => state,
        InvoiceListUpdateRequested: state => (Object.assign(Object.assign({}, state), { isUpdating: true })),
        InvoiceListUpdated: state => (Object.assign(Object.assign({}, state), { isUpdating: false })),
        InvoiceListNotUpdated: state => (Object.assign(Object.assign({}, state), { isUpdating: false })),
        Started: identity,
        Stopped: identity,
    },
});
export const $PassiveInvoiceBulkEditActions = passiveInvoiceBulkEditSlice.actions;
export const $passiveInvoiceBulkEditSlice = {
    selector: {
        initialValues: (state) => state.passiveInvoiceBulkEdit.initialValues,
        isUpdating: (state) => state.passiveInvoiceBulkEdit.isUpdating,
    },
};
export default passiveInvoiceBulkEditSlice.reducer;
