import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import qs from 'qs';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
import { LeadCodec } from '../models/Lead.codec';
const getLead = (search) => ({
    email: search.email,
    lastName: search.lastName,
    phoneNumber: search.phoneNumber,
});
export const getLeads = (search) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get(`${BAZAAR_URL}/api/v1/leads? ${qs.stringify({ filter: Object.assign({}, getLead(search)) })}`), e => e), taskEither.chainEitherKW(response => t.array(LeadCodec).decode(response.data)));
