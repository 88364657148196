import { createSlice } from '@reduxjs/toolkit';
import { option } from 'fp-ts';
import { constant, identity } from 'fp-ts/function';
const initialState = {
    loading: false,
    error: option.none,
};
export const invoiceTransmissionSlice = createSlice({
    name: 'InvoiceTransmissionSaga',
    initialState,
    reducers: {
        startInvoiceTransmission: identity,
        endInvoiceTransmission: constant(initialState),
        transmitInvoice: (state, _) => state,
        transmitInvoiceBulk: identity,
        InvoiceTransmissionRequested: state => (Object.assign(Object.assign({}, state), { loading: true, error: option.none })),
        InvoiceTransmitted: state => (Object.assign(Object.assign({}, state), { loading: false, error: option.none })),
        InvoiceNotTransmitted: (state, action) => (Object.assign(Object.assign({}, state), { loading: false, error: option.some(action.payload) })),
    },
});
const isLoading = (state) => state.invoiceTransmission.loading;
export const $InvoiceTransmission = invoiceTransmissionSlice.actions;
export const $invoiceTransmissionSlice = {
    selector: { isLoading },
};
export default invoiceTransmissionSlice.reducer;
