import { Matchable } from '../Matchable';
import { Tagged } from '../Tagged';
const FIRED_ALIAS = 'Running';
const TYPE_SEPARATOR = ':';
export function Process(fired = FIRED_ALIAS) {
    return (name, matches) => {
        const f = (input) => ({
            _tag: fired,
            type: type(name, fired),
            timestamp: Date.now(),
            timestamps: {
                idle: -Infinity,
                running: -Infinity,
                failed: -Infinity,
                done: -Infinity,
                [fired.toLowerCase()]: Date.now(),
            },
            payload: input,
            name,
            input,
            matches: undefined !== matches ? (x, y) => matches(x.input, y.input) : () => true,
        });
        Object.defineProperty(f, 'name', { writable: true });
        f.name = name;
        Object.defineProperty(f, 'name', { writable: false });
        f.type = {
            fired: type(name, fired),
            idle: type(name, 'Idle'),
            running: type(name, 'Running'),
            failed: type(name, 'Failed'),
            done: type(name, 'Done'),
        };
        f.is = {
            idle: (action) => type(name, 'Idle') === action.type,
            running: (action) => type(name, 'Running') === action.type,
            failed: (action) => type(name, 'Failed') === action.type,
            done: (action) => type(name, 'Done') === action.type,
        };
        return f;
    };
}
const type = (name, tag) => `${name}${TYPE_SEPARATOR}${tag}`;
function Running(process) {
    return () => (Object.assign(Object.assign({}, process), { _tag: 'Running', type: type(process.name, 'Running'), timestamp: Date.now(), timestamps: Object.assign(Object.assign({}, process.timestamps), { running: Date.now() }) }));
}
function Failed(process) {
    return (error) => {
        const _error = error instanceof Error
            ? {
                name: error.name,
                message: error.message,
                stack: error.stack,
            }
            : error;
        return Object.assign(Object.assign({}, process), { _tag: 'Failed', type: type(process.name, 'Failed'), timestamp: Date.now(), timestamps: Object.assign(Object.assign({}, process.timestamps), { failed: Date.now() }), payload: _error, error: _error });
    };
}
function Done(process) {
    return (output) => (Object.assign(Object.assign({}, process), { _tag: 'Done', type: type(process.name, 'Done'), timestamp: Date.now(), timestamps: Object.assign(Object.assign({}, process.timestamps), { done: Date.now() }), payload: output, output }));
}
const instance = {
    matches: (x, y) => x.name === y.name && (Matchable.is(x) ? x.matches(x, y) : true),
};
const tags = {
    Idle: null,
    Running: null,
    Failed: null,
    Done: null,
};
const is = (o) => Tagged.is(o) && o._tag in tags;
const refinement = (tag) => (process) => tag === process._tag;
is.idle = (process) => refinement('Idle')(process);
is.running = (process) => refinement('Running')(process);
is.failed = (process) => refinement('Failed')(process);
is.done = (process) => refinement('Done')(process);
Process.matches = instance.matches;
Process.Running = Running;
Process.Failed = Failed;
Process.Done = Done;
Process.is = is;
