import { array } from 'fp-ts/Array';
import { chain, fold, fromOption, left, mapLeft, right } from 'fp-ts/Either';
import { fromNullable } from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { Json } from '../Json';
export const StorageCache = (storage) => ({
    get: key => () => pipe(storage.getItem(key), fromNullable, fromOption(() => ReferenceError(`Cannot find cache item "${key}"`)), mapLeft(array.of), chain(Json.fromString), fold(errors => left(errors), json => right(json))),
    set: (key, value) => () => pipe(Json.toString(value), fold(error => left(error), string => {
        storage.setItem(key, string);
        return right(undefined);
    })),
    removeItem: key => () => pipe(storage.getItem(key), fromNullable, fromOption(() => ReferenceError(`Cannot find cache item "${key}"`)), mapLeft(array.of), chain(Json.fromString), fold(errors => left(errors), () => right(storage.removeItem(key)))),
    clear: () => () => {
        storage.clear();
    },
});
