import axios from 'axios';
import { TOKEN } from '../constants';
import { LocalStorage } from './localStorage';
const methods = ['delete', 'get', 'head', 'patch', 'post', 'put'];
export const request = (client, method) => ({ url, params, headers, data, paramsSerializer }) => {
    // eslint-disable-next-line no-useless-catch
    try {
        const auth = JSON.parse(LocalStorage.getItem(TOKEN));
        return client({
            data,
            headers: auth ? Object.assign(Object.assign({}, headers), { Authorization: `Bearer ${auth}` }) : headers,
            method,
            params,
            url,
            paramsSerializer,
        }).then((response) => {
            const token = response.headers.authorization.split(' ');
            if (token.length > 1) {
                LocalStorage.setItem(TOKEN, JSON.stringify(token[1]));
            }
            return response;
        });
    }
    catch (e) {
        throw e;
    }
};
export const HttpClient = methods.reduce((result, method) => (Object.assign(Object.assign({}, result), { [method]: request(axios, method) })), {});
