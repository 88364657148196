import { readonlyRecord } from 'fp-ts';
import { pipe } from 'fp-ts/function';
export const EMPTY = '---EMPTY---';
export const objectWithValues = (obj) => pipe(obj, readonlyRecord.filter(a => !isEmpty(a)));
export const isObjectEmpty = (obj) => {
    if (!obj) {
        return true;
    }
    return Object.entries(obj).length === 0 && obj.constructor === Object;
};
export const isEmpty = (value) => EMPTY === value || '' === value || null === value || undefined === value;
export const convertToString = (id) => (id ? id : 'none');
