import { jsx as _jsx } from "react/jsx-runtime";
import { Chip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(() => ({
    light: { backgroundColor: '#D9E2FF', color: '#00164D' },
    light2: { backgroundColor: '#FFDF93', color: '#4B3900' },
    grey: { backgroundColor: '#E1E2E5', color: '#00164D' },
    box: {
        paddingRight: '8px',
        paddingLeft: '8px',
    },
}));
export const Badge = ({ title, variant, fontWeight = 'regular' }) => {
    const { classes, cx } = useStyles();
    return (_jsx(Chip, { sx: { fontWeight: fontWeight === 'bold' ? '500' : 'inherit' }, size: "small", label: title, className: cx(classes.box, variant === 'light' && classes.light, variant === 'grey' && classes.grey, variant === 'light2' && classes.light2) }));
};
