import { createSlice } from '@reduxjs/toolkit';
import { option, readonlyArray } from 'fp-ts';
import { constant, identity, pipe } from 'fp-ts/function';
const initialState = {
    loading: { coassigneeList: false, updateCoassignee: false, paperwork: false },
    error: { coassigneeList: false, updateCoassignee: false, paperwork: false },
    initialValues: null,
    coassigneeList: [],
};
const coassignee = (paperworkList) => pipe(paperworkList, readonlyArray.lookup(0), option.map(paperwork => paperwork.coAssignee), option.chain(option.fromNullable), option.match(constant({ coassigneeFullName: '', coassigneeId: '' }), ({ fullName, legacyId }) => ({
    coassigneeFullName: fullName,
    coassigneeId: legacyId.toString(),
})));
export const coassigneeSlice = createSlice({
    name: 'CoassigneeSaga',
    initialState,
    reducers: {
        start: (state, _) => state,
        stop: identity,
        fetchCoassigneeList: (state, _) => state,
        fetchPaperworkById: (state, _) => state,
        CoassigneeListRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { coassigneeList: true }), error: Object.assign(Object.assign({}, state.error), { coassigneeList: false }) })),
        CoassegneeListFetched: (state, event) => (Object.assign(Object.assign({}, state), { coassigneeList: pipe(event.payload, readonlyArray.map(user => ({
                coassigneeFullName: user.fullName,
                coassigneeId: user.id,
            }))), error: Object.assign(Object.assign({}, state.error), { coassigneeList: false }), loading: Object.assign(Object.assign({}, state.loading), { coassigneeList: false }) })),
        CoassigneeListNotFetched: state => (Object.assign(Object.assign({}, state), { error: Object.assign(Object.assign({}, state.error), { coassigneeList: true }), loading: Object.assign(Object.assign({}, state.loading), { coassigneeList: false }) })),
        updateCoassignee: (state, _) => state,
        fetchPaperwork: identity,
        PaperworkRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: true }), error: Object.assign(Object.assign({}, state.error), { paperwork: false }) })),
        PaperwordFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }), error: Object.assign(Object.assign({}, state.error), { paperwork: false }), initialValues: coassignee(event.payload) })),
        PaperworkNotFetched: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperwork: false }), error: Object.assign(Object.assign({}, state.error), { paperwork: true }) })),
        CoassigneeUpdateRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { updateCoassignee: true }), error: Object.assign(Object.assign({}, state.error), { updateCoassignee: false }) })),
        CoassigneeUpdated: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { updateCoassignee: false }), error: Object.assign(Object.assign({}, state.error), { updateCoassignee: false }) })),
        CoassigneeNotUpdated: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { updateCoassignee: false }), error: Object.assign(Object.assign({}, state.error), { updateCoassignee: true }) })),
        Started: identity,
        Stopped: constant(initialState),
    },
});
export const $Coassignee = coassigneeSlice.actions;
export const $coassigneeSlice = {
    coassignee,
    selector: {
        hasCoassigneeListError: (state) => state.coassignee.error.coassigneeList || state.coassignee.error.paperwork,
        isCossigneeListLoading: (state) => state.coassignee.loading.coassigneeList,
        isUpdatingCoassignee: (state) => state.coassignee.loading.updateCoassignee,
        coassigneeList: (state) => state.coassignee.coassigneeList,
        initialValues: (state) => state.coassignee.initialValues,
    },
};
export default coassigneeSlice.reducer;
