import { option, taskEither } from 'fp-ts';
import { not } from 'fp-ts/Predicate';
import { pipe } from 'fp-ts/function';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
export const validate = (paperwork) => {
    const schemaVersionOption = pipe(paperwork.data.attributes.data._schema_version, option.fromNullable, option.map(Number), option.filter(not(isNaN)));
    if (option.isNone(schemaVersionOption)) {
        return taskEither.left(new Error('Invalid schema version provided'));
    }
    return pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).post(`${BAZAAR_URL}/api/v1/schemas/fmp/validate`, {
        data: paperwork.data.attributes.data,
        version: paperwork.data.attributes.data._schema_version,
        context: { recordId: paperwork.data.id },
    }), e => e));
};
