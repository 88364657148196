import { either, taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
import { InvoiceCodec } from '../models/Invoice/Invoice.codec';
export const approveInvoice = (payload) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).post(`${BAZAAR_URL}/api/v1/invoices/${payload.invoiceId}/${payload.invoiceApprovalToSet}`), e => e), taskEither.chainEitherKW(response => pipe(response.data, t.type({ data: InvoiceCodec }).decode, either.map(({ data }) => data))));
