import * as t from 'io-ts';
import { DateFromISOString, readonlyNonEmptyArray } from 'io-ts-types';
import { ActiveInvoiceCustomerCodec } from '../ActiveInvoiceCustomer/ActiveInvoiceCustomer';
import { ActiveInvoiceItemCodec } from '../ActiveInvoiceItem/ActiveInvoiceItem';
import { IdentifierCodec } from '../Identifier/Identifier';
export const ActiveInvoiceCodec = t.type({
    _id: IdentifierCodec,
    customer: ActiveInvoiceCustomerCodec,
    issueDate: DateFromISOString,
    items: readonlyNonEmptyArray(ActiveInvoiceItemCodec, 'ActiveInvoiceItems'),
}, 'ActiveInvoice');
