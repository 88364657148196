import { either } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { all, call, debounce, put, takeLatest } from 'typed-redux-saga';
import { PassiveInvoiceInputCodec } from '../../models/PassiveInvoiceInput/PassiveInvoiceInput';
import { createInvoice } from '../../web/createInvoice';
import { getUserList } from '../../web/getUserList';
import { $InvoiceCreation } from './InvoiceCreationState';
function* refreshUserList(action) {
    yield* put($InvoiceCreation.UserListRequested());
    const users = yield* call(getUserList(action.payload));
    if (either.isLeft(users)) {
        yield* put($InvoiceCreation.UserListNotFetched(users.left));
    }
    else {
        yield* put($InvoiceCreation.UserListFetched(users.right));
    }
}
function* createAttempt(invoiceCreationRequested) {
    yield* put($InvoiceCreation.InvoiceCreationRequested());
    const invoiceFormEither = pipe(invoiceCreationRequested.payload, PassiveInvoiceInputCodec.decode, either.map(passiveInvoiceInput => (Object.assign(Object.assign({}, passiveInvoiceInput), { userId: passiveInvoiceInput.userId.toString(), hasEnasarco: passiveInvoiceInput.hasEnasarco === 'si' }))));
    if (either.isLeft(invoiceFormEither)) {
        yield* put($InvoiceCreation.InvoiceNotCreated(invoiceFormEither.left));
    }
    else {
        const invoiceEither = yield* call(createInvoice(invoiceFormEither.right));
        if (either.isLeft(invoiceEither)) {
            yield* put($InvoiceCreation.InvoiceNotCreated(invoiceEither.left));
        }
        else {
            yield* put($InvoiceCreation.InvoiceCreated());
        }
    }
}
function* invoiceCreation() {
    yield* put($InvoiceCreation.fetchUserList());
    yield* takeLatest($InvoiceCreation.createInvoice, createAttempt);
}
export function* InvoiceCreationSaga() {
    yield* all([
        debounce(1000, $InvoiceCreation.fetchUserList, refreshUserList),
        takeLatest($InvoiceCreation.startInvoiceCreation, invoiceCreation),
    ]);
}
