import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
import { LeadCodec } from '../models/Lead.codec';
const getLeadPayload = (fmpSearch) => {
    return {
        firstName: fmpSearch.firstName,
        lastName: fmpSearch.lastName,
        email: fmpSearch.email,
        phoneNumber: fmpSearch.phoneNumber,
        channel: fmpSearch.channel,
    };
};
export const saveLead = (fmpSearch) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).post(`${BAZAAR_URL}/api/v1/leads`, getLeadPayload(fmpSearch)), e => e), taskEither.chainEitherKW(response => LeadCodec.decode(response.data)));
