import { either, readonlyArray } from 'fp-ts';
import { isLeft } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { nonEmptyArray } from 'io-ts-types';
import { decode } from 'json-api-codec';
import { push } from 'redux-first-history';
import { put } from 'redux-saga/effects';
import { all, call, takeEvery } from 'typed-redux-saga';
import { RECORDS, RECORDS_V1 } from '../../api/constants';
import { Either } from '../../lib/Either';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { ClientError } from '../../lib/http/ClientError';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
import { Process } from '../../lib/redux/Process';
import { Context } from '../../model/Context';
import { RecordC } from '../../model/Record';
import { Notification } from '../../utils/notification';
import { validate } from '../../web/record/validate';
import { PipelinesConfirmRequest } from '../actions/pipelines';
import { NotificationAction } from '../notification/NotificationAction';
import { RecordAction } from './RecordAction';
function* duplicateRecord(action) {
    try {
        const response = yield* call(PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).post, `${RECORDS}/${action.payload}/clones`);
        const record = Either.fromValidation(RecordC.decode(decode(response.data)));
        if (isLeft(record)) {
            throw record.left;
        }
        yield put(Process.Done(action)());
        yield put(NotificationAction.Push(Notification.info('Pratica duplicata correttamente')));
        yield put(push(`/pratica/${record.right.id}`, {
            from: 'activeRecords',
        }));
    }
    catch (e) {
        if (e.response) {
            yield put(Process.Failed(action)(ClientError(new Error('Si è verificato un errore nella duplicazione'), e.response)));
            yield put(NotificationAction.Push(Notification.error('Si è verificato un errore nella duplicazione')));
        }
        else if (e instanceof Array) {
            yield put(Process.Failed(action)(ClientError(new Error('Si è verificato un errore nella duplicazione'))));
            yield put(NotificationAction.Push(Notification.error('Si è verificato un errore nella duplicazione')));
        }
        else {
            yield put(Process.Failed(action)(ClientError(e)));
            yield put(NotificationAction.Push(Notification.error('Si è verificato un errore nella duplicazione')));
        }
    }
}
function* updateRecord(action) {
    const validateEither = yield* call(validate(action.payload.recordData));
    if (either.isLeft(validateEither)) {
        const invalidParamsEither = pipe(validateEither.left, t.type({
            response: t.type({
                data: t.type({
                    'invalid-params': nonEmptyArray(t.type({ name: t.string, reason: t.string })),
                }),
            }),
        }).decode);
        if (either.isLeft(invalidParamsEither)) {
            yield put(Process.Failed(action)(ClientError(new Error(action.payload.onFailMessage))));
            yield put(NotificationAction.Push(Notification.error('Si è verificato un errore')));
        }
        else {
            const invalidParams = pipe(pipe(invalidParamsEither.right.response.data['invalid-params'], readonlyArray.map(({ name }) => name)));
            yield put(Process.Failed(action)(invalidParams));
        }
    }
    else {
        try {
            const response = yield* call(PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).put, `${RECORDS}/${action.payload.recordData.data.id}`, action.payload.recordData);
            const record = Either.fromValidation(RecordC.decode(decode(response.data)));
            if (isLeft(record)) {
                throw record.left;
            }
            yield put(Process.Done(action)(record.right));
            if (action.payload.onSuccessMessage) {
                yield put(NotificationAction.Push(Notification.info(action.payload.onSuccessMessage)));
            }
        }
        catch (e) {
            if (e.response) {
                yield put(Process.Failed(action)(ClientError(new Error(action.payload.onFailMessage), e.response)));
                if (action.payload.onFailMessage) {
                    yield put(NotificationAction.Push(Notification.error(action.payload.onFailMessage)));
                }
            }
            else if (e instanceof Array) {
                yield put(Process.Failed(action)(ClientError(new Error('Si è verificato un errore nella modifica'))));
                if (action.payload.onFailMessage) {
                    yield put(NotificationAction.Push(Notification.error(action.payload.onFailMessage)));
                }
            }
            else {
                yield put(Process.Failed(action)(ClientError(e)));
                if (action.payload.onFailMessage) {
                    yield put(NotificationAction.Push(Notification.error(action.payload.onFailMessage)));
                }
            }
        }
    }
}
function* fetchRecord(action) {
    try {
        const recordId = action.payload.recordId;
        const response = action.payload.pooled
            ? yield* call(PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get, `${RECORDS}/${recordId}`)
            : yield* call(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer').get, `${RECORDS}/${recordId}`);
        const record = Either.fromValidation(RecordC.decode(decode(response.data)));
        if (isLeft(record)) {
            throw record.left;
        }
        yield put(Process.Done(action)(record.right));
    }
    catch (e) {
        if (e.response) {
            yield put(Process.Failed(action)(ClientError({ name: e.name, message: e.message, stack: e.stack }, e.response)));
        }
        else if (e instanceof Array) {
            const errorMessage = 'Si è verificato un errore nella ricezione';
            yield put(Process.Failed(action)(ClientError(new Error(errorMessage))));
            NotificationAction.Push(Notification.error(errorMessage));
        }
        else {
            yield put(Process.Failed(action)(ClientError(e)));
        }
    }
}
function* signDocument(action) {
    const { documents, recordStatusCode, record, pipeline, instances, onSuccessMessage, onFailMessage } = action.payload;
    yield put(PipelinesConfirmRequest({
        record,
        pipeline,
        instances,
        onSuccessMessage,
        onFailMessage,
        isDigitalLoading: true,
        documents,
        recordStatusCode,
    }));
    yield put(Process.Done(action)());
}
function* cancelDocument(action) {
    try {
        yield* call(PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).delete, `${RECORDS_V1}/${action.payload}/sign`);
        yield put(NotificationAction.Push(Notification.info('Firma digitale dei documenti revocata correttamente')));
        const recordResponse = yield* call(Context.http().get, `${RECORDS}/${action.payload}`);
        const r = Either.fromValidation(RecordC.decode(decode(recordResponse.data)));
        if (isLeft(r)) {
            throw r.left;
        }
        yield put(Process.Done(action)(r.right));
    }
    catch (e) {
        if (e.response) {
            yield put(Process.Failed(action)(ClientError({ name: e.name, message: e.message, stack: e.stack }, e.response)));
        }
        else {
            yield put(Process.Failed(action)(ClientError(e)));
        }
        yield put(NotificationAction.Push(Notification.error("C'è stato un errore nella revoca della richiesta")));
    }
}
export function* RecordSaga() {
    yield* all([
        yield* takeEvery(RecordAction.Cancel.type.fired, cancelDocument),
        yield* takeEvery(RecordAction.Sign.type.fired, signDocument),
        yield* takeEvery(RecordAction.Detail.type.fired, fetchRecord),
        yield* takeEvery(RecordAction.Update.type.fired, updateRecord),
        yield* takeEvery(RecordAction.Duplicate.type.fired, duplicateRecord),
    ]);
}
