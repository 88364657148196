import { readonlyRecord, string } from 'fp-ts';
import { not } from 'fp-ts/Predicate';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { IdentifierCodec } from '../Identifier/Identifier';
import { NumberFromStringCodec } from '../NumberFromString';
const codec = t.type({
    userId: IdentifierCodec,
    purpose: t.keyof({
        Acquisto: null,
        Surroga: null,
    }),
    amount: NumberFromStringCodec,
}, 'MpnInvoiceInput');
const canSave = (values) => pipe(values, readonlyRecord.map(value => value.toString()), readonlyRecord.every(not(string.isEmpty)));
export const $mpnInvoiceForm = { canSave, codec };
