import { monoid, number, readonlyArray } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { IdentifierUtils } from '../Identifier.codec';
import { InvoiceCodec } from '../Invoice/Invoice.codec';
export const InvoiceOutputCodec = t.readonlyArray(InvoiceCodec);
const itemsTotalAmount = (predicate) => (invoices) => pipe(invoices, readonlyArray.map(invoice => pipe(invoice.items, readonlyArray.filter(items => (predicate ? predicate(items) : true)), readonlyArray.map(items => items.amount), monoid.concatAll(number.MonoidSum))), monoid.concatAll(number.MonoidSum));
const uniqueUserIdList = (invoiceList) => pipe(invoiceList, readonlyArray.map(invoice => invoice.userId), readonlyArray.uniq(IdentifierUtils.Eq));
const invoiceListLength = (invoiceList) => invoiceList.length;
export const InvoiceOutputUtils = {
    uniqueUserIdList,
    itemsTotalAmount,
    invoiceListLength,
};
