import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useAuthorization } from '../../authorization/useAuthorization';
import { PERMISSION } from '../../store/security/SecurityState';
import { InvoiceListHistory } from './InvoiceListHistory';
import { InvoicesList } from './InvoicesList';
import { RecordListWithPayin } from './RecordListWithPayin';
import { RecordPanel } from './RecordPanel';
import { TabPanel } from './TabPanel';
const useStyles = makeStyles()(theme => ({
    sticky: {
        background: theme.palette.common.white,
        position: 'sticky',
        top: 0,
        zIndex: 1,
        height: 80,
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(5),
    },
    mb4: { marginBottom: theme.spacing(4) },
    zIndex2: { zIndex: 2 },
}));
const handleChange = (setTabId) => (_, newTabId) => setTabId(newTabId);
export const PassiveInvoiceTabs = () => {
    const [tabId, setTabId] = useState(0);
    const { classes } = useStyles();
    const canViewRecordPanel = useAuthorization([PERMISSION.VIEW_RECORD_LIST]);
    const canViewMyInvoicesText = useAuthorization([PERMISSION.VIEW_MY_INVOICES_TEXT]);
    return (_jsxs(_Fragment, { children: [_jsx("div", { className: clsx(classes.sticky, classes.mb4, classes.zIndex2), children: _jsx(Typography, { variant: "h1", children: canViewMyInvoicesText ? 'Le mie fatture' : 'Fatturazione passiva' }) }), _jsxs(Container, { children: [_jsx(Box, { style: { marginBottom: 16 }, children: _jsxs(Tabs, { value: tabId, onChange: handleChange(setTabId), children: [canViewRecordPanel && _jsx(Tab, { label: "Registrazione payin" }), canViewRecordPanel && _jsx(Tab, { label: "Elenco pratiche" }), _jsx(Tab, { label: "Fatture da inviare" }), _jsx(Tab, { label: "Fatture inviate" })] }) }), canViewRecordPanel ? (_jsxs(_Fragment, { children: [_jsx(TabPanel, { value: tabId, index: 0, children: _jsx(RecordListWithPayin, {}) }), _jsx(TabPanel, { value: tabId, index: 1, children: _jsx(RecordPanel, {}) }), _jsx(TabPanel, { value: tabId, index: 2, children: _jsx(InvoicesList, {}) }), _jsx(TabPanel, { value: tabId, index: 3, children: _jsx(InvoiceListHistory, {}) })] })) : (_jsxs(_Fragment, { children: [_jsx(TabPanel, { value: tabId, index: 0, children: _jsx(InvoicesList, {}) }), _jsx(TabPanel, { value: tabId, index: 1, children: _jsx(InvoiceListHistory, {}) })] }))] })] }));
};
