import { either, readonlyArray, taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import qs from 'qs';
import { BASE_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { PooledClient, HttpClientPool } from '../../lib/http/PooledClient';
import { UserC } from '../../model/User';
import { UserCodec } from '../models/User/User';
const mapToUser = (artooUser) => (Object.assign({ fullName: `${artooUser.first_name} ${artooUser.last_name}`, firstName: artooUser.first_name, lastName: artooUser.last_name }, artooUser));
export const getUserListWithRole = (filter = {}) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get(`${BASE_URL}/api/v1/user?${qs.stringify(Object.assign({ role: 'ROLE_NEXUS_BILLABLE' }, filter))}`), e => e), taskEither.chainEitherKW(response => pipe(response.data, t.type({ items: t.readonlyArray(UserC) }).decode, either.map(artooUserList => artooUserList.items), either.map(readonlyArray.map(mapToUser)), either.chain(t.readonlyArray(UserCodec).decode))));
