import { array, taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import qs from 'qs';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
import { BazaarQuotationCodec } from '../models/BazaarQuotation.codec';
const getInstituteNames = (institutes) => pipe(institutes, array.filter(({ isChecked }) => isChecked), array.map(({ name }) => name));
const getAttributesNames = (attributes) => pipe(attributes, array.filter(({ isChecked }) => isChecked), array.map(({ name }) => name));
export const quoteMortgageRequestById = (id, filters) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).post(`${BAZAAR_URL}/api/v1/mortgage/requests/${id}/quote?${qs.stringify({
    instituteNames: getInstituteNames(filters.institutes),
    attributes: getAttributesNames(filters.attributes),
})}`), e => e), taskEither.chainEitherKW(response => t.array(BazaarQuotationCodec).decode(response.data)));
