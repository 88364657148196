import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { quoteView } from '../../view/QuoteView';
import { Badge } from '../Badge';
import { CardSection } from './CardSection';
const getBadgeProps = (attribute) => {
    switch (attribute) {
        case 'green':
            return option.some({ label: 'Mutuo green', color: 'lightGreen' });
        case 'under36':
            return option.some({ label: 'Mutuo under 36', color: 'lightGrey' });
        default:
            return option.none;
    }
};
export const QuoteCard = ({ quote }) => {
    const formattedQuote = quoteView(quote);
    return (_jsxs(Box, { style: { backgroundColor: '#F8F9FB', padding: 20, borderRadius: '4px' }, children: [_jsx(Typography, { sx: { marginBottom: '4px' }, children: formattedQuote.submittedAt }), _jsx(Card, { square: false, elevation: 0, children: _jsxs(CardContent, { children: [_jsxs(Grid, { container: true, children: [quote.attributes.length > 0 && (_jsx(Grid, { container: true, children: quote.attributes.map((name, key) => pipe(name, getBadgeProps, option.match(() => null, ({ color, label }) => _jsx(Badge, { title: label, color: color }, key)))) })), _jsx(Grid, { container: true, children: _jsx(Typography, { sx: { marginBottom: '8px' }, variant: "h3", children: formattedQuote.instituteName }) }), _jsx(Grid, { container: true, children: _jsx(Typography, { children: formattedQuote.productName }) })] }), _jsx(Divider, { sx: { marginTop: '16px', marginBottom: '16px' } }), _jsx(Grid, { sx: { marginBottom: '16px' }, container: true, wrap: "nowrap", alignItems: "center", justifyContent: "space-between", children: _jsx(Grid, { item: true, children: _jsxs(Grid, { container: true, flexDirection: "column", children: [_jsx(Grid, { item: true, children: _jsx(Typography, { children: "Rata mensile" }) }), _jsx(Grid, { item: true, children: _jsx(Typography, { variant: "h1", sx: { fontSize: 32 }, children: formattedQuote.installment }) })] }) }) }), _jsx(CardSection, { quoteDetails: formattedQuote.sections.payment }), _jsx(Divider, { sx: { marginTop: '16px', marginBottom: '16px' } }), _jsx(CardSection, { title: "Sostenibilit\u00E0 rata", quoteDetails: formattedQuote.sections.installmentSustainability }), _jsx(Divider, { sx: { marginTop: '16px', marginBottom: '16px' } }), _jsx(CardSection, { title: "Costi una tantum", quoteDetails: formattedQuote.sections.oneTimeCosts })] }) })] }));
};
