import { memoize } from 'fortepiano/function';
import { Intercom } from '../lib/Intercom';
import { StorageCache } from '../lib/cache/StorageCache';
import { TypedCache } from '../lib/cache/TypedCache';
import { AuthClient } from '../lib/http/AuthClient';
import { AxiosClient } from '../lib/http/AxiosClient';
import { JsonClient } from '../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../lib/http/PooledClient';
const AUTH_SCHEMA_JWT = 'Bearer';
const cache = TypedCache(StorageCache(localStorage));
const httpClient = AuthClient(JsonClient(AxiosClient), cache, AUTH_SCHEMA_JWT);
const pooledClient = PooledClient(httpClient, HttpClientPool);
const _memoizedClient = (client) => ({
    delete: memoize(client.delete),
    get: memoize(client.get),
    post: memoize(client.post),
    put: memoize(client.put),
    patch: memoize(client.patch),
});
const memoizedClient = _memoizedClient(httpClient);
const memoizedPooledClient = _memoizedClient(pooledClient);
export const Context = {
    cache: cache,
    http: (options) => {
        const { memoized, pooled } = Object.assign({ memoized: false, pooled: true }, options);
        let client = httpClient;
        if (memoized) {
            if (pooled) {
                client = memoizedPooledClient;
            }
            else {
                client = memoizedClient;
            }
        }
        else {
            if (pooled) {
                client = pooledClient;
            }
        }
        return client;
    },
    intercom: Intercom(process.env.REACT_APP_INTERCOM),
};
