import { createSlice } from '@reduxjs/toolkit';
import { option } from 'fp-ts';
import { constant, identity } from 'fp-ts/function';
const loadingInitialState = { userList: false, invoiceCreation: false };
const initialState = {
    loading: loadingInitialState,
    error: option.none,
    users: [],
    initialValues: {
        amount: '',
        userId: '',
        userFullName: '',
        purpose: '',
    },
};
export const invoiceCreationFromMpnSlice = createSlice({
    name: 'InvoiceCreationFromMpnSaga',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        createInvoice: (state, _) => state,
        fetchUserList: (state, _) => state,
        Started: identity,
        Stopped: constant(initialState),
        UserListRequested: state => (Object.assign(Object.assign({}, state), { users: [], error: option.none, loading: Object.assign(Object.assign({}, state.loading), { userList: true }) })),
        UserListFetched: (state, action) => (Object.assign(Object.assign({}, state), { users: action.payload, error: option.none, loading: Object.assign(Object.assign({}, state.loading), { userList: false }) })),
        UserListNotFetched: (state, action) => (Object.assign(Object.assign({}, state), { users: [], error: option.some(action.payload), loading: Object.assign(Object.assign({}, state.loading), { userList: false }) })),
        InvoiceCreationRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { invoiceCreation: true }), error: option.none })),
        InvoiceCreated: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { invoiceCreation: false }), error: option.none })),
        InvoiceNotCreated: (state, action) => (Object.assign(Object.assign({}, state), { error: option.some(action.payload), loading: Object.assign(Object.assign({}, state.loading), { invoiceCreation: false }) })),
    },
});
const assignees = (state) => state.invoiceCreationFromMpn.users;
const isUsersLoading = (state) => state.invoiceCreationFromMpn.loading.userList;
const initialValues = (state) => state.invoiceCreationFromMpn.initialValues;
export const $InvoiceCreationFromMpn = invoiceCreationFromMpnSlice.actions;
export const $invoiceCreationFromMpnSlice = {
    selector: { assignees, isUsersLoading, initialValues },
};
export default invoiceCreationFromMpnSlice.reducer;
