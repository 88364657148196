import { format } from 'date-fns';
import { option, readonlyArray } from 'fp-ts';
import { constant, pipe } from 'fp-ts/function';
import { currencyView } from '../view/CurrencyView';
import { householdView } from '../view/HouseholdView';
import { jobView } from '../view/JobView';
import { mortgagePurposeView } from '../view/MortgagePurposeView';
import { procedureStatusView } from '../view/ProcedureStatusView';
import { productTypeView } from '../view/ProductTypeView';
import { quoteView } from './QuoteView';
import { rateView } from './RateView';
const job = (participantJob) => pipe(jobView.jobList, readonlyArray.findFirst(({ value }) => value === participantJob), option.map(({ label }) => label), option.getOrElse(constant('')));
export const requestWithQuotesView = (requestWithQuotesModel) => (Object.assign(Object.assign({}, requestWithQuotesModel), { notes: requestWithQuotesModel.notes, rate: rateView(requestWithQuotesModel.rate), purpose: mortgagePurposeView(requestWithQuotesModel.purpose), requestedAmount: requestWithQuotesModel.requestedAmount === null ? null : currencyView(requestWithQuotesModel.requestedAmount), leftoverAmount: requestWithQuotesModel.leftoverAmount === null ? null : currencyView(requestWithQuotesModel.leftoverAmount), procedureStatus: procedureStatusView(requestWithQuotesModel.procedureStatus), productType: productTypeView(requestWithQuotesModel.productType), propertyLocation: `${requestWithQuotesModel.property.location.city} (${requestWithQuotesModel.property.location.provinceCode})`, propertyValue: currencyView(requestWithQuotesModel.property.value), duration: `${requestWithQuotesModel.duration} anni`, participants: [
        ...requestWithQuotesModel.applicants.map(applicantModel => (Object.assign(Object.assign({}, applicantModel), { age: `${applicantModel.age} anni`, currentDebtInstallment: currencyView(applicantModel.currentDebtInstallment), employment: job(applicantModel.employment), familyUnit: householdView(applicantModel.familyUnit), isForeigner: applicantModel.isForeigner ? 'Sì' : 'No', netIncome: currencyView(applicantModel.netIncome), residenceLocation: applicantModel.residenceLocation
                ? `${applicantModel.residenceLocation.city} (${applicantModel.residenceLocation.provinceCode})`
                : null, type: 'richiedente' }))),
        ...requestWithQuotesModel.guarantors.map(guarantorModel => (Object.assign(Object.assign({}, guarantorModel), { age: `${guarantorModel.age} anni`, currentDebtInstallment: currencyView(guarantorModel.currentDebtInstallment), employment: job(guarantorModel.employment), familyUnit: householdView(guarantorModel.familyUnit), isForeigner: guarantorModel.isForeigner ? 'Sì' : 'No', netIncome: currencyView(guarantorModel.netIncome), residenceLocation: null, type: 'garante' }))),
    ], submittedBy: `${requestWithQuotesModel.submittedBy.firstName} ${requestWithQuotesModel.submittedBy.lastName}`, channel: requestWithQuotesModel.lead.channel, submittedAt: format(new Date(requestWithQuotesModel.submittedAt), 'dd/MM/yyyy'), quotations: requestWithQuotesModel.quotations.map(quoteView) }));
