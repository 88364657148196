import { either, taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { PooledClient, HttpClientPool } from '../../lib/http/PooledClient';
import { CommissionsForm } from '../state/commissionsForm/CommissionsForm';
import { CommissionsTableCodec } from '../state/commissionsForm/CommissionsTable';
// export const editCommissions = async (
//     recordId: Identifier,
//     commissionsForm: CommissionsForm
// ): Promise<either.Either<t.Errors | Error | HttpError, CommissionsTable>> =>
//     pipe(
//         commissionsForm,
//         CommissionsForm.codec.encode,
//         commissions =>
//             $context.http.patch(`${$context.api.url}/api/v1/records/${recordId}`, {
//                 body: { data: { commissions } },
//             }),
//         taskEither.chainW(response =>
//             pipe(
//                 response.body,
//                 t.type({ data: t.type({ commissions: CommissionsTableCodec }) }).decode,
//                 either.map(({ data: { commissions } }) => commissions),
//                 taskEither.fromEither
//             )
//         )
//     )()
export const editCommissions = (recordId, commissionsForm) => pipe(taskEither.tryCatch(() => pipe(commissionsForm, CommissionsForm.codec.encode, commissions => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).patch(`${BAZAAR_URL}/api/v1/records/${recordId}`, { data: { commissions } })), e => e), taskEither.chainEitherKW(response => pipe(response.data, t.type({ data: t.type({ commissions: CommissionsTableCodec }) }).decode, either.map(({ data: { commissions } }) => commissions))));
