import { fold } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { HttpResponse } from './index';
const CACHE_TOKEN = 'session.token';
const AuthRequest = (request, cache, schema) => (url, data, headers, responseType) => {
    const auth = pipe(cache.get(CACHE_TOKEN, t.string)(), fold(() => undefined, token => ({ Authorization: `${schema} ${token}` })));
    return request(url, data, Object.assign(Object.assign({}, auth), headers), responseType).then(response => {
        const responseHeaders = HttpResponse.lens.headers.get(response);
        let matches;
        if ('authorization' in responseHeaders &&
            (matches = responseHeaders.authorization.match(/^(\w+) +(.+)$/)) &&
            schema === matches[1]) {
            const token = matches[2] || null;
            cache.set(CACHE_TOKEN, token)();
        }
        return response;
    });
};
export const AuthClient = (client, cache, schema) => ({
    get: AuthRequest(client.get, cache, schema),
    post: AuthRequest(client.post, cache, schema),
    put: AuthRequest(client.put, cache, schema),
    delete: AuthRequest(client.delete, cache, schema),
    patch: AuthRequest(client.patch, cache, schema),
});
