import { readonlyArray, string } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { NonEmptyString } from 'io-ts-types/lib/NonEmptyString';
import { IdentifierCodec, IdentifierUtils } from '../Identifier.codec';
import { RoleCodec } from './Role';
export const UserCodec = t.intersection([
    t.type({
        id: IdentifierCodec,
        username: NonEmptyString,
        email: NonEmptyString,
        enabled: t.boolean,
        roles: t.array(RoleCodec),
        firstName: t.string,
        lastName: t.string,
        created_at: NonEmptyString,
        fullName: t.string,
    }),
    t.partial({ data: t.record(t.string, t.unknown) }),
], 'User');
const getUserByFullName = (userFullName) => (userList) => pipe(userList, readonlyArray.findFirst(user => string.Eq.equals(user.fullName, userFullName)));
const getUserById = (id) => (users) => pipe(users, readonlyArray.findFirst(user => IdentifierUtils.Eq.equals(user.id, id)));
export const UserUtils = {
    getUserById,
    getUserByFullName,
};
