import * as t from 'io-ts';
export const JOB_VALUES = {
    permanentEmployee: 'permanentEmployee',
    fixedTermEmployee: 'fixedTermEmployee',
    selfEmployedWithVatNumber: 'selfEmployedWithVatNumber',
    selfEmployed: 'selfEmployed',
    freelance: 'freelance',
    pensioner: 'pensioner',
    homemaker: 'homemaker',
    student: 'student',
    indemonstrableIncome: 'indemonstrableIncome',
    unemployed: 'unemployed',
    director: 'director',
    domestic: 'domestic',
    borderWorker: 'borderWorker',
    other: 'other',
};
export const jobModel = t.keyof(JOB_VALUES);
