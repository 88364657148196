export const flagTheme = {
    main: {
        unknown: 'rgba(216,216,216,0.5)',
        success: 'rgba(220,237,49,0.5)',
        error: 'rgba(249,178,192,0.5)',
        warning: 'rgba(252,239,159,0.5)',
    },
    dark: {
        unknown: '#757575',
        success: '#c6d52c',
        error: '#a50020',
        warning: '#eeba00',
    },
};
