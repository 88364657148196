import { session } from '../utils/session';
const trigger = (w, tag) => {
    if (!w.hj) {
        return;
    }
    w.hj('trigger', tag);
};
const identifyUser = (w, user, roles) => {
    if (!w.hj) {
        return;
    }
    w.hj('identify', user.username, {
        commission_profile: session.userDataValue(user)('profilo_provvigionale'),
        store: session.userDataValue(user)('store'),
        role: session.matchRole(roles)(user),
    });
};
const guestUser = (w) => {
    if (!w.hj) {
        return;
    }
    w.hj('identify', 'null', {
        commission_profile: '',
        store: '',
        role: '',
    });
};
export const Hotjar = { trigger, identifyUser, guestUser };
