import { decode } from 'json-api-codec';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { getRecordList } from '../../api/record';
import { ClientError } from '../../lib/http/ClientError';
import { Process } from '../../lib/redux/Process';
import { RecordListActions } from './RecordListActions';
function* fetchRecordList(action) {
    try {
        const { data } = yield call(getRecordList, action.payload.filter, action.payload.url);
        const records = decode(data);
        yield put(Process.Done(action)({
            recordList: records,
            meta: data.meta,
            links: data.links,
        }));
    }
    catch (e) {
        if (e.response) {
            yield put(Process.Failed(action)(ClientError({ name: e.name, message: e.message, stack: e.stack }, e.response)));
        }
        else {
            yield put(Process.Failed(action)(ClientError(e)));
        }
    }
}
function* watchRecordListRequest() {
    yield takeEvery(RecordListActions.List.type.fired, fetchRecordList);
}
export function* recordListSaga() {
    yield all([fork(watchRecordListRequest)]);
}
