import { createSlice } from '@reduxjs/toolkit';
import { either, option, readonlyArray } from 'fp-ts';
import { constant, constNull, identity, pipe } from 'fp-ts/function';
import { $currency } from '../../models/Currency/Currency';
import { InvoiceUtils } from '../../models/Invoice/Invoice.codec';
import { PaginationUtils } from '../../models/Pagination/Pagination.codec';
import { UserUtils } from '../../models/User/User.codec';
const PAGE_LIMIT = 10;
const initialStateTablePagination = {
    limit: PAGE_LIMIT,
    offset: 0,
    currentPage: 0,
    isNextPageDisabled: false,
    isPreviousPageDisabled: false,
};
const initialState = {
    loading: { table: false, pdfDownload: false, userList: false },
    error: { userList: false },
    userList: [],
    table: {
        pagination: initialStateTablePagination,
        invoiceList: null,
        userList: null,
        filter: undefined,
    },
    userListInitialValues: { fullName: '' },
};
const isNextPageDisabled = (pagination, invoiceList) => invoiceList.length < pagination.limit;
const tablePagination = (invoiceList, pagination) => (Object.assign(Object.assign({}, pagination), { currentPage: PaginationUtils.currentPage(pagination), isNextPageDisabled: isNextPageDisabled(pagination, invoiceList), isPreviousPageDisabled: !PaginationUtils.canGoBack(pagination) }));
const getCurrency = (invoices) => pipe(invoices, InvoiceUtils.amount, $currency.euro, option.getOrElse(constant('')));
const invoiceCell = (invoice) => (users) => ({
    id: invoice._id,
    statusCode: invoice.statusCode,
    user: pipe(users, UserUtils.getUserById(invoice.userId), option.match(() => ({ id: '', fullName: '', firstName: '', lastName: '' }), user => ({
        id: user.id.toString(),
        fullName: user.fullName,
        firstName: user.firstName,
        lastName: user.lastName,
    }))),
    amount: getCurrency(invoice.items),
    hasEnasarco: InvoiceUtils.hasEnasarco(invoice.hasEnasarco),
    documentNumber: InvoiceUtils.documentNumber(invoice.documentNumber),
    month: InvoiceUtils.month(invoice.month),
});
const invoiceListTable = (state) => {
    const invoiceList = state.invoiceHistory.table.invoiceList;
    const userList = state.invoiceHistory.table.userList;
    return invoiceList !== null && userList !== null
        ? pipe(invoiceList, readonlyArray.map(invoice => pipe(userList, invoiceCell(invoice))), either.of, either.filterOrElseW(readonlyArray.isNonEmpty, constant([])), either.map(body => ({
            headers: [
                {
                    userFullName: 'Collaboratore',
                    amount: 'Imponibile',
                    enasarco: 'Enasarco',
                    documentNumber: 'ID fattura',
                    month: 'Mese competenza',
                },
            ],
            body: body,
        })), either.getOrElseW(constNull))
        : null;
};
export const invoiceHistorySlice = createSlice({
    name: 'InvoiceHistorySaga',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        Started: identity,
        Stopped: constant(initialState),
        fetchNextInvoicePage: identity,
        fetchPreviousInvoicePage: identity,
        fetchFirstInvoicePage: identity,
        fetchUserList: (state, _) => state,
        fetchInvoiceListWithUserList: (state, _) => state,
        downloadInvoice: (state, _) => state,
        UserListRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { userList: true }), error: Object.assign(Object.assign({}, state.error), { userList: false }) })),
        UserListFetched: (state, event) => (Object.assign(Object.assign({}, state), { userList: pipe(event.payload, readonlyArray.toArray), loading: Object.assign(Object.assign({}, state.loading), { userList: false }), error: Object.assign(Object.assign({}, state.error), { userList: false }) })),
        UserListNotFetched: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { userList: false }), error: Object.assign(Object.assign({}, state.error), { userList: true }) })),
        InvoiceListWithUserListRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { table: true }) })),
        InvoiceListWithUserListFetched: (state, event) => (Object.assign(Object.assign({}, state), { table: Object.assign(Object.assign({}, state.table), { invoiceList: event.payload.invoiceList, userList: event.payload.userList, pagination: tablePagination(event.payload.invoiceList, event.payload.pagination), filter: event.payload.tableFilter }), loading: Object.assign(Object.assign({}, state.loading), { table: false }) })),
        InvoiceListWithUserListNotFetched: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { table: false }) })),
        InvoiceDownloadRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { pdfDownload: true }) })),
        InvoiceDownloaded: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { pdfDownload: false }) })),
        InvoiceNotDownloaded: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { pdfDownload: false }) })),
    },
});
export const $InvoiceHistory = invoiceHistorySlice.actions;
export const $invoiceHistorySlice = {
    PAGE_LIMIT,
    selector: {
        invoiceListTable,
        isTableLoading: (state) => state.invoiceHistory.loading.table,
        isUserListLoading: (state) => state.invoiceHistory.loading.userList,
        isAnyInvoiceDownloading: (state) => state.invoiceHistory.loading.pdfDownload,
        pagination: (state) => state.invoiceHistory.table.pagination,
        filter: (state) => state.invoiceHistory.table.filter,
        userList: (state) => state.invoiceHistory.userList,
        userListInitialValues: (state) => state.invoiceHistory.userListInitialValues,
        isUserListWithError: (state) => state.invoiceHistory.error.userList,
    },
};
export default invoiceHistorySlice.reducer;
