import { array } from 'fp-ts/Array';
import { chain, mapLeft, parseJSON, stringifyJSON, toError } from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { Either as E } from './Either';
export const JsonC = t.recursion('Json', () => t.union([t.null, t.boolean, t.number, t.string, t.array(JsonC), t.record(t.string, JsonC)]));
export const Json = {
    fromString: (string) => pipe(parseJSON(string, toError), mapLeft(array.of), chain(json => E.fromValidation(JsonC.decode(json)))),
    toString: (json) => stringifyJSON(json, toError),
};
