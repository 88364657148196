import { CLEAR_PIPELINE, PIPELINES_CONFIGURATIONS_FAILURE, PIPELINES_CONFIGURATIONS_REQUEST, PIPELINES_CONFIGURATIONS_SUCCESS, PIPELINES_CONFIRM_FAILURE, PIPELINES_CONFIRM_REQUEST, PIPELINES_CONFIRM_SUCCESS, PIPELINES_MODAL_FAILURE, PIPELINES_MODAL_REQUEST, PIPELINES_MODAL_SUCCESS, } from '../actions/pipelines';
import { PipelineState } from '../state/pipelines';
export const PipelinesReducer = (state = PipelineState.empty, { type, payload }) => {
    switch (type) {
        case CLEAR_PIPELINE: {
            return Object.assign(Object.assign({}, state), { error: '', message: '', status: undefined });
        }
        case PIPELINES_CONFIGURATIONS_REQUEST: {
            return Object.assign(Object.assign({}, state), { error: '', isConfigurationLoading: true });
        }
        case PIPELINES_CONFIGURATIONS_FAILURE: {
            return Object.assign(Object.assign({}, state), { error: payload.error, isConfigurationLoading: false });
        }
        case PIPELINES_MODAL_REQUEST:
        case PIPELINES_CONFIRM_REQUEST:
            // eslint-disable-next-line no-case-declarations
            const isUpdatingRecordStatus = payload.isUpdatingRecordStatus ? { isUpdatingRecordStatus: true } : {};
            // eslint-disable-next-line no-case-declarations
            const isDigitalLoading = payload.isDigitalLoading ? { isDigitalLoading: true } : {};
            return Object.assign(Object.assign(Object.assign(Object.assign({}, state), isDigitalLoading), isUpdatingRecordStatus), { isLoading: true });
        case PIPELINES_MODAL_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoading: false, error: payload.error, status: payload.status });
        case PIPELINES_CONFIRM_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoading: false, error: payload.error, status: payload.status, isDigitalLoading: false, isUpdatingRecordStatus: false });
        case PIPELINES_MODAL_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false, error: '', configurations: payload.configurations, instances: payload.instances, history: payload.history });
        case PIPELINES_CONFIRM_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoading: false, error: '', status: undefined, message: payload.message, isDigitalLoading: false, isUpdatingRecordStatus: false });
        case PIPELINES_CONFIGURATIONS_SUCCESS:
            return Object.assign(Object.assign({}, state), { message: '', error: '', isConfigurationLoading: false });
        default:
            return state;
    }
};
