import { jsx as _jsx } from "react/jsx-runtime";
import { Chip } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
const useStyles = makeStyles()(() => ({
    lightGreen: { backgroundColor: '#54FDC4', color: '#6F797A' },
    lightGrey: { backgroundColor: '#EDEEF0', color: '#00513B' },
    box: {
        marginRight: '8px',
        marginBottom: '8px',
    },
}));
export const Badge = ({ color, title }) => {
    const { classes } = useStyles();
    return (_jsx(Chip, { size: "small", label: title, className: clsx(classes.box, color === 'lightGreen' && classes.lightGreen, color === 'lightGrey' && classes.lightGrey) }));
};
