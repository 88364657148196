import { either } from 'fp-ts';
import { all, call, cancel, put, select, take, takeLatest, takeLeading } from 'typed-redux-saga';
import { isUserAuthorized } from '../../../authorization/useAuthorization';
import { PERMISSION, SecurityState } from '../../../store/security/SecurityState';
import { getRecordById } from '../../web/record/getRecordById';
import { $ActiveInvoicing } from './ActiveInvoicingState';
function* fetchRecord(recordId) {
    yield* put($ActiveInvoicing.RecordRequested());
    const maybeRecord = yield* call(getRecordById(recordId));
    if (either.isLeft(maybeRecord)) {
        yield* put($ActiveInvoicing.RecordNotFetched());
    }
    else {
        yield* put($ActiveInvoicing.RecordFetched(maybeRecord.right));
    }
}
export function* ActiveInvoicingSaga() {
    yield* takeLeading($ActiveInvoicing.start, function* () {
        const userPermissionList = yield* select(SecurityState.selector.permissionList);
        if (isUserAuthorized(userPermissionList)(PERMISSION.VIEW_ACTIVE_INVOICING_RECORD_LIST)) {
            yield* put($ActiveInvoicing.Started());
            const task = yield* all([
                yield* takeLatest($ActiveInvoicing.fetchRecord, function* (fetchRecordCommand) {
                    yield* call(fetchRecord, fetchRecordCommand.payload.recordId);
                }),
            ]);
            yield* take($ActiveInvoicing.stop);
            yield* cancel(task);
            yield* put($ActiveInvoicing.Stopped());
        }
    });
}
