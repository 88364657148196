import { isBefore, isValid } from 'date-fns';
import { $string } from 'fortepiano';
import { boolean, option, string } from 'fp-ts';
import { constUndefined, constant, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
export const SchemaVersionC = t.union([t.string, t.number]);
const START_DATE = new Date(2023, 1, 23); // See FAG-4545
const fromStringToDate = (dateString) => pipe(new Date(dateString), option.fromNullable, option.filter(isValid));
const fallbackVersion = (recordCreationDate, startDate) => () => pipe(isBefore(recordCreationDate, startDate), boolean.match(constant(string.empty), constant('1')));
const version = (recordCreationDate, startDate) => (recordData) => pipe(recordData._schema_version, option.fromNullable, option.match(fallbackVersion(recordCreationDate, startDate), schemaVersion => schemaVersion.toString()));
const url = (baseUrlOption, tokenOption) => (record) => pipe(baseUrlOption, option.map(baseUrl => string.Semigroup.concat(baseUrl, '/api/v1/schemas/fmp')), option.bindTo('schemaBaseUrl'), option.bind('schemaVersion', () => pipe(record.created_at, fromStringToDate, option.map(recordCreationDate => version(recordCreationDate, START_DATE)(record.data)))), option.map(({ schemaBaseUrl, schemaVersion }) => $string.infix('/')(schemaBaseUrl, schemaVersion)), option.map(pipe(tokenOption, option.match(constant(''), token => `?t=${token}`), $string.suffix)), option.getOrElseW(constUndefined));
export const $schemaVersion = {
    fallbackVersion,
    url,
    version,
    fromStringToDate,
};
