export const applicantStateMapper = (applicantModel) => {
    var _a, _b;
    return ({
        placeOfResidence: {
            name: (_b = (_a = applicantModel.residenceLocation) === null || _a === void 0 ? void 0 : _a.city) !== null && _b !== void 0 ? _b : '',
            postalCode: applicantModel.residenceLocation ? applicantModel.residenceLocation.postalCode : '',
            province: {
                shortName: applicantModel.residenceLocation ? applicantModel.residenceLocation.provinceCode : '',
            },
        },
        placeOfResidenceInput: applicantModel.residenceLocation === null
            ? ''
            : `${applicantModel.residenceLocation.city} (${applicantModel.residenceLocation.provinceCode})`,
        nationality: applicantModel.isForeigner === false ? 'italian' : 'other',
        age: applicantModel.age,
        household: applicantModel.familyUnit,
        job: applicantModel.employment,
        formattedNetMonthlyIncome: applicantModel.netIncome,
        rawNetMonthlyIncome: applicantModel.netIncome,
        formattedOngoingDebtInstallment: applicantModel.currentDebtInstallment,
        rawOngoingDebtInstallment: applicantModel.currentDebtInstallment,
        type: 'richiedente',
    });
};
