import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import EditIcon from '@mui/icons-material/Create';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
const selectedText = (invoicesSelected) => invoicesSelected === 1 ? '1 fattura selezionata' : `${invoicesSelected} fatture selezionate`;
export const EnhancedTableToolbar = ({ invoicesSelected, onEdit }) => {
    return (_jsxs(Toolbar, { sx: Object.assign({ pl: { sm: 2 }, pr: { xs: 1, sm: 1 } }, (invoicesSelected > 0 && {
            bgcolor: theme => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        })), children: [invoicesSelected > 0 ? (_jsx(Typography, { sx: { flex: '1 1 100%' }, color: "inherit", variant: "subtitle1", component: "div", children: selectedText(invoicesSelected) })) : null, invoicesSelected > 0 && (_jsx(Tooltip, { title: "Modifica fatture", onClick: onEdit, children: _jsx(IconButton, { children: _jsx(EditIcon, {}) }) }))] }));
};
