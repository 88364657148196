import { jsx as _jsx } from "react/jsx-runtime";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { $InvoiceListVisibility, $invoiceListVisibilitySlice, } from '../state/invoiceListVisibility/InvoiceListVisibilityState';
const onChange = (visibility, toggleInvoiceListVisibilityCommand, dispatch) => () => {
    dispatch(toggleInvoiceListVisibilityCommand({ showInvoiceList: !visibility }));
};
export const InvoiceSwitch = () => {
    const dispatch = useAppDispatch();
    const visibility = useAppSelector($invoiceListVisibilitySlice.selector.visibility);
    const isLoading = useAppSelector($invoiceListVisibilitySlice.selector.isLoading);
    useEffect(() => {
        dispatch($InvoiceListVisibility.start());
        return () => {
            dispatch($InvoiceListVisibility.stop());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsx(FormControlLabel, { disabled: isLoading, control: _jsx(Switch, { color: "primary" }), label: _jsx(Typography, { children: "Visibilit\u00E0 fatture" }), labelPlacement: "start", checked: visibility, onChange: onChange(visibility, $InvoiceListVisibility.toggleInvoiceListVisibility, dispatch) }));
};
