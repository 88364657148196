import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { Avatar, Grid, Link, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()(theme => ({
    container: {
        backgroundColor: theme.palette.common.white,
    },
    icon: {
        width: 26,
        height: 26,
    },
    mr4: {
        marginRight: theme.spacing(4),
    },
}));
export const ContactBox = ({ phone, email }) => {
    const { classes } = useStyles();
    return (_jsxs(Grid, { container: true, className: classes.container, sx: { alignItems: 'center' }, children: [_jsxs(Grid, { item: true, sx: { display: 'flex', alignItems: 'center', mr: '16px' }, children: [_jsx(Avatar, { sx: { bgcolor: '#DCE1FF', mr: '8px' }, className: classes.icon, children: _jsx(EmailOutlinedIcon, { sx: { color: '#405AA9', width: 16 } }) }), _jsx(Link, { underline: "none", href: `mailto:${email}`, children: _jsx(Typography, { color: "#6F797A", children: email }) })] }), _jsxs(Grid, { item: true, sx: { display: 'flex', alignItems: 'center' }, children: [_jsx(Avatar, { sx: { bgcolor: '#DCE1FF', mr: '8px' }, className: classes.icon, children: _jsx(PhoneOutlinedIcon, { sx: { color: '#405AA9', width: 16 } }) }), _jsx(Link, { underline: "none", href: `tel:0039${phone}`, children: _jsx(Typography, { color: "#6F797A", children: phone }) })] })] }));
};
