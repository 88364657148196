import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, CardContent, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { FieldArray, Form, useFormikContext } from 'formik';
import { option, readonlyArray } from 'fp-ts';
import { constVoid, pipe } from 'fp-ts/function';
import React from 'react';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $number } from '../../models/Number/Number';
import { PassiveInvoiceInputUtils } from '../../models/PassiveInvoiceInput/PassiveInvoiceInput';
import { $InvoiceCreation, $invoiceCreationSlice } from '../../state/invoiceCreation/InvoiceCreationState';
import { $invoiceEditSlice } from '../../state/invoiceEdit/InvoiceEditState';
import { FormHelperError } from '../FormHelperError';
const handleAutocompleteChange = (setFieldValue) => (_, assignee) => {
    if (!assignee) {
        return;
    }
    setFieldValue('userFullName', assignee.fullName);
    setFieldValue('userId', assignee.id);
};
const handleDateChange = (setFieldValue) => (date) => {
    setFieldValue('month', date);
};
const pushItem = (push) => () => push({
    description: '',
    amount: '',
});
const removeItem = (index, remove) => () => remove(index);
const onInputChange = (dispatch, userListFetchAttemptAction) => (_, userValue) => {
    if (userValue.length > 2) {
        dispatch(userListFetchAttemptAction({ fullName: userValue }));
    }
};
export const NewInvoiceDialogForm = ({ editMode }) => {
    const formik = useFormikContext();
    const dispatch = useAppDispatch();
    const enasarcoDeficitOption = useAppSelector($invoiceEditSlice.selector.enasarcoDeficitOption);
    const assignees = editMode
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
            useAppSelector($invoiceEditSlice.selector.assignees)
        : // eslint-disable-next-line react-hooks/rules-of-hooks
            useAppSelector($invoiceCreationSlice.selector.assignees);
    const { values: formikValues } = useFormikContext();
    const radio = _jsx(Radio, { size: "small" });
    return (_jsxs(Form, { children: [_jsx(Autocomplete, { autoSelect: true, disableClearable: true, style: { marginBottom: 16 }, options: assignees, getOptionLabel: ({ fullName }) => fullName, disabled: editMode, defaultValue: {
                    fullName: formik.values.userFullName,
                    id: formik.values.userId,
                }, onInputChange: editMode ? constVoid : onInputChange(dispatch, $InvoiceCreation.fetchUserList), onChange: handleAutocompleteChange(formik.setFieldValue), renderInput: params => (_jsx(TextField, Object.assign({ variant: "standard" }, params, { required: true, value: formik.values.userFullName, onChange: formik.handleChange, name: "userFullName", label: "Collaboratore", inputProps: Object.assign(Object.assign({}, params.inputProps), { autoComplete: 'off' }) }))) }), _jsxs(FormControl, { variant: "standard", component: "fieldset", required: true, fullWidth: true, children: [_jsx(FormLabel, { children: "Enasarco" }), _jsxs(RadioGroup, { name: "hasEnasarco", value: formik.values.hasEnasarco, onChange: formik.handleChange, row: true, children: [_jsx(FormControlLabel, { value: "si", control: radio, label: "S\u00EC", labelPlacement: "end" }), _jsx(FormControlLabel, { value: "no", control: radio, label: "No", labelPlacement: "end" })] }), option.isSome(enasarcoDeficitOption) && (_jsx(FormHelperError, { error: `Hai superato il massimale Enasarco di ${enasarcoDeficitOption.value}` }))] }), _jsx(DatePicker, { slotProps: {
                    textField: {
                        variant: 'standard',
                        required: true,
                        name: 'month',
                        style: { marginBottom: 16 },
                        fullWidth: true,
                    },
                }, views: ['month', 'year'], value: formik.values.month, label: "Mese competenza", onChange: handleDateChange(formik.setFieldValue) }), _jsx(FieldArray, { name: "items", children: ({ remove, push }) => (_jsxs(_Fragment, { children: [pipe(formikValues.items, readonlyArray.mapWithIndex((index, item) => (_jsx(Card, { style: { marginBottom: 16 }, children: _jsx(CardContent, { children: _jsxs(Grid, { wrap: "nowrap", container: true, justifyContent: "space-between", alignItems: "center", children: [_jsxs(Grid, { item: true, xs: 11, children: [_jsx(Grid, { container: true, style: { marginBottom: 8 }, children: _jsx(Typography, { variant: "body2", children: item.customerFullName }) }), _jsxs(Grid, { container: true, wrap: "nowrap", justifyContent: "space-between", alignItems: "center", children: [_jsx(Grid, { item: true, xs: 7, children: _jsx(TextField, { variant: "standard", required: true, style: { marginBottom: 16 }, fullWidth: true, name: `items.${index}.description`, disabled: item.isDescriptionDisabled, label: "Descrizione", value: item.description, onChange: formik.handleChange }) }), _jsx(Grid, { item: true, xs: 4, children: _jsx(TextField, { variant: "standard", required: true, style: { marginBottom: 16 }, fullWidth: true, name: `items.${index}.amount`, label: "Importo da fatturare", InputProps: {
                                                                    startAdornment: (_jsx(InputAdornment, { position: "start", children: "\u20AC" })),
                                                                }, type: "number", value: $number.roundToSecondDigit(item.amount), onChange: formik.handleChange }) })] })] }), _jsx(Grid, { item: true, children: _jsx(Grid, { item: true, children: _jsx(DeleteIcon, { style: { cursor: 'pointer', textAlign: 'right' }, onClick: removeItem(index, remove) }) }) })] }, index) }) })))), _jsx(Button, { onClick: pushItem(push), style: { marginBottom: 16 }, startIcon: _jsx(AddIcon, {}), children: "Aggiungi altre componenti" })] })) }), _jsx(Grid, { container: true, justifyContent: "center", children: _jsx(Button, { type: "submit", variant: "contained", disabled: !PassiveInvoiceInputUtils.isPassiveInvoiceInput(formikValues), children: "Salva" }) })] }));
};
