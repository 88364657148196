import { leadModelMapper } from './leadModelMapper';
export const requestWithQuotesModelMapper = (quoteRequest) => ({
    id: quoteRequest.id,
    notes: quoteRequest.notes || '',
    rate: quoteRequest.rate,
    purpose: quoteRequest.purpose,
    requestedAmount: quoteRequest.requestedAmount,
    leftoverAmount: quoteRequest.leftoverAmount,
    procedureStatus: quoteRequest.procedureStatus,
    productType: quoteRequest.productType,
    property: {
        value: quoteRequest.property.value, // valore dell'immobile
        location: {
            city: quoteRequest.property.location.city,
            provinceCode: quoteRequest.property.location.provinceCode,
            postalCode: quoteRequest.property.location.postalCode,
        },
    },
    duration: quoteRequest.duration,
    applicants: quoteRequest.applicants.map(applicant => ({
        age: applicant.age,
        currentDebtInstallment: applicant.currentDebtInstallment,
        employment: applicant.employment,
        familyUnit: applicant.familyUnit,
        isForeigner: applicant.isForeigner,
        netIncome: applicant.netIncome,
        residenceLocation: applicant.residenceLocation === null
            ? null
            : {
                city: applicant.residenceLocation.city,
                provinceCode: applicant.residenceLocation.provinceCode,
                postalCode: applicant.residenceLocation.postalCode,
            },
        type: 'applicant',
    })),
    guarantors: quoteRequest.guarantors.map(guarantor => ({
        age: guarantor.age,
        currentDebtInstallment: guarantor.currentDebtInstallment,
        employment: guarantor.employment,
        familyUnit: guarantor.familyUnit,
        isForeigner: guarantor.isForeigner,
        netIncome: guarantor.netIncome,
        type: 'guarantor',
    })),
    submittedBy: { firstName: quoteRequest.submittedBy.firstName, lastName: quoteRequest.submittedBy.lastName },
    submittedAt: quoteRequest.submittedAt,
    lead: leadModelMapper(quoteRequest._embedded.lead),
    quotations: quoteRequest._embedded.quotations.map(quote => ({
        id: quote.id,
        rate: quote.rate,
        product: { externalId: quote.product.externalId, name: quote.product.name },
        institute: { externalId: quote.institute.externalId, name: quote.institute.name },
        appraisal: quote.appraisal,
        tan: quote.tan,
        spread: quote.spread,
        installment: quote.installment,
        taeg: quote.taeg,
        investigation: quote.investigation,
        alternativeTax: quote.alternativeTax, // imposta sostitutiva
        feasibilityConditionsAndOutcomes: {
            ltvRatio: quote.feasibilityConditionsAndOutcomes.ltvRatio,
            netIncomeInstallmentRatio: quote.feasibilityConditionsAndOutcomes.netIncomeInstallmentRatio,
            age: quote.feasibilityConditionsAndOutcomes.age.value,
            duration: quote.feasibilityConditionsAndOutcomes.duration.value,
            mediationAmount: {
                value: quote.feasibilityConditionsAndOutcomes.mediationAmount.value,
                outcome: quote.feasibilityConditionsAndOutcomes.mediationAmount.outcome,
            },
        },
        attributes: quote.attributes,
        submittedAt: quote.submittedAt,
    })),
});
