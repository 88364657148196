import { either, readonlyArray } from 'fp-ts';
import { constFalse, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { $identifier } from '../../model/Identifier';
import { UserC } from '../../model/User';
import { filter as filterUtils } from '../../utils/filter';
import { AGENTS_RECORDS_FILTER_FETCHED, AGENTS_RECORDS_FILTER_NOT_FETCHED, AGENTS_RECORDS_FILTER_REQUESTED, ASSIGNEE_FILTER_FAILURE, ASSIGNEE_FILTER_REQUEST, ASSIGNEE_FILTER_SUCCESS, RECORD_LIST_WITH_FILTERS_FAILURE, RECORD_LIST_WITH_FILTERS_REQUEST, RECORD_LIST_WITH_FILTERS_SUCCESS, STATUS_FILTER_FAILURE, STATUS_FILTER_REQUEST, STATUS_FILTER_SUCCESS, UPDATE_FILTER_FAILURE, UPDATE_FILTER_REQUEST, UPDATE_FILTER_SUCCESS, } from '../actions/filter';
import { FilterState } from '../state/filter';
export const canViewAgentsRecordsFilter = (idToExclude) => (u) => pipe(u, t.readonlyArray(UserC).decode, either.map(userHierarchy => pipe(userHierarchy, readonlyArray.filter(user => !$identifier.Eq2.equals(user.id, idToExclude)), readonlyArray.isNonEmpty)), either.getOrElse(constFalse));
export const FilterReducer = (state = FilterState.empty, { type, payload }) => {
    switch (type) {
        case ASSIGNEE_FILTER_REQUEST:
            return Object.assign(Object.assign({}, state), { isAssigneeLoading: true, assignee: state.assignee });
        case ASSIGNEE_FILTER_SUCCESS:
            return Object.assign(Object.assign({}, state), { isAssigneeLoading: false, assignee: payload.assignee });
        case ASSIGNEE_FILTER_FAILURE:
            return Object.assign(Object.assign({}, state), { isAssigneeLoading: false, assignee: [] });
        case STATUS_FILTER_REQUEST:
            return Object.assign(Object.assign({}, state), { isStatusLoading: true, status: state.status });
        case STATUS_FILTER_SUCCESS:
            return Object.assign(Object.assign({}, state), { isStatusLoading: false, status: payload.status });
        case STATUS_FILTER_FAILURE:
            return Object.assign(Object.assign({}, state), { isStatusLoading: false, status: [] });
        case UPDATE_FILTER_REQUEST:
            return Object.assign(Object.assign({}, state), { activeRecords: Object.assign(Object.assign({}, state.activeRecords), payload.activeRecords), closedRecords: Object.assign(Object.assign({}, state.closedRecords), payload.closedRecords), url: payload.url, isLoading: true, error: '', message: '' });
        case RECORD_LIST_WITH_FILTERS_REQUEST:
            return Object.assign(Object.assign({}, state), { activeRecords: Object.assign(Object.assign({}, state.activeRecords), payload.activeRecords), closedRecords: Object.assign(Object.assign({}, state.closedRecords), payload.closedRecords), isClosedRecordsSearching: filterUtils.isSearching(Object.assign(Object.assign({}, state.closedRecords), payload.closedRecords)), isActiveRecordsSearching: filterUtils.isSearching(Object.assign(Object.assign({}, state.activeRecords), payload.activeRecords)), url: payload.url, isLoading: true, error: '', message: '' });
        case UPDATE_FILTER_SUCCESS:
            return Object.assign(Object.assign({}, state), { activeRecords: Object.assign(Object.assign({}, state.activeRecords), payload.activeRecords), closedRecords: Object.assign(Object.assign({}, state.closedRecords), payload.closedRecords), url: payload.url, isLoading: false, message: '', error: '' });
        case RECORD_LIST_WITH_FILTERS_SUCCESS:
            return Object.assign(Object.assign({}, state), { activeRecords: Object.assign(Object.assign({}, state.activeRecords), payload.activeRecords), closedRecords: Object.assign(Object.assign({}, state.closedRecords), payload.closedRecords), isClosedRecordsSearching: filterUtils.isSearching(Object.assign(Object.assign({}, state.closedRecords), payload.closedRecords)), isActiveRecordsSearching: filterUtils.isSearching(Object.assign(Object.assign({}, state.activeRecords), payload.activeRecords)), url: payload.url, isLoading: false, message: '', error: '' });
        case RECORD_LIST_WITH_FILTERS_FAILURE:
        case UPDATE_FILTER_FAILURE:
            return Object.assign(Object.assign(Object.assign({}, state), payload), { isLoading: false, message: '' });
        case AGENTS_RECORDS_FILTER_NOT_FETCHED:
            return Object.assign(Object.assign({}, state), { canViewAgentsRecordsFilter: { isVisible: true, hasError: true } });
        case AGENTS_RECORDS_FILTER_REQUESTED:
            return Object.assign(Object.assign({}, state), { canViewAgentsRecordsFilter: Object.assign(Object.assign({}, state.canViewAgentsRecordsFilter), { hasError: false }) });
        case AGENTS_RECORDS_FILTER_FETCHED:
            return Object.assign(Object.assign({}, state), { canViewAgentsRecordsFilter: {
                    isVisible: canViewAgentsRecordsFilter(payload.loggedUserId)(payload.userHierarchy),
                    hasError: false,
                } });
        case 'security/LOGOUT:Done':
            return Object.assign(Object.assign({}, state), { canViewAgentsRecordsFilter: { isVisible: false, hasError: null } });
        default:
            return state;
    }
};
