import * as t from 'io-ts';
import { readonlyNonEmptyArray } from 'io-ts-types';
import { ArtooRecordAgentCodec } from '../ArtooRecordAgent/ArtooRecordAgent';
import { ArtooRecordDataCodec } from '../ArtooRecordData/ArtooRecordData';
import { ArtooRecordStateCodec } from '../ArtooRecordState/ArtooRecordState';
import { IdentifierCodec } from '../Identifier/Identifier';
export const ArtooRecordCodec = t.type({
    id: IdentifierCodec,
    data: ArtooRecordDataCodec,
    status: ArtooRecordStateCodec,
    agenti: readonlyNonEmptyArray(ArtooRecordAgentCodec),
}, 'ArtooRecord');
