import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { Container, Divider, Drawer, Grid, Link, Paper, Typography } from '@mui/material';
import { useState } from 'react';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { NotificationAction } from '../../../store/notification/NotificationAction';
import { Notification } from '../../../utils/notification';
import { paperworkIdView } from '../../view/PaperworkIdView';
import { PaperworkDetail } from '../molecules/PaperworkDetail';
import { PaperworkHistory } from '../molecules/PaperworkHistory';
export const Sidebar = ({ paperwork }) => {
    const [openPaperworkDetailDrawer, setOpenPaperworkDetailDrawer] = useState(false);
    const dispatch = useAppDispatch();
    return (_jsxs(Container, { maxWidth: "xs", component: Paper, elevation: 0, sx: { padding: '32px 16px', minHeight: '100vh', marginRight: '16px' }, children: [_jsx(Container, { children: _jsx(Drawer, { open: openPaperworkDetailDrawer, anchor: "right", children: _jsx(PaperworkDetail, { paperwork: paperwork, onClose: () => setOpenPaperworkDetailDrawer(false) }) }) }), _jsxs(Grid, { container: true, alignItems: "center", justifyContent: "space-between", flexWrap: "nowrap", sx: { mb: '8px', mt: '16px' }, children: [_jsxs(Grid, { container: true, item: true, alignItems: "center", flexWrap: "nowrap", children: [_jsx(DescriptionOutlinedIcon, {}), _jsx(Typography, { variant: "h3", component: "span", sx: { marginLeft: '8px' }, children: "Gestione pratica" })] }), _jsx(Grid, { container: true, item: true, justifyContent: "flex-end", xs: 3, children: _jsx(Typography, { children: _jsx(Link, { underline: "none", onClick: () => {
                                    setOpenPaperworkDetailDrawer(true);
                                }, children: "Dettagli" }) }) })] }), _jsx(Divider, { sx: { mt: '8px', mb: '8px' } }), _jsxs(Grid, { container: true, alignItems: "center", justifyContent: "space-between", flexWrap: "nowrap", children: [_jsx(Grid, { item: true, children: _jsx(Typography, { fontWeight: 500, color: "#6F797A", component: "span", children: "ID pratica" }) }), _jsx(Grid, { item: true, children: _jsxs(Grid, { container: true, item: true, flexWrap: "nowrap", alignItems: "center", children: [_jsx(Typography, { component: "span", children: paperworkIdView(paperwork.details.id) }), _jsx(FileCopyOutlinedIcon, { onClick: () => {
                                        navigator.clipboard.writeText(paperwork.details.id);
                                        dispatch(NotificationAction.Push(Notification.info('ID pratica copiato')));
                                    }, fontSize: "small", sx: { cursor: 'pointer', ml: '4px' } })] }) })] }), _jsx(Grid, { children: _jsx(PaperworkHistory, { transitionsHistory: paperwork.details.transitionsHistory }) })] }));
};
