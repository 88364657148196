import { either } from 'fp-ts';
import { all, call, cancel, put, select, take, takeLatest, takeLeading } from 'typed-redux-saga';
import { isUserAuthorized } from '../../../authorization/useAuthorization';
import { NotificationAction } from '../../../store/notification/NotificationAction';
import { PERMISSION, SecurityState } from '../../../store/security/SecurityState';
import { Notification } from '../../../utils/notification';
import { invoiceListVisibility } from '../../web/invoiceListVisibility';
import { $InvoiceListVisibility } from './InvoiceListVisibilityState';
function* currentVisibility() {
    try {
        yield* put($InvoiceListVisibility.InvoiceListCurrentVisibilityRequested());
        const visibilityEither = yield* call(invoiceListVisibility({
            method: 'get',
        }));
        if (either.isLeft(visibilityEither)) {
            throw visibilityEither.left;
        }
        yield* put($InvoiceListVisibility.InvoiceListCurrentVisibilityFetched(visibilityEither.right));
    }
    catch (e) {
        yield* put($InvoiceListVisibility.InvoiceListCurrentVisibilityNotFetched(e));
    }
}
function* toggleVisibility(action) {
    try {
        yield* put($InvoiceListVisibility.InvoiceListVisibilityToggleRequested());
        const response = yield* call(invoiceListVisibility({
            method: 'post',
            visibility: action.payload.showInvoiceList ? 'enable' : 'disable',
        }));
        if (either.isLeft(response)) {
            throw response.left;
        }
        yield* put($InvoiceListVisibility.InvoiceListVisibilityToggled(response.right));
        yield* put(NotificationAction.Push(Notification.info(`Visibilità fatture: ${response.right ? 'attivata' : 'disattivata'}`)));
    }
    catch (e) {
        yield* put($InvoiceListVisibility.InvoiceListVisibilityNotToggled(e));
    }
}
export function* InvoiceListVisibility() {
    yield* takeLeading($InvoiceListVisibility.start, function* () {
        const userPermissionList = yield* select(SecurityState.selector.permissionList);
        if (isUserAuthorized(userPermissionList)(PERMISSION.VIEW_INVOICE_LIST_SWITCH)) {
            yield* put($InvoiceListVisibility.Started());
            yield* call(currentVisibility);
            const task = yield* all([
                yield* takeLatest($InvoiceListVisibility.toggleInvoiceListVisibility, toggleVisibility),
            ]);
            yield* take($InvoiceListVisibility.stop);
            yield* cancel(task);
            yield* put($InvoiceListVisibility.Stopped());
        }
    });
}
