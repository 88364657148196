import * as t from 'io-ts';
import { IdentifierC } from './Identifier';
import { RoleC } from './Role';
import { UserDataC } from './UserDataC';
export const UserC = t.intersection([
    t.type({
        id: IdentifierC,
        username: t.string,
        email: t.string,
        enabled: t.boolean,
        roles: t.array(RoleC),
        first_name: t.string,
        last_name: t.string,
        created_at: t.string,
    }),
    t.partial({
        data: t.union([t.array(t.unknown), UserDataC]),
    }),
], 'User');
