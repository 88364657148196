import { createSlice } from '@reduxjs/toolkit';
import { array, readonlyArray } from 'fp-ts';
import { constant, identity, pipe } from 'fp-ts/function';
import { BazaarQuotationUtils } from '../models/BazaarQuotation.codec';
import { ChannelUtils } from '../models/Channel.codec';
import { LocationUtils } from '../models/Location.codec';
import { PurposeUtils } from '../models/Purpose.codec';
import { QuotationUtils } from '../models/Quotation.codec';
import { searchStateMapper } from './mappers/searchMapper';
const INSTITUTES = [
    'Banca di Cividale',
    'Banco BPM',
    'Banco di Sardegna',
    'BCC Roma',
    'BNL',
    'BPER',
    'CheBanca!',
    'Credit Agricole',
    'Extrabanca',
    'ING Bank',
    'MPS',
];
const INITIAL_FILTER_COUNTER = 1;
export const getInstitutesDefaultFilter = () => pipe(INSTITUTES, readonlyArray.map(institute => ({ name: institute, isChecked: false })), readonlyArray.toArray);
export const getAttributesDefaultFilter = () => [
    { isChecked: false, name: 'green', label: 'Mutuo green' },
    { isChecked: false, name: 'under36', label: 'Mutuo under 36' },
];
export const leadInitialValues = {
    channel: '',
    email: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
};
const initialState = {
    loading: {
        firstParticipantPlaceOfResidenceList: false,
        realEstateLocationList: false,
        quotationList: false,
        fetchLeads: false,
        saveLead: false,
        updateRequest: false,
    },
    quotationRequestId: null,
    leadsSearch: [],
    quotationSelected: null,
    quotationParticipants: [],
    quotationList: [],
    quotationListLength: 0,
    activeFiltersCounter: INITIAL_FILTER_COUNTER,
    initialValues: Object.assign(Object.assign({}, leadInitialValues), { notes: '', tempNotes: '', filters: {
            rate: BazaarQuotationUtils.rate.FIXED,
            institutes: getInstitutesDefaultFilter(),
            attributes: getAttributesDefaultFilter(),
        }, rate: BazaarQuotationUtils.rate.FIXED, procedureStatus: '', productType: 'Mutuo', mortgageDuration: '', formattedLeftover: '', rawLeftover: '', formattedRealEstateActualValue: '', rawRealEstateActualValue: '', realEstateLocation: {
            name: '',
            postalCode: '',
            province: { shortName: '' },
        }, realEstateLocationInput: '', formattedRealEstateRequestedAmount: '', rawRealEstateRequestedAmount: '', formattedBrokerage: '', rawBrokerage: '', tempFormattedBrokerage: '', tempRawBrokerage: '', mortgagePurpose: '', participants: [
            {
                placeOfResidence: {
                    name: '',
                    postalCode: '',
                    province: { shortName: '' },
                },
                placeOfResidenceInput: '',
                nationality: 'italian',
                age: '',
                household: '',
                job: '',
                formattedNetMonthlyIncome: '',
                rawNetMonthlyIncome: '',
                formattedOngoingDebtInstallment: '',
                rawOngoingDebtInstallment: '',
                type: 'richiedente',
            },
        ] }),
    realEstateLocationList: [],
    firstParticipantPlaceOfResidenceList: [],
    channelList: ChannelUtils.channelList,
    purposeList: PurposeUtils.purposeList,
};
const overrideQuotation = (quotationKey, propsToOverride) => (quotationList) => pipe(quotationList, array.map(quotation => (quotation.key === quotationKey ? Object.assign(Object.assign({}, quotation), propsToOverride) : quotation)));
const overrideEveryQuotation = (propsToOverride) => (quotationList) => pipe(quotationList, array.map(quotation => (Object.assign(Object.assign({}, quotation), propsToOverride))));
export const quoteSlice = createSlice({
    name: 'Quote',
    initialState,
    reducers: {
        start: (state, _) => state,
        stop: identity,
        selectQuotation: (state, _) => state,
        fetchRealEstateLocation: (state, _) => state,
        fetchFirstParticipantPlaceOfResidence: (state, _) => state,
        fetchQuotation: (state, _) => state,
        updateRequest: (state, _) => state,
        UpdateRequestRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { updateRequest: true }) })),
        RequestUpdated: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { updateRequest: false }) })),
        RequestNotUpdated: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { updateRequest: false }) })),
        searchLeads: (state, _) => state,
        saveLead: (state, _) => state,
        increaseFilterCounter: (state, _) => state,
        decreaseFilterCounter: (state, _) => state,
        resetFilterCounter: identity,
        resetIsSaved: identity,
        IsSavedResetted: state => (Object.assign(Object.assign({}, state), { quotationList: overrideEveryQuotation({ isSaved: false })(state.quotationList) })),
        saveQuote: (state, _) => state,
        SaveQuoteRequested: (state, event) => (Object.assign(Object.assign({}, state), { quotationList: overrideQuotation(event.payload, { isSaving: true })(state.quotationList) })),
        QuoteSaved: (state, event) => (Object.assign(Object.assign({}, state), { quotationList: overrideQuotation(event.payload, { isSaved: true, isSaving: false })(state.quotationList) })),
        QuoteNotSaved: (state, event) => (Object.assign(Object.assign({}, state), { quotationList: overrideQuotation(event.payload, { isSaving: false })(state.quotationList) })),
        FilterCounterResetted: state => (Object.assign(Object.assign({}, state), { activeFiltersCounter: INITIAL_FILTER_COUNTER })),
        FilterCounterIncreased: (state, event) => (Object.assign(Object.assign({}, state), { activeFiltersCounter: event.payload + 1 })),
        FilterCounterDecreased: (state, event) => (Object.assign(Object.assign({}, state), { activeFiltersCounter: event.payload - 1 })),
        SaveLeadRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { saveLead: true }) })),
        LeadSaved: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { saveLead: false }) })),
        LeadNotSaved: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { saveLead: false }) })),
        SearchLeadsRequested: state => (Object.assign(Object.assign({}, state), { leadsSearch: [], loading: Object.assign(Object.assign({}, state.loading), { fetchLeads: true }) })),
        LeadsFound: (state, event) => (Object.assign(Object.assign({}, state), { leadsSearch: event.payload, loading: Object.assign(Object.assign({}, state.loading), { fetchLeads: false }) })),
        LeadsNotFound: state => (Object.assign(Object.assign({}, state), { leadsSearch: [], loading: Object.assign(Object.assign({}, state.loading), { fetchLeads: false }) })),
        QuotationRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { quotationList: true }) })),
        QuotationFetched: (state, event) => (Object.assign(Object.assign({}, state), { initialValues: event.payload.search, quotationList: QuotationUtils.quotationList(event.payload.bazaarQuotation), loading: Object.assign(Object.assign({}, state.loading), { quotationList: false }), quotationListLength: event.payload.bazaarQuotation.length, quotationParticipants: pipe(event.payload.participants, readonlyArray.toArray), quotationRequestId: event.payload.quotationRequestId })),
        QuotationNotFetched: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { quotationList: false }) })),
        RealEstateLocationRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { realEstateLocationList: true }) })),
        RealEstateLocationFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { realEstateLocationList: false }), realEstateLocationList: LocationUtils.realEstateLocationList(event.payload) })),
        RealEstateLocationNotFetched: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { realEstateLocationList: false }) })),
        FirstParticipantPlaceOfResidenceRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { firstParticipantPlaceOfResidenceList: true }) })),
        FirstParticipantPlaceOfResidenceFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { firstParticipantPlaceOfResidenceList: false }), firstParticipantPlaceOfResidenceList: LocationUtils.participantLocationList(event.payload) })),
        FirstParticipantPlaceOfResidenceNotFetched: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { firstParticipantPlaceOfResidenceList: false }) })),
        Stopped: constant(initialState),
        Started: (state, event) => event.payload.requestWithQuotesModel
            ? Object.assign(Object.assign({}, state), { initialValues: searchStateMapper(event.payload.requestWithQuotesModel) }) : state,
    },
});
export const $Quote = quoteSlice.actions;
export const $QuoteSlice = {
    selector: {
        initialValues: (state) => state.quote.initialValues,
        channelList: (state) => state.quote.channelList,
        purposeList: (state) => state.quote.purposeList,
        firstParticipantPlaceOfResidenceList: (state) => state.quote.firstParticipantPlaceOfResidenceList,
        isFirstParticipantPlaceOfResidenceListLoading: (state) => state.quote.loading.firstParticipantPlaceOfResidenceList,
        realEstateLocationList: (state) => state.quote.realEstateLocationList,
        isRealEstateLocationListLoading: (state) => state.quote.loading.realEstateLocationList,
        quotationList: (state) => state.quote.quotationList,
        isQuotationListLoading: (state) => state.quote.loading.quotationList,
        quotationListLength: (state) => state.quote.quotationListLength,
        quotationParticipants: (state) => state.quote.quotationParticipants,
        leadsSearch: (state) => state.quote.leadsSearch,
        isSearchingLead: (state) => state.quote.loading.fetchLeads,
        isSavingLead: (state) => state.quote.loading.saveLead,
        isRequestUpdating: (state) => state.quote.loading.updateRequest,
        quotationRequestId: (state) => state.quote.quotationRequestId,
        activeFiltersCounter: (state) => state.quote.activeFiltersCounter,
    },
};
export const QuoteState = quoteSlice.reducer;
