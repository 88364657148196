import { either } from 'fp-ts';
import { all, call, cancel, put, select, take, takeEvery, takeLatest, takeLeading } from 'typed-redux-saga';
import { isUserAuthorized } from '../../../authorization/useAuthorization';
import { PERMISSION, SecurityState } from '../../../store/security/SecurityState';
import { InvoiceStatusCodeUtils } from '../../models/InvoiceStatusCode/InvoiceStatusCode';
import { PaginationUtils } from '../../models/Pagination/Pagination.codec';
import { downloadActiveInvoice } from '../../web/activeInvoice/downloadActiveInvoice';
import { getActiveInvoiceList } from '../../web/activeInvoice/getActiveInvoiceList';
import { $ActiveInvoiceHistory, $activeInvoiceHistorySlice } from './ActiveInvoiceHistoryState';
function* tryDownloadInvoice(downloadCommand) {
    yield* put($ActiveInvoiceHistory.InvoiceRequested(downloadCommand.payload.id));
    const invoicePdfEither = yield* call(downloadActiveInvoice({
        id: downloadCommand.payload.id,
        customerFullName: downloadCommand.payload.customerFullName,
        invoiceTypology: downloadCommand.payload.invoiceTypology,
    }));
    if (either.isLeft(invoicePdfEither)) {
        yield* put($ActiveInvoiceHistory.InvoiceNotDownloaded(downloadCommand.payload.id));
    }
    else {
        yield* put($ActiveInvoiceHistory.InvoiceDownloaded(downloadCommand.payload.id));
    }
}
function* fetchInvoiceList(pagination, activeInvoiceHistoryForm) {
    yield* put($ActiveInvoiceHistory.InvoiceListRequested());
    const activeInvoiceListEither = yield* call(getActiveInvoiceList(Object.assign({ sort: '-_timestamps.updatedAt', statusCode: InvoiceStatusCodeUtils.TRANSMITTED, page: pagination }, activeInvoiceHistoryForm)));
    if (either.isLeft(activeInvoiceListEither)) {
        yield* put($ActiveInvoiceHistory.InvoiceListNotFetched());
    }
    else {
        yield* put($ActiveInvoiceHistory.InvoiceListFetched({
            pagination: {
                limit: pagination.limit,
                offset: pagination.offset,
            },
            invoiceList: activeInvoiceListEither.right,
        }));
    }
}
function* fetchNextInvoicePage() {
    const pagination = yield* select($activeInvoiceHistorySlice.selector.pagination);
    yield* call(fetchInvoiceList, {
        limit: pagination.limit,
        offset: PaginationUtils.increaseOffset($activeInvoiceHistorySlice.PAGE_LIMIT)(pagination),
    });
}
function* fetchPreviousInvoicePage() {
    const pagination = yield* select($activeInvoiceHistorySlice.selector.pagination);
    const offset = PaginationUtils.decreaseOffset($activeInvoiceHistorySlice.PAGE_LIMIT)(pagination);
    if (offset >= 0) {
        yield* call(fetchInvoiceList, {
            limit: pagination.limit,
            offset: offset,
        });
    }
}
export function* ActiveInvoiceHistorySaga() {
    yield* takeLeading($ActiveInvoiceHistory.start, function* () {
        const userPermissionList = yield* select(SecurityState.selector.permissionList);
        if (isUserAuthorized(userPermissionList)(PERMISSION.VIEW_ACTIVE_INVOICE_HISTORY)) {
            yield* put($ActiveInvoiceHistory.Started());
            const paginationInitialState = {
                limit: $activeInvoiceHistorySlice.PAGE_LIMIT,
                offset: 0,
            };
            const task = yield* all([
                yield* takeLatest($ActiveInvoiceHistory.fetchInvoiceList, function* (fetchInvoiceListCommand) {
                    yield* call(fetchInvoiceList, fetchInvoiceListCommand.payload.pagination, fetchInvoiceListCommand.payload.activeInvoiceHistoryForm);
                }),
                yield* takeEvery($ActiveInvoiceHistory.downloadInvoice, tryDownloadInvoice),
                yield* takeLeading($ActiveInvoiceHistory.fetchNextInvoicePage, function* () {
                    yield* call(fetchNextInvoicePage);
                }),
                yield* takeLeading($ActiveInvoiceHistory.fetchPreviousInvoicePage, function* () {
                    yield* call(fetchPreviousInvoicePage);
                }),
                yield* takeLeading($ActiveInvoiceHistory.fetchFirstInvoicePage, function* () {
                    yield* call(fetchInvoiceList, paginationInitialState);
                }),
            ]);
            yield* put($ActiveInvoiceHistory.fetchInvoiceList({
                pagination: paginationInitialState,
            }));
            yield* take($ActiveInvoiceHistory.stop);
            yield* cancel(task);
            yield* put($ActiveInvoiceHistory.Stopped());
        }
    });
}
