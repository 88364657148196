import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import qs from 'qs';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
import { CommissionPlansCodec } from '../models/CommissionPlans.codec';
export const getCommissionPlans = (queryParams) => {
    return pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get(`${BAZAAR_URL}/api/v1/commission-plans?${qs.stringify(queryParams)}`), e => e), taskEither.chainEitherKW(response => CommissionPlansCodec.decode(response.data)));
};
