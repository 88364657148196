import { $type } from 'fortepiano';
import * as t from 'io-ts';
import { readonlyNonEmptyArray } from 'io-ts-types';
export const ActiveInvoiceTransmisionInputCodec = t.type({
    customer: t.type({
        firstName: t.string,
        lastName: t.string,
        taxCode: t.string,
        zipCode: t.string,
        city: t.string,
        address: t.string,
        province: t.string,
    }),
    items: readonlyNonEmptyArray(t.type({
        agentId: t.string,
        agentFullName: t.string,
        amount: t.number,
        recordId: t.string,
        typology: $type.literalUnion(['MEDIAZIONE', 'PENALE']),
        description: t.string,
    }, 'ActiveInvoiceTransmisionInputItem'), 'ActiveInvoiceTransmisionInputItems'),
}, 'ActiveInvoiceTransmisionInput');
