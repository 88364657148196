import * as t from 'io-ts';
import { NonEmptyString } from 'io-ts-types/lib/NonEmptyString';
import { IdentifierCodec } from '../Identifier/Identifier';
export const UserCodec = t.intersection([
    t.type({
        id: IdentifierCodec,
        username: NonEmptyString,
        email: NonEmptyString,
        enabled: t.boolean,
        firstName: t.string,
        lastName: t.string,
        created_at: NonEmptyString,
        fullName: t.string,
    }),
    t.partial({ data: t.record(t.string, t.unknown) }),
], 'User');
