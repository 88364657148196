import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Grid, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import { useCallback, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { $PaperworkProcess } from '../../state/PaperworkProcessState';
import { FilePreview } from '../molecules/FilePreview';
export const useStyles = makeStyles()(() => ({
    link: { color: '#405AA9', cursor: 'pointer', textDecoration: 'underline' },
    dropAreaText: {
        margin: '0% 20%',
    },
    dropArea: {
        padding: 20,
        textAlign: 'center',
        height: 50,
    },
    dragOverFalse: {
        background: '#fafafa',
        border: '2px dashed #aaa',
    },
    dragOverTrue: {
        background: '#eee',
        border: '2px dashed #000',
    },
    mt8: {
        marginTop: 8,
    },
    mb16px: {
        marginBottom: '16px',
    },
}));
export const DragDropFileUpload = ({ documentTypeId, canUpload, files }) => {
    const [dragOver, setDragOver] = useState(false);
    const { classes } = useStyles();
    const dispatch = useAppDispatch();
    const handleDragOver = useCallback((event) => {
        event.preventDefault();
        setDragOver(true);
    }, []);
    const handleDragLeave = useCallback((event) => {
        event.preventDefault();
        setDragOver(false);
    }, []);
    const handleDrop = useCallback((event) => {
        event.preventDefault();
        setDragOver(false);
        const fileList = (event.dataTransfer !== null && event.dataTransfer.files && event.dataTransfer.files) || null;
        dispatch($PaperworkProcess.uploadFiles({ fileList: Array.from(fileList || []), documentTypeId: documentTypeId }));
    }, [dispatch, documentTypeId]);
    const handleChange = useCallback((event) => {
        dispatch($PaperworkProcess.uploadFiles({
            fileList: Array.from(event.target.files || []),
            documentTypeId: documentTypeId,
        }));
    }, [dispatch, documentTypeId]);
    return (_jsxs(_Fragment, { children: [canUpload && (_jsxs(Paper, { elevation: 0, component: Paper, onDragOver: handleDragOver, onDragLeave: handleDragLeave, onDrop: handleDrop, className: clsx(classes.mb16px, classes.dropArea, dragOver ? classes.dragOverTrue : classes.dragOverFalse), children: [_jsx("input", { accept: ".pdf,.PDF,.jpg,.jpeg,.jfif,.JPG,.JPE,.png,.PNG,.bmp,.xls,.xlsx,.csv,.ods,.doc,.docx,.txt,.rtf,.odt", style: { display: 'none' }, id: "raised-button-file", multiple: true, type: "file", onChange: handleChange }), _jsx(Grid, { style: { height: '100%' }, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", children: _jsx("div", { className: classes.dropAreaText, children: _jsx(Typography, { children: _jsxs("strong", { children: [_jsx("label", { className: classes.link, htmlFor: "raised-button-file", children: "Clicca qui" }), ' ', "per caricare il file, oppure trascinalo in questa area", ' '] }) }) }) })] })), _jsx(Grid, { container: true, sx: { '&>.MuiGrid-root': { mb: '8px' } }, children: files &&
                    files.map((file, index) => (_jsx(FilePreview, { file: file, onDelete: canUpload ? () => { } : undefined }, index))) })] }));
};
