import { combineReducers } from '@reduxjs/toolkit';
import { RecordItemState } from './RecordItemState';
import { DocumentState } from './documents/DocumentState';
import { RecordHistoryState } from './history/HistoryState';
const reducer = combineReducers({
    item: RecordItemState.reducer,
    documents: DocumentState.reducer,
    history: RecordHistoryState.reducer,
});
export const RecordState = {
    reducer,
};
