import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { $Session } from '../../session/state/SessionState';
export const TokenProvider = ({ tokenRefreshIntervalMilliseconds, children }) => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!tokenRefreshIntervalMilliseconds) {
            return;
        }
        dispatch($Session.start());
        const tryToRefreshToken = () => {
            dispatch($Session.refreshToken());
        };
        const intervalId = setInterval(tryToRefreshToken, tokenRefreshIntervalMilliseconds);
        return () => {
            clearInterval(intervalId);
            dispatch($Session.stop());
        };
    }, [dispatch, tokenRefreshIntervalMilliseconds]);
    return children;
};
