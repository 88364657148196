import { createSlice } from '@reduxjs/toolkit';
import { option } from 'fp-ts';
import { constant, identity } from 'fp-ts/function';
const initialState = {
    loading: false,
    error: option.none,
    visibility: false,
};
export const invoiceListVisibilitySlice = createSlice({
    name: 'InvoiceListVisibilitySaga',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        Started: identity,
        Stopped: constant(initialState),
        toggleInvoiceListVisibility: (state, _) => state,
        InvoiceListCurrentVisibilityRequested: state => (Object.assign(Object.assign({}, state), { loading: true, error: option.none })),
        InvoiceListCurrentVisibilityFetched: (state, action) => (Object.assign(Object.assign({}, state), { loading: false, error: option.none, visibility: action.payload })),
        InvoiceListCurrentVisibilityNotFetched: (state, action) => (Object.assign(Object.assign({}, state), { error: option.some(action.payload), loading: false })),
        InvoiceListVisibilityToggleRequested: state => (Object.assign(Object.assign({}, state), { loading: true, error: option.none })),
        InvoiceListVisibilityToggled: (state, action) => (Object.assign(Object.assign({}, state), { loading: false, error: option.none, visibility: action.payload })),
        InvoiceListVisibilityNotToggled: (state, action) => (Object.assign(Object.assign({}, state), { error: option.some(action.payload), loading: false })),
    },
});
const visibility = (state) => state.invoiceListVisibility.visibility;
const isLoading = (state) => state.invoiceListVisibility.loading;
export const $InvoiceListVisibility = invoiceListVisibilitySlice.actions;
export const $invoiceListVisibilitySlice = {
    selector: {
        visibility,
        isLoading,
    },
};
export default invoiceListVisibilitySlice.reducer;
