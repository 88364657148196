import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, IconButton, Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { tss } from 'tss-react';
import { paperworkIdView } from '../../view/PaperworkIdView';
import { Row } from '../atoms/Row';
const useStyles = tss.create(() => ({
    container: { padding: 12 },
    title: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '16px',
    },
    footer: {
        display: 'flex',
        justifyContent: 'center',
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'space-between',
        width: '332px',
    },
    content: { display: 'flex', flexDirection: 'column' },
}));
export function PaperworkDetail({ onClose, paperwork }) {
    const { classes, cx } = useStyles();
    return (_jsxs(Box, { className: classes.box, children: [_jsx("div", { className: classes.content, children: _jsxs("div", { className: classes.container, children: [_jsxs("div", { className: classes.title, children: [_jsx(Typography, { variant: "h2", children: "Gestione pratica" }), _jsx(IconButton, { onClick: onClose, children: _jsx(CloseIcon, {}) })] }), _jsx(Row, { label: "ID pratica", value: paperworkIdView(paperwork.details.id) }), _jsx(Row, { label: "Data di creazione", value: format(paperwork.details.createdAt, 'dd/MM/yyyy') }), _jsx(Row, { label: "Creata da", value: `${paperwork.details.createdBy.firstName} ${paperwork.details.createdBy.lastName}` }), _jsx(Row, { label: "In carico a", value: `${paperwork.details.assignedTo.firstName} ${paperwork.details.assignedTo.lastName}` }), _jsx(Row, { label: "Rubinetto", value: paperwork.data.rubinetto })] }) }), _jsxs("div", { children: [_jsx(Divider, {}), _jsx("div", { className: cx(classes.container, classes.footer), children: _jsx(Button, { variant: "contained", onClick: onClose, children: "Chiudi" }) })] })] }));
}
