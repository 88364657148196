import { createSlice } from '@reduxjs/toolkit';
import { option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { FILTER_STATUS } from '../../constants';
import { $routes } from '../../routes/routes';
const initialState = {};
const routeFromLocationStateOption = (from) => {
    let route = option.none;
    switch (from) {
        case 'activeRecords':
            route = option.some($routes.ACTIVE_RECORDS);
            break;
        case 'closedRecords':
            route = option.some($routes.CLOSED_RECORDS);
            break;
    }
    return route;
};
const fallbackRoute = (recordStatusCode) => {
    switch (recordStatusCode) {
        case FILTER_STATUS.EROGATA:
        case FILTER_STATUS.DECLINATA:
        case FILTER_STATUS.RITIRATA:
        case FILTER_STATUS.MANCANZA_REQUISITI:
        case FILTER_STATUS.ERRORE_CARICAMENTO:
        case FILTER_STATUS.SEGNALATO_WIF:
        case FILTER_STATUS.ARCHIVIATO:
            return $routes.CLOSED_RECORDS;
        default:
            return $routes.ACTIVE_RECORDS;
    }
};
const redirectRoute = (recordStatusCode) => (maybeLocationState) => pipe(maybeLocationState, option.fromNullable, option.chain(locationState => pipe(locationState.from, option.fromNullable, option.chain(routeFromLocationStateOption))), option.getOrElse(() => fallbackRoute(recordStatusCode)));
export const navigationSlice = createSlice({
    name: 'Navigation',
    initialState,
    reducers: {
        goBack: (state, _) => state,
    },
});
export const $Navigation = navigationSlice.actions;
export const $NavigationSlice = {
    redirectRoute,
    fallbackRoute,
    routeFromLocationStateOption,
    selector: {},
};
export const NavigationState = navigationSlice.reducer;
