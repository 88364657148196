import { isNone, none, some } from 'fp-ts/Option';
import { Refinement } from '../../../lib/Refinement';
import { Reducer } from '../../../lib/redux/Reducer';
import { HistoryAction } from './HistoryAction';
const empty = none;
const reducer = Reducer(Refinement.any(HistoryAction.Timeline.is.done))(empty)({
    [HistoryAction.Timeline.type.done]: (state, timeline) => (Object.assign(Object.assign({}, state), some(timeline))),
});
export const matchHistory = (state, props) => {
    if (isNone(state.record.history)) {
        return none;
    }
    if (isNone(state.record.item)) {
        return none;
    }
    if (state.record.item.value.id !== Number(props.match.params.id)) {
        return none;
    }
    return removeDuplicate(state.record.history);
};
const removeDuplicate = (list) => list._tag === 'Some'
    ? some(list.value.reduce((acc, val) => { var _a; return (acc.length && ((_a = acc[acc.length - 1]) === null || _a === void 0 ? void 0 : _a.status.code)) === val.status.code ? acc : [...acc, val]; }, []))
    : none;
export const RecordHistoryState = {
    reducer: reducer,
    selectors: {
        removeDuplicate,
        matchHistory: (state, props) => matchHistory(state, props),
    },
};
