import * as t from 'io-ts';
export const paperworkSummary = t.type({
    id: t.string,
    currentStatus: t.type({ code: t.string, label: t.string }),
    productType: t.string,
    assignedTo: t.type({
        artooId: t.string,
        firstName: t.string,
        lastName: t.string,
    }),
    createdBy: t.type({
        artooId: t.string,
        firstName: t.string,
        lastName: t.string,
    }),
    createdAt: t.string,
    data: t.type({
        nome: t.string,
        cognome: t.string,
        email: t.string,
        codice_fiscale: t.string,
        rubinetto: t.string,
        telefono_cellulare: t.string,
        tipo_di_prodotto_long: t.string,
    }),
});
