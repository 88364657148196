var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { taskEither } from 'fp-ts';
import { flow, identity, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { decode as JsonApiDecode } from 'json-api-codec';
import qs from 'qs';
import { HttpClient } from '../lib/HttpClient';
import { AssigneeC } from '../model/Assignee';
import { BASE_URL, RECORDS, RECORDS_STATES, RECORDS_USERS } from './constants';
export const getRecordList = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (filter = {}, customUrl) {
    return yield HttpClient.get({
        url: customUrl && customUrl !== '' ? `${BASE_URL}${customUrl}` : RECORDS,
        params: Object.assign({}, filter),
        paramsSerializer: params => {
            return qs.stringify(params);
        },
    });
});
export const getStatus = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (filter = {}) {
    return yield HttpClient.get({
        url: RECORDS_STATES,
        params: Object.assign({}, filter),
        paramsSerializer: params => {
            return qs.stringify(params);
        },
    });
});
export const getUser = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (filter = {}) {
    return yield pipe(taskEither.tryCatch(() => HttpClient.get({
        url: RECORDS_USERS,
        params: Object.assign({}, filter),
        paramsSerializer: params => qs.stringify(params),
    }), identity), taskEither.map(httpResponse => httpResponse.data), taskEither.chainEitherKW(flow(JsonApiDecode, t.array(AssigneeC).decode)))();
});
