import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()(theme => ({
    buttonField: {
        marginTop: theme.spacing(4),
        width: '100%',
    },
    textField: {
        marginRight: theme.spacing(1),
        marginTop: theme.spacing(2),
        width: '100%',
    },
}));
