import { Iterable } from './Iterable';
function all(...refinements) {
    return (u) => Iterable.foldr(() => true, (refinement, is) => refinement(u) && is())(refinements);
}
function any(...refinements) {
    return (u) => Iterable.foldr(() => false, (refinement, is) => refinement(u) || is(), Iterable.Fold.Strategy.Recursion)(refinements);
}
export const Refinement = {
    all: all,
    any: any,
};
