import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { array } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { $InvoiceCreationFromRecord, $invoiceCreationFromRecordSlice, } from '../state/invoiceCreationFromRecord/InvoiceCreationFromRecordState';
import { Banner } from './Banner';
import { DialogTitle } from './DialogTitle';
export const InvoiceFromRecordDialog = ({ onClose, userFullName, recordId, payins, isTransmitted }) => {
    const dispatch = useAppDispatch();
    const isLoading = useAppSelector($invoiceCreationFromRecordSlice.selector.isLoading);
    const invoicesItemsNumber = useAppSelector($invoiceCreationFromRecordSlice.selector.invoicesItemsNumber);
    const invoicesItemsNumberError = useAppSelector($invoiceCreationFromRecordSlice.selector.invoicesItemsNumberError);
    useEffect(() => {
        dispatch($InvoiceCreationFromRecord.start({ recordId }));
        return () => {
            dispatch($InvoiceCreationFromRecord.stop());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(Dialog, { open: true, fullWidth: true, maxWidth: "sm", children: [_jsx(DialogTitle, { onClose: onClose, title: `${userFullName} - Pratica ${recordId}` }), _jsxs(DialogContent, { children: [invoicesItemsNumberError !== null && _jsx(Banner, { message: invoicesItemsNumberError }), _jsxs(Grid, { container: true, style: { marginBottom: 14 }, children: [invoicesItemsNumber !== null && invoicesItemsNumber > 0 && !isTransmitted && (_jsx(Card, { elevation: 0, style: {
                                    backgroundColor: '#F6F6F6',
                                    width: '100%',
                                    marginBottom: 16,
                                }, square: true, children: _jsx(CardContent, { children: _jsx(Grid, { children: _jsxs(Typography, { children: [_jsx("strong", { children: "Fatture da inviare:" }), " ", invoicesItemsNumber] }) }) }) })), payins.length === 0 ? (_jsx(Typography, { children: "Payin non disponibile" })) : (pipe(payins, array.mapWithIndex((key, payin) => (_jsxs(Box, { style: {
                                    width: '100%',
                                    marginBottom: 16,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                }, children: [_jsx(Grid, { container: true, style: { marginBottom: 8 }, children: _jsx(Typography, { children: _jsx("strong", { children: payin.productType }) }) }), _jsxs(Grid, { container: true, wrap: "nowrap", justifyContent: "space-between", children: [_jsxs(Grid, { item: true, children: [_jsx(Typography, { children: "Totale fatturazione" }), _jsx(Typography, { children: payin.totalCommissionAmount })] }), _jsxs(Grid, { item: true, children: [_jsx(Typography, { children: "Payin residuo" }), _jsx(Typography, { children: payin.leftoverPayinAmount })] }), _jsxs(Grid, { item: true, children: [_jsx(Typography, { children: "Payin da fatturare" }), _jsx(Typography, { children: payin.billablePayinAmount })] })] })] }, key)))))] }), !isTransmitted && payins.length > 0 && (_jsx(Grid, { container: true, justifyContent: "center", children: _jsx(Button, { variant: "contained", disabled: isLoading, onClick: () => {
                                dispatch($InvoiceCreationFromRecord.createInvoice({
                                    recordId,
                                    onSuccess: onClose,
                                }));
                            }, children: "Crea" }) }))] })] }));
};
