import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import React, { useState } from 'react';
import { $url } from '../../utils/Url';
import History from '../History/';
import { Sidebar } from '../Sidebar/SidebarComponent';
import Topbar from '../Topbar/';
import { useStyles } from './Layout.styles';
export const Layout = ({ component: Component, props }) => {
    const url = new URL(window.location.href);
    const hasHistory = $url.matchPathname(url)(/pratica\/.+$/);
    const [open, setOpen] = useState(false);
    const { classes } = useStyles();
    return (_jsxs("div", { className: classes.height100, children: [_jsx(Topbar, { setOpen: setOpen }), _jsx(Sidebar, { open: open, setOpen: setOpen }), _jsxs("div", { className: clsx(hasHistory && classes.gridContainer, classes.height100), children: [_jsx("div", { id: "scroll-navbar", className: clsx(classes.overflowAuto, classes.heightCalc), children: _jsx(Component, Object.assign({}, props)) }), hasHistory && _jsx(History, Object.assign({}, props))] })] }));
};
