import { createSlice } from '@reduxjs/toolkit';
import { $string } from 'fortepiano';
import { option, readonlyArray, readonlyNonEmptyArray, readonlyRecord } from 'fp-ts';
import { constant, constFalse, identity, pipe } from 'fp-ts/function';
import { $currency } from '../../models/Currency/Currency';
import { PaginationUtils } from '../../models/Pagination/Pagination.codec';
const PAGE_LIMIT = 50;
const initialState = {
    loading: { table: false, invoiceIdList: {} },
    table: null,
    tablePagination: {
        limit: PAGE_LIMIT,
        offset: 0,
        currentPage: 0,
        isNextPageDisabled: false,
        isPreviousPageDisabled: false,
    },
    initialValues: { 'items.recordId': '' },
};
const invoiceHistoryTable = (activeInvoiceList) => ({
    headers: {
        customerFullName: 'Nome e cognome',
        amount: 'Imponibile',
        agentFullName: 'Collaboratore',
        typology: 'Tipologia',
        id: 'ID',
    },
    body: pipe(activeInvoiceList, readonlyArray.map(activeInvoice => ({
        customerFullName: $string.infix(' ')(activeInvoice.customer.firstName, activeInvoice.customer.lastName),
        amount: pipe(activeInvoice.items, readonlyNonEmptyArray.head, activeInvoiceItem => activeInvoiceItem.amount, $currency.toEuros),
        agentFullName: pipe(activeInvoice.items, readonlyNonEmptyArray.head, activeInvoiceItem => activeInvoiceItem.agentFullName),
        typology: pipe(activeInvoice.items, readonlyNonEmptyArray.head, activeInvoiceItem => activeInvoiceItem.typology, $string.lowercase, $string.capitalize),
        id: activeInvoice._id,
    }))),
});
const isInvoiceDownloading = (invoiceId) => (invoiceIdList) => pipe(invoiceIdList, readonlyRecord.lookup(invoiceId.toString()), option.getOrElse(constFalse));
const isNextPageDisabled = (pagination, invoiceList) => invoiceList.length < pagination.limit;
const tablePagination = (pagination, invoiceList) => (Object.assign(Object.assign({}, pagination), { currentPage: PaginationUtils.currentPage(pagination), isNextPageDisabled: isNextPageDisabled(pagination, invoiceList), isPreviousPageDisabled: !PaginationUtils.canGoBack(pagination) }));
export const activeInvoiceHistorySlice = createSlice({
    name: 'ActiveInvoiceHistorySaga',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        fetchNextInvoicePage: identity,
        fetchPreviousInvoicePage: identity,
        fetchFirstInvoicePage: identity,
        fetchInvoiceList: (state, _) => state,
        downloadInvoice: (state, _) => state,
        InvoiceListRequested: state => (Object.assign(Object.assign({}, state), { table: null, loading: Object.assign(Object.assign({}, state.loading), { table: true }) })),
        InvoiceListFetched: (state, event) => (Object.assign(Object.assign({}, state), { table: invoiceHistoryTable(event.payload.invoiceList), tablePagination: tablePagination(event.payload.pagination, event.payload.invoiceList), loading: Object.assign(Object.assign({}, state.loading), { table: false }) })),
        InvoiceListNotFetched: state => (Object.assign(Object.assign({}, state), { table: null, loading: Object.assign(Object.assign({}, state.loading), { table: false }) })),
        InvoiceRequested: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { invoiceIdList: Object.assign(Object.assign({}, state.loading.invoiceIdList), { [event.payload]: true }) }) })),
        InvoiceDownloaded: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { invoiceIdList: Object.assign(Object.assign({}, state.loading.invoiceIdList), { [event.payload]: false }) }) })),
        InvoiceNotDownloaded: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { invoiceIdList: Object.assign(Object.assign({}, state.loading.invoiceIdList), { [event.payload]: false }) }) })),
        Started: identity,
        Stopped: constant(initialState),
    },
});
export const $ActiveInvoiceHistory = activeInvoiceHistorySlice.actions;
export const $activeInvoiceHistorySlice = {
    isInvoiceDownloading,
    PAGE_LIMIT,
    selector: {
        pagination: (state) => state.activeInvoiceHistory.tablePagination,
        initialValues: (state) => state.activeInvoiceHistory.initialValues,
        tableOption: (state) => state.activeInvoiceHistory.table === null || state.activeInvoiceHistory.table.body === null
            ? option.none
            : option.some(state.activeInvoiceHistory.table),
        isTableLoading: (state) => state.activeInvoiceHistory.loading.table,
        isInvoiceLoading: (state) => (invoiceId) => isInvoiceDownloading(invoiceId)(state.activeInvoiceHistory.loading.invoiceIdList),
    },
};
export default activeInvoiceHistorySlice.reducer;
