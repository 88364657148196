import { ALL_FILTER } from '../constants';
import { isObjectEmpty, objectWithValues } from './object';
export const isObjectWithValidValueEmpty = (obj) => {
    const objWithValidValues = objectWithValidValue(obj);
    return isObjectEmpty(objWithValidValues);
};
export const objectWithValidValue = (obj) => {
    const withValues = objectWithValues(obj);
    return Object.keys(withValues)
        .filter((key) => obj[key].length > 1 ||
        (typeof obj[key] === 'boolean' && obj[key] === true) ||
        (typeof obj[key] === 'number' && obj[key].toString().length > 1) ||
        Array.isArray(obj[key]) ||
        key === 'hidden' ||
        key === 'agenteId' ||
        key === 'states')
        .reduce((acc, key) => (Object.assign(Object.assign({}, acc), { [key]: obj[key] })), {});
};
export const isSearching = (obj) => {
    const searchObj = Object.keys(obj).reduce((acc, key) => key === 'hidden' ||
        (key === 'states' && (obj[key] === ALL_FILTER.STATES || obj[key] === ALL_FILTER.RECORDS))
        ? acc
        : Object.assign(Object.assign({}, acc), { [key]: obj[key] }), {});
    return !isObjectWithValidValueEmpty(objectWithValidValue(searchObj));
};
export const filter = { isSearching, fromValues: objectWithValidValue };
