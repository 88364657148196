import { createSlice } from '@reduxjs/toolkit';
import { option } from 'fp-ts';
import { constant, identity, pipe } from 'fp-ts/function';
import { $currency } from '../../models/Currency/Currency';
const initialState = {
    loading: false,
    table: null,
    artooRecordAgents: null,
    artooRecordData: null,
    initialValues: { recordId: '' },
};
const artooRecordTable = (artooRecord) => ({
    headers: {
        clientFullName: 'Nome e cognome',
        recordId: 'ID pratica',
        brokerageAmount: 'Mediazione',
        recordState: 'Stato di lavorazione',
    },
    body: [
        {
            clientFullName: `${artooRecord.data.nome} ${artooRecord.data.cognome}`,
            recordId: artooRecord.id,
            brokerageAmount: pipe(artooRecord.data.mutuo_importo_di_mediazione, option.fromNullable, option.map($currency.toEuros), option.getOrElse(constant('-'))),
            recordState: artooRecord.status.name,
        },
    ],
});
export const activeInvoicingSlice = createSlice({
    name: 'ActiveInvoicingSaga',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        fetchRecord: (state, _) => state,
        RecordRequested: state => (Object.assign(Object.assign({}, state), { loading: true, table: null, artooRecordAgents: null, artooRecordData: null })),
        RecordFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: false, table: artooRecordTable(event.payload), artooRecordAgents: event.payload.agenti, artooRecordData: event.payload.data })),
        RecordNotFetched: state => (Object.assign(Object.assign({}, state), { loading: false, table: null, artooRecordAgents: null, artooRecordData: null })),
        Started: identity,
        Stopped: constant(initialState),
    },
});
export const $ActiveInvoicing = activeInvoicingSlice.actions;
export const $activeInvoicingSlice = {
    selector: {
        table: (state) => state.activeInvoicing.table,
        isLoading: (state) => state.activeInvoicing.loading,
        initialValues: (state) => state.activeInvoicing.initialValues,
        artooRecordData: (state) => state.activeInvoicing.artooRecordData,
        artooRecordAgents: (state) => state.activeInvoicing.artooRecordAgents,
    },
};
export default activeInvoicingSlice.reducer;
