import { either } from 'fp-ts';
import * as t from 'io-ts';
import { push } from 'redux-first-history';
import { all, call, cancel, debounce, put, take, takeLeading } from 'typed-redux-saga';
import { $routes } from '../../../routes/routes';
import { NotificationAction } from '../../../store/notification/NotificationAction';
import { Notification } from '../../../utils/notification';
import { getLocation } from '../../web/location';
import { quoteMpnMortgageRequestById } from '../../web/quoteMpnMortgageRequestById';
import { selectMpnQuotation } from '../../web/selectMpnQuotation';
import { $MpnQuotation, getAdditionalParticipants } from './State';
function* fetchQuotation(quotationCommand) {
    yield* put($MpnQuotation.QuotationRequested());
    yield* put(push($routes.QUOTE_MPN_RESULTS));
    const quotationEither = yield* call(quoteMpnMortgageRequestById(quotationCommand.payload.quotationRequestId));
    if (either.isLeft(quotationEither)) {
        yield put(NotificationAction.Push(Notification.error('Si è verificato un errore. Riprova')));
        yield* put($MpnQuotation.QuotationNotFetched());
    }
    else {
        yield* put($MpnQuotation.QuotationFetched({
            participants: quotationCommand.payload.quote.participants,
            bazaarQuotation: quotationEither.right,
        }));
    }
}
function* fetchLocation(locationSearch, participantIndex) {
    if (t.string.is(locationSearch) && locationSearch.length >= 2) {
        yield* put($MpnQuotation.LocationRequested({ participantIndex: participantIndex }));
        const response = yield* call(getLocation(locationSearch));
        if (either.isLeft(response)) {
            yield* put($MpnQuotation.LocationNotFetched({ participantIndex: participantIndex }));
        }
        else {
            yield* put($MpnQuotation.LocationFetched({
                participantIndex: participantIndex,
                bazaarLocation: response.right,
            }));
        }
    }
}
function* sendToFmc(sendToFmcCommand) {
    yield* put($MpnQuotation.SendToFmcRequested());
    const responseEither = yield* call(selectMpnQuotation(sendToFmcCommand.payload.quotation, sendToFmcCommand.payload.mpnSearch, sendToFmcCommand.payload.search));
    if (either.isRight(responseEither)) {
        yield put(NotificationAction.Push(Notification.info('La pratica è stata inviata')));
        const onSuccess = sendToFmcCommand.payload.onSuccess;
        if (onSuccess) {
            yield* call(onSuccess);
        }
        yield* put($MpnQuotation.SentToFmc());
    }
    else {
        yield put(NotificationAction.Push(Notification.error('Si è verificato un errore. Riprova')));
        yield* put($MpnQuotation.SendToFmcFailed());
    }
}
function* selectQuotation(selectQuotationCommand) {
    const applicants = getAdditionalParticipants('richiedente')(selectQuotationCommand.payload.search.participants);
    const guarantors = getAdditionalParticipants('garante')(selectQuotationCommand.payload.search.participants);
    yield* put(push($routes.QUOTE_MPN));
    if (applicants.length > 0 || guarantors.length > 0) {
        yield* put(NotificationAction.Push(Notification.info('Per caricare più di due intestatari e due garanti è necessario indicarlo nel foglio note')));
    }
    yield* put($MpnQuotation.QuotationSelected({
        quotation: selectQuotationCommand.payload.quotation,
        search: selectQuotationCommand.payload.search,
    }));
}
export function* MpnQuotationSaga() {
    yield* takeLeading($MpnQuotation.start, function* () {
        yield* put($MpnQuotation.Started());
        const task = yield* all([
            yield* takeLeading($MpnQuotation.sendToFmc, sendToFmc),
            yield* takeLeading($MpnQuotation.fetchQuotation, fetchQuotation),
            yield* takeLeading($MpnQuotation.selectQuotation, selectQuotation),
            yield* debounce(1000, $MpnQuotation.fetchLocation, function* (fetchLocationCommand) {
                yield* call(fetchLocation, fetchLocationCommand.payload.locationSearch, fetchLocationCommand.payload.participantIndex);
            }),
        ]);
        yield* take($MpnQuotation.stop);
        yield* cancel(task);
        yield* put($MpnQuotation.Stopped());
    });
}
