const empty = {
    configurations: [],
    instances: [],
    history: [],
    isLoading: false,
    isConfigurationLoading: false,
    error: '',
    message: '',
    isDigitalLoading: false,
    isUpdatingRecordStatus: false,
};
export const PipelineState = {
    empty,
};
