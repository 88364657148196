import { configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';
import { Saga } from './Saga';
import { State } from './State';
const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({
    history: createBrowserHistory(),
});
export const Store = () => {
    const reducer = State.reducer(routerReducer);
    const sagaMiddleware = createSagaMiddleware();
    const sentryEnhancer = createReduxEnhancer();
    const store = configureStore({
        reducer,
        middleware: getDefaultMiddleware => getDefaultMiddleware({
            immutableCheck: {
                ignoredPaths: ['processes'],
            },
            serializableCheck: false,
        })
            .concat(sagaMiddleware)
            .concat(routerMiddleware),
        enhancers: [sentryEnhancer],
    });
    sagaMiddleware.run(Saga);
    if ('production' !== process.env.NODE_ENV && module.hot) {
        module.hot.accept('./Store', () => store.replaceReducer(reducer));
    }
    return store;
};
export const history = createReduxHistory(Store());
