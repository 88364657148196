import { createSlice } from '@reduxjs/toolkit';
import { $number } from 'fortepiano';
import { number, option, readonlyArray } from 'fp-ts';
import { concatAll } from 'fp-ts/Monoid';
import { constant, identity, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { $currency } from '../../models/Currency/Currency';
import { InvoiceUtils } from '../../models/Invoice/Invoice.codec';
import { InvoiceItemUtils } from '../../models/InvoiceItem/InvoiceItem';
const roundCurrency = (n) => pipe(n, $number.round(2), euros);
const euros = (n) => pipe(n, $currency.euro, option.getOrElse(constant('')));
const formatAmount = (groupedItemWithTotalList) => pipe(groupedItemWithTotalList, readonlyArray.map(groupedInvoice => (Object.assign(Object.assign({}, groupedInvoice), { 
    // eg. item.amount: 3.334 , 3.334, 3.334.
    // Rounded => 3.33, 3.33, 3.33 => 10 will be the total rounded
    total: roundCurrency(groupedInvoice.total), items: pipe(groupedInvoice.items, readonlyArray.map(item => ({
        description: item.description,
        amount: roundCurrency(item.amount), // round the amount
    }))) }))));
const addTotalToGroupedInvoiceList = (groupedInvoiceByRecordId) => pipe(groupedInvoiceByRecordId, readonlyArray.map(groupedInvoice => (Object.assign(Object.assign({}, groupedInvoice), { 
    // this is the subtotal of each invoice
    // it is not rounded
    total: pipe(groupedInvoice.items, readonlyArray.map(item => item.amount), concatAll(number.MonoidSum)) }))));
const itemDescriptionIndex = (item) => (itemList) => pipe(itemList, readonlyArray.findIndex(({ description }) => description === item.description));
const mergeItemAmount = (item, itemList) => (index) => pipe(itemList, readonlyArray.modifyAt(index, matchedItem => (Object.assign(Object.assign({}, matchedItem), { amount: item.amount + matchedItem.amount }))), option.getOrElse(constant(itemList)));
const groupItemsByItemDescription = (groupedInvoice) => pipe(groupedInvoice, readonlyArray.map(invoice => ({
    record: invoice.record,
    items: pipe(invoice.items, readonlyArray.reduce([], (itemListByRecord, item) => pipe(itemListByRecord, itemDescriptionIndex(item), option.match(constant([...itemListByRecord, item]), mergeItemAmount(item, itemListByRecord))))),
})));
const recordFromItem = (item) => ({
    record: {
        recordId: item.recordId,
        customerFullName: InvoiceItemUtils.customerFullName(item),
    },
    items: [item],
});
const createGroupedInvoice = (item) => (itemsByRecord) => () => item.recordId === undefined || item.recordId === null ? itemsByRecord : [...itemsByRecord, recordFromItem(item)];
const addItemToGroupedInvoice = (item, itemsByRecord) => (index) => pipe(itemsByRecord, readonlyArray.modifyAt(index, groupedInvoice => (Object.assign(Object.assign({}, groupedInvoice), { items: [...groupedInvoice.items, item] }))), option.getOrElse(constant(itemsByRecord)));
const groupedInvoiceIndex = (item) => (itemsByRecord) => pipe(itemsByRecord, readonlyArray.findIndex(({ record }) => record.recordId === item.recordId));
const groupItemsByRecordId = (invoice) => pipe(invoice.items, readonlyArray.reduce([], (itemsByRecord, item) => pipe(itemsByRecord, groupedInvoiceIndex(item), option.match(createGroupedInvoice(item)(itemsByRecord), addItemToGroupedInvoice(item, itemsByRecord)))));
const ungroupedItems = (invoice) => pipe(invoice.items, readonlyArray.filter(item => t.null.is(item.recordId) || t.undefined.is(item.recordId)), readonlyArray.map(item => ({
    description: item.description,
    amount: roundCurrency(item.amount),
})));
const groupedInvoiceList = (invoice) => pipe(invoice, groupItemsByRecordId, groupItemsByItemDescription, addTotalToGroupedInvoiceList, formatAmount);
const initialState = {
    loading: false,
    error: option.none,
    groupedItems: [],
    ungroupedItems: [],
    month: '',
    hasEnasarco: '',
    username: '',
    total: '',
    isTransmitted: undefined,
};
export const invoiceDetailSlice = createSlice({
    name: 'InvoiceDetail',
    initialState,
    reducers: {
        start: (state, _) => state,
        stop: identity,
        fetchInvoiceDetail: (state, _) => state,
        Started: identity,
        Stopped: constant(initialState),
        InvoiceDetailRequested: state => (Object.assign(Object.assign({}, state), { loading: true, error: option.none })),
        InvoiceDetailFetched: (state, action) => (Object.assign(Object.assign({}, state), { loading: false, error: option.none, groupedItems: groupedInvoiceList(action.payload.invoice), ungroupedItems: ungroupedItems(action.payload.invoice), month: InvoiceUtils.month(action.payload.invoice.month), hasEnasarco: InvoiceUtils.enasarco(action.payload.invoice.hasEnasarco), username: action.payload.user.fullName, total: pipe(action.payload.invoice.items, InvoiceUtils.amount, roundCurrency), isTransmitted: pipe(action.payload.invoice, InvoiceUtils.isTransmitted) })),
        InvoiceDetailFailed: (state, action) => (Object.assign(Object.assign({}, state), { loading: false, error: option.some(action.payload) })),
    },
});
const invoiceDetail = (state) => state.invoiceDetail;
export const $InvoiceDetail = invoiceDetailSlice.actions;
export const $invoiceDetailSlice = {
    selector: {
        groupedInvoiceList,
        groupItemsByRecordId,
        ungroupedItems,
        invoiceDetail,
        addTotalToGroupedInvoiceList,
        groupItemsByItemDescription,
    },
};
export default invoiceDetailSlice.reducer;
