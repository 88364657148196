import axios from 'axios';
export const AxiosClient = {
    get: (url, data, headers, responseType) => {
        return axios.get(url, {
            params: data,
            headers: headers,
            responseType: responseType,
        });
    },
    post: (url, data, headers) => {
        return axios.post(url, data, { headers: headers });
    },
    delete: (url, data, headers) => {
        return axios.delete(url, { data, headers: headers });
    },
    put: (url, data, headers) => {
        return axios.put(url, data, { headers: headers });
    },
    patch: (url, data, headers) => {
        return axios.patch(url, data, { headers: headers });
    },
};
