import * as t from 'io-ts';
export const RecordStateC = t.intersection([
    t.type({
        name: t.string,
        code: t.string,
        important: t.boolean,
        updated_at: t.string,
    }),
    t.partial({
        feedback: t.string,
    }),
], 'RecordState');
