import { either, taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { PooledClient, HttpClientPool } from '../../lib/http/PooledClient';
const attachBlob = (filenameWithExtension, blob) => {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filenameWithExtension);
    document.body.appendChild(link);
    link.click();
};
export const downloadInvoice = (document) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get(`${BAZAAR_URL}/api/v1/invoices/${document.id}/pdf`, {}, {}, 'blob'), e => e), taskEither.chainEitherKW(response => pipe(response.data, t.unknown.decode, either.map(u => {
    attachBlob(`${document.fileName}.pdf`, new Blob([u], {
        type: 'application/pdf',
    }));
    return u;
}))));
