import { EmptyAction, PayloadedAction } from '../../lib/PayloadedAction';
export const PIPELINES_MODAL_REQUEST = 'RECORD_PIPELINES_MODAL_REQUEST';
export const PIPELINES_MODAL_FAILURE = 'RECORD_PIPELINES_MODAL_FAILURE';
export const PIPELINES_MODAL_SUCCESS = 'RECORD_PIPELINES_MODAL_SUCCESS';
export const PIPELINES_CONFIRM_REQUEST = 'RECORD_PIPELINES_CONFIRM_REQUEST';
export const PIPELINES_CONFIRM_FAILURE = 'RECORD_PIPELINES_CONFIRM_FAILURE';
export const PIPELINES_CONFIRM_SUCCESS = 'RECORD_PIPELINES_CONFIRM_SUCCESS';
export const PIPELINES_CONFIGURATIONS_REQUEST = 'RECORD_PIPELINES_CONFIGURATIONS_REQUEST';
export const PIPELINES_CONFIGURATIONS_FAILURE = 'RECORD_PIPELINES_CONFIGURATIONS_FAILURE';
export const PIPELINES_CONFIGURATIONS_SUCCESS = 'RECORD_PIPELINES_CONFIGURATIONS_SUCCESS';
export const CLEAR_PIPELINE = 'CLEAR_PIPELINE';
export const PipelinesModalRequest = PayloadedAction(PIPELINES_MODAL_REQUEST);
export const PipelinesModalFailure = PayloadedAction(PIPELINES_MODAL_FAILURE);
export const PipelinesModalSuccess = PayloadedAction(PIPELINES_MODAL_SUCCESS);
export const PipelinesConfirmRequest = PayloadedAction(PIPELINES_CONFIRM_REQUEST);
export const PipelinesConfirmFailure = PayloadedAction(PIPELINES_CONFIRM_FAILURE);
export const PipelinesConfirmSuccess = PayloadedAction(PIPELINES_CONFIRM_SUCCESS);
export const PipelinesConfigurationsRequest = PayloadedAction(PIPELINES_CONFIGURATIONS_REQUEST);
export const PipelinesConfigurationsFailure = PayloadedAction(PIPELINES_CONFIGURATIONS_FAILURE);
export const PipelinesConfigurationsSuccess = PayloadedAction(PIPELINES_CONFIGURATIONS_SUCCESS);
export const ClearPipeline = EmptyAction(CLEAR_PIPELINE);
