import { array, option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
const firstColumnTable = (commissions) => pipe(commissions, array.traverse(option.Applicative)(array.head), option.map(array.map(({ productType }) => productType)), option.getOrElseW(() => []));
export const commissionsTableModelMapper = (commissions) => {
    const firstColumn = firstColumnTable(commissions);
    return {
        body: pipe(commissions, array.mapWithIndex((index, commission) => [
            firstColumn[index] || '',
            ...pipe(commission, array.map(({ value }) => value)),
        ])),
        headers: pipe(commissions, array.head, option.map(array.map(({ role, profile }) => role || profile)), option.map(array.map(header => (header === 'FMP' ? 'marginalità FMP' : header))), option.match(() => [], profiles => (profiles.length === 0 ? profiles : ['', ...profiles]))),
    };
};
