export class Session {
    static getToken() {
        const rawToken = localStorage.getItem(Session.TOKEN_KEY);
        if (!rawToken) {
            throw new Error('no token found');
        }
        if (typeof rawToken !== 'string') {
            throw new Error('invalid token');
        }
        const parsedToken = JSON.parse(rawToken);
        if (typeof parsedToken !== 'string') {
            throw new Error('invalid token');
        }
        return parsedToken;
    }
    static setToken(token) {
        localStorage.setItem(Session.TOKEN_KEY, JSON.stringify(token));
    }
}
Session.TOKEN_KEY = 'session.token';
