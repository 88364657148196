import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Container, Grid, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { push } from 'redux-first-history';
import { makeStyles } from 'tss-react/mui';
import { useAppSelector } from '../../hooks/useAppSelector';
import { $routes } from '../../routes/routes';
import { $RequestWithQuotesSlice, $RequestWithQuotes } from '../state/RequestWithQuotesState';
import { Brief } from './Brief';
import { QuotesContainer } from './QuotesContainer';
const useStyles = makeStyles()(theme => ({
    sticky: {
        background: theme.palette.common.white,
        position: 'sticky',
        top: 0,
        zIndex: 1,
        height: 80,
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(5),
    },
    mb4: { marginBottom: theme.spacing(4) },
    zIndex2: { zIndex: 2 },
    container: {
        paddingTop: '26px',
        backgroundColor: 'transparent',
    },
    filter: {
        marginBottom: '36px',
    },
    arrow: {
        cursor: 'pointer',
        paddingRight: '8px',
        paddingTop: '4px',
    },
    icon: {
        backgroundColor: '#DCE1FF',
        color: '#405AA9',
        borderRadius: '50%',
        padding: '4px',
    },
    mr16px: {
        marginRight: '16px',
    },
    colorGrey: {
        color: '#6F797A',
    },
    quoteBriefContainer: {
        padding: '32px 16px',
        minHeight: '100vh',
    },
}));
export const RequestWithQuotesLayout = () => {
    const { classes } = useStyles();
    const dispatch = useDispatch();
    const requestWithQuotes = useAppSelector($RequestWithQuotesSlice.selector.requestWithQuotes);
    const { requestId } = useParams();
    useEffect(() => {
        if (!requestId) {
            dispatch(push($routes.PROCESSING_QUOTES));
        }
        else {
            dispatch($RequestWithQuotes.start({ requestId }));
        }
        return () => {
            dispatch($RequestWithQuotes.stop());
        };
    }, [dispatch, requestId]);
    return (requestWithQuotes && (_jsxs(_Fragment, { children: [_jsx("div", { className: clsx(classes.sticky, classes.mb4, classes.zIndex2), children: _jsxs(Grid, { container: true, alignItems: "flex-start", children: [_jsx(Grid, { item: true, children: _jsx(ArrowBackIcon, { className: classes.arrow, onClick: () => {
                                    dispatch(push($routes.PROCESSING_QUOTES));
                                } }) }), _jsxs(Grid, { item: true, children: [_jsxs(Typography, { variant: "h1", children: [requestWithQuotes.lead.firstName, " ", requestWithQuotes.lead.lastName] }), _jsxs(Grid, { container: true, alignItems: "center", wrap: "nowrap", children: [_jsx(MailOutlineIcon, { className: clsx(classes.mr16px, classes.icon) }), _jsx(Typography, { className: clsx(classes.colorGrey, classes.mr16px), children: requestWithQuotes.lead.email }), _jsx(LocalPhoneOutlinedIcon, { className: clsx(classes.mr16px, classes.icon) }), _jsx(Typography, { className: clsx(classes.colorGrey, classes.mr16px), children: requestWithQuotes.lead.phoneNumber })] })] })] }) }), _jsx(Container, { children: _jsxs(Grid, { container: true, wrap: "nowrap", children: [_jsx(Grid, { item: true, xs: 6, children: _jsx("div", { className: classes.mr16px, children: _jsx(Container, { component: Paper, elevation: 0, className: classes.quoteBriefContainer, children: _jsx(Brief, { requestWithQuotes: requestWithQuotes }) }) }) }), _jsx(Grid, { container: true, children: _jsx(Container, { component: Paper, elevation: 0, style: { minHeight: '100vh' }, children: _jsx(QuotesContainer, { requestWithQuotes: requestWithQuotes }) }) })] }) })] })));
};
