import { makeStyles } from 'tss-react/mui';
const SIDEBAR_WIDTH = 340;
export const useStyles = makeStyles()(theme => ({
    loader: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: theme.zIndex.modal - 1,
    },
    circularProgress: {
        margin: 'auto',
        position: 'absolute',
        top: '50vh',
        left: '50%',
        transform: `translateX(calc(-50%))`,
    },
    transformTranslateSidebar: {
        transform: `translateX(calc(-50% - ${SIDEBAR_WIDTH / 2}px))`,
    },
    background: {
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
}));
