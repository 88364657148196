import { Refinement } from '../Refinement';
export function Action(type) {
    const f = (payload) => ({
        type,
        payload,
    });
    f.type = type;
    f.is = (action) => type === action.type;
    return f;
}
function any(...actions) {
    return Refinement.any(...actions.map(action => action.is));
}
Action.is = {
    any,
};
