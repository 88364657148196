import { createSlice } from '@reduxjs/toolkit';
import { option } from 'fp-ts';
import { constant, identity } from 'fp-ts/function';
import { $InvoiceDetail } from '../invoiceDetail/invoiceDetailState';
import { $InvoiceEdit } from '../invoiceEdit/InvoiceEditState';
const initialState = {
    loading: false,
    error: option.none,
    isApproved: undefined,
};
export const invoiceApprovalSlice = createSlice({
    name: 'InvoiceApprovalSaga',
    initialState,
    extraReducers: builder => {
        builder.addCase($InvoiceEdit.InvoiceRequested, state => (Object.assign(Object.assign({}, state), { error: option.none, loading: true })));
        builder.addCase($InvoiceEdit.InvoiceFetched, (state, action) => (Object.assign(Object.assign({}, state), { isApproved: action.payload.invoice.approved, error: option.none, loading: false })));
        builder.addCase($InvoiceEdit.InvoiceNotFetched, (state, action) => (Object.assign(Object.assign({}, state), { error: option.some(action.payload), loading: false })));
        builder.addCase($InvoiceDetail.InvoiceDetailRequested, state => (Object.assign(Object.assign({}, state), { error: option.none, loading: true })));
        builder.addCase($InvoiceDetail.InvoiceDetailFetched, (state, action) => (Object.assign(Object.assign({}, state), { isApproved: action.payload.invoice.approved, error: option.none, loading: false })));
        builder.addCase($InvoiceDetail.InvoiceDetailFailed, (state, action) => (Object.assign(Object.assign({}, state), { error: option.some(action.payload), loading: false })));
    },
    reducers: {
        start: (state, _) => state,
        stop: identity,
        Started: identity,
        Stopped: constant(initialState),
        toggleInvoiceApproval: (state, _) => state,
        InvoiceApprovalToggleRequested: state => (Object.assign(Object.assign({}, state), { loading: true, error: option.none })),
        InvoiceApprovalToggled: (state, action) => (Object.assign(Object.assign({}, state), { loading: false, isApproved: action.payload, error: option.none })),
        InvoiceApprovalNotToggled: (state, action) => (Object.assign(Object.assign({}, state), { error: option.some(action.payload), loading: false })),
    },
});
const isApproved = (state) => state.invoiceApproval.isApproved;
export const $InvoiceApproval = invoiceApprovalSlice.actions;
export const $invoiceApprovalSlice = {
    selector: {
        isApproved,
    },
};
export default invoiceApprovalSlice.reducer;
