import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Grid, TextField } from '@mui/material';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { makeStyles } from 'tss-react/mui';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { $ProcessingQuotes, $ProcessingQuotesSlice } from '../state/ProcessingQuotesState';
export const useStyles = makeStyles()(() => ({
    label: { backgroundColor: '#fff', paddingRight: 5 },
    mb0: { marginBottom: 0 },
    mr8: { marginRight: '8px' },
}));
const onSubmit = (dispatch) => (formData) => {
    dispatch($ProcessingQuotes.getLeads({ filter: formData }));
};
export const Filter = () => {
    const initialValues = useAppSelector($ProcessingQuotesSlice.selector.appliedFilters);
    const isLeadsTableLoading = useAppSelector($ProcessingQuotesSlice.selector.isLeadsTableLoading);
    const dispatch = useAppDispatch();
    const { classes } = useStyles();
    return (_jsx(Formik, { onSubmit: onSubmit(dispatch), initialValues: initialValues, children: ({ values, handleChange, handleBlur }) => (_jsx(Form, { children: _jsxs(Grid, { container: true, alignItems: "center", children: [_jsx(Grid, { item: true, children: _jsx(TextField, { className: clsx(classes.mb0, classes.mr8), InputLabelProps: {
                                classes: {
                                    root: classes.label,
                                },
                            }, disabled: isLeadsTableLoading, label: "Nome", name: "firstName", variant: "outlined", onChange: handleChange, onBlur: handleBlur, value: values.firstName }) }), _jsx(Grid, { item: true, children: _jsx(TextField, { className: clsx(classes.mb0, classes.mr8), InputLabelProps: {
                                classes: {
                                    root: classes.label,
                                },
                            }, disabled: isLeadsTableLoading, label: "Cognome", name: "lastName", variant: "outlined", onChange: handleChange, onBlur: handleBlur, value: values.lastName }) }), _jsx(Grid, { item: true, children: _jsx(Button, { type: "submit", variant: "contained", disabled: isLeadsTableLoading, children: "Cerca" }) })] }) })) }));
};
