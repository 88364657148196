import { either } from 'fp-ts';
import { all, call, put, takeEvery, takeLatest } from 'typed-redux-saga';
import { transmitEveryInvoice, transmitInvoice } from '../../web/transmitInvoice';
import { $InvoiceTransmission } from './InvoiceTransmissionState';
function* transmit(action) {
    yield* put($InvoiceTransmission.InvoiceTransmissionRequested());
    const response = yield* call(transmitInvoice(action.payload));
    if (either.isLeft(response)) {
        yield* put($InvoiceTransmission.InvoiceNotTransmitted(response.left));
    }
    else {
        yield* put($InvoiceTransmission.InvoiceTransmitted());
    }
}
function* transmitBulk() {
    yield* put($InvoiceTransmission.InvoiceTransmissionRequested());
    const response = yield* call(transmitEveryInvoice());
    if (either.isLeft(response)) {
        yield* put($InvoiceTransmission.InvoiceNotTransmitted(response.left));
    }
    else {
        yield* put($InvoiceTransmission.InvoiceTransmitted());
    }
}
function* invoiceTransmit() {
    yield* all([
        takeEvery($InvoiceTransmission.transmitInvoice, transmit),
        takeLatest($InvoiceTransmission.transmitInvoiceBulk, transmitBulk),
    ]);
}
export function* InvoiceTransmissionSaga() {
    yield* all([yield* takeLatest($InvoiceTransmission.startInvoiceTransmission.type, invoiceTransmit)]);
}
