var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { $mock } from 'fortepiano';
import { boolean, either, io, number, readonlyArray } from 'fp-ts';
import { struct } from 'fp-ts/Eq';
import { pipe } from 'fp-ts/function';
import { Eq } from 'fp-ts/string';
import * as t from 'io-ts';
import { $currency } from '../../models/Currency/Currency';
import { RoleCommissionItemCodec } from '../../models/RoleCommissionItem/RoleCommissionItem';
import { CommissionItemCodec, CommissionItemMock } from './CommissionItem';
const FMP = 'Ricavo FMP';
const TOTAL = 'TOTALE';
const withFormProps = (commissions) => pipe(commissions, readonlyArray.map(readonlyArray.map(commission => (Object.assign(Object.assign({}, commission), { formattedValue: $currency.toEuros(commission.value), isReadonly: boolean.SemigroupAny.concat(Eq.equals(commission.productType, TOTAL), Eq.equals(commission.profile, FMP)) })))));
const codecCommissionsForm = t.readonlyArray(t.readonlyArray(t.intersection([CommissionItemCodec, t.type({ isReadonly: t.boolean, formattedValue: t.string })])));
const codecCommissionsTable = t.readonlyArray(t.readonlyArray(RoleCommissionItemCodec));
const codec = new t.Type('CommissionsForm', codecCommissionsForm.is, (u, c) => pipe(u, codecCommissionsTable.decode, either.map(withFormProps), either.match(() => t.failure(u, c), t.success)), commissionsForm => pipe(commissionsForm, readonlyArray.map(commissions => pipe(commissions, readonlyArray.filter(commission => commission.isReadonly === false), 
// eslint-disable-next-line @typescript-eslint/no-unused-vars
readonlyArray.map((_a) => {
    var { formattedValue, isReadonly } = _a, commission = __rest(_a, ["formattedValue", "isReadonly"]);
    return commission;
}))), readonlyArray.filter(readonlyArray.isNonEmpty)));
const isFormChanged = (currentValues, initialValues) => io.of(!readonlyArray.getEq(readonlyArray.getEq(struct({ value: number.Eq }))).equals(currentValues, initialValues));
const mock = pipe($mock.readonlyNonEmptyArray($mock.readonlyNonEmptyArray(pipe($mock.struct({
    isReadonly: $mock.boolean,
    formattedValue: $mock.string,
}), $mock.bindTo('formValues'), $mock.apS('commissionItemMock', CommissionItemMock), $mock.map(({ formValues, commissionItemMock }) => (Object.assign(Object.assign({}, formValues), commissionItemMock)))))));
export const CommissionsForm = {
    codec,
    isFormChanged,
    withFormProps,
    mock,
};
