import { createSlice } from '@reduxjs/toolkit';
import { array, monoid, number, option, readonlyArray, readonlyRecord } from 'fp-ts';
import { constant, identity, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { $currency } from '../../models/Currency/Currency';
import { RecordDataInstituteUtils } from '../../models/RecordDataInstitute';
const initialState = {
    loading: { recordList: false, users: false },
    error: { users: option.none, recordList: option.none },
    itemsToTransmit: [],
    transmittedItems: [],
    users: [],
    filterInitialValues: {
        userId: '',
        userFullName: '',
    },
};
const fullName = (record) => pipe(record.data, readonlyRecord.lookup('cognome_e_nome'), option.filter(t.string.is));
const getAuthor = (record) => pipe(record.agenti[0], author => `${author.first_name} ${author.last_name}`);
const billablePayins = (payins) => pipe(payins, readonlyArray.map((payin) => ({
    billablePayinAmount: $currency.toEuros(payin.billablePayinAmount),
    leftoverPayinAmount: $currency.toEuros(payin.leftoverPayinAmount),
    totalCommissionAmount: $currency.toEuros(payin.totalCommissionAmount),
    productType: payin.productType,
})), readonlyArray.toArray);
const formattedTotalCommissionAmount = (payins) => pipe(payins, readonlyArray.map(payin => payin.totalCommissionAmount), monoid.concatAll(number.MonoidSum), $currency.toEuros);
export const recordListWithCommissionsSlice = createSlice({
    name: 'RecordsSaga',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        Started: identity,
        Stopped: constant(initialState),
        fetchUserList: (state, _) => state,
        fetchRecordWithCommissions: (state, _) => state,
        RecordWithCommissionsRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { recordList: true }), error: Object.assign(Object.assign({}, state.error), { recordList: option.none }) })),
        RecordWithCommissionsNotFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { recordList: false }), error: Object.assign(Object.assign({}, state.error), { recordList: option.some(event.payload) }) })),
        RecordWithCommissionsFetched: (state, event) => {
            const items = pipe(event.payload, readonlyArray.map(({ record, payins, hasError }) => ({
                fullName: pipe(record, fullName, option.getOrElse(constant(''))),
                institute: RecordDataInstituteUtils.institute(record),
                productType: record.data.tipo_di_prodotto,
                totalCommissionAmount: formattedTotalCommissionAmount(payins),
                isTransmitted: pipe(payins, readonlyArray.every(payin => payin.isTotallyEmitted)),
                recordId: record.id,
                author: getAuthor(record),
                hasError: hasError,
                payins: billablePayins(payins),
            })), readonlyArray.toArray, array.partition(item => item.isTransmitted));
            return Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { recordList: false }), error: Object.assign(Object.assign({}, state.error), { recordList: option.none }), itemsToTransmit: items.left, transmittedItems: items.right });
        },
        UserListFetched: (state, event) => (Object.assign(Object.assign({}, state), { users: event.payload, loading: Object.assign(Object.assign({}, state.loading), { users: false }), error: Object.assign(Object.assign({}, state.error), { users: option.none }) })),
        UserListNotFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { users: false }), users: [], error: Object.assign(Object.assign({}, state.error), { users: option.some(event.payload) }) })),
        UserListRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { users: true }), error: Object.assign(Object.assign({}, state.error), { users: option.none }), users: [] })),
    },
});
const itemsToTransmit = (state) => state.records.itemsToTransmit;
const transmittedItems = (state) => state.records.transmittedItems;
const isLoading = (state) => state.records.loading.recordList;
const filterInitialValues = (state) => state.records.filterInitialValues;
const assignees = (state) => state.records.users;
const assigneeError = (state) => option.isSome(state.records.error.users);
const isUserListLoading = (state) => state.records.loading.users;
export const $RecordListWithCommissions = recordListWithCommissionsSlice.actions;
export const $recordListWithCommissionsSlice = {
    billablePayins,
    selector: {
        transmittedItems,
        itemsToTransmit,
        isLoading,
        filterInitialValues,
        assignees,
        assigneeError,
        isUserListLoading,
    },
};
export default recordListWithCommissionsSlice.reducer;
