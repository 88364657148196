import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import { toggle } from '../../../common/state/toggle';
import { useActiveScroll } from '../../../hooks/useActiveScroll';
import { useAppSelector } from '../../../hooks/useAppSelector';
import { $PaperworkProcessSlice } from '../../state/PaperworkProcessState';
import { ContactBox } from '../atoms/ContactBox';
import { PaperworkProcessModal } from './PaperworkProcessModal';
export const useStyles = makeStyles()(theme => ({
    scroll: {
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15)',
    },
    sticky: {
        background: theme.palette.common.white,
        position: 'sticky',
        top: 0,
        zIndex: 1,
        height: 80,
        display: 'flex',
        alignItems: 'center',
        paddingRight: theme.spacing(5),
        paddingLeft: theme.spacing(5),
    },
    mr5: {
        marginRight: theme.spacing(5),
    },
    mt3: {
        marginTop: theme.spacing(3),
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    alignItemsFirst: {
        alignItems: 'first baseline',
    },
    alignItemsCenter: {
        alignItems: 'center',
    },
}));
export const Navbar = ({ email, fullName, phoneNumber, nextActions }) => {
    const { classes } = useStyles();
    const scrollActive = useActiveScroll('scroll-navbar');
    const [showPaperworkStatusDialog, setShowPaperworkStatusDialog] = useState(false);
    const loading = useAppSelector($PaperworkProcessSlice.selector.loading);
    return (_jsxs(_Fragment, { children: [showPaperworkStatusDialog && (_jsx(PaperworkProcessModal, { isOpen: showPaperworkStatusDialog, onClose: toggle(setShowPaperworkStatusDialog), nextActions: nextActions, isLoading: loading.processPaperwork })), _jsx("div", { className: clsx(classes.sticky, scrollActive && classes.scroll), children: _jsxs(Grid, { container: true, wrap: "nowrap", className: classes.alignItemsCenter, children: [_jsx(Grid, { container: true, item: true, className: clsx(classes.cursorPointer, classes.alignItemsFirst), wrap: "nowrap", children: _jsx(Grid, { item: true, children: _jsxs(Grid, { container: true, direction: "column", children: [_jsx(Typography, { variant: "h2", children: fullName }), _jsx("div", { className: classes.mt3, children: _jsx(ContactBox, { phone: phoneNumber, email: email }) })] }) }) }), _jsx(Grid, { item: true, container: true, justifyContent: "flex-end", children: _jsx(Button, { disabled: nextActions.length === 0, variant: "contained", onClick: toggle(setShowPaperworkStatusDialog), children: "Modifica stato" }) })] }) })] }));
};
