import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Form, Formik } from 'formik';
import { array } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { $ActiveInvoiceCreation, $activeInvoiceCreationSlice, } from '../state/activeInvoiceCreation/ActiveInvoiceCreationState';
import { DialogTitle } from './DialogTitle';
export const ActiveInvoiceCreationDialog = ({ onClose, artooRecordAgents, artooRecordData, recordId, }) => {
    const dispatch = useAppDispatch();
    const initialValues = useAppSelector($activeInvoiceCreationSlice.selector.initialValues);
    const customersRegistry = useAppSelector($activeInvoiceCreationSlice.selector.customersRegistry);
    const isMinTransmittableDateEnabled = useAppSelector($activeInvoiceCreationSlice.selector.isMinTransmittableDateEnabled);
    const minTransmittableDate = useAppSelector($activeInvoiceCreationSlice.selector.minTransmittableDate);
    const minTransmittableDateError = useAppSelector($activeInvoiceCreationSlice.selector.minTransmittableDateError);
    const canSave = useAppSelector($activeInvoiceCreationSlice.selector.canSave);
    const handleDateChange = (setFieldValue) => (date) => {
        if (date === null) {
            return;
        }
        setFieldValue('issueDate', date);
    };
    useEffect(() => {
        dispatch($ActiveInvoiceCreation.start({
            artooRecordData,
            artooRecordAgents,
            recordId,
        }));
        return () => {
            dispatch($ActiveInvoiceCreation.stop());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(Dialog, { open: true, fullWidth: true, maxWidth: "sm", children: [_jsx(DialogTitle, { onClose: onClose, title: "Dati fattura" }), _jsx(DialogContent, { children: _jsx(Formik, { enableReinitialize: true, onSubmit: activeInvoiceCreationForm => {
                        dispatch($ActiveInvoiceCreation.transmitInvoice({
                            activeInvoiceCreationForm,
                            customersRegistry,
                            onSuccess: onClose,
                        }));
                    }, initialValues: initialValues, children: ({ handleChange, setFieldValue, values }) => (_jsxs(Form, { children: [_jsxs(FormControl, { fullWidth: true, variant: "standard", required: true, style: { marginBottom: 16 }, children: [_jsx(InputLabel, { children: "Anagrafica" }), _jsx(Select, { variant: "standard", name: "customerFullNameWithTier", value: values.customerFullNameWithTier, onChange: handleChange, children: pipe(customersRegistry, array.mapWithIndex((key, { fullNameWithTier }) => (_jsx(MenuItem, { value: fullNameWithTier, children: fullNameWithTier }, key)))) })] }), _jsxs(FormControl, { fullWidth: true, variant: "standard", required: true, style: { marginBottom: 8 }, children: [_jsx(FormLabel, { children: "Tipologia" }), _jsxs(RadioGroup, { row: true, name: "invoiceTypology", value: values.invoiceTypology, onChange: handleChange, children: [_jsx(FormControlLabel, { value: "MEDIAZIONE", control: _jsx(Radio, { size: "small" }), label: "Mediazione" }), _jsx(FormControlLabel, { value: "PENALE", control: _jsx(Radio, { size: "small" }), label: "Penale" })] })] }), _jsxs(FormControl, { fullWidth: true, variant: "standard", disabled: true, required: true, style: { marginBottom: 16 }, children: [_jsx(InputLabel, { children: "Descrizione" }), _jsx(Select, { variant: "standard", name: "invoiceDescription", value: values.invoiceDescription, onChange: handleChange, children: _jsx(MenuItem, { value: values.invoiceDescription, children: values.invoiceDescription }) })] }), _jsx(DatePicker, { slotProps: {
                                    textField: {
                                        fullWidth: true,
                                        name: 'issueDate',
                                        variant: 'standard',
                                        required: true,
                                        style: { marginBottom: 16 },
                                        error: minTransmittableDateError !== null,
                                        helperText: minTransmittableDateError !== null ? minTransmittableDateError : '',
                                    },
                                }, disabled: !isMinTransmittableDateEnabled, minDate: minTransmittableDate, maxDate: new Date(), format: "dd MMMM yyyy", value: values.issueDate, label: "Data di emissione", onChange: handleDateChange(setFieldValue) }), _jsx(TextField, { fullWidth: true, variant: "standard", required: true, value: values.invoiceAmount, onChange: handleChange, name: "invoiceAmount", label: "Importo", type: "number", inputProps: { min: 0, step: '.01' }, InputProps: {
                                    startAdornment: _jsx(InputAdornment, { position: "start", children: "\u20AC" }),
                                }, style: { marginBottom: 16, width: 'inherit' } }), _jsx(Grid, { container: true, justifyContent: "center", children: _jsx(Button, { type: "submit", variant: "contained", disabled: !canSave(values), children: "Invia" }) })] })) }) })] }));
};
