import * as t from 'io-ts';
export const PROCEDURE_STATUS_VALUES = {
    searchingProperty: 'searchingProperty',
    propertyIdentified: 'propertyIdentified',
    compromiseSigned: 'compromiseSigned',
    ownedProperty: 'ownedProperty',
    purchaseProposalMade: 'purchaseProposalMade',
    ongoingNegotiation: 'ongoingNegotiation',
    notarialActEstablished: 'notarialActEstablished',
};
export const procedureStatus = t.keyof(PROCEDURE_STATUS_VALUES);
