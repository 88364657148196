import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
import { CeilingsCodec } from '../models/Ceilings.codec';
export const getCeilings = () => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get(`${BAZAAR_URL}/api/v1/ceilings`), e => e), taskEither.chainEitherKW(response => CeilingsCodec.decode(response.data)));
