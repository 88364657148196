import * as t from 'io-ts';
const DRAFT = 'DRAFT';
const READY = 'READY';
const TRANSMITTED = 'TRANSMITTED';
export const InvoiceStatusCodeCodec = t.keyof({
    DRAFT,
    READY,
    TRANSMITTED,
}, 'InvoiceStatusCode');
export const InvoiceStatusCodeUtils = { DRAFT, READY, TRANSMITTED };
