var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { StorageCache } from '../lib/cache/StorageCache';
import { TypedCache } from '../lib/cache/TypedCache';
import { AuthClient } from '../lib/http/AuthClient';
import { AxiosClient } from '../lib/http/AxiosClient';
import { JsonClient } from '../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../lib/http/PooledClient';
import { PIPELINES, RECORDS } from './constants';
// const reduceInstancesToOnePromise = (
//   arr: Array<PipelineInstance>,
//   fn: (instance: PipelineInstance) => Promise<HttpResponse>
// ): Promise<HttpResponse> =>
//   arr.reduce(
//     (
//       instancesPromise: Promise<HttpResponse>,
//       instance
//     ): Promise<HttpResponse> => instancesPromise.then(() => fn(instance)),
//     (Promise.resolve() as unknown) as Promise<HttpResponse>
//   )
export const startConfiguration = (record, configurations) => __awaiter(void 0, void 0, void 0, function* () {
    return configurations.reduce((promise, pipeline) => {
        // const instancesToCancel = /(^|:)cancel:.+/.test(pipeline.code)
        //   ? instances
        //   : instances.filter(({ supported }) => supported === false)
        // const instancesToNext = instances.filter(
        //   ({ supported }) => supported === true || supported === null
        // )
        // let cancelPromise = (Promise.resolve() as unknown) as Promise<HttpResponse>
        // let nextPromise = (Promise.resolve() as unknown) as Promise<HttpResponse>
        return (promise
            .then(() => {
            // if (/(^|:)next:.+/.test(pipeline.code)) {
            //   nextPromise = reduceInstancesToOnePromise(
            //     instancesToNext,
            //     nextPipeline
            //   )
            // }
            // cancelPromise = reduceInstancesToOnePromise(
            //   instancesToCancel,
            //   cancelPipeline
            // )
        })
            // .then(() => nextPromise)
            // .then(() => cancelPromise)
            .then(() => startPipeline(record, pipeline.code)));
    }, Promise.resolve());
});
export const getPipelinesMeta = (id) => __awaiter(void 0, void 0, void 0, function* () {
    return yield PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get(`${RECORDS}/${id}`);
});
export const startPipeline = (record, pipeline) => __awaiter(void 0, void 0, void 0, function* () {
    return yield PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).post(`${RECORDS}/${record}/pipelines`, {
        data: {
            type: 'pipelines',
            attributes: {
                code: pipeline,
            },
        },
    });
});
export const nextPipeline = (instance) => __awaiter(void 0, void 0, void 0, function* () {
    return yield PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).post(`${PIPELINES}/${instance.pipeline}/next`);
});
export const cancelPipeline = (instance) => __awaiter(void 0, void 0, void 0, function* () {
    return yield PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).delete(`${PIPELINES}/${instance.pipeline}`);
});
