import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { BAZAAR_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { HttpClientPool, PooledClient } from '../../lib/http/PooledClient';
import { CommissionPlanDetailCodec } from '../models/CommissionPlanDetail.codec';
export const updateCommissionPlan = (commissionPlanId, paperworkIds, commissionPlan, description) => {
    const formData = new FormData();
    paperworkIds.forEach(item => formData.append('recordIds[]', item));
    if (description) {
        formData.append('description', description);
    }
    if (commissionPlan) {
        formData.append('commissionPlan', commissionPlan);
    }
    return pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).patch(`${BAZAAR_URL}/api/v1/commission-plans/${commissionPlanId}`, formData, {
        'Content-type': 'multipart/form-data',
    }), e => e), taskEither.chainEitherKW(response => CommissionPlanDetailCodec.decode(response.data)));
};
