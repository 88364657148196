export const fileTypeView = (type) => {
    switch (type) {
        case 'text/csv':
            return 'CSV';
        case 'application/pdf':
            return 'PDF';
        case 'image/jpeg':
            return 'JPEG';
        case 'image/png':
            return 'PNG';
        case 'image/bmp':
            return 'BMP';
        case 'application/rtf':
            return 'RTF';
        case 'application/vnd.ms-excel':
            return 'XLS';
        case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            return 'XLSX';
        case 'text/plain':
            return 'TXT';
        case 'application/vnd.oasis.opendocument.spreadsheet':
            return 'ODS';
        case 'application/msword':
            return 'DOC';
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            return 'DOCX';
        case 'application/vnd.oasis.opendocument.text':
            return 'ODT';
        default:
            return '';
    }
};
