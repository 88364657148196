import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import clsx from 'clsx';
import { format } from 'date-fns';
import PersonIcon from '../../../../assets/icons/person.svg';
import { ROLES } from '../../../constants';
import { session } from '../../../utils/session';
import { useStyles } from './HistoryCard.styles';
export const HistoryCard = ({ history }) => {
    const formattedTimestamp = format(new Date(history.updated_at), 'dd/MM/yyyy, HH:mm');
    const userRole = session.matchRole(ROLES)(history.user);
    const { classes } = useStyles();
    const feedback = history.status.feedback || 'unknown';
    return (_jsx(Card, { elevation: 0, classes: { root: classes.card }, className: classes.borderColorGrey, children: _jsxs(CardContent, { classes: { root: classes.cardContent }, children: [_jsx(Typography, { className: classes.mb10px, variant: "subtitle1", display: "block", children: formattedTimestamp }), _jsxs(Grid, { container: true, item: true, className: clsx(classes.mb10px, classes.alignItemsCenter), children: [_jsx(PersonIcon, {}), _jsxs(Typography, { variant: "body1", className: clsx(classes.ml2, classes.mr15px), children: [history.user.first_name, " ", history.user.last_name] }), _jsx(Typography, { variant: "subtitle1", className: classes.grey, children: userRole })] }), _jsx(Typography, { variant: "subtitle1", className: clsx(classes[feedback], classes.ptb5px, classes.plr10px, classes.statusBox), children: history.status.name })] }) }));
};
