import { jsx as _jsx } from "react/jsx-runtime";
import { Grid, Typography } from '@mui/material';
export const PageTitle = ({ title }) => {
    return (_jsx(Grid, { container: true, alignItems: "center", sx: {
            backgroundColor: 'white',
            height: '80px',
            paddingLeft: '20px',
            paddingRight: '20px',
            marginBottom: '16px',
            position: 'sticky',
            top: 0,
            zIndex: 2,
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.15)',
        }, children: _jsx(Typography, { variant: "h1", children: title }) }));
};
