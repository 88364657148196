import * as t from 'io-ts';
import { DocumentCategoryC } from './DocumentCategory';
import { DocumentStateC } from './DocumentState';
import { IdentifierC } from './Identifier';
export const DocumentC = t.type({
    id: IdentifierC,
    token: t.string,
    category: DocumentCategoryC,
    updated_at: t.string,
    created_at: t.string,
    mime_type: t.string,
    file_name: t.string,
    file_size: t.number,
    filled_out: t.boolean,
    status: DocumentStateC,
    data: t.union([t.array(t.unknown), t.record(t.string, t.union([t.string, t.number, t.boolean]))]),
}, 'Document');
