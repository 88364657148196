import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import qs from 'qs';
import { BASE_URL } from '../../api/constants';
import { StorageCache } from '../../lib/cache/StorageCache';
import { TypedCache } from '../../lib/cache/TypedCache';
import { AuthClient } from '../../lib/http/AuthClient';
import { AxiosClient } from '../../lib/http/AxiosClient';
import { JsonClient } from '../../lib/http/JsonClient';
import { PooledClient, HttpClientPool } from '../../lib/http/PooledClient';
import { ArtooRecordCodec } from '../models/ArtooRecord/ArtooRecord';
export const getRecordById = (recordId, queryParams = {}) => {
    return pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).get(`${BASE_URL}/api/v1/pratica/${recordId}?${qs.stringify(Object.assign({}, queryParams))}`), e => e), taskEither.chainEitherKW(response => ArtooRecordCodec.decode(response.data)));
};
