import * as t from 'io-ts';
import { ExternalIdentifierCodec } from '../ExternalIdentifier/ExternalIdentifier';
import { RoleNormalizedCodec } from '../RoleNormalized/RoleNormalized';
export const RoleCommissionItemCodec = t.type({
    productType: t.string,
    profile: t.string,
    value: t.number,
    userId: t.union([t.null, ExternalIdentifierCodec]),
    role: t.union([t.null, RoleNormalizedCodec]),
}, 'RoleCommissionItem');
