import { getYear } from 'date-fns';
import { either } from 'fp-ts';
import { all, call, cancel, put, take, takeLeading } from 'typed-redux-saga';
import { NotificationAction } from '../../store/notification/NotificationAction';
import { Notification } from '../../utils/notification';
import { addCeiling } from '../web/addCeiling';
import { getCeilings } from '../web/getCeilings';
import { $Enasarco } from './State';
function* tryAddCeiling(addCeilingCommand) {
    yield* put($Enasarco.CeilingAdditionRequested());
    const currentYear = getYear(new Date());
    const addCeilingEither = yield* call(addCeiling(addCeilingCommand.payload.rawCeilingAmount, currentYear));
    if (either.isRight(addCeilingEither)) {
        yield* put($Enasarco.CeilingAdded());
        yield* put(NotificationAction.Push(Notification.info('I massimali sono stati aggiornati correttamente')));
    }
    else {
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore. Riprova')));
        yield* put($Enasarco.CeilingNotAdded());
    }
}
function* tryGetCeilings() {
    yield* put($Enasarco.CeilingsRequested());
    const ceilingsEither = yield* call(getCeilings());
    if (either.isRight(ceilingsEither)) {
        yield* put($Enasarco.CeilingsFound(ceilingsEither.right));
    }
    else {
        yield* put($Enasarco.CeilingsNotFetched());
    }
}
export function* EnasarcoSaga() {
    yield* takeLeading($Enasarco.start, function* () {
        yield* put($Enasarco.Started());
        yield* call(tryGetCeilings);
        const task = yield* all([yield* takeLeading($Enasarco.addCeiling, tryAddCeiling)]);
        yield* take($Enasarco.stop);
        yield* cancel(task);
        yield* put($Enasarco.Stopped());
    });
}
