import * as t from 'io-ts';
const NullableNumber = t.union([t.number, t.null]);
export const EnasarcoCodec = t.type({
    turnover: NullableNumber,
    enasarco: t.type({
        ceiling: NullableNumber,
        leftover: NullableNumber,
        turnover: NullableNumber,
    }),
}, 'Enasarco');
