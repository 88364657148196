import * as t from 'io-ts';
export class ArtooEnvironment {
    constructor(sessionStorage) {
        this.sessionStorage = sessionStorage;
    }
    getUrl() {
        const reviewEnvironmentRegex = /^https?:\/\/.+\.k8s-facile.it$/;
        const API_KEY = 'API_HOST';
        const params = new URLSearchParams(window.location.search);
        const urlParam = params.get(API_KEY);
        const isDevUrl = t.string.is(urlParam) && reviewEnvironmentRegex.test(urlParam.trim());
        if (isDevUrl) {
            this.sessionStorage.setItem(API_KEY, urlParam);
        }
        const cachedUrl = this.sessionStorage.getItem(API_KEY);
        const isCachedDevUrl = t.string.is(cachedUrl) && reviewEnvironmentRegex.test(cachedUrl.trim());
        const url = isCachedDevUrl ? cachedUrl.trim() : process.env.REACT_APP_BASE_URL;
        if (!url) {
            throw Error('missing env variable');
        }
        return url;
    }
}
