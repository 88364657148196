import { array, option } from 'fp-ts';
import { pipe, constNull } from 'fp-ts/function';
import * as t from 'io-ts';
import { currencyView } from '../view/CurrencyView';
import { BazaarQuotationCodec } from './BazaarQuotation.codec';
import { RateLabelCodec, RateUtils } from './Rate.codec';
const formatDecimals = (n) => new Intl.NumberFormat('it-IT', { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(n);
const getSubsistenceThreshold = (subsistenceThreshold) => {
    if (subsistenceThreshold === true) {
        return 'OK';
    }
    else if (subsistenceThreshold === false) {
        return 'KO';
    }
    return null;
};
const quotationList = (bazaarQuotationList) => pipe(bazaarQuotationList, array.mapWithIndex((index, quotation) => ({
    key: index,
    isSaved: false,
    isSaving: false,
    raw: quotation,
    netIncomeInstallmentRatioValue: pipe(quotation.feasibilityConditionsAndOutcomes.netIncomeInstallmentRatio, option.fromNullable, option.map(({ value }) => `${value}%`), option.getOrElseW(constNull)),
    netIncomeInstallmentRatioOutcome: pipe(quotation.feasibilityConditionsAndOutcomes.netIncomeInstallmentRatio, option.fromNullable, option.map(({ outcome }) => outcome), option.getOrElseW(constNull)),
    netIncomeInstallmentRatioThreshold: pipe(quotation.feasibilityConditionsAndOutcomes.netIncomeInstallmentRatio, option.fromNullable, option.map(({ threshold }) => threshold), option.getOrElseW(constNull)),
    ltvRatio: pipe(quotation.feasibilityConditionsAndOutcomes.ltvRatio, option.fromNullable, option.map(({ value }) => `${value}%`), option.getOrElseW(constNull)),
    ltvThreshold: pipe(quotation.feasibilityConditionsAndOutcomes.ltvRatio, option.fromNullable, option.map(({ threshold }) => threshold), option.getOrElseW(constNull)),
    ltvOutcome: pipe(quotation.feasibilityConditionsAndOutcomes.ltvRatio, option.fromNullable, option.map(({ outcome }) => outcome), option.getOrElseW(constNull)),
    appraisal: currencyView(quotation.appraisal), // perizia
    installment: currencyView(quotation.installment), // rata mensile
    investigation: currencyView(quotation.investigation), // istruttoria
    taeg: pipe(quotation.taeg, formatDecimals, taeg => `${taeg} %`),
    spread: pipe(quotation.spread, formatDecimals, spread => `${spread} %`),
    tan: pipe(quotation.tan, formatDecimals, tan => `${tan} %`),
    subsistenceThreshold: getSubsistenceThreshold(quotation.subsistenceThreshold),
    rate: RateUtils.getRateLabel(quotation.rate),
    productId: quotation.product.externalId,
    instituteId: quotation.institute.externalId,
    quotationId: quotation.externalId || '',
    instituteName: quotation.institute.name,
    attributes: quotation.attributes,
    productName: quotation.product.name,
    provider: quotation.provider,
    alternativeTax: currencyView(quotation.alternativeTax),
    installmentExpense: pipe(quotation.installmentExpense, option.fromNullable, option.match(() => currencyView(''), currencyView)),
})));
export const QuotationCodec = t.type({
    key: t.number,
    isSaved: t.boolean,
    isSaving: t.boolean,
    raw: BazaarQuotationCodec,
    rate: RateLabelCodec,
    quotationId: t.string,
    productName: t.string,
    productId: t.string,
    instituteName: t.string,
    instituteId: t.string,
    attributes: t.array(t.string),
    provider: t.union([t.literal('mpn'), t.literal('egg')]),
    appraisal: t.string, // perizia
    tan: t.string,
    spread: t.string,
    installment: t.string, // rata mensile
    taeg: t.string,
    investigation: t.string, // instruttoria
    subsistenceThreshold: t.union([t.literal('OK'), t.literal('KO'), t.null]), // soglia di sussistenza
    alternativeTax: t.string,
    installmentExpense: t.string,
    netIncomeInstallmentRatioValue: t.union([t.null, t.string]),
    netIncomeInstallmentRatioOutcome: t.union([t.null, t.boolean]),
    netIncomeInstallmentRatioThreshold: t.union([t.null, t.number]),
    ltvRatio: t.union([t.null, t.string]),
    ltvOutcome: t.union([t.null, t.boolean]),
    ltvThreshold: t.union([t.null, t.number]),
}, 'Quotation');
export const QuotationUtils = { quotationList };
