import { makeStyles } from 'tss-react/mui';
export const useStyles = makeStyles()(() => ({
    gridContainer: {
        display: 'grid',
        gridTemplateColumns: 'calc(100% - 340px) 340px',
        gridColumnGap: 0,
        minHeight: '100vh',
    },
    height100: {
        height: '100%',
    },
    heightCalc: {
        height: 'calc(100% - 60px)',
    },
    overflowAuto: {
        overflow: 'auto',
    },
}));
