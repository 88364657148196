import { createSlice } from '@reduxjs/toolkit';
import { either, option, readonlyNonEmptyArray } from 'fp-ts';
import { constant, identity, pipe } from 'fp-ts/function';
import { $currency } from '../../models/Currency/Currency';
import { InvoiceUtils } from '../../models/Invoice/Invoice.codec';
import { InvoiceItemUtils } from '../../models/InvoiceItem/InvoiceItem';
import { UserUtils } from '../../models/User/User.codec';
const initialState = {
    loading: {
        invoiceList: false,
        userList: false,
        updateInvoice: false,
        enasarco: false,
    },
    error: option.none,
    initialValues: option.none,
    users: [],
    invoiceId: option.none,
    enasarcoDeficit: option.none,
};
const userFullName = (userId) => (userListEither) => pipe(userListEither, either.match(constant(''), userList => pipe(userList, UserUtils.getUserById(userId), option.map(user => user.fullName), option.getOrElse(constant('')))));
const getMonth = (value) => pipe(value, option.fromNullable, option.match(() => new Date(), month => new Date(month)));
const getItems = (items) => pipe(items, readonlyNonEmptyArray.map(item => (Object.assign(Object.assign({}, item), { isDescriptionDisabled: InvoiceItemUtils.hasRecordId(item), customerFullName: InvoiceItemUtils.customerFullName(item) }))));
export const invoiceEditSlice = createSlice({
    name: 'InvoiceEdit',
    initialState,
    reducers: {
        startInvoiceEdit: (state, action) => (Object.assign(Object.assign({}, state), { invoiceId: option.some(action.payload) })),
        endInvoiceEdit: constant(initialState),
        fetchUserList: (state, _) => state,
        fetchUserEnasarco: identity,
        UserEnasarcoRequested: state => (Object.assign(Object.assign({}, state), { error: option.none, loading: Object.assign(Object.assign({}, state.loading), { enasarco: true }) })),
        UserEnasarcoFetched: (state, action) => (Object.assign(Object.assign({}, state), { enasarcoDeficit: pipe(action.payload.invoice, InvoiceUtils.enasarcoDeficit(action.payload.enasarco.enasarco.leftover), option.chain($currency.euro)), error: option.none, loading: Object.assign(Object.assign({}, state.loading), { enasarco: false }) })),
        UserEnasarcoNotFetched: (state, action) => (Object.assign(Object.assign({}, state), { enasarcoDeficit: option.none, error: option.some(action.payload), loading: Object.assign(Object.assign({}, state.loading), { enasarco: false }) })),
        updateInvoice: (state, _) => state,
        UserListRequested: state => (Object.assign(Object.assign({}, state), { users: [], error: option.none, loading: Object.assign(Object.assign({}, state.loading), { userList: true }) })),
        UserListFetched: (state, action) => (Object.assign(Object.assign({}, state), { users: action.payload, error: option.none, loading: Object.assign(Object.assign({}, state.loading), { userList: false }) })),
        UserListNotFetched: (state, action) => (Object.assign(Object.assign({}, state), { users: [], error: option.some(action.payload), loading: Object.assign(Object.assign({}, state.loading), { userList: false }) })),
        InvoiceRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { invoiceList: true }), error: option.none })),
        InvoiceNotFetched: (state, action) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { invoiceList: false }), error: option.some(action.payload) })),
        InvoiceFetched: (state, action) => (Object.assign(Object.assign({}, state), { initialValues: option.some({
                hasEnasarco: InvoiceUtils.enasarco(action.payload.invoice.hasEnasarco),
                month: getMonth(action.payload.invoice.month),
                userFullName: userFullName(action.payload.invoice.userId)(action.payload.users),
                userId: action.payload.invoice.userId,
                items: getItems(action.payload.invoice.items),
            }), loading: Object.assign(Object.assign({}, state.loading), { invoiceList: false }), error: option.none })),
        InvoiceUpdateRequested: state => (Object.assign(Object.assign({}, state), { error: option.none, loading: Object.assign(Object.assign({}, state.loading), { updateInvoice: true }) })),
        InvoiceUpdated: state => (Object.assign(Object.assign({}, state), { error: option.none, loading: Object.assign(Object.assign({}, state.loading), { updateInvoice: false }) })),
        InvoiceNotUpdated: (state, action) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { updateInvoice: false }), error: option.some(action.payload) })),
    },
});
const initialValues = (state) => state.invoiceEdit.initialValues;
const assignees = (state) => state.invoiceEdit.users;
const invoiceId = (state) => state.invoiceEdit.invoiceId;
const isLoading = (state) => state.invoiceEdit.loading.invoiceList;
const enasarcoDeficitOption = (state) => state.invoiceEdit.enasarcoDeficit;
export const $InvoiceEdit = invoiceEditSlice.actions;
export const $invoiceEditSlice = {
    selector: {
        enasarcoDeficitOption,
        assignees,
        initialValues,
        invoiceId,
        is: { loading: isLoading },
    },
};
export default invoiceEditSlice.reducer;
