import { taskEither } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { BAZAAR_URL } from '../../../api/constants';
import { StorageCache } from '../../../lib/cache/StorageCache';
import { TypedCache } from '../../../lib/cache/TypedCache';
import { AuthClient } from '../../../lib/http/AuthClient';
import { AxiosClient } from '../../../lib/http/AxiosClient';
import { JsonClient } from '../../../lib/http/JsonClient';
import { PooledClient, HttpClientPool } from '../../../lib/http/PooledClient';
export const transmitActiveInvoice = (activeInvoicePayload) => pipe(taskEither.tryCatch(() => PooledClient(AuthClient(JsonClient(AxiosClient), TypedCache(StorageCache(localStorage)), 'Bearer'), HttpClientPool).post(`${BAZAAR_URL}/api/v1/active-invoices`, {
    data: activeInvoicePayload,
}), e => e));
