import { option, readonlyArray } from 'fp-ts';
import { constant, pipe } from 'fp-ts/function';
import { PROCEDURE_STATUS_VALUES } from '../models/ProcedureStatusModel';
export const procedureStatus = [
    {
        label: "Alla ricerca dell'immobile",
        value: PROCEDURE_STATUS_VALUES.searchingProperty,
    },
    {
        label: 'Individuato immobile',
        value: PROCEDURE_STATUS_VALUES.propertyIdentified,
    },
    {
        label: 'Firmato compromesso',
        value: PROCEDURE_STATUS_VALUES.compromiseSigned,
    },
    {
        label: 'Immobile già di proprietà',
        value: PROCEDURE_STATUS_VALUES.ownedProperty,
    },
    {
        label: 'Fatta proposta di acquisto',
        value: PROCEDURE_STATUS_VALUES.purchaseProposalMade,
    },
    { label: 'Trattativa in corso', value: PROCEDURE_STATUS_VALUES.ongoingNegotiation },
    { label: 'Atto notarile fissato', value: PROCEDURE_STATUS_VALUES.notarialActEstablished },
];
export const procedureStatusView = (procedureStatusModel) => pipe(procedureStatus, readonlyArray.findFirst(({ value }) => value === procedureStatusModel), option.map(({ label }) => label), option.getOrElse(constant('')));
