import { either } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import { all, call, cancel, put, select, take, takeLeading } from 'typed-redux-saga';
import { isUserAuthorized } from '../../../authorization/useAuthorization';
import { PERMISSION, SecurityState } from '../../../store/security/SecurityState';
import { getInvoice } from '../../web/getInvoiceItem';
import { getUser } from '../../web/getUser';
import { $InvoiceDetail } from './invoiceDetailState';
const noUserFoundError = () => new Error('no user found');
const getUserFromState = (state) => pipe(state.security.user, either.fromOption(noUserFoundError));
function* fetchInvoiceDetail(action) {
    try {
        yield* put($InvoiceDetail.InvoiceDetailRequested());
        const userPermissionList = yield* select(SecurityState.selector.permissionList);
        const invoiceEither = yield* call(getInvoice(action.payload));
        if (either.isLeft(invoiceEither)) {
            throw invoiceEither.left;
        }
        let userEither = either.left(noUserFoundError);
        if (isUserAuthorized(userPermissionList)(PERMISSION.FETCH_USER_FROM_INVOICE_DETAIL)) {
            userEither = yield* call(getUser(invoiceEither.right.userId));
        }
        else {
            userEither = yield* select(getUserFromState);
        }
        if (either.isLeft(userEither)) {
            throw userEither.left;
        }
        yield* put($InvoiceDetail.InvoiceDetailFetched({
            invoice: invoiceEither.right,
            user: userEither.right,
        }));
    }
    catch (e) {
        yield* put($InvoiceDetail.InvoiceDetailFailed(e));
    }
}
export function* InvoiceDetailSaga() {
    yield* takeLeading($InvoiceDetail.start, function* (action) {
        yield* put($InvoiceDetail.Started());
        const task = yield* all([yield* takeLeading($InvoiceDetail.fetchInvoiceDetail, fetchInvoiceDetail)]);
        yield* put($InvoiceDetail.fetchInvoiceDetail(action.payload));
        yield* take($InvoiceDetail.stop);
        yield* cancel(task);
        yield* put($InvoiceDetail.Stopped());
    });
}
