import { createSlice } from '@reduxjs/toolkit';
import { either, option, readonlyArray } from 'fp-ts';
import { constant, flow, pipe } from 'fp-ts/function';
import { $currency } from '../../models/Currency/Currency';
import { InvoiceUtils } from '../../models/Invoice/Invoice.codec';
import { UserUtils } from '../../models/User/User.codec';
const initialState = {
    loading: false,
    error: option.none,
    invoices: either.left('no data'),
    users: either.left('no data'),
    enasarcoOverview: [],
};
export const invoiceListSlice = createSlice({
    name: 'InvoiceList',
    initialState,
    reducers: {
        startInvoiceList: (state, _) => state,
        endInvoiceList: constant(initialState),
        fetchInvoiceTable: (state, _) => state,
        InvoiceTableRequested: (state, action) => (Object.assign(Object.assign({}, state), { loading: action.payload, error: option.none })),
        InvoiceTableFetched: (state, action) => (Object.assign(Object.assign({}, state), { invoices: either.right(action.payload.invoices), users: either.right(action.payload.users), enasarcoOverview: action.payload.enasarcoOverview, loading: false, error: option.none })),
        InvoiceTableNotFetched: (state, action) => (Object.assign(Object.assign({}, state), { users: either.left('no users'), invoices: either.left('no invoices'), enasarcoOverview: [], error: option.some(action.payload), loading: false })),
    },
});
const getCurrency = (invoices) => pipe(invoices, InvoiceUtils.amount, $currency.euro, option.getOrElse(constant('')));
const enasarcoByInvoiceId = (invoiceId) => (enasarcoOverviewList) => pipe(enasarcoOverviewList, readonlyArray.findFirst(enasarco => enasarco.invoiceId === invoiceId), option.chain(({ enasarco }) => enasarco));
const enasarcoDeficit = (invoice, enasarcoOverviewList) => pipe(invoice.hasEnasarco, option.fromNullable, option.filter(enasarco => enasarco === true), option.chain(() => pipe(enasarcoOverviewList, enasarcoByInvoiceId(invoice._id), option.chain(({ enasarco }) => InvoiceUtils.enasarcoDeficit(enasarco.leftover)(invoice)))));
const invoiceCell = (invoice, enasarcoOverview) => (users) => ({
    id: invoice._id,
    statusCode: invoice.statusCode,
    fullName: pipe(users, UserUtils.getUserById(invoice.userId), option.match(() => '', user => user.fullName)),
    amount: getCurrency(invoice.items),
    hasEnasarco: InvoiceUtils.hasEnasarco(invoice.hasEnasarco),
    enasarcoDeficit: enasarcoDeficit(invoice, enasarcoOverview),
    documentNumber: InvoiceUtils.documentNumber(invoice.documentNumber),
    month: InvoiceUtils.month(invoice.month),
    approved: invoice.approved,
});
const invoiceTable = (state) => pipe(state.invoiceList.invoices, either.map(flow(readonlyArray.map(invoice => pipe(state.invoiceList.users, either.map(invoiceCell(invoice, state.invoiceList.enasarcoOverview)), option.fromEither)), readonlyArray.compact)), either.filterOrElseW(readonlyArray.isNonEmpty, constant([])), either.map(body => ({
    headers: [
        {
            approved: '',
            assignee: 'Collaboratore',
            amount: 'Imponibile',
            enasarco: 'Enasarco',
            documentNumber: 'ID fattura',
            month: 'Mese competenza',
        },
    ],
    body: body,
})));
const isLoading = (state) => state.invoiceList.loading;
export const $InvoiceList = invoiceListSlice.actions;
export const $invoiceListSlice = {
    selector: {
        isLoading,
        invoiceTable,
        enasarcoByInvoiceId,
        enasarcoDeficit,
    },
};
export default invoiceListSlice.reducer;
