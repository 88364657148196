import { curry } from 'fortepiano/function';
import { readonlyArray as RA, apply } from 'fp-ts';
import { constTrue, pipe } from 'fp-ts/function';
import { aggregate } from './Aggregate';
/**
 * {@link https://en.wikipedia.org/wiki/Cartesian_product}
 */
const cartesian = apply.sequenceT(RA.Apply);
const allElems = (E) => (...elems) => (as) => pipe(elems, RA.intersection(E)(as), curry(EqSize.equals)(elems));
const anyElem = (E) => (...elems) => (as) => pipe(elems, RA.some(elem => pipe(as, RA.elem(E)(elem))));
/**
 * @example
 * import { eq, string } from 'fp-ts'
 * import { Eq } from 'fp-ts/Eq'
 * import { pipe } from 'fp-ts/function'
 *
 * type User = { name: string; mother: string; father: string }
 *
 * const eqParents: Eq<User> = pipe(
 *   eq.tuple(string.Eq, string.Eq),
 *   eq.contramap(({ mother, father }) => [mother, father] as const)
 * )
 * const siblings = same(eqParents)
 *
 * expect(
 *   siblings([
 *     { name: 'Thomas', mother: 'Edith', father: 'Matthew' },
 *     { name: 'Thomas', mother: 'Edith', father: 'Richard' },
 *   ])
 * ).toBe(false)
 * expect(
 *   siblings([
 *     { name: 'Thomas', mother: 'Edith', father: 'Matthew' },
 *     { name: 'William', mother: 'Edith', father: 'Matthew' },
 *   ])
 * ).toBe(true)
 */
const same = (E) => (as) => pipe(as, RA.matchLeft(constTrue, (head, tail) => pipe(tail, RA.every(curry(E.equals)(head)))));
const EqSize = aggregate.getEq(RA);
export const readonlyArray = Object.assign(Object.assign({}, RA), { cartesian,
    allElems,
    anyElem,
    same,
    EqSize });
