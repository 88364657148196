import { fold } from 'fp-ts/Option';
import { pipe } from 'fp-ts/function';
import { Lenses } from '../../lib/Lenses';
import { Node } from '../../lib/Tree';
import { CategoryRequirement } from './CategoryRequirement';
const lenses = Lenses.fromProps()({
    children: 'children',
});
const fromNode = (node) => lenses.children.set(Node.lens().nodes.get(node).map(fromNode))(pipe(Node.optional().value.getOption(node), fold(() => ({
    id: '',
    code: '',
    title: '',
    required: false,
    children: [],
    fillable: false,
}), requirement => ({
    id: CategoryRequirement.lens.id.get(requirement),
    code: CategoryRequirement.lens.code.get(requirement),
    title: CategoryRequirement.lens.name.get(requirement),
    required: CategoryRequirement.lens.required.get(requirement),
    fillable: CategoryRequirement.lens.fillable.get(requirement),
    children: [],
}))));
export const Category = {
    lens: lenses,
    fromNode: fromNode,
};
