import { Tree } from '../../lib/Tree';
import { Category } from './Category';
import { CategoryRequirement } from './CategoryRequirement';
export const CategoryHierarchy = {
    fromRequirements: (requirements) => {
        const leaves = requirements.map(category => ({
            id: `${CategoryRequirement.lens.id.get(category)}`,
            ref: `${CategoryRequirement.lens.parent.get(category)}`,
            value: category,
        }));
        return Tree.lens().nodes.get(Tree.fromLeaves(leaves)).map(Category.fromNode);
    },
};
