import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Form, Formik } from 'formik';
import { useEffect } from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { $PassiveInvoiceBulkEditActions, $passiveInvoiceBulkEditSlice, } from '../state/passiveInvoiceBulkEditActions/PassiveInvoiceBulkEditActionsState';
import { DialogTitle } from './DialogTitle';
const handleDateChange = (setFieldValue) => (date) => {
    if (date === null) {
        return;
    }
    setFieldValue('month', date);
};
const selectedText = (invoicesSelected) => invoicesSelected.length === 1 ? 'Modifica 1 fattura' : `Modifica ${invoicesSelected.length} fatture`;
const onSubmit = (dispatch, updateInvoices, invoiceListId, onClose, resetSelection) => (formData) => {
    if (formData.month === null && formData.hasEnasarco === null) {
        return;
    }
    dispatch(updateInvoices({
        formData: formData,
        invoiceListId: invoiceListId,
        onSuccess: () => {
            onClose();
            resetSelection();
        },
    }));
};
const radio = _jsx(Radio, { size: "small" });
export const BulkEditDialog = ({ onClose, resetSelection, invoiceListId, open }) => {
    const dispatch = useAppDispatch();
    const initialValues = useAppSelector($passiveInvoiceBulkEditSlice.selector.initialValues);
    const isUpdating = useAppSelector($passiveInvoiceBulkEditSlice.selector.isUpdating);
    useEffect(() => {
        dispatch($PassiveInvoiceBulkEditActions.start());
        return () => {
            dispatch($PassiveInvoiceBulkEditActions.stop());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (_jsxs(Dialog, { open: open, fullWidth: true, maxWidth: "sm", children: [_jsx(DialogTitle, { onClose: onClose, title: selectedText(invoiceListId) }), _jsx(DialogContent, { children: _jsx(Formik, { initialValues: initialValues, onSubmit: onSubmit(dispatch, $PassiveInvoiceBulkEditActions.updateInvoices, invoiceListId, onClose, resetSelection), children: ({ values, setFieldValue, handleChange }) => (_jsxs(Form, { children: [_jsx(DatePicker, { slotProps: {
                                    textField: {
                                        variant: 'standard',
                                        name: 'month',
                                        fullWidth: true,
                                        style: { marginBottom: 32 },
                                    },
                                }, views: ['month', 'year'], value: values.month, label: "Mese competenza", onChange: handleDateChange(setFieldValue) }), _jsxs(FormControl, { variant: "standard", component: "fieldset", fullWidth: true, children: [_jsx(FormLabel, { children: "Enasarco" }), _jsxs(RadioGroup, { name: "hasEnasarco", value: values.hasEnasarco, onChange: handleChange, row: true, children: [_jsx(FormControlLabel, { value: "si", control: radio, label: "S\u00EC", labelPlacement: "end" }), _jsx(FormControlLabel, { value: "no", control: radio, label: "No", labelPlacement: "end" })] })] }), _jsx(Grid, { container: true, justifyContent: "center", children: _jsx(Button, { type: "submit", variant: "contained", disabled: isUpdating, children: "Salva" }) })] })) }) })] }));
};
