import { array, either, option } from 'fp-ts';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import { push } from 'redux-first-history';
import { all, call, cancel, debounce, put, select, take, takeLeading } from 'typed-redux-saga';
import { $routes } from '../../routes/routes';
import { Session } from '../../session/state/Session';
import { NotificationAction } from '../../store/notification/NotificationAction';
import { Notification } from '../../utils/notification';
import { getLeads } from '../web/getLeads';
import { getLocation } from '../web/location';
import { quoteMortgageRequestById } from '../web/quoteMortgageRequestById';
import { saveLead } from '../web/saveLead';
import { saveMortgageRequest } from '../web/saveMortgageRequest';
import { saveQuote } from '../web/saveQuote';
import { $Quote } from './State';
function* fetchFirstParticipantPlaceOfResidence(locationSearch) {
    if (t.string.is(locationSearch) && locationSearch.length >= 2) {
        yield* put($Quote.FirstParticipantPlaceOfResidenceRequested());
        const response = yield* call(getLocation(locationSearch));
        if (either.isLeft(response)) {
            yield* put($Quote.FirstParticipantPlaceOfResidenceNotFetched());
        }
        else {
            yield* put($Quote.FirstParticipantPlaceOfResidenceFetched(response.right));
        }
    }
}
function* fetchRealEstateLocation(locationSearch) {
    if (t.string.is(locationSearch) && locationSearch.length >= 2) {
        yield* put($Quote.RealEstateLocationRequested());
        const response = yield* call(getLocation(locationSearch));
        if (either.isLeft(response)) {
            yield* put($Quote.RealEstateLocationNotFetched());
        }
        else {
            yield* put($Quote.RealEstateLocationFetched(response.right));
        }
    }
}
function* fetchQuotation(quotationCommand) {
    yield* put($Quote.QuotationRequested());
    const mortgageRequestIdEither = yield* call(saveMortgageRequest(quotationCommand.payload.quote));
    if (either.isLeft(mortgageRequestIdEither)) {
        yield put(NotificationAction.Push(Notification.error('Si è verificato un errore. Riprova')));
        yield* put($Quote.QuotationNotFetched());
    }
    else {
        const quotationEither = yield* call(quoteMortgageRequestById(mortgageRequestIdEither.right.id, quotationCommand.payload.quote.filters));
        if (either.isRight(quotationEither)) {
            yield* put(push($routes.QUOTE_RESULTS));
            const onSuccess = quotationCommand.payload.onSuccess;
            if (onSuccess) {
                yield* call(onSuccess);
            }
            yield* put($Quote.QuotationFetched({
                search: quotationCommand.payload.quote,
                participants: quotationCommand.payload.quote.participants,
                bazaarQuotation: quotationEither.right,
                quotationRequestId: mortgageRequestIdEither.right.id,
            }));
        }
        else {
            yield* put($Quote.QuotationNotFetched());
        }
    }
}
function* trySaveLead(saveLeadCommand) {
    yield* put($Quote.SaveLeadRequested());
    const saveLeadEither = yield* call(saveLead(saveLeadCommand.payload.search));
    if (either.isLeft(saveLeadEither)) {
        yield* put($Quote.LeadNotSaved());
    }
    else {
        yield* call(saveLeadCommand.payload.setFieldValue, 'leadId', saveLeadEither.right.id);
        yield* put($Quote.LeadSaved());
    }
}
function* searchLeads(searchLeadsCommand) {
    yield* put($Quote.SearchLeadsRequested());
    const leadsEither = yield* call(getLeads(searchLeadsCommand.payload.search));
    if (either.isLeft(leadsEither)) {
        yield put(NotificationAction.Push(Notification.error('Si è verificato un errore. Riprova')));
        yield* put($Quote.LeadsNotFound());
    }
    else if (leadsEither.right.length === 0) {
        const saveLeadEither = yield* call(saveLead(searchLeadsCommand.payload.search));
        if (either.isRight(saveLeadEither)) {
            yield* call(searchLeadsCommand.payload.setFieldValue, 'leadId', saveLeadEither.right.id);
            const onFailure = searchLeadsCommand.payload.onFailure;
            if (onFailure) {
                yield* call(onFailure);
            }
        }
        else {
            yield put(NotificationAction.Push(Notification.error('Si è verificato un errore. Riprova')));
        }
        yield* put($Quote.LeadsNotFound());
    }
    else {
        const onSuccess = searchLeadsCommand.payload.onSuccess;
        if (onSuccess) {
            yield* call(onSuccess);
        }
        yield* put($Quote.LeadsFound(leadsEither.right));
    }
}
function* increaseFilterCounter(command) {
    yield* put($Quote.FilterCounterIncreased(command.payload.currentActiveFiltersCounter));
}
function* decreaseFilterCounter(command) {
    yield* put($Quote.FilterCounterDecreased(command.payload.currentActiveFiltersCounter));
}
function* resetFilterCounter() {
    yield* put($Quote.FilterCounterResetted());
}
function* trySaveQuote(command) {
    yield* put($Quote.SaveQuoteRequested(command.payload.quotationKey));
    const quotationList = yield* select((state) => state.quote.quotationList);
    const quotationToSaveOption = pipe(quotationList, array.findFirst(quotation => quotation.key === command.payload.quotationKey), option.filter(quotation => quotation.isSaved === false));
    if (option.isSome(quotationToSaveOption) && command.payload.quotationRequestId) {
        const saveQuoteEither = yield* call(saveQuote(quotationToSaveOption.value.raw, command.payload.brokerageAmount, command.payload.quotationRequestId));
        if (either.isRight(saveQuoteEither)) {
            yield* put($Quote.QuoteSaved(command.payload.quotationKey));
        }
        else {
            yield* put($Quote.QuoteNotSaved(command.payload.quotationKey));
        }
    }
    else {
        yield* put($Quote.QuoteNotSaved(command.payload.quotationKey));
    }
}
function* updateRequest(quoteService, command) {
    yield* put($Quote.UpdateRequestRequested());
    const token = Session.getToken();
    const requestWithQuotesEither = yield* call(quoteService.updateRequest({
        token: token,
        requestId: command.payload.requestId,
        payload: { notes: command.payload.notes },
    }));
    if (either.isRight(requestWithQuotesEither)) {
        const onSuccess = command.payload.onSuccess;
        if (onSuccess) {
            yield* call(onSuccess);
        }
        yield* put($Quote.RequestUpdated());
    }
    else {
        yield* put($Quote.RequestNotUpdated());
        yield* put(NotificationAction.Push(Notification.error('Si è verificato un errore nel salvataggio della nota')));
    }
}
function* resetIsSaved() {
    yield* put($Quote.IsSavedResetted());
}
export function* QuoteSaga(quoteService) {
    yield* takeLeading($Quote.start, function* (startCommand) {
        var _a;
        yield* put($Quote.Started({ requestWithQuotesModel: (_a = startCommand.payload.requestWithQuotes) !== null && _a !== void 0 ? _a : undefined }));
        const task = yield* all([
            yield* takeLeading($Quote.updateRequest, function* (command) {
                yield* call(updateRequest, quoteService, command);
            }),
            yield* takeLeading($Quote.resetIsSaved, resetIsSaved),
            yield* takeLeading($Quote.saveQuote, trySaveQuote),
            yield* takeLeading($Quote.fetchQuotation, fetchQuotation),
            yield* takeLeading($Quote.searchLeads, searchLeads),
            yield* takeLeading($Quote.saveLead, trySaveLead),
            yield* takeLeading($Quote.increaseFilterCounter, increaseFilterCounter),
            yield* takeLeading($Quote.decreaseFilterCounter, decreaseFilterCounter),
            yield* takeLeading($Quote.resetFilterCounter, resetFilterCounter),
            yield* debounce(1000, $Quote.fetchFirstParticipantPlaceOfResidence, function* (fetchLocationCommand) {
                yield* call(fetchFirstParticipantPlaceOfResidence, fetchLocationCommand.payload.locationSearch);
            }),
            yield* debounce(1000, $Quote.fetchRealEstateLocation, function* (fetchRealEstateLocationCommand) {
                yield* call(fetchRealEstateLocation, fetchRealEstateLocationCommand.payload.locationSearch);
            }),
        ]);
        yield* take($Quote.stop);
        yield* cancel(task);
        yield* put($Quote.Stopped());
    });
}
