import { $mock } from 'fortepiano';
import { pipe } from 'fp-ts/function';
import * as t from 'io-ts';
const detailCodec = t.string;
export const BazaarErrorCodec = t.type({
    detail: detailCodec,
    status: t.number,
    title: t.string,
});
export const BazaarErrorMock = (bazaarError) => pipe($mock.struct({
    detail: $mock.string,
    status: $mock.integer(400, 599),
    title: $mock.string,
}), $mock.map(bazaarErrorMock => (Object.assign(Object.assign({}, bazaarErrorMock), bazaarError))));
