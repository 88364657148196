import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, CircularProgress, Collapse, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';
import { makeStyles } from 'tss-react/mui';
import { Authorization } from '../../authorization/Authorization';
import { Pagination } from '../../common/ui/molecules/Pagination';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { $routes } from '../../routes/routes';
import { PERMISSION } from '../../store/security/SecurityState';
import { $ProcessingQuotes, $ProcessingQuotesSlice } from '../state/ProcessingQuotesState';
import { currencyView } from '../view/CurrencyView';
import { mortgagePurposeView } from '../view/MortgagePurposeView';
import { productTypeView } from '../view/ProductTypeView';
const useStyles = makeStyles()(() => ({
    headerLabel: {
        color: '#6F797A',
        textTransform: 'uppercase',
    },
    tableSmallCell: {
        width: '40px',
    },
}));
const getRequestByLead = (requestByLeadTable, leadId) => {
    return requestByLeadTable[leadId] || [];
};
export const LeadTable = () => {
    const dispatch = useAppDispatch();
    const { classes } = useStyles();
    const pagination = useAppSelector($ProcessingQuotesSlice.selector.pagination);
    const leadsTable = useAppSelector($ProcessingQuotesSlice.selector.leadsTable);
    const isLeadsTableLoading = useAppSelector($ProcessingQuotesSlice.selector.isLeadsTableLoading);
    const appliedFilters = useAppSelector($ProcessingQuotesSlice.selector.appliedFilters);
    const quoteRequestByLeadTable = useAppSelector($ProcessingQuotesSlice.selector.quoteRequestByLeadTable);
    const isQuoteRequestByLeadTableLoading = useAppSelector($ProcessingQuotesSlice.selector.isQuoteRequestByLeadTableLoading);
    const leadSections = useAppSelector($ProcessingQuotesSlice.selector.leadSections);
    return isLeadsTableLoading ? (_jsx(Box, { style: { display: 'flex', justifyContent: 'center' }, children: _jsx(CircularProgress, {}) })) : (_jsxs(_Fragment, { children: [_jsx(TableContainer, { component: Paper, elevation: 0, children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { className: classes.tableSmallCell }), _jsx(TableCell, { children: _jsx(Typography, { className: classes.headerLabel, children: "Nome" }) }), _jsx(TableCell, { children: _jsx(Typography, { className: classes.headerLabel, children: "Email" }) }), _jsx(TableCell, { children: _jsx(Typography, { className: classes.headerLabel, children: "Telefono" }) }), _jsx(TableCell, { children: _jsx(Typography, { className: classes.headerLabel, children: "Rubinetto" }) }), _jsx(Authorization, { permissions: [PERMISSION.VIEW_ASSIGNED_TO_COLUMN_LEAD_LIST], children: _jsx(TableCell, { children: _jsx(Typography, { className: classes.headerLabel, children: "Creato da" }) }) }), _jsx(TableCell, {})] }) }), _jsx(TableBody, { children: leadsTable.map((leadRow, key) => (_jsxs(React.Fragment, { children: [_jsxs(TableRow, { onClick: () => {
                                            dispatch($ProcessingQuotes.toggleLeadSection({ leadId: leadRow.id }));
                                            dispatch($ProcessingQuotes.getQuoteRequestByLead({
                                                leadId: leadRow.id,
                                                quoteRequestByLeadTable: quoteRequestByLeadTable,
                                            }));
                                        }, sx: { cursor: 'pointer', '&:last-child td, &:last-child th': { border: 0 } }, children: [_jsx(TableCell, { children: _jsx(IconButton, { size: "small", children: leadSections[leadRow.id] ? (_jsx(KeyboardArrowUpIcon, {})) : (_jsx(KeyboardArrowDownIcon, {})) }) }), _jsx(TableCell, { children: _jsxs(Typography, { children: [leadRow.firstName, " ", leadRow.lastName] }) }), _jsx(TableCell, { children: _jsx(Typography, { children: leadRow.email }) }), _jsx(TableCell, { children: _jsx(Typography, { children: leadRow.phoneNumber }) }), _jsx(TableCell, { children: _jsx(Typography, { children: leadRow.channel }) }), _jsx(Authorization, { permissions: [PERMISSION.VIEW_ASSIGNED_TO_COLUMN_LEAD_LIST], children: _jsx(TableCell, { children: _jsxs(Typography, { children: [leadRow.assignedTo.firstName, " ", leadRow.assignedTo.lastName] }) }) }), _jsx(TableCell, { align: "right", children: _jsx(IconButton, { sx: { marginLeft: '8px' }, color: "primary", onClick: e => {
                                                        e.stopPropagation();
                                                        dispatch($ProcessingQuotes.deleteLead({
                                                            leadId: leadRow.id,
                                                            filter: appliedFilters,
                                                        }));
                                                    }, children: _jsx(DeleteOutlineIcon, {}) }) })] }, key), _jsx(TableRow, { children: _jsx(TableCell, { style: { padding: 0 }, colSpan: 7, children: _jsx(Collapse, { in: leadSections[leadRow.id], children: _jsxs(Table, { children: [!isQuoteRequestByLeadTableLoading[leadRow.id] &&
                                                            getRequestByLead(quoteRequestByLeadTable, leadRow.id).length ===
                                                                0 && (_jsx(TableBody, { children: _jsx(TableRow, { children: _jsx(TableCell, { align: "center", colSpan: 7, children: "Non sono presenti preventivi salvati per il cliente selezionato." }) }) })), isQuoteRequestByLeadTableLoading[leadRow.id] === true && (_jsx(TableBody, { children: _jsx(TableRow, { children: _jsx(TableCell, { align: "center", colSpan: 7, children: _jsx(CircularProgress, { size: 20 }) }) }) })), _jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { className: classes.tableSmallCell }), _jsx(TableCell, { children: _jsx(Typography, { children: _jsx("strong", { children: "Data richiesta" }) }) }), _jsx(TableCell, { children: _jsx(Typography, { children: _jsx("strong", { children: "Prodotto" }) }) }), _jsx(TableCell, { children: _jsx(Typography, { children: _jsx("strong", { children: "Finalit\u00E0" }) }) }), _jsx(TableCell, { children: _jsx(Typography, { children: _jsx("strong", { children: "Importo richiesto" }) }) }), _jsx(TableCell, {})] }) }), getRequestByLead(quoteRequestByLeadTable, leadRow.id).map((request, quoteRequestKey) => (_jsx(TableBody, { children: _jsxs(TableRow, { sx: {
                                                                    '&:last-child td, &:last-child th': {
                                                                        border: 0,
                                                                    },
                                                                }, children: [_jsx(TableCell, { className: classes.tableSmallCell }), _jsx(TableCell, { children: _jsx(Typography, { children: format(new Date(request.submittedAt), 'dd/MM/yyyy') }) }), _jsx(TableCell, { children: _jsx(Typography, { children: productTypeView(request.productType) }) }), _jsx(TableCell, { children: _jsx(Typography, { children: mortgagePurposeView(request.purpose) }) }), _jsx(TableCell, { children: _jsx(Typography, { children: currencyView(request.requestedAmount ||
                                                                                request.leftoverAmount ||
                                                                                0) }) }), _jsxs(TableCell, { align: "right", children: [_jsx(Button, { variant: "contained", onClick: () => {
                                                                                    window.open(`${$routes.PROCESSING_QUOTES}/${request.id}`, '_blank');
                                                                                }, children: "Dettagli" }), _jsx(IconButton, { sx: { marginLeft: '8px' }, color: "primary", onClick: () => {
                                                                                    dispatch($ProcessingQuotes.deleteRequest({
                                                                                        requestId: request.id,
                                                                                        leadId: leadRow.id,
                                                                                    }));
                                                                                }, children: _jsx(DeleteOutlineIcon, {}) })] })] }) }, quoteRequestKey)))] }) }) }) })] }, key))) })] }) }), _jsx(Pagination, { previousPageCommand: () => dispatch($ProcessingQuotes.fetchPreviousPage({ filter: appliedFilters })), nextPageCommand: () => dispatch($ProcessingQuotes.fetchNextPage({ filter: appliedFilters })), firstPageCommand: () => dispatch($ProcessingQuotes.fetchFirstPage({ filter: appliedFilters })), currentPage: pagination.currentPage, isNextPageDisabled: pagination.isNextPageDisabled, isPreviousPageDisabled: pagination.isPreviousPageDisabled })] }));
};
