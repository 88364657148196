import { option } from 'fp-ts';
import { constant, pipe } from 'fp-ts/function';
import { all, fork, put, select, takeEvery } from 'redux-saga/effects';
import { call } from 'typed-redux-saga';
import { cancelPipeline, getPipelinesMeta, nextPipeline, startConfiguration, startPipeline } from '../../api/pipelines';
import { Notification } from '../../utils/notification';
import { session } from '../../utils/session';
import { PIPELINES_CONFIGURATIONS_REQUEST, PIPELINES_CONFIRM_REQUEST, PIPELINES_MODAL_REQUEST, PipelinesConfigurationsFailure, PipelinesConfigurationsSuccess, PipelinesConfirmFailure, PipelinesConfirmSuccess, PipelinesModalFailure, PipelinesModalSuccess, } from '../actions/pipelines';
import { NotificationAction } from '../notification/NotificationAction';
import { ProcessState } from '../processes/ProcessState';
import { RecordAction } from '../record/RecordAction';
import { SecurityActions } from '../security/SecurityActions';
function* postConfigurations(action) {
    try {
        const { record, configurations } = action.payload;
        yield call(startConfiguration, record, configurations);
        yield put(PipelinesConfigurationsSuccess({ message: '' }));
        yield put(RecordAction.Detail({ recordId: record, pooled: true }));
        if (action.payload.onSuccessMessage) {
            yield put(NotificationAction.Push(Notification.info(action.payload.onSuccessMessage)));
        }
    }
    catch (e) {
        if (e.response) {
            if (e.response.status === 401) {
                const state = yield select();
                if (!ProcessState.selector.has.running(SecurityActions.Logout)(state)) {
                    yield put(SecurityActions.Logout(session.fromError(e.response.data.error)));
                }
            }
            else {
                const errorMessage = pipe(action.payload.onFailMessage, option.fromNullable, option.getOrElse(constant('Si è verificato un errore')));
                yield put(PipelinesConfigurationsFailure({
                    error: errorMessage,
                }));
                yield put(NotificationAction.Push(Notification.error(errorMessage)));
            }
        }
        else {
            yield put(PipelinesConfigurationsFailure({ error: e.message }));
        }
    }
}
function* fetchPipelinesMeta(action) {
    var _a, _b, _c, _d, _e, _f;
    try {
        const response = yield* call(getPipelinesMeta, action.payload.id);
        const responseData = response.data;
        yield put(PipelinesModalSuccess({
            configurations: ((_b = (_a = responseData === null || responseData === void 0 ? void 0 : responseData.meta) === null || _a === void 0 ? void 0 : _a.pipelinesMetadata) === null || _b === void 0 ? void 0 : _b.configurations) || [],
            instances: ((_d = (_c = responseData === null || responseData === void 0 ? void 0 : responseData.meta) === null || _c === void 0 ? void 0 : _c.pipelinesMetadata) === null || _d === void 0 ? void 0 : _d.pipelines) || [],
            history: ((_f = (_e = responseData === null || responseData === void 0 ? void 0 : responseData.meta) === null || _e === void 0 ? void 0 : _e.pipelinesMetadata) === null || _f === void 0 ? void 0 : _f.history) || [],
        }));
    }
    catch (e) {
        if (e.response) {
            if (e.response.status === 403) {
                yield put(PipelinesModalFailure({
                    status: e.response.status,
                    error: '',
                }));
            }
            else if (e.response.status === 401) {
                const state = yield select();
                if (!ProcessState.selector.has.running(SecurityActions.Logout)(state)) {
                    yield put(SecurityActions.Logout(session.fromError(e.response.data.error)));
                }
            }
            else {
                yield put(PipelinesModalFailure({
                    error: 'Si è verificato un errore nel download degli esiti',
                }));
                yield put(NotificationAction.Push(Notification.error('Si è verificato un errore nel download degli esiti')));
            }
        }
        else {
            yield put(PipelinesModalFailure({ error: e.message }));
        }
    }
}
export const factSheetCode = 'production' === process.env.NODE_ENV &&
    /^https?:\/\/.+(\.facile\.it|\.public\.k8s-facile\.it)\/?$/.test(window.location.href)
    ? 'nexus-documentazione_generale.documenti_nexus.documenti_condivisi.foglio_informativo'
    : 'documentazione_generale.documenti_nexus.documenti_condivisi.foglio_informativo';
const isFactSheetDocumentAvailable = (documents) => {
    const factSheetDocument = documents.find(document => document.category.code === factSheetCode);
    if (!factSheetDocument) {
        return true;
    }
    const isSigned = factSheetDocument.status.code === 'signed';
    const uploadedFactSheetDocument = documents.find(document => document.category.code === factSheetCode && !document.filled_out);
    return isSigned || uploadedFactSheetDocument;
};
const isFactSheetMandatory = (pipelineCode, recordStatusCode) => recordStatusCode === 'inserimento_dati_e_raccolta_documenti' &&
    (pipelineCode === 'state:next:agente:consegna_a_bo_tirana' ||
        pipelineCode === 'state:next:agente:richiesta_approvazione_ha');
function* postPipeline({ payload: { documents, recordStatusCode, record, pipeline, instances, onSuccessMessage, onSuccessAction, onFailMessage, }, }) {
    let next = false;
    const MANDATORY_DOCUMENTS_MISSING_ERROR = 'Documenti obbligatori non caricati';
    if (!isFactSheetDocumentAvailable(documents) && isFactSheetMandatory(pipeline.code, recordStatusCode)) {
        yield put(NotificationAction.Push(Notification.error(!next ? MANDATORY_DOCUMENTS_MISSING_ERROR : onFailMessage)));
        yield put(PipelinesConfirmFailure({ error: MANDATORY_DOCUMENTS_MISSING_ERROR }));
        return;
    }
    try {
        if (/(^|:)next:.+/.test(pipeline.code)) {
            const valid = instances
                .filter(instance => instance.supported)
                .reduce(
            // eslint-disable-next-line eqeqeq, no-eq-null
            (isValid, instance) => isValid && (null == instance.valid ? true : instance.valid), true);
            if (!valid) {
                throw Error(MANDATORY_DOCUMENTS_MISSING_ERROR);
            }
            for (const instance of instances.filter(({ supported }) => supported)) {
                yield call(nextPipeline, instance);
            }
            for (const instance of instances.filter(({ supported }) => !supported)) {
                yield call(cancelPipeline, instance);
            }
        }
        if (/(^|:)cancel:.+/.test(pipeline.code)) {
            for (const instance of instances) {
                yield call(cancelPipeline, instance);
            }
        }
        next = true;
        yield call(startPipeline, record, pipeline.code);
        if (/(^|:)head:.+/.test(pipeline.code)) {
            yield put(RecordAction.Detail({ recordId: record, pooled: true }));
        }
        yield put(PipelinesConfirmSuccess({
            message: /(^|:)documents:.+/.test(pipeline.code) ? '' : onSuccessMessage,
        }));
        yield put(NotificationAction.Push(Notification.info(/(^|:)documents:.+/.test(pipeline.code) ? '' : onSuccessMessage)));
        if (onSuccessAction) {
            yield call(onSuccessAction);
        }
    }
    catch (e) {
        if (e.response || MANDATORY_DOCUMENTS_MISSING_ERROR === e.message) {
            if (e.response && e.response.status === 401) {
                const state = yield select();
                if (!ProcessState.selector.has.running(SecurityActions.Logout)(state)) {
                    yield put(SecurityActions.Logout(session.fromError(e.response.data.error)));
                }
            }
            else if (e.response &&
                e.response.data &&
                e.response.data.errors &&
                Array.isArray(e.response.data.errors) &&
                e.response.data.errors.length > 0 &&
                e.response.data.errors[0].code === 'Facile\\Artoo\\Exception\\DuplicateSignersException') {
                const errorMessage = "C'è stato un errore nella richiesta di firma. L'indirizzo email e il telefono cellulare di ogni partecipante devono essere diversi.";
                yield put(PipelinesConfirmFailure({
                    error: errorMessage,
                }));
                yield put(NotificationAction.Push(Notification.error(errorMessage)));
            }
            else {
                yield put(PipelinesConfirmFailure({
                    error: !next ? MANDATORY_DOCUMENTS_MISSING_ERROR : onFailMessage,
                }));
                yield put(NotificationAction.Push(Notification.error(!next ? MANDATORY_DOCUMENTS_MISSING_ERROR : onFailMessage)));
            }
        }
        else {
            yield put(PipelinesConfirmFailure({ error: e.message }));
        }
    }
}
function* watchPipelinesConfigurationsRequest() {
    yield takeEvery(PIPELINES_CONFIGURATIONS_REQUEST, postConfigurations);
}
function* watchPipelinesModalRequest() {
    yield takeEvery(PIPELINES_MODAL_REQUEST, fetchPipelinesMeta);
}
function* watchPipelinesConfirmRequest() {
    yield takeEvery(PIPELINES_CONFIRM_REQUEST, postPipeline);
}
export function* pipelinesSaga() {
    yield all([
        fork(watchPipelinesConfigurationsRequest),
        fork(watchPipelinesModalRequest),
        fork(watchPipelinesConfirmRequest),
    ]);
}
