export const palette = {
    background: {
        default: '#F2F2F2',
    },
    primary: {
        main: '#03256C',
        dark: '#02194B',
        light: '#E5E9F0',
    },
    secondary: {
        main: '#7CEA9C',
        light: '#D7F8E1',
        dark: '#6FD28C',
    },
    error: {
        main: '#ED002F',
    },
    info: {
        main: '#3D52D5',
        light: '#D8DCF6',
        dark: '#24317F',
    },
    success: {
        main: '#DCED31',
        light: '#EDF698',
        dark: '#C6D52C',
    },
    common: {
        black: '#0D1225',
        white: '#fff',
    },
    text: {
        primary: '#0D1225',
        secondary: '#757575',
    },
    grey: {
        A100: '#F2F2F2', // Gray Light
        A200: '#D8D8D8',
        '300': '#F4F4F4',
        A400: '#757575', // Gray Very Dark
        A700: '#9B9B9B',
    },
};
export const colors = {
    lightLime: 'rgba(237,246,152,0.5)',
    alertDangerDark: '#A50020',
    amaranth: '#ed254e',
    greyF9: '#f9f9f9',
    paleGrey: '#f2f7fb',
    warningLight: '#fcef9f',
};
