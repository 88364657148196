import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Button from '@mui/material/Button';
import { useEffect } from 'react';
import ApprovedOutlined from '../../../assets/approvedOutlined.svg';
import ApprovedFilled from '../../../assets/approvedWhite.svg';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';
import { $InvoiceApproval, $invoiceApprovalSlice } from '../state/invoiceApproval/InvoiceApprovalState';
const buttonPrimaryColor = '#6DC260';
const onClick = (dispatch, approved) => () => {
    dispatch($InvoiceApproval.toggleInvoiceApproval({
        approved: !approved,
    }));
};
export const ApproveButton = ({ invoiceId }) => {
    const dispatch = useAppDispatch();
    const isApproved = useAppSelector($invoiceApprovalSlice.selector.isApproved);
    useEffect(() => {
        dispatch($InvoiceApproval.start({ invoiceId }));
        return () => {
            dispatch($InvoiceApproval.stop());
        };
    }, [dispatch, invoiceId]);
    return (_jsxs(_Fragment, { children: [isApproved === undefined && (_jsx(Button, { sx: { borderColor: buttonPrimaryColor, marginBottom: '16px' }, disabled: true, variant: "outlined", children: "Approva fattura" })), isApproved === true && (_jsx(Button, { sx: {
                    borderColor: buttonPrimaryColor,
                    color: '#fff',
                    marginBottom: '16px',
                    '&:hover': {
                        backgroundColor: buttonPrimaryColor,
                        borderColor: buttonPrimaryColor,
                    },
                    backgroundColor: buttonPrimaryColor,
                }, onClick: onClick(dispatch, isApproved), startIcon: _jsx(ApprovedFilled, {}), variant: "outlined", children: "Approva fattura" })), isApproved === false && (_jsx(Button, { sx: {
                    borderColor: buttonPrimaryColor,
                    marginBottom: '16px',
                    color: buttonPrimaryColor,
                    '&:hover': {
                        backgroundColor: 'inherit',
                        borderColor: buttonPrimaryColor,
                    },
                }, onClick: onClick(dispatch, isApproved), startIcon: _jsx(ApprovedOutlined, {}), variant: "outlined", children: "Approva fattura" }))] }));
};
