import { either } from 'fp-ts';
import { all, call, cancel, debounce, put, select, take, takeEvery, takeLatest, takeLeading } from 'typed-redux-saga';
import { isUserAuthorized } from '../../../authorization/useAuthorization';
import { PERMISSION, SecurityState } from '../../../store/security/SecurityState';
import { InvoiceOutputUtils } from '../../models/InvoiceOutput/InvoiceOutput.codec';
import { InvoiceStatusCodeUtils } from '../../models/InvoiceStatusCode/InvoiceStatusCode';
import { PaginationUtils } from '../../models/Pagination/Pagination.codec';
import { downloadInvoice } from '../../web/downloadInvoice';
import { getInvoiceList } from '../../web/getInvoiceList';
import { getUser } from '../../web/getUser';
import { getUserList } from '../../web/getUserList';
import { $InvoiceHistory, $invoiceHistorySlice } from './InvoiceHistoryState';
function* download(action) {
    yield* put($InvoiceHistory.InvoiceDownloadRequested());
    const downloadEither = yield* call(downloadInvoice(action.payload));
    if (either.isLeft(downloadEither)) {
        yield* put($InvoiceHistory.InvoiceNotDownloaded());
    }
    else {
        yield* put($InvoiceHistory.InvoiceDownloaded());
    }
}
function* fetchUserList(invoiceHistoryForm) {
    yield* put($InvoiceHistory.UserListRequested());
    const users = yield* call(getUserList({
        fullName: invoiceHistoryForm.fullName,
    }));
    if (either.isLeft(users)) {
        yield* put($InvoiceHistory.UserListNotFetched());
    }
    else {
        yield* put($InvoiceHistory.UserListFetched(users.right));
    }
}
function* fetchInvoiceList(pagination, invoiceHistoryTableFilter) {
    const userPermissionList = yield* select(SecurityState.selector.permissionList);
    const canViewAssigneeTableRow = isUserAuthorized(userPermissionList)(PERMISSION.VIEW_ASSIGNEE_TABLE_ROW);
    yield* put($InvoiceHistory.InvoiceListWithUserListRequested());
    const invoices = yield* call(getInvoiceList(Object.assign({ statusCode: [InvoiceStatusCodeUtils.TRANSMITTED], sort: '-_timestamps.updatedAt', page: {
            limit: pagination.limit,
            offset: pagination.offset,
        } }, invoiceHistoryTableFilter)));
    if (either.isLeft(invoices)) {
        yield* put($InvoiceHistory.InvoiceListWithUserListNotFetched());
    }
    else {
        if (canViewAssigneeTableRow) {
            const userIdList = InvoiceOutputUtils.uniqueUserIdList(invoices.right);
            const userList = [];
            for (let i = 0; i <= userIdList.length - 1; i++) {
                const user = yield* call(getUser(userIdList[i]));
                if (either.isLeft(user)) {
                    throw user.left;
                }
                else {
                    userList.push(user.right);
                }
            }
            yield* put($InvoiceHistory.InvoiceListWithUserListFetched({
                userList: userList,
                invoiceList: invoices.right,
                pagination: {
                    limit: pagination.limit,
                    offset: pagination.offset,
                },
                tableFilter: invoiceHistoryTableFilter,
            }));
        }
        else {
            yield* put($InvoiceHistory.InvoiceListWithUserListFetched({
                userList: [],
                invoiceList: invoices.right,
                pagination: {
                    limit: pagination.limit,
                    offset: pagination.offset,
                },
                tableFilter: invoiceHistoryTableFilter,
            }));
        }
    }
}
function* fetchNextInvoicePage() {
    const pagination = yield* select($invoiceHistorySlice.selector.pagination);
    const invoiceHistoryTableFilter = yield* select($invoiceHistorySlice.selector.filter);
    yield* put($InvoiceHistory.fetchInvoiceListWithUserList({
        tableFilter: invoiceHistoryTableFilter,
        pagination: {
            limit: pagination.limit,
            offset: PaginationUtils.increaseOffset($invoiceHistorySlice.PAGE_LIMIT)(pagination),
        },
    }));
}
function* fetchPreviousInvoicePage() {
    const pagination = yield* select($invoiceHistorySlice.selector.pagination);
    const invoiceHistoryTableFilter = yield* select($invoiceHistorySlice.selector.filter);
    const offset = PaginationUtils.decreaseOffset($invoiceHistorySlice.PAGE_LIMIT)(pagination);
    if (offset >= 0) {
        yield* put($InvoiceHistory.fetchInvoiceListWithUserList({
            tableFilter: invoiceHistoryTableFilter,
            pagination: {
                limit: pagination.limit,
                offset: offset,
            },
        }));
    }
}
function* fetchFirstInvoicePage() {
    const invoiceHistoryTableFilter = yield* select($invoiceHistorySlice.selector.filter);
    yield* put($InvoiceHistory.fetchInvoiceListWithUserList({
        tableFilter: invoiceHistoryTableFilter,
        pagination: {
            limit: $invoiceHistorySlice.PAGE_LIMIT,
            offset: 0,
        },
    }));
}
export function* InvoiceHistorySaga() {
    yield* takeLatest($InvoiceHistory.start, function* () {
        yield* put($InvoiceHistory.Started());
        const task = yield* all([
            yield* takeEvery($InvoiceHistory.downloadInvoice, download),
            yield* takeLatest($InvoiceHistory.fetchInvoiceListWithUserList, function* (fetchInvoiceListCommand) {
                yield* call(fetchInvoiceList, fetchInvoiceListCommand.payload.pagination, fetchInvoiceListCommand.payload.tableFilter);
            }),
            yield* debounce(1000, $InvoiceHistory.fetchUserList, function* (fetchUserListCommand) {
                yield* call(fetchUserList, fetchUserListCommand.payload.invoiceHistoryForm);
            }),
            yield* takeLeading($InvoiceHistory.fetchNextInvoicePage, function* () {
                yield* call(fetchNextInvoicePage);
            }),
            yield* takeLeading($InvoiceHistory.fetchPreviousInvoicePage, function* () {
                yield* call(fetchPreviousInvoicePage);
            }),
            yield* takeLeading($InvoiceHistory.fetchFirstInvoicePage, fetchFirstInvoicePage),
        ]);
        yield* put($InvoiceHistory.fetchInvoiceListWithUserList({
            pagination: {
                limit: $invoiceHistorySlice.PAGE_LIMIT,
                offset: 0,
            },
        }));
        yield* take($InvoiceHistory.stop);
        yield* cancel(task);
        yield* put($InvoiceHistory.Stopped());
    });
}
