import { createSlice } from '@reduxjs/toolkit';
import { array, option, readonlyArray } from 'fp-ts';
import { constFalse, constTrue, constant, identity, pipe } from 'fp-ts/function';
import { PaginationUtils } from '../models/Pagination/Pagination.codec';
const PAGE_LIMIT = 10;
const initialStateTablePagination = {
    size: PAGE_LIMIT,
    offset: 0,
    currentPage: 0,
    isNextPageDisabled: false,
    isPreviousPageDisabled: false,
};
const commissionPlanFormInitialValues = { effectiveDate: new Date(), notes: '', file: null };
const initialState = {
    commissionPlanFormInitialValues: commissionPlanFormInitialValues,
    linkedPaperworksInitialValues: { paperworkId: '' },
    paperworksSearch: null,
    paperworksToLink: [],
    loading: { upload: false, table: false, paperworks: false, update: false },
    commissionPlanTable: [],
    tablePagination: initialStateTablePagination,
    commissionPlan: null,
};
const isNextPageDisabled = (pagination, commissionPlans) => commissionPlans.length < pagination.size;
const tablePagination = (commissionPlans, pagination) => (Object.assign(Object.assign({}, pagination), { currentPage: PaginationUtils.currentPage(pagination), isNextPageDisabled: isNextPageDisabled(pagination, commissionPlans), isPreviousPageDisabled: !PaginationUtils.canGoBack(pagination) }));
const getCommissionPlansTable = (commissionsPlan) => {
    return pipe(commissionsPlan, readonlyArray.map(commissionPlan => ({
        fileId: commissionPlan.id,
        effectiveDate: new Date(commissionPlan.validFrom),
        notes: commissionPlan.description || '',
        uploadedBy: `${commissionPlan.uploadedBy.firstName} ${commissionPlan.uploadedBy.lastName}`,
        paperworkIds: commissionPlan.recordIds,
    })), readonlyArray.toArray);
};
const getPaperworks = (paperworkIds, artooPaperworks, paperworksSearch, paperworksToLink) => pipe(artooPaperworks, readonlyArray.map(artooPaperwork => ({
    id: artooPaperwork.id.toString(),
    productType: artooPaperwork.data.tipo_di_prodotto,
    agentFullName: `${artooPaperwork.agenti[0].first_name} ${artooPaperwork.agenti[0].last_name}`,
    isChecked: pipe(paperworkIds, array.findFirst(linkedPaperworkId => linkedPaperworkId === artooPaperwork.id.toString()), option.match(() => pipe([...(paperworksSearch || []), ...paperworksToLink], array.findFirst(paperwork => paperwork.id === artooPaperwork.id.toString()), option.match(constFalse, ({ isChecked }) => isChecked)), constTrue)),
})), readonlyArray.toArray);
const replacePaperwork = (index, paperworkToReplace) => (paperworks) => [
    ...paperworks.slice(0, index),
    Object.assign(Object.assign({}, paperworkToReplace), { isChecked: !paperworkToReplace.isChecked }),
    ...paperworks.slice(index + 1),
];
const toggleSearchPaperwork = (searchPaperworks, paperwork) => {
    const paperworkToToggleIndex = searchPaperworks.findIndex(({ id }) => paperwork.id === id);
    const paperworkToToggle = searchPaperworks[paperworkToToggleIndex];
    return paperworkToToggle !== undefined
        ? replacePaperwork(paperworkToToggleIndex, paperworkToToggle)(searchPaperworks)
        : [];
};
const toggleLinkedPaperwork = (linkedPaperworks, paperwork) => {
    const paperworkToToggleIndex = linkedPaperworks.findIndex(({ id }) => paperwork.id === id);
    const paperworkToToggle = linkedPaperworks[paperworkToToggleIndex];
    return paperworkToToggle
        ? replacePaperwork(paperworkToToggleIndex, paperworkToToggle)(linkedPaperworks)
        : [...linkedPaperworks, Object.assign(Object.assign({}, paperwork), { isChecked: !paperwork.isChecked })];
};
export const commissionPlanSlice = createSlice({
    name: 'commissionPlan',
    initialState,
    reducers: {
        start: identity,
        stop: identity,
        uploadNewCommissionPlan: (state, _) => state,
        fetchNextCommissionPlanPage: identity,
        fetchPreviousCommissionPlanPage: identity,
        fetchFirstCommissionPlanPage: identity,
        replaceCommissionPlan: (state, _) => state,
        CommissionPlanReplacementRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { upload: true }) })),
        CommissionPlanReplaced: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { upload: false }) })),
        CommissionPlanNotReplaced: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { upload: false }) })),
        selectCommissionPlan: (state, _) => state,
        CommissionPlanSelected: (state, event) => (Object.assign(Object.assign({}, state), { commissionPlan: event.payload.commissionPlan, commissionPlanFormInitialValues: event.payload.commissionPlan
                ? Object.assign(Object.assign({}, state.commissionPlanFormInitialValues), { notes: event.payload.commissionPlan.notes, effectiveDate: new Date(event.payload.commissionPlan.effectiveDate), file: null }) : commissionPlanFormInitialValues })),
        resetLinkedPaperworkSearch: identity,
        LinkedPaperworkSearchResetted: state => (Object.assign(Object.assign({}, state), { linkedPaperworksInitialValues: { paperworkId: '' }, paperworksSearch: null, paperworksToLink: [] })),
        updateCommissionPlan: (state, _) => state,
        CommissionPlanUpdateRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { update: true }) })),
        CommissionPlanUpdated: state => (Object.assign(Object.assign({}, state), { paperworks: [], loading: Object.assign(Object.assign({}, state.loading), { update: false }) })),
        CommissionPlanNotUpdated: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { update: false }) })),
        togglePaperwork: (state, _) => state,
        PaperworkToggled: (state, event) => (Object.assign(Object.assign({}, state), { paperworksSearch: toggleSearchPaperwork(event.payload.paperworks, event.payload.paperworkToToggle), paperworksToLink: toggleLinkedPaperwork(state.paperworksToLink, event.payload.paperworkToToggle) })),
        fetchPaperworksByPaperworkId: (state, _) => state,
        PaperworksRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperworks: true }) })),
        PaperworksFetched: (state, event) => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperworks: false }), paperworksSearch: getPaperworks(event.payload.paperworkIds, event.payload.artooPaperworks, event.payload.paperworksSearch, event.payload.paperworksToLink) })),
        PaperworksNotFetched: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { paperworks: false }) })),
        CommissionPlansTableRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { table: true }) })),
        EmptyCommissionPlansTableFetched: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { table: false }), tablePagination: Object.assign(Object.assign({}, state.tablePagination), { isNextPageDisabled: true }) })),
        CommissionPlansTableFetched: (state, event) => (Object.assign(Object.assign({}, state), { commissionPlanTable: getCommissionPlansTable(event.payload.commissionPlans), tablePagination: tablePagination(event.payload.commissionPlans, event.payload.pagination), loading: Object.assign(Object.assign({}, state.loading), { table: false }) })),
        CommissionPlansTableNotFetched: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { table: false }) })),
        NewCommissionPlanRequested: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { upload: true }) })),
        NewCommissionPlanUploaded: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { upload: false }) })),
        NewCommissionPlanNotUploaded: state => (Object.assign(Object.assign({}, state), { loading: Object.assign(Object.assign({}, state.loading), { upload: false }) })),
        Stopped: constant(initialState),
        Started: identity,
    },
});
export const $CommissionPlan = commissionPlanSlice.actions;
export const $CommissionPlanSlice = {
    PAGE_LIMIT,
    selector: {
        commissionPlan: (state) => state.commissionPlan.commissionPlan,
        commissionPlanFormInitialValues: (state) => state.commissionPlan.commissionPlanFormInitialValues,
        linkedPaperworksInitialValues: (state) => state.commissionPlan.linkedPaperworksInitialValues,
        paperworksSearch: (state) => state.commissionPlan.paperworksSearch,
        isUploadingCommissionPlan: (state) => state.commissionPlan.loading.upload,
        commissionPlanTable: (state) => state.commissionPlan.commissionPlanTable,
        pagination: (state) => state.commissionPlan.tablePagination,
        isPaperworksLoading: (state) => state.commissionPlan.loading.paperworks,
        isCommissionsPlanTableLoading: (state) => state.commissionPlan.loading.table,
        isUpdatingCommissionPlan: (state) => state.commissionPlan.loading.update,
        paperworksToLink: (state) => state.commissionPlan.paperworksToLink,
    },
};
export const CommissionPlanState = commissionPlanSlice.reducer;
