import { JOB_VALUES } from '../models/JobModel';
const jobList = [
    {
        label: 'Dipendente a tempo indeterminato',
        value: JOB_VALUES.permanentEmployee,
    },
    {
        label: 'Dipendente a tempo determinato',
        value: JOB_VALUES.fixedTermEmployee,
    },
    {
        label: 'Autonomo con P.IVA',
        value: JOB_VALUES.selfEmployedWithVatNumber,
    },
    {
        label: 'Lavoratore autonomo',
        value: JOB_VALUES.selfEmployed,
    },
    {
        label: 'Libero professionista',
        value: JOB_VALUES.freelance,
    },
    {
        label: 'Pensionato',
        value: JOB_VALUES.pensioner,
    },
    {
        label: 'Casalinga',
        value: JOB_VALUES.homemaker,
    },
    {
        label: 'Studente',
        value: JOB_VALUES.student,
    },
    {
        label: 'Reddito non dimostrabile',
        value: JOB_VALUES.indemonstrableIncome,
    },
    {
        label: 'Disoccupato',
        value: JOB_VALUES.unemployed,
    },
    {
        label: 'Amministratore di società',
        value: JOB_VALUES.director,
    },
    {
        label: 'Colf/Collaboratore',
        value: JOB_VALUES.domestic,
    },
    {
        label: 'Frontaliere',
        value: JOB_VALUES.borderWorker,
    },
    {
        label: 'Altro',
        value: JOB_VALUES.other,
    },
];
export const jobView = {
    jobList,
};
