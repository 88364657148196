import { either } from 'fp-ts';
import { all, call, cancel, put, take, takeLatest, takeLeading } from 'typed-redux-saga';
import { NotificationAction } from '../../../store/notification/NotificationAction';
import { Notification } from '../../../utils/notification';
import { approveInvoice } from '../../web/approveInvoice';
import { $InvoiceApproval } from './InvoiceApprovalState';
function* toggleApproval({ approved, invoiceId }) {
    try {
        yield* put($InvoiceApproval.InvoiceApprovalToggleRequested());
        const response = yield* call(approveInvoice({
            invoiceApprovalToSet: approved ? 'approve' : 'reject',
            invoiceId: invoiceId,
        }));
        if (either.isLeft(response)) {
            throw response.left;
        }
        yield* put($InvoiceApproval.InvoiceApprovalToggled(response.right.approved));
        if (response.right.approved) {
            yield* put(NotificationAction.Push(Notification.info('Fattura approvata')));
        }
    }
    catch (e) {
        yield* put($InvoiceApproval.InvoiceApprovalNotToggled(e));
    }
}
export function* InvoiceApproval() {
    yield* takeLeading($InvoiceApproval.start, function* (startAction) {
        yield* put($InvoiceApproval.Started());
        const task = yield* all([
            yield* takeLatest($InvoiceApproval.toggleInvoiceApproval, function* (toggleInvoiceApprovalAction) {
                yield* call(toggleApproval, {
                    invoiceId: startAction.payload.invoiceId,
                    approved: toggleInvoiceApprovalAction.payload.approved,
                });
            }),
        ]);
        yield* take($InvoiceApproval.stop);
        yield* cancel(task);
        yield* put($InvoiceApproval.Stopped());
    });
}
